import { useHistory } from "react-router-dom";
import { NotificationInfo } from "../../../../system/types/Notification";
import { useState } from "react";
import { Grid } from "@mui/material";
import {
  MobileTableContentBox,
  MobileTableContentDetailBox,
  MobileTableContentsBox,
  MobileTableTitleBox,
} from "../../../../styles/theme";
import { SortAscending } from "@phosphor-icons/react";
import { GRAYPALEDISABLED } from "../../../../styles/Color";
import uuid from "react-uuid";
import { isoDateFormatter } from "../../../../components/Common/isoDateFormatter";
import { notiTypeFormatter } from "../../../../components/Common/notiTypeFormatter";
import { CustomButtonGreen } from "../../../../styles/buttonStyle";

interface NotiMobileListProps {
  notiData: NotificationInfo[];
}

function NotiMobileList({ notiData }: NotiMobileListProps) {
  const history = useHistory();
  const [detailInfoId, setDetailInfoId] = useState(0);
  const [infoOpen, setInfoOpen] = useState(false);
  function onChangeDetailInfoId(index: number) {
    if (infoOpen && index !== detailInfoId) {
      setDetailInfoId(index);
    } else {
      setInfoOpen(!infoOpen);
      setDetailInfoId(index);
    }
  }

  function onMove(path: string) {
    history.push(`/${path}`);
  }

  return (
    <Grid style={{ marginTop: "10px" }}>
      <MobileTableTitleBox container alignItems="center">
        <SortAscending size={32} style={{ color: GRAYPALEDISABLED }} />
      </MobileTableTitleBox>
      <Grid>
        {notiData.map((dt: NotificationInfo, index: number) => (
          <Grid key={uuid()}>
            <MobileTableContentsBox
              container
              onClick={() => onChangeDetailInfoId(index)}
            >
              <MobileTableContentBox item xs={3}>
                {notiTypeFormatter(dt.type)}
              </MobileTableContentBox>
              <MobileTableContentBox item xs={6}>
                {dt.body}
              </MobileTableContentBox>
              <MobileTableContentBox item xs={3}>
                {isoDateFormatter(new Date(dt.createdAt))}
              </MobileTableContentBox>
            </MobileTableContentsBox>
            {infoOpen && detailInfoId === index && (
              <Grid container>
                <MobileTableContentDetailBox item xs={4}>
                  유형
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={8}>
                  {notiTypeFormatter(dt.type)}
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={4}>
                  알림내역
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={8}>
                  {dt.title}
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={4}>
                  작성된 내용
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={8}>
                  {dt.body}
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={4}>
                  작성 날짜
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={8}>
                  {isoDateFormatter(new Date(dt.createdAt))}
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={4}>
                  확인 여부
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={8}>
                  {dt.isRead ? "읽음" : "안 읽음"}
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={12}>
                  <CustomButtonGreen onClick={() => onMove(dt.path)}>
                    자세히 보기
                  </CustomButtonGreen>
                </MobileTableContentDetailBox>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default NotiMobileList;
