import { Box, Grid, Modal } from "@mui/material";
import {
  DropDownListComponentBox,
  DropDownListComponentGridBox,
  InputBox,
  ModalStyle,
  PointerItem,
} from "../../../../../styles/theme";
import { CustomText } from "../../../../../styles/CustomText";
import { GREENTITLE, REDTITLE } from "../../../../../styles/Color";
import { CheckCircle, X } from "@phosphor-icons/react";
import { CustomButtonGreen } from "../../../../../styles/buttonStyle";
import { EmployeeInfo } from "../../../../../types/Employee/Employee";
import { GroupInfo } from "../../../../../types/Group/group";
import { AlertModal } from "../../../../../components/Common/AlertModal";
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from "react";
import { GroupApi } from "../../../../../system/api/groupApi";
import { ErrorHandler } from "../../../../../system/ApiService";

const itemTemplate = (data: EmployeeInfo) => {
  return (
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={4}>
        {data.name}
      </Grid>
      <Grid item container xs={8} justifyContent="right">
        {data.jobTitle?.name}{" "}
        {data.jobTitle?.name && data.jobPosition?.name && "/"}{" "}
        {data.jobPosition?.name}
      </Grid>
    </Grid>
  );
};

const valueTemplate = (data: EmployeeInfo) => {
  return (
    <CustomText type="subtitle" bold400 color="#000000">
      {data.name}
    </CustomText>
  );
};

type AdminChildrenGroupAddModalProps = {
  isOpen: boolean;
  handleAddModal: (status: boolean) => void;
  groupInfo: GroupInfo;
  employeeList: EmployeeInfo[];
  childrenGroupInfo: GroupInfo;
  setChildrenGroupInfo: Dispatch<SetStateAction<GroupInfo>>;
  refreshData: () => void;
};

const AdminChildrenGroupAddModal = ({
  isOpen,
  handleAddModal,
  groupInfo,
  employeeList,
  childrenGroupInfo,
  setChildrenGroupInfo,
  refreshData,
}: AdminChildrenGroupAddModalProps) => {
  // name값이 비어있는지 체크
  const checkNameValidate = useCallback((data: GroupInfo) => {
    if (data.name === "") {
      AlertModal("check", "이름을 작성해 주세요.");
      return false;
    }
    return true;
  }, []);

  // Handle Input Change
  const handleInputChange = useCallback(
    (event) => {
      setChildrenGroupInfo({
        ...childrenGroupInfo,
        [(event.target as HTMLInputElement).name]: event.target.value,
      });
    },
    [childrenGroupInfo]
  );

  // Handle Dropdown Change
  const handleDropdownChange = useCallback(
    (event) => {
      setChildrenGroupInfo({
        ...childrenGroupInfo,
        [(event.target as HTMLInputElement).name]: event.target.itemData,
      });
    },
    [childrenGroupInfo]
  );

  const positionFields = { text: "name", value: "id" };

  // 저장 버튼 - 생성
  const handlePostGroupInfo = useCallback(() => {
    if (checkNameValidate(childrenGroupInfo)) {
      GroupApi.createGroupInfo(childrenGroupInfo)
        .then(() => {
          refreshData();
          handleAddModal(false);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [checkNameValidate, childrenGroupInfo, handleAddModal, refreshData]);

  // Enter시 저장 수행
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        handlePostGroupInfo();
      }
    },
    [handlePostGroupInfo]
  );

  return (
    <Modal open={isOpen} onClose={() => handleAddModal(false)}>
      <Box sx={ModalStyle}>
        <Grid container spacing={2}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            item
            xs={12}
          >
            <CustomText type="title">하위 그룹 생성</CustomText>

            <PointerItem onClick={() => handleAddModal(false)}>
              <X size={32} />
            </PointerItem>
          </Grid>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="4px">이름</Grid>
              {childrenGroupInfo.name === "" ? (
                <CustomText type="small" color={REDTITLE}>
                  * 이름을 입력해 주세요.
                </CustomText>
              ) : (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              )}
            </Grid>
            <InputBox
              type="text"
              name="name"
              onChange={(e) => {
                handleInputChange(e);
              }}
              value={childrenGroupInfo.name || ""}
              placeholder="이름"
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="4px">그룹 관리자</Grid>
              {childrenGroupInfo.manager?.name === "" ? (
                <CustomText type="small" color={REDTITLE}>
                  * 그룹 관리자를 선택해주세요.
                </CustomText>
              ) : (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              )}
            </Grid>
            <DropDownListComponentGridBox>
              <DropDownListComponentBox
                dataSource={employeeList}
                fields={positionFields}
                name="manager"
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  handleDropdownChange(e);
                }}
                value={childrenGroupInfo.manager?.id}
                placeholder="그룹 관리자를 선택해주세요."
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                autoComplete="new-password"
              />
            </DropDownListComponentGridBox>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="right" mt="20px">
          <CustomButtonGreen onClick={handlePostGroupInfo}>
            저장
          </CustomButtonGreen>
        </Grid>
      </Box>
    </Modal>
  );
};
export default AdminChildrenGroupAddModal;
