import { Backdrop } from "@mui/material";
import HashLoader from "react-spinners/HashLoader";
import { useRecoilValue } from "recoil";
import { LoadingAtom } from "../../system/atoms";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "rgba(255, 255, 255, 0.7)",
  borderWidth: "10px",
};

export default function LoadingSpinnerModal() {
  const loadingState = useRecoilValue(LoadingAtom);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loadingState}
    >
      <HashLoader
        color={"#971B2F"}
        loading={true}
        cssOverride={override}
        size={40}
      />
    </Backdrop>
  );
}
