import client from "../ApiService";
import { _BasicApi } from "../BasicApi";
import { ContactTypeListInfo, DropdownListInfo } from "../types/List";

const listUri: string = "/list";

class _ListApi extends _BasicApi {
  getDropdownList(type: string, searchText?: string) {
    return this.client.get(`${listUri}/${type}`, {
      params: { searchText: searchText },
    });
  }

  updateDropdownList(data: DropdownListInfo) {
    return this.client.put(`${listUri}`, data);
  }
  createDropdownList(data: DropdownListInfo) {
    return this.client.post(`${listUri}`, data);
  }
  deleteDropdownList(id: number) {
    return this.client.delete(`${listUri}`, { params: { id: id } });
  }
  getContactTypeList(searchText?: string) {
    return this.client.get(`${listUri}/contact`, {
      params: { searchText: searchText },
    });
  }

  updateContactTypeList(data: ContactTypeListInfo) {
    return this.client.put(`${listUri}/contact`, data);
  }
  createContactTypeList(data: ContactTypeListInfo) {
    return this.client.post(`${listUri}/contact`, data);
  }
  deleteContactTypeList(id: number) {
    return this.client.delete(`${listUri}/contact`, { params: { id: id } });
  }
}

export const ListApi = new _ListApi(client);
