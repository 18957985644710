import { L10n } from "@syncfusion/ej2-base";
import { useCallback, useEffect, useState } from "react";
import { CustomButtonGreen } from "../../../../styles/buttonStyle";
import { Box, Grid, Modal } from "@mui/material";
import { ModalStyle, SearchBoxComponent } from "../../../../styles/theme";
import AdminUserModal from "./AdminUserModal";

import { InitEmployeeInfo } from "../../../../system/types/initObject";
import { ErrorHandler } from "../../../../system/ApiService";
import { AlertModal } from "../../../../components/Common/AlertModal";
import { GroupApi } from "../../../../system/api/groupApi";
import { EmployeeApi } from "../../../../system/api/employeeApi";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {
  Pc,
  TabletMobile,
} from "../../../../components/Common/Responseive/ResponsiveScreen";
import AdminUserTablePc from "./AdminUserTablePc";
import AdminUserTableMobile from "./AdminUserTableMobile";
import {
  EmployeeInfo,
  EmployeeTableInfo,
} from "../../../../types/Employee/Employee";
import { GroupInfo } from "../../../../types/Group/group";

L10n.load({
  ko: {
    grid: {
      EmptyRecord: "조회된 데이터가 없습니다.",
      EmptyDataSourceError: "조회된 데이터가 없습니다.",
      Sort: "정렬",
      Clear: "초기화",
      SortAscending: "오름차순 정렬",
      SortDescending: "내림차순 정렬",
      None: "없음",
      Ascending: "오름차순",
      Descending: "내림차순",
      OKButton: "적용",
      CancelButton: "취소",
      StartsWith: "시작하는 문자",
      EndsWith: "끝나는 문자",
      Contains: "포함",
      Equal: "같음",
      NotEqual: "같지 않음",
      LessThan: "보다 적은",
      LessThanOrEqual: "보다 같거나 적은",
      GreaterThan: "보다 많은",
      GreaterThanOrEqual: "보다 같거나 많은",
      ChooseDate: "날짜를 선택해주세요.",
      EnterValue: "검색어를 입력해주세요.",
      SelectAll: "모두 선택",
      Blanks: "비어있음",
      NoResult: "결과가 없습니다.",
      ClearFilter: "초기화",
      TextFilter: "문구 필터",
      NumberFilter: "숫자 필터",
      DateFilter: "날짜 필터",
      DateTimeFilter: "날짜 시간 필터",
      Between: "사이에",
      CustomFilter: "사용자 정의 필터",
      CustomFilterPlaceHolder: "검색어를 입력해주세요.",
      CustomFilterDatePlaceHolder: "날짜를 선택해주세요.",
      AND: "그리고",
      OR: "또는",
      ShowRowsWhere: "다음 조건의 행을 표시",
      MatchCase: "대소문자 구분",
      SortAtoZ: "오름차순",
      SortZtoA: "내림차순",
      SortByOldest: "오래된 순",
      SortByNewest: "최신 순",
      AddCurrentSelection: "필터에 현재 선택 항목 추가",
      Search: "검색할 문구를 입력해주세요.",
    },
    pager: {
      currentPageInfo: "{1} 페이지 중 {0}",
      totalItemsInfo: "(총 {0} 명)",
      firstPageTooltip: "첫 페이지로 이동",
      lastPageTooltip: "마지막 페이지로 이동",
      nextPageTooltip: "다음 페이지로 이동",
      previousPageTooltip: "이전 페이지로 이동",
      nextPagerTooltip: "다음 페이지로",
      previousPagerTooltip: "이전 페이지로",
    },
  },
});

function AdminUserTable() {
  const [modalType, setModalType] = useState("");
  const [data, setData] = useState<EmployeeTableInfo[]>([]);
  const [employeeData, setEmployeeData] =
    useState<EmployeeInfo>(InitEmployeeInfo);
  const [preSearchWord, setPreSearchWord] = useState("");
  const [searchWord, setSearchWord] = useState("");

  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setEmployeeData(InitEmployeeInfo);
    setOpen(false);
  };

  const [groupData, setGroupData] = useState<GroupInfo[]>([]);
  const moveToAddEmployee = () => {
    setModalType("추가");
    setOpen(true);
  };

  const onChange = (e: any) => {
    setPreSearchWord(e.target.value);
  };

  const moveToUpdateEmployee = (props: EmployeeInfo) => {
    setEmployeeData({
      ...InitEmployeeInfo,
      id: props.id,
      email: props.email,
      name: props.name,
      phone: props.phone,
      picture: props.picture,
      jobTitle: props.jobTitle,
      jobPosition: props.jobPosition,
      group: props.group,
      role: props.role,
      isResignation: props.isResignation,
      enabled: props.enabled,
      isSales: props.isSales,
    });
    setModalType("수정");
    setOpen(true);
  };

  const refreshEmployeeData = useCallback(() => {
    // 데이터 조회
    EmployeeApi.GetEmployee(searchWord)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchWord]);

  const ClickSearch = () => {
    setSearchWord(preSearchWord);
  };

  useEffect(() => {
    refreshEmployeeData();
    GroupApi.getAllGroupInfo()
      .then((res) => {
        setGroupData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [refreshEmployeeData]);

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      ClickSearch();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="right">
          <SearchBoxComponent
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="검색어를 입력하세요."
              inputProps={{ "aria-label": "검색어를 입력하세요." }}
              onChange={onChange}
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={ClickSearch}
            >
              <SearchIcon />
            </IconButton>
          </SearchBoxComponent>
          <CustomButtonGreen
            onClick={moveToAddEmployee}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            계정 추가
          </CustomButtonGreen>
        </Grid>

        <Pc>
          <AdminUserTablePc
            data={data}
            moveToUpdateEmployee={moveToUpdateEmployee}
          />
        </Pc>
        <TabletMobile>
          <AdminUserTableMobile
            data={data}
            moveToUpdateEmployee={moveToUpdateEmployee}
          />
        </TabletMobile>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <AdminUserModal
              employeeData={employeeData}
              setEmployeeData={setEmployeeData}
              modalType={modalType}
              handleClose={handleClose}
              refreshEmployeeData={refreshEmployeeData}
              groupData={groupData}
            />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
}

export default AdminUserTable;
