import { useCallback, useEffect, useState } from "react";
import { AdminGroupItemBox } from "../../../../../styles/adminStyle";
import { useGenerateEditColumnList } from "../../../../../hooks/Table/useGenerateColumnList";
import AdminGroupSectionTitle from "./AdminChildrenGroupSectionTitle";
import AdminGroupTable from "./AdminGroupTable";
import { GroupInfo } from "../../../../../types/Group/group";
import { InitGroupInfo } from "../../../../../system/types/initObject";
import { EmployeeInfo } from "../../../../../types/Employee/Employee";
import AdminGroupAddModal from "./AdminChildrenGroupAddModal";
import { GoalApi } from "../../../../../api/Goal/goalApi";
import dayjs from "dayjs";
import { ErrorHandler } from "../../../../../system/ApiService";
import { AxiosError } from "axios";
import {
  GetGroupGoalsRequest,
  GetGroupGoalsResponse,
  GroupGoalResponse,
} from "../../../../../types/DashBoard/Achievement";
type AdminGroupSettingProps = {
  groupData: GroupInfo;
  refreshData: () => void;
  employeeList: EmployeeInfo[];
};
const AdminChildrenGroupSection = ({
  groupData,
  employeeList,
  refreshData,
}: AdminGroupSettingProps) => {
  const [columnList, setColumnList] = useState(
    useGenerateEditColumnList([
      { key: "name", value: "이름", isEdit: false, width: "60px" },
      { key: "salesAmount", value: "매출액", isEdit: true, type: "number" },
      {
        key: "newLeadsInbound",
        value: "신규 인바운드",
        isEdit: true,
        type: "number",
      },
      {
        key: "newLeadsOutbound",
        value: "신규 아웃바운드",
        isEdit: true,
        type: "number",
      },
      {
        key: "inboundLeadConversion",
        value: "인바운드<br/>리드전환",
        isEdit: true,
        type: "number",
      },
      {
        key: "outboundLeadConversion",
        value: "아웃바운드<br/>리드전환",
        isEdit: true,
        type: "number",
      },
    ])
  );

  const [childrenGroupInfo, setChildrenGroupInfo] = useState<GroupInfo>({
    ...InitGroupInfo,
  });
  const [childrenGroupList, setChildrenGroupList] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // 하위그룹의 모든 목표값 정보 가져오기
  const getAllChildrenGroupGoals = useCallback(async (childrenList) => {
    const result: GroupGoalResponse[] = [];
    const params = {
      year: dayjs().year(),
    };
    for (let i = 0; i < childrenList.length; i++) {
      const { id, isSaleGroup } = childrenList[i];
      if (isSaleGroup) {
        try {
          const { data } = await GoalApi.getGroupGoals<
            GetGroupGoalsRequest,
            GetGroupGoalsResponse
          >(id, params);
          const { groupGoal } = data[0];
          result.push(groupGoal);
        } catch (err) {
          const message = ErrorHandler(err as AxiosError);
          setErrorMessage(message);
        }
      }
    }
    return result;
  }, []);

  // RowList Convert
  const convertedRowList = useCallback(
    async (data) => {
      const childrenGroupGoalList = await getAllChildrenGroupGoals(data);
      // TODO: type 문제 childrenGroupList와 해결
      const result = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item.isSaleGroup) {
          const newItem = { ...item }; // item의 기존 값 유지

          const matchingGoalItem = childrenGroupGoalList.find(
            (goal) => goal.group.id === item.id
          );

          newItem.goalIsSet = matchingGoalItem ? true : false;

          if (matchingGoalItem) newItem["goalId"] = matchingGoalItem.id;

          columnList.forEach(({ key }) => {
            // groupEmployeeList에서 해당 항목의 값 가져오기
            const existingValue = newItem[key]; // groupEmployeeList에서 가져오기

            if (existingValue !== undefined) {
              // groupEmployeeList에 값이 있을 경우
              newItem[key] = existingValue;
            } else if (matchingGoalItem && key in matchingGoalItem) {
              // groupEmployeeList에 없고 matchingGoalItem에서 값을 가져올 수 있는 경우
              newItem[key] =
                matchingGoalItem[key as keyof typeof matchingGoalItem]; // matchingGoalItem에서 값 가져오기
            } else {
              // 두 곳 모두에 값이 없을 경우 0으로 초기화
              newItem[key] = 0;
            }
          });
          result.push(newItem);
        }
      }

      return result;
    },
    [columnList, getAllChildrenGroupGoals]
  );

  // handle Modal
  const handleAddModal = useCallback(
    (status: boolean) => {
      setIsOpen(status);
      if (!status) {
        setChildrenGroupInfo({
          ...InitGroupInfo,
          level: groupData.level + 1,
          parentId: groupData.id ? groupData.id : null,
        });
      }
    },
    [groupData.id, groupData.level]
  );

  // 하위 그룹 목표 조회
  const getChildrenGroupGoals = useCallback(async () => {
    const convertedData = await convertedRowList(groupData.children);
    setChildrenGroupList(convertedData);
  }, [convertedRowList, groupData.children]);

  useEffect(() => {
    setChildrenGroupInfo((prev) => ({
      ...prev,
      level: groupData.level + 1,
      parentId: groupData.id ? groupData.id : null,
    }));

    getChildrenGroupGoals();
  }, [groupData.id, groupData.level, getChildrenGroupGoals]);

  return (
    <>
      <AdminGroupItemBox container>
        <AdminGroupSectionTitle
          groupData={groupData}
          handleAddModal={handleAddModal}
        />
        <AdminGroupTable
          columnList={columnList}
          rowList={childrenGroupList}
          errorMessage={errorMessage}
          refreshData={refreshData}
        />
      </AdminGroupItemBox>
      <AdminGroupAddModal
        isOpen={isOpen}
        groupInfo={groupData}
        employeeList={employeeList}
        handleAddModal={handleAddModal}
        childrenGroupInfo={childrenGroupInfo}
        setChildrenGroupInfo={setChildrenGroupInfo}
        refreshData={refreshData}
      />
    </>
  );
};

export default AdminChildrenGroupSection;
