import { CustomText } from "../../../styles/CustomText";

function AnswerPage() {
  return (
    <>
      <CustomText type="title"> 신규 답변 내역</CustomText>
    </>
  );
}

export default AnswerPage;
