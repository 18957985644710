import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GRAYPALEBACKGROUND2, GRAYPALEBORDER } from "./Color";

export const AdminGroupListBorder = styled(Grid)({
  padding: "10px 0px 10px 5px",
  borderTop: `1px solid ${GRAYPALEBORDER}`,
});

export const AdminGroupListHoverText = styled(Grid)({
  "&:hover": { textDecoration: "underline" },
});

export const AdminGroupDivider = styled(Grid)({
  marginTop: "20px",
  borderTop: `1px solid ${GRAYPALEBORDER}`,
});

export const AdminGroupItemBox = styled(Grid)({
  marginTop: "20px",
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
});

export const AdminGroupItemBoxNoMargin = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
});

interface paddingnumberProps {
  paddingnumber: string;
}

export const AdminGroupItemBoxTitle = styled(Grid)(
  ({ paddingnumber }: paddingnumberProps) => ({
    backgroundColor: GRAYPALEBACKGROUND2,
    padding: paddingnumber,
  })
);

export const AdminGroupItemBoxContents = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  padding: "10px",
});

export const AdminGroupItemBoxContentsPadding = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  padding: "6px 10px",
});

export const TabContentBox = styled(Grid)({
  height: "70vh",
  overflow: "scroll",
  marginTop: "10px",
  msOverflowStyle: "none",
});

export const TabContentMobileBox = styled(Grid)({
  marginTop: "10px",
  width: "100vw",
});
