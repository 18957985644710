import { Chip } from "@mui/material";

const ConvertToChip = ({
  label,
  color,
  width,
}: {
  label: string;
  color: string;
  width: string;
}) => {
  return (
    <Chip
      label={label}
      size="small"
      variant="outlined"
      sx={{
        minWidth: width,
        marginRight: "4px",
        borderColor: color,
        "& .MuiChip-label": {
          color: color, // 텍스트 색상 변경
        },
      }}
    />
  );
};

export default ConvertToChip;
