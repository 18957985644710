import { TextField } from "@mui/material";
import { CommonCommonInputProps } from "../../../types/Common/CommonSearch";

const CommonInput: React.FC<CommonCommonInputProps> = ({
  label,
  value,
  onChange,
  t,
}) => {
  return (
    <TextField
      fullWidth
      value={value}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      size="small"
      margin="none"
      placeholder={t("Search Placeholder", {
        label: t(label),
        Search: t("Search"),
      })}
    />
  );
};

export default CommonInput;
