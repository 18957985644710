import { Grid } from "@mui/material";
import { LeadMyTableInfo } from "../../../../system/types/Customer";
import {
  TableBorderBox,
  TableContentBox,
  TableTitleBox,
} from "../../../../styles/theme";
import uuid from "react-uuid";
import SplitterBox from "./SplitterBox";
import { useState } from "react";

interface MySplitterListProps {
  data: LeadMyTableInfo[];
}

function MySplitterList({ data }: MySplitterListProps) {
  const [detailedId, setDetailedId] = useState<null | number>(null);

  const onChangeFocus = (id: number) => {
    if (detailedId === id) {
      setDetailedId(null);
    } else {
      setDetailedId(id);
    }
  };
  return (
    <Grid container mt="10px">
      <Grid item xs={3}>
        <TableBorderBox>
          <TableTitleBox item xs={12}>
            1개월 이내
          </TableTitleBox>
          <Grid container>
            {data
              .filter((item: LeadMyTableInfo) => item.step === "1개월이내")
              .map((dt: LeadMyTableInfo, index) => (
                <Grid key={uuid()} container>
                  <SplitterBox
                    data={dt}
                    onChangeFocus={onChangeFocus}
                    detailedId={detailedId}
                  />
                </Grid>
              ))}
            {data.filter((item: LeadMyTableInfo) => item.step === "1개월이내")
              .length === 0 && (
              <TableContentBox
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                조회된 데이터가 없습니다.
              </TableContentBox>
            )}
          </Grid>
        </TableBorderBox>
      </Grid>
      <Grid item xs={3}>
        <TableBorderBox>
          <TableTitleBox item xs={12}>
            3개월 이내
          </TableTitleBox>
          <Grid container>
            {data
              .filter((item: LeadMyTableInfo) => item.step === "3개월이내")
              .map((dt: LeadMyTableInfo, index) => (
                <Grid key={uuid()} container>
                  <SplitterBox
                    data={dt}
                    onChangeFocus={onChangeFocus}
                    detailedId={detailedId}
                  />
                </Grid>
              ))}
            {data.filter((item: LeadMyTableInfo) => item.step === "3개월이내")
              .length === 0 && (
              <TableContentBox
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                조회된 데이터가 없습니다.
              </TableContentBox>
            )}
          </Grid>
        </TableBorderBox>
      </Grid>
      <Grid item xs={3}>
        <TableBorderBox>
          <TableTitleBox item xs={12}>
            6개월 이내
          </TableTitleBox>

          <Grid container>
            {data
              .filter((item: LeadMyTableInfo) => item.step === "6개월이내")
              .map((dt: LeadMyTableInfo, index) => (
                <Grid key={uuid()} container>
                  <SplitterBox
                    data={dt}
                    onChangeFocus={onChangeFocus}
                    detailedId={detailedId}
                  />
                </Grid>
              ))}
            {data.filter((item: LeadMyTableInfo) => item.step === "6개월이내")
              .length === 0 && (
              <TableContentBox
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                조회된 데이터가 없습니다.
              </TableContentBox>
            )}
          </Grid>
        </TableBorderBox>
      </Grid>
      <Grid item xs={3}>
        <TableBorderBox>
          <TableTitleBox item xs={12}>
            12개월 이내
          </TableTitleBox>
          <Grid container>
            {data
              .filter((item: LeadMyTableInfo) => item.step === "12개월이내")
              .map((dt: LeadMyTableInfo, index) => (
                <Grid key={uuid()} container>
                  <SplitterBox
                    data={dt}
                    onChangeFocus={onChangeFocus}
                    detailedId={detailedId}
                  />
                </Grid>
              ))}
            {data.filter((item: LeadMyTableInfo) => item.step === "12개월이내")
              .length === 0 && (
              <TableContentBox
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                조회된 데이터가 없습니다.
              </TableContentBox>
            )}
          </Grid>
        </TableBorderBox>
      </Grid>
    </Grid>
  );
}
export default MySplitterList;
