import client from "../ApiService";
import { _BasicApi } from "../BasicApi";
import { AddressInfo } from "../types/Customer";

const baseUri: string = "/addresses";

class _AddressApi extends _BasicApi {
  updateAddresses(addressId: number, addressDt: AddressInfo) {
    return this.client.put(`${baseUri}/${addressId}`, addressDt);
  }

  getAddressDistrictInfo(searchType: string, siDo?: string, gunGu?: string) {
    return this.client.get(`${baseUri}/district/list`, {
      params: { searchType: searchType, siDo: siDo, gunGu: gunGu },
    });
  }
  getAddresssListInfo(siDo?: string, gunGu?: string) {
    return this.client.get(`${baseUri}/list`, {
      params: { siDo: siDo, gunGu: gunGu },
    });
  }
}

export const AddressApi = new _AddressApi(client);
