import { EmployeeInfo } from "../../types/Employee/Employee";
import client from "../ApiService";
import { _BasicApi } from "../BasicApi";

import { SalesProtectionSettingInfo } from "../types/Setting";

const baseUri: string = "/admin";

class _AdminApi extends _BasicApi {
  CreateBranchEmployee(data: EmployeeInfo) {
    return this.client.post(`${baseUri}/employee`, data);
  }
  UpdateBranchEmployee(data: EmployeeInfo) {
    return this.client.put(`${baseUri}/employee`, data);
  }
  GetSalesProtection() {
    return this.client.get(`${baseUri}/sales`);
  }
  UpdateSalesProtection(dt: SalesProtectionSettingInfo) {
    return this.client.put(`${baseUri}/sales`, dt);
  }
}

export const AdminApi = new _AdminApi(client);
