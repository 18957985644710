import { useHistory, useLocation } from "react-router-dom";

export const useMovePage = () => {
  const history = useHistory();
  const location = useLocation();

  const navigateTo = (path: string, queryParams = {}) => {
    // 현재 URL의 쿼리 파라미터 가져오기
    const currentParams = new URLSearchParams(location.search);

    // 새로 전달된 queryParams를 덮어쓰기
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        currentParams.delete(key); // null 또는 undefined일 경우 해당 파라미터 삭제
      } else {
        currentParams.set(key, String(value)); // 값이 있을 경우 파라미터 설정
      }
    });

    // 최종 쿼리 문자열 생성
    const queryString = currentParams.toString();
    const fullPath = queryString ? `${path}?${queryString}` : path;

    history.push(fullPath);
  };

  return navigateTo;
};
