import { Autocomplete, Box, Chip, Grid } from "@mui/material";
import { CustomTextField } from "../../styles/theme";
import { EmployeeInfo } from "../../types/Employee/Employee";

interface SelectMultiEmployeeProps {
  list?: EmployeeInfo[];
  onChange: (data: EmployeeInfo[]) => void;
  employeeList: EmployeeInfo[];
}

function SelectMultiEmployee({
  list,
  onChange: _onChange,
  employeeList,
}: SelectMultiEmployeeProps) {
  const auto = { width: "100%", minWidth: "500px" };

  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        options={employeeList}
        value={list}
        defaultValue={list}
        defaultChecked={true}
        disableCloseOnSelect
        onChange={(event: any, newValue: EmployeeInfo[]) => {
          _onChange(newValue);
        }}
        isOptionEqualToValue={(option: EmployeeInfo, value: EmployeeInfo) =>
          option.id === value.id
        }
        getOptionLabel={(option: EmployeeInfo) => option.name}
        renderOption={(props, option: EmployeeInfo) => (
          <Box component="li" {...props}>
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item xs={4}>
                {option.name}
              </Grid>
              <Grid item container xs={8} justifyContent="right">
                {option.jobTitle?.name}{" "}
                {option.jobTitle?.name && option.jobPosition?.name && "/"}{" "}
                {option.jobPosition?.name}
              </Grid>
            </Grid>
          </Box>
        )}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            placeholder="이름으로 검색 후 선택해 주세요."
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              variant="outlined"
            />
          ))
        }
        style={auto}
      />
    </>
  );
}

export default SelectMultiEmployee;
