import {
  LoginPageRightBox,
  LoginPageRightModalBox,
} from "../../../styles/mainStyle";

import PasswordBox from "./PasswordBox";

function PasswordRightBox() {
  return (
    <LoginPageRightBox container justifyContent="center" alignItems="center">
      <LoginPageRightModalBox container alignItems="center">
        <PasswordBox />
      </LoginPageRightModalBox>
    </LoginPageRightBox>
  );
}

export default PasswordRightBox;
