import { Box, Chip, Grid, Modal, Tooltip } from "@mui/material";
import { Fragment, useState } from "react";
import { CustomText } from "../../../../styles/CustomText";
import { BaseWhite } from "../../../../styles/Color";
import { SalesProtectionStatisticsInfo } from "../../../../types/Group/group";
import uuid from "react-uuid";
import SecurityIcon from "@mui/icons-material/Security";
import { SalesProtectionTooltipStyle } from "../../../../styles/List/Lead/leadPageStyle";
import { PointerItem } from "../../../../styles/theme";
import { X } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
const SalesProtectionStatusTooltip = ({
  salesProtectionData,
  t,
}: {
  salesProtectionData: SalesProtectionStatisticsInfo[];
  t: TFunction;
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const totalProtectionCount = salesProtectionData.reduce(
    (accumulator, current) => {
      return accumulator + current.totalProtectionCount;
    },
    0
  );

  const protectionCount = salesProtectionData.reduce((accumulator, current) => {
    return accumulator + current.protectionCount;
  }, 0);
  return (
    <>
      <Tooltip
        arrow
        title={
          <Fragment>
            <CustomText type="body" color={BaseWhite}>
              {t("[Branch Name : Used / Total Count]")}
            </CustomText>
            {salesProtectionData.map((dt: SalesProtectionStatisticsInfo) => (
              <CustomText type="body" key={uuid()} color={BaseWhite}>
                {dt.groupName +
                  " : " +
                  dt.protectionCount +
                  "/" +
                  dt.totalProtectionCount}
              </CustomText>
            ))}
          </Fragment>
        }
        sx={{ marginRight: "4px" }}
      >
        <Chip
          icon={<SecurityIcon />}
          label={protectionCount + "/" + totalProtectionCount}
          variant="outlined"
          onClick={() => setOpen(true)}
          style={{ cursor: "pointer" }}
        />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SalesProtectionTooltipStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title"> 영업 보호권 현황</CustomText>

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid item xs={12}>
              <CustomText type="subtitle">
                [지사명 : 사용 개수 / 지사 내 영업 보호권 개수]
              </CustomText>
              {salesProtectionData.map((dt: SalesProtectionStatisticsInfo) => (
                <CustomText
                  type="subtitle"
                  bold400
                  key={uuid()}
                  marginTop="12px"
                >
                  {dt.groupName +
                    " : " +
                    dt.protectionCount +
                    "/" +
                    dt.totalProtectionCount}
                </CustomText>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default SalesProtectionStatusTooltip;
