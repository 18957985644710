import Swal from "sweetalert2";
import { CustomerApi } from "../../../../system/api/customerApi";
import { ErrorHandler } from "../../../../system/ApiService";
import { AlertModal } from "../../AlertModal";
import { GREENCONTENTS, REDTITLE } from "../../../../styles/Color";
import { TableCellData } from "../../../../types/Common/CommonMuiTable";

const CancelSalesProtection = (row: TableCellData) => {
  if (row.id && row.salesProtection) {
    Swal.fire({
      title: "영업보호 설정을 해제하시겠습니까?",
      text: "선택하신 가망고객의 영업보호권이 해제됩니다.",
      icon: "question",
      confirmButtonText: "예",
      cancelButtonText: "아니오",
      showCancelButton: true,
      confirmButtonColor: GREENCONTENTS,
      cancelButtonColor: REDTITLE,
    }).then((result) => {
      if (result.isConfirmed) {
        CustomerApi.updateSalesProtection(row.id, false)
          .then(() => {
            //   refreshData();
            //   refreshProtection();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    });
  }
};

export default CancelSalesProtection;
