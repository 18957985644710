import client from "../../system/ApiService";
import { _BasicApi } from "../../system/BasicApi";

const baseUri: string = "/product";

class _ProductApi extends _BasicApi {
  getProductInfo<RQ, RP>(params?: RQ) {
    return this.client.get<RP>(`${baseUri}`, { params });
  }

  getProductFamilytInfo<RQ, RP>(params?: RQ) {
    return this.client.get<RP>(`${baseUri}/family`, { params });
  }
}

export const ProductApi = new _ProductApi(client);
