import { DeviceStatusType } from "../../types/Common/MediaType";
import { Gray700 } from "../Color";

const CloseIconFontSize = {
  pc: "32px",
  tablet: "32px",
  mobile: "16px",
};
export const CloseIconStyle = (deviceType: DeviceStatusType) => {
  return {
    fontSize: CloseIconFontSize[deviceType],
    cursor: "pointer",
    fill: Gray700,
  };
};
