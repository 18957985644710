import { styled } from "@mui/material/styles";
import {
  Brand25,
  Brand600,
  ErrorBackground,
  ErrorText,
  SuccessBackground,
  SuccessText,
  WaitingBackground,
  WaitingText,
  WarningBackground,
  WarningText,
} from "../Color";
import { styledWithoutProps } from "../deviceTypeStyle";

interface StyledDeviceTypeProps {
  deviceType: "pc" | "tablet" | "mobile";
}

const CommonButtonWidth: Record<"pc" | "tablet" | "mobile", string> = {
  pc: "100px",
  tablet: "90px",
  mobile: "80px",
};

export const CommonButton = styledWithoutProps("button")<StyledDeviceTypeProps>(
  ({ deviceType }: StyledDeviceTypeProps) => ({
    border: "none",
    width: CommonButtonWidth[deviceType], // 타입이 올바르게 매칭되어야 합니다.
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    transition: "all 0.2s",
    borderRadius: "4px",
    cursor: "pointer",
  })
);

export const ErrorButton = styled(CommonButton)({
  backgroundColor: ErrorBackground,
  color: ErrorText,
  "&:hover": {
    backgroundColor: ErrorText,
    color: ErrorBackground,
  },
});

export const WarningButton = styled(CommonButton)({
  backgroundColor: WarningBackground,
  color: WarningText,
  "&:hover": {
    backgroundColor: WarningText,
    color: WarningBackground,
  },
});

export const SuccessButton = styled(CommonButton)({
  backgroundColor: SuccessBackground,
  color: SuccessText,
  "&:hover": {
    backgroundColor: SuccessText,
    color: SuccessBackground,
  },
});

export const WaitingButton = styled(CommonButton)({
  backgroundColor: WaitingBackground,
  color: WaitingText,
  "&:hover": {
    backgroundColor: WaitingText,
    color: WaitingBackground,
  },
  path: { color: WaitingText },
  "&:hover svg": {
    fill: WaitingBackground,
  },
});

export const BrandButton = styled(CommonButton)({
  backgroundColor: Brand25,
  color: Brand600,
  "&:hover": {
    backgroundColor: Brand600,
    color: Brand25,
  },
});
