import { Box, styled } from "@mui/material";
import { DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";

export const FilteringWrapper = styled(Box)({
  height: "100%",
  width: "100%",
  maxHeight: "640px",
});

export const FilteringInnerWrapper = styled(Box)({
  height: "calc( 100% - 100px)",
  overflowY: "scroll",
  overflowX: "hidden",
});

export const FilteringColumnWrapper = styled(Box)({
  padding: "0px 8px 4px 4px",
});

export const FilteringTitle = styled(Box)({
  fontWeight: "600",
});

export const DropDownTreeComponentBox = styled(DropDownTreeComponent)(() => ({
  width: "100%",
  height: "2rem",
  border: "0px",
  fontSize: "16px",
  "&::placeholder": {
    color: "#B2B4B8 !important",
    fontSize: "14px !important",
  },
}));
