import { FooterStyle, MarginLayout } from "../../styles/theme";
import { SECONDARY } from "../../styles/Color";
import { CustomText } from "../../styles/CustomText";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Grid } from "@mui/material";
function Footer() {
  return (
    <FooterStyle>
      <Grid container justifyContent="center">
        <MarginLayout marginNumber={10}>
          <CustomText type="body" bold400 color={SECONDARY}>
            Copyright <CopyrightIcon fontSize="small" />{" "}
            {new Date().getFullYear()} InBody CO., LTD. All right reserved
          </CustomText>
        </MarginLayout>
      </Grid>
    </FooterStyle>
  );
}

export default Footer;
