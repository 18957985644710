import client from "../../system/ApiService";
import { _BasicApi } from "../../system/BasicApi";

class _GoalApi extends _BasicApi {
  // 그룹 목표 조회
  getGroupGoals<RQ, RP>(groupId: number, params: RQ) {
    return this.client.get<RP>(`/group-goals/${groupId}`, { params });
  }

  // 그룹 목표 전체 조회
  getAllGroupGoals<RQ, RP>(params: RQ) {
    return this.client.get<RP>(`/group-goals/all`, { params });
  }

  // 그룹 목표 설정
  postGroupGoal<RQ, RP>(params: RQ) {
    return this.client.post<RQ, RP>(`/group-goals/groups`, params);
  }
  // 그룹 목표 수정
  patchGroupGoal<RQ, RP>(goalId: number, params: RQ) {
    return this.client.patch<RQ, RP>(`/group-goals/${goalId}`, params);
  }

  // 임직원 개인 목표 조회
  getEmployeeGoals<RQ, RP>(params: RQ) {
    return this.client.get<RP>(`/employee-goals/my`, { params });
  }

  // 임직원 개인 목표 설정
  postEmployeeGoal<RQ, RP>(params: RQ) {
    return this.client.post<RQ, RP>(`/employee-goals/employees`, params);
  }
  // 임직원 개인 목표 수정
  patchEmployeeGoal<RQ, RP>(goalId: number, params: RQ) {
    return this.client.patch<RQ, RP>(`/employee-goals/${goalId}`, params);
  }
}

export const GoalApi = new _GoalApi(client);
