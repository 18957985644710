import { Grid } from "@mui/material";
import { AdminGroupItemBoxTitle } from "../../../../../styles/adminStyle";
import { CustomText } from "../../../../../styles/CustomText";
import { CustomButtonSmallCommon } from "../../../../../styles/buttonStyle";

type AdminGroupInfoSectionTitleProps = {
  handleUpdateModal: (status: boolean) => void;
};

const AdminGroupInfoSectionTitle = ({
  handleUpdateModal,
}: AdminGroupInfoSectionTitleProps) => {
  return (
    <AdminGroupItemBoxTitle
      paddingnumber="6px 10px"
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid container item xs={6}>
        <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
          정보
        </CustomText>
      </Grid>
      <Grid container item justifyContent="right" xs={6}>
        <CustomButtonSmallCommon onClick={() => handleUpdateModal(true)}>
          수정
        </CustomButtonSmallCommon>
      </Grid>
    </AdminGroupItemBoxTitle>
  );
};

export default AdminGroupInfoSectionTitle;
