import dayjs, { Dayjs } from "dayjs";

import { useEffect, useState } from "react";
import { SearchDateRangeProps } from "../../../../types/Common/CommonSearch";
import CommonDatePicker from "../../../../components/Common/Form/CommonDatePicker";

const DateRangeSearch = ({ value, handleChange }: SearchDateRangeProps) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(
    value[0] ? dayjs(value[0]) : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    value[1] ? dayjs(value[1]) : null
  );

  // Update local state when the value prop changes
  useEffect(() => {
    setStartDate(value[0] ? dayjs(value[0]) : null);
    setEndDate(value[1] ? dayjs(value[1]) : null);
  }, [value]);

  const createHandleDateChange =
    (index: number) =>
    (newValue: Dayjs | null, keyboardInputValue?: string) => {
      const updatedDates: [Dayjs | null, Dayjs | null] = [startDate, endDate];
      updatedDates[index] = newValue;
      handleChange(updatedDates);

      // Update local state
      if (index === 0) {
        setStartDate(newValue);
      } else {
        setEndDate(newValue);
      }
    };

  return (
    <div>
      <CommonDatePicker
        label="시작일"
        dateValue={startDate}
        onChange={createHandleDateChange(0)}
      />
      <CommonDatePicker
        label="종료일"
        dateValue={endDate}
        onChange={createHandleDateChange(1)}
      />
    </div>
  );
};

export default DateRangeSearch;
