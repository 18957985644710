import { CustomText } from "../../../styles/CustomText";
import MyTableList from "./components/MyTableList";

function MyPage() {
  return (
    <>
      <CustomText type="title"> 내 거래 목록</CustomText>
      <MyTableList type="my" />
    </>
  );
}

export default MyPage;
