import { AxiosError } from "axios";
import HttpClient from "./http-client";
import { AlertModal } from "../components/Common/AlertModal";
import { t } from "i18next";

//TODO: env로 변경할 것
const url: string | undefined = `${process.env.REACT_APP_API_SERVER_URI}`;
// const url: string|undefined = 'http://192.168.45.111:8787/api';
const client = new HttpClient(url);

export default client;

function isErrorWithMessage(error: unknown): string {
  if (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  ) {
    return (error as Record<string, unknown>).message as string;
  } else {
    return "";
  }
}

export function ErrorHandler(error: AxiosError): string {
  let message = "";
  if (error.response) {
    // message += error.response.data;
    message = isErrorWithMessage(error.response.data);
    if (message === "" || message === "[object Object]") {
      //여전히 빈 상태라면...
      switch (error.response.status) {
        case 401:
          message = t("Authentication has expired; re-login is required");
          AlertModal("msg", message);
          localStorage.clear();
          break;
        case 400: //Bad Request
          message = t("Data input is incorrect");
          break;
        case 403: //Forbidden
          message = t("Access denied");
          break;
        case 404: //NotFound
          message = t("We couldn't find the result you were looking for");
          break;
        case 405: //method not allowd
          message = t("405: Access denied");
          break;
        case 500:
          message = t("A server error has occurred");
          break;
        default:
          message = t(
            "An unknown error has occurred. Please contact the administrator"
          );
          break;
      }
    }
  } else {
    // alert(message);
    //  message += "서버에서 데이터를 가져올 수 없습니다.";
  }
  return message;
}
