import { useRecoilValue } from "recoil";
import { useQueryParams } from "../../../hooks/Router/useQueryParmas";
import { SwitchRoleDisplayModeContentMap } from "../../../types/Common/SwitchComponent";
import {
  DisplayMode,
  SwitchPortletContentMapProps,
} from "../../../types/DashBoard/Portlet";
import { EmployeeRole } from "../../../types/Employee/Employee";

import { MyInfoState } from "../../../system/atoms";
import SwitchRoleDisplayModeComponent from "../../../components/Common/Switch/SwitchRoleDisplayModeComponent";
import { lazy, Suspense } from "react";

const GroupAchievementSection = lazy(
  () => import("./Achievement/Group/GroupAchievementSection")
);
const EmployeeAchievementSection = lazy(
  () => import("./Achievement/Employee/EmployeeAchievementSection")
);
const GroupInOutboundSection = lazy(
  () => import("./InOutbound/Group/GroupInOutboundSection")
);
const EmployeeInOutboundSection = lazy(
  () => import("./InOutbound/Employee/EmployeeInOutboundSection")
);

const SwitchReportContentMap: SwitchRoleDisplayModeContentMap<SwitchPortletContentMapProps> =
  {
    [EmployeeRole.ADMIN]: {
      [DisplayMode.FIRST]: {
        1: GroupAchievementSection,
        2: GroupInOutboundSection,
      },
      [DisplayMode.SECOND]: {
        1: EmployeeAchievementSection,
        2: EmployeeInOutboundSection,
      },
    },
    [EmployeeRole.MANAGER]: {
      [DisplayMode.FIRST]: {
        1: GroupAchievementSection,
        2: GroupInOutboundSection,
      },
      [DisplayMode.SECOND]: {
        1: EmployeeAchievementSection,
        2: EmployeeInOutboundSection,
      },
    },
    [EmployeeRole.USER]: {
      [DisplayMode.FIRST]: { 1: GroupAchievementSection },
      [DisplayMode.SECOND]: {
        1: EmployeeAchievementSection,
        2: EmployeeInOutboundSection,
      },
    },
    [EmployeeRole.QAQC]: {
      [DisplayMode.FIRST]: {},
      [DisplayMode.SECOND]: {},
    },
    [EmployeeRole.CS]: {
      [DisplayMode.FIRST]: {},
      [DisplayMode.SECOND]: {},
    },
  };

const SwitchReportContent = () => {
  const displayModeFromParams =
    (useQueryParams("displayMode") as DisplayMode) ?? DisplayMode.FIRST;
  const { role } = useRecoilValue(MyInfoState);
  const displayModeContent =
    role && SwitchReportContentMap[role as EmployeeRole][displayModeFromParams];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {displayModeContent &&
        Object.entries(displayModeContent).map(([key, Component]) => (
          <SwitchRoleDisplayModeComponent
            key={key}
            switchContentMap={SwitchReportContentMap}
            role={role as EmployeeRole}
            id={Number(key)}
            displayMode={displayModeFromParams}
          />
        ))}
    </Suspense>
  );
};
export default SwitchReportContent;
