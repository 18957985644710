import Swal from "sweetalert2";

export const successModal = (message?: string) => {
  return Swal.fire({
    title:
      "<div style='font-family:Noto sans CJK KR;font-size:20px;'>" +
      message +
      "</div>",
    icon: "success",
    showConfirmButton: false,
    timer: 1500,
  });
};
