import {
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import { useCallback, useEffect, useState } from "react";
import { CustomerApi } from "../../../system/api/customerApi";
import { ErrorHandler } from "../../../system/ApiService";
import { AlertModal } from "../../../components/Common/AlertModal";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  LoadingAtom,
  MyInfoState,
  SearchDataAtom,
} from "../../../system/atoms";

import { GroupApi } from "../../../system/api/groupApi";
import { AxiosError, AxiosResponse } from "axios";
import {
  SuccessButton,
  WaitingButton,
} from "../../../styles/Button/buttonStyle";
import { useDeviceType } from "../../../hooks/useDeviceType";
import {
  EnumType,
  FilterObject,
  LeadBoardType,
  LeadListClassification,
} from "../../../types/Lead/List";
import { BaseWhite, WaitingText } from "../../../styles/Color";
import { useHistory } from "react-router-dom";
import { Urls } from "../../../route/Urls";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { useTranslation } from "react-i18next";

import CommonMuiTable from "../../../components/Common/Table/CommonMuiTable";
import FilteringList from "./components/FilteringList";

import {
  FilteringActivateButton,
  LeadDropdownWrapper,
} from "../../../styles/List/Lead/leadPageStyle";
import CommonDropdown from "../../../components/Common/Form/CommonDropdown";
import { SalesProtectionStatisticsInfo } from "../../../types/Group/group";
import {
  TableCellData,
  TableHeadCellData,
} from "../../../types/Common/CommonMuiTable";
import CancelSalesProtection from "../../../components/Common/Lead/SalesProtection/CancelSaelsProtection";
import ConvertToTableData from "./components/ConverToTableData";
import SalesProtectionStatusTooltip from "./components/SalesProtectionStatusTooltip";
import { useGenerateColumnList } from "../../../hooks/Table/useGenerateColumnList";
import { EmployeeRole } from "../../../types/Employee/Employee";
import { CustomersApi } from "../../../api/Customers/customersApi";
import {
  CustomerInfo,
  GetAllCustomersInfoResponse,
} from "../../../types/Customers/Customers";
import { LeadStatusStore } from "../../../stores/Lead/leadStore";
import {
  getChangedColumnSortStatus,
  getSortColumnList,
} from "../../../utils/Table/table";
import { ModalStyle, PointerItem } from "../../../styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import { CloseIconStyle } from "../../../styles/Common/commonModalStyle";
import { CustomerSearchValidation } from "./validation/CustomerSearchValidation";

import DownloadIcon from "@mui/icons-material/Download";
import SettingsIcon from "@mui/icons-material/Settings";

function LeadPage() {
  const [data, setData] = useState<CustomerInfo[]>([]);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [searchDt, setSearchDt] = useRecoilState(SearchDataAtom);
  const { role, id, groups } = useRecoilValue(MyInfoState);
  const deviceType = useDeviceType();
  const [, setLoadingSpinnerState] = useRecoilState(LoadingAtom);
  const history = useHistory();
  const [filteringButtonState, setFilteringButtonState] = useState(false);
  const { t } = useTranslation();
  const [listClassification, setListClassification] =
    useState<LeadListClassification>(LeadListClassification.ALL_ITEMS);
  const [boardType, setBoardType] = useState<LeadBoardType>(LeadBoardType.List);
  const [filterObjects, setFilterObjects] = useState<FilterObject[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [leadStatus, setLeadStatus] = useRecoilState(LeadStatusStore);
  // 줄바꿈 활성화 상태 확인
  const [usingEllipsis, setUsingEllipsis] = useState(false);
  const initColumnList = useGenerateColumnList([
    {
      key: "salesProtection",
      value: "",
      handleBodyCellClick: (row: TableCellData) => CancelSalesProtection(row),
      width: "28px",
      usingEllipsis: usingEllipsis ? false : true,
    },
    {
      key: "status",
      value: "",
      width: "28px",
    },
    {
      key: "step",
      value: t("step"),
      sortStatus: "none",
      sortKey: "step.name",
      width: "100px",
    },
    {
      key: "groupName",
      value: t("Branch"),
      sortStatus: "none",
      sortKey: "group.name",
      width: "120px",
    },
    {
      key: "placeName",
      value: t("placeName"),
      width: "160px",
      sortStatus: "none",
      sortKey: "address.placeName",
    },
    {
      key: "address",
      value: t("address"),
      width: "240px",
      sortStatus: "none",
      sortKey: "address.road",
    },
    {
      key: "productName",
      value: t("Product Name"),
      width: "180px",
    },
    {
      key: "totalPrice",
      value: t("Total Price"),
      width: "120px",
    },
    {
      key: "businessType",
      value: t("businessType"),
      width: "120px",
      sortStatus: "none",
      sortKey: "businessType.name",
    },
    {
      key: "saleType",
      value: t("salesType"),
      width: "120px",
      sortStatus: "none",
      sortKey: "saleType.name",
    },
    {
      key: "contactType",
      value: t("contactType"),
      width: "160px",
      sortStatus: "none",
      sortKey: "contactType.sub",
    },
    {
      key: "supplyType",
      value: t("supplyType"),
      width: "120px",
      sortStatus: "none",
      sortKey: "supplyType.name",
    },
    {
      key: "contractType",
      value: t("contractType"),
      width: "120px",
      sortStatus: "none",
      sortKey: "contractType.name",
    },
    {
      key: "sellerName",
      value: t("Seller"),
      width: "100px",
      sortStatus: "none",
      sortKey: "seller.name",
    },
    {
      key: "tags",
      value: t("tags"),
      width: "160px",
    },
    {
      key: "intermediaryCompany",
      value: t("Intermediary"),
      width: "120px",
      sortStatus: "none",
      sortKey: "intermediaryCompany.address.placeName",
    },
    {
      key: "purchaseProbability",
      value: t("PurchaseProbability"),
      width: "100px",
      sortStatus: "none",
      sortKey: "purchaseProbability",
    },
    {
      key: "estimatedDate",
      value: t("estimatedDate"),
      width: "120px",
      sortStatus: "none",
      sortKey: "estimatedDate",
    },
    {
      key: "lastActivityDate",
      value: t("activityDate"),
      width: "120px",
    },
    {
      key: "lastFollowUpDate",
      value: t("followUpDate"),
      width: "120px",
      sortStatus: "none",
      sortKey: "lastFollowUpDate",
    },
    {
      key: "createdAt",
      value: t("CreatedAt"),
      width: "120px",
      sortStatus: "none",
      sortKey: "createdAt",
    },
  ]);

  const [dropdownData, setDropdownData] = useState<Record<string, any[]>>({});
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  // 다운로드 버튼 메뉴
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // 설정 메뉴
  const [anchorSettings, setAnchorSettings] = useState<null | HTMLElement>(
    null
  );
  const [settingsModal, setSettingsModal] = useState(false);
  const handleModalClose = () => {
    setFilteringButtonState(false);
  };

  const handleSettingModalClose = () => {
    setSettingsModal(false);
  };

  const handleusingEllipsis = () => {
    setUsingEllipsis((prev) => !prev);
    setLeadStatus((prev) => ({
      ...prev,
      columnList: prev.columnList.map((column) => ({
        ...column,
        usingEllipsis: usingEllipsis,
      })),
    }));
    setAnchorSettings(null);
  };

  const switchColumnList = useCallback(() => {
    setLeadStatus((prev) => ({
      ...prev,
      columnList: initColumnList,
    }));
  }, []);

  useEffect(() => {
    switchColumnList();
  }, []);

  // 정렬
  const handleHeadCellClick = useCallback(
    (headCell: TableHeadCellData) => {
      const result = getChangedColumnSortStatus(
        headCell,
        leadStatus.columnList
      );
      setLeadStatus((prev) => ({ ...prev, columnList: result }));
    },
    [leadStatus.columnList]
  );

  // type이 pc일때는 그대로 return, 그 외 deviceType일 때 지사/상호명/제품군만 표시
  const convertColumnList = (
    columns: TableHeadCellData[],
    deviceType: string
  ): TableHeadCellData[] => {
    if (deviceType === "pc") return columns;
    return columns.filter((column) =>
      ["groupName", "placeName", "orders"].includes(column.key)
    );
  };

  // enum 객체를 드롭다운 컴포넌트에서 사용할 옵션 배열로 변환
  const createOptions = (enumObj: EnumType) => {
    return Object.entries(enumObj).map(([key, value]) => ({
      key,
      value,
      label: t(value),
    }));
  };

  const handlePagenation = useCallback((_, v: number) => {
    setLeadStatus((prev) => ({ ...prev, pageNumber: v }));
  }, []);
  // row 클릭 시 상세 페이지로 이동
  const handleRowClick = (row: TableCellData) => {
    if (row?.id) {
      history.push(`/list/detail/${row.id}`);
    }
  };

  const postAllCustomersInfo = useCallback(
    async (currentFilterObject?: FilterObject[]) => {
      const filterData = currentFilterObject
        ? currentFilterObject
        : filterObjects;
      // CustomerSearchValidation 사용하여 유효성 검사
      if (!CustomerSearchValidation(filterData, t)) {
        return; // 유효성 검사를 통과하지 못하면 함수 종료
      }
      const sortColumnList = getSortColumnList(leadStatus.columnList);
      const queryParams = {
        page: leadStatus.pageNumber - 1,
        size: 10,
        ...(sortColumnList.length > 0 && { sort: sortColumnList }),
      };

      try {
        const response = await CustomersApi.postAllCustomersInfo<
          FilterObject[],
          GetAllCustomersInfoResponse
        >(filterData, {
          params: queryParams,
        });

        if (response?.data) {
          setData(response.data.customers ? response.data.customers : []);
          setTotalPageNumber(response.data.totalPages);
        }
        // 모달 열렸을 때 모달 닫기
        handleModalClose();
      } catch (err) {
        const message = ErrorHandler(err as AxiosError);
        AlertModal("msg", message);
      }
    },
    [leadStatus.pageNumber, leadStatus.columnList, listClassification]
  );

  useEffect(() => {
    postAllCustomersInfo();
  }, [postAllCustomersInfo]);

  // filteringList를 나타낸 chip에서 해당 filter 삭제 기능
  const handleDeleteFilteringList = (field: string) => {
    // filterObjects에서 해당 필터 제거
    setFilterObjects((prevFilters) => {
      const updatedFilters = prevFilters.filter(
        (filter) => filter.field !== field
      );

      // 필터가 업데이트된 후 데이터를 다시 불러오기
      postAllCustomersInfo(updatedFilters);

      return updatedFilters;
    });

    // selectedColumns에서 해당 필드 제거
    setSelectedColumns((prevColumns) =>
      prevColumns.filter((column) => column !== field)
    );
  };

  const downloadExcel = () => {
    setLoadingSpinnerState(true);
    CustomerApi.getCustomerInfo(searchDt, 0, 10000, true)
      .then((res) => {
        const blob = new Blob([res.data]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.style.display = "none";
        const injectFilename = (res: AxiosResponse<any, any>) => {
          var filename = "";
          var disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return decodeURI(filename);
        };
        link.download = injectFilename(res);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        AlertModal("success", "엑셀 다운로드가 완료되었습니다.");
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        setLoadingSpinnerState(false);
      });
  };

  // 날짜
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const day = new Date().getDate();

  const todayDate = `${year}${month >= 10 ? month : "0" + month}${
    day >= 10 ? day : "0" + day
  }`;

  function AdminDownload() {
    setLoadingSpinnerState(true);
    CustomerApi.getAdminCustomerFile()
      .then((res) => {
        // 받아온 데이터를 Blob으로 변환
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        // Blob을 파일로 다운로드
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `관리자용_PipeLine_정보_${todayDate}.xlsx`; // 다운로드될 파일 이름 설정
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        setLoadingSpinnerState(false);
      });
  }

  // 영업 보호권 데이터
  const [salesProtectionData, setSalesProtectionData] = useState<
    SalesProtectionStatisticsInfo[]
  >([]);

  const refreshProtection = () => {
    GroupApi.getSalesProtectionStatistics()
      .then((res) => {
        setSalesProtectionData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    refreshProtection();
  }, []);

  const handleChangeListClassification = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as LeadListClassification;
    setFilterObjects(() => {
      switch (selectedValue) {
        case "My Items":
          return [
            {
              comparator: "IN",
              field: "sellerId",
              values: id !== undefined && id !== null ? [id] : [],
            },
          ];
        case "Group Items":
          if (
            groups &&
            groups.length > 0 &&
            groups[0].id !== undefined &&
            groups[0].id !== null
          ) {
            // groups 값이 존재하는지 확인
            return [
              {
                comparator: "IN",
                field: "groupId",
                values: [groups[0].id],
              },
            ];
          }
          // groups 값이 없는 경우 빈 배열 반환
          return [];
        default:
          return [];
      }
    });

    setListClassification(selectedValue);
  };

  const handleChangeLeadBoardType = (event: SelectChangeEvent) => {
    setBoardType(event.target.value as LeadBoardType);
  };

  const handleMoveToWritePage = () => {
    history.push(Urls.registerByCase);
  };

  const handleOpenDownloadMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSettingsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSettings(event.currentTarget);
  };

  const handleCloseSettingsMenu = () => {
    setAnchorSettings(null);
  };

  // 필터링
  const handleChangeFilteringButtonState = () => {
    setFilteringButtonState(!filteringButtonState);
  };

  return (
    <>
      <Grid container item xs={12} justifyContent="space-between">
        <CustomText type="title"> {t("Lead")}</CustomText>
        <Grid>
          <Tooltip title={<div style={{ color: "#fff" }}>{t("Download")}</div>}>
            <IconButton onClick={handleOpenDownloadMenu}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={<div style={{ color: "#fff" }}>{t("Settings")}</div>}>
            <IconButton
              style={{ marginLeft: "4px" }}
              onClick={handleOpenSettingsMenu}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseDownloadMenu}
        >
          {(role as EmployeeRole) === EmployeeRole.ADMIN && (
            <MenuItem onClick={AdminDownload}>
              <CustomText type="subtitle" bold400 cursorPointer>
                {t("Admin Download")}
              </CustomText>
            </MenuItem>
          )}

          <MenuItem onClick={downloadExcel}>
            <CustomText type="subtitle" bold400 cursorPointer>
              {t("Excel Download")}
            </CustomText>
          </MenuItem>
        </Menu>
        <Menu
          anchorEl={anchorSettings}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorSettings)}
          onClose={handleCloseSettingsMenu}
        >
          <MenuItem
            onClick={() => {
              setSettingsModal(true);
              setAnchorSettings(null);
            }}
          >
            <CustomText type="subtitle" bold400 cursorPointer>
              {t("Manage Columns")}
            </CustomText>
          </MenuItem>
          <MenuItem onClick={handleusingEllipsis}>
            <CustomText type="subtitle" bold400 cursorPointer>
              {usingEllipsis ? t("Truncate Text") : t("Wrap Text")}
            </CustomText>
          </MenuItem>
        </Menu>
      </Grid>

      <Grid container item xs={12}>
        <Grid container item xs={12} sm={6} mt="8px">
          {filteringButtonState ? (
            <FilteringActivateButton
              deviceType={deviceType}
              onClick={handleChangeFilteringButtonState}
            >
              <FilterAltIcon
                fontSize="small"
                sx={{ marginRight: "4px", fill: BaseWhite }}
              />
              {t("Filters")}
            </FilteringActivateButton>
          ) : (
            <WaitingButton
              deviceType={deviceType}
              onClick={handleChangeFilteringButtonState}
            >
              <FilterAltOffIcon
                fontSize="small"
                sx={{ marginRight: "4px", fill: WaitingText }}
              />
              {t("Filters")}
            </WaitingButton>
          )}
          <LeadDropdownWrapper>
            <CommonDropdown
              value={listClassification}
              options={createOptions(LeadListClassification).map((option) => ({
                key: option.key,
                value: option.value,
                label: option.label,
              }))}
              handleChange={handleChangeListClassification}
              sx={{ height: "40px", marginLeft: "4px" }}
            />
          </LeadDropdownWrapper>

          <LeadDropdownWrapper>
            <CommonDropdown
              value={boardType}
              options={createOptions(LeadBoardType).map((option) => ({
                key: option.key,
                value: option.value,
                label: option.label,
              }))}
              handleChange={handleChangeLeadBoardType}
              sx={{ height: "40px", marginLeft: "4px" }}
            />
          </LeadDropdownWrapper>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          justifyContent="right"
          alignItems="center"
          mt="8px"
        >
          <SalesProtectionStatusTooltip
            salesProtectionData={salesProtectionData}
            t={t}
          />
          <SuccessButton
            deviceType={deviceType}
            onClick={handleMoveToWritePage}
          >
            {t("Registeration")}
          </SuccessButton>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={1} mt="8px">
        {filterObjects.map((filter, index) => (
          <Chip
            key={index}
            label={t(filter.field)}
            onDelete={() => handleDeleteFilteringList(filter.field)}
            color="default"
            variant="outlined"
          />
        ))}
      </Stack>
      <Grid container item xs={12} mt="8px">
        <CommonMuiTable
          columnList={convertColumnList(leadStatus.columnList, deviceType)}
          rowList={ConvertToTableData(data, t)}
          pageNumber={leadStatus.pageNumber}
          totalPageNumber={totalPageNumber}
          handlePaginationChange={handlePagenation}
          handleRowClick={handleRowClick}
          handleHeadCellClick={handleHeadCellClick}
        />
      </Grid>
      <Modal open={filteringButtonState} onClose={handleModalClose}>
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title"> {t("Filter List")}</CustomText>

              <PointerItem onClick={handleModalClose}>
                <CloseIcon sx={CloseIconStyle(deviceType)} />
              </PointerItem>
            </Grid>
            <FilteringList
              filterObjects={filterObjects}
              setFilterObjects={setFilterObjects}
              postAllCustomersInfo={postAllCustomersInfo}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              dropdownData={dropdownData}
              setDropdownData={setDropdownData}
              inputValues={inputValues}
              setInputValues={setInputValues}
            />
          </Grid>
        </Box>
      </Modal>
      <Modal open={settingsModal} onClose={handleSettingModalClose}>
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title">
                {" "}
                {t("Lead") + " " + t("Settings")}
              </CustomText>

              <PointerItem onClick={handleSettingModalClose}>
                <CloseIcon sx={CloseIconStyle(deviceType)} />
              </PointerItem>
            </Grid>
            {/* 내용 */}
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default LeadPage;
