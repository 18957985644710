import Message from "../../../../../components/Common/Message/message";
import { CustomText } from "../../../../../styles/CustomText";
import { Grid } from "@mui/material";
import CommonEditMuiTable from "../../../../../components/Common/Table/CommonEditMuiTable";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../../../styles/Color";

import { AlertModal } from "../../../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../../../system/ApiService";
import {
  GroupInfo,
  GroupInfoWithAchievement,
} from "../../../../../types/Group/group";
import { EditTableHeadCellData } from "../../../../../types/Common/CommonMuiTable";
import { AxiosError } from "axios";
import { GoalApi } from "../../../../../api/Goal/goalApi";
import dayjs from "dayjs";
import { GroupApi } from "../../../../../api/Group/groupApi";

type AdminChildrenGroupTableProps = {
  columnList: EditTableHeadCellData[];
  rowList: any;
  errorMessage: string;
  refreshData: () => void;
};

const AdminChildrenGroupTable = ({
  columnList,
  rowList,
  errorMessage,
  refreshData,
}: AdminChildrenGroupTableProps) => {
  // POST - 하위 그룹 목표 생성
  const postChildrenGroupGoal = useCallback(
    async (groupInfo: GroupInfoWithAchievement) => {
      try {
        const {
          id,
          name,
          salesAmount,
          newLeadsInbound,
          newLeadsOutbound,
          inboundLeadConversion,
          outboundLeadConversion,
        } = groupInfo;
        const params = {
          year: dayjs().year(),
          salesAmount: Number(salesAmount),
          newLeadsInbound: Number(newLeadsInbound),
          newLeadsOutbound: Number(newLeadsOutbound),
          inboundLeadConversion: Number(inboundLeadConversion),
          outboundLeadConversion: Number(outboundLeadConversion),
          group: {
            id,
            name,
          },
        };
        await GoalApi.postGroupGoal(params);
        refreshData();
      } catch (err) {
        const message = ErrorHandler(err as AxiosError);
        AlertModal("msg", message);
      }
    },
    [refreshData]
  );

  // PATCH - 하위 그룹 목표 수정
  const patchChildrenGroupGoal = useCallback(
    async (groupInfo: GroupInfoWithAchievement) => {
      try {
        const {
          id,
          goalId,
          name,
          salesAmount,
          newLeadsInbound,
          newLeadsOutbound,
          inboundLeadConversion,
          outboundLeadConversion,
        } = groupInfo;
        const params = {
          year: dayjs().year(),
          salesAmount: Number(salesAmount),
          newLeadsInbound: Number(newLeadsInbound),
          newLeadsOutbound: Number(newLeadsOutbound),
          inboundLeadConversion: Number(inboundLeadConversion),
          outboundLeadConversion: Number(outboundLeadConversion),
          group: {
            id,
            name,
          },
        };
        await GoalApi.patchGroupGoal(goalId as number, params);
        refreshData();
      } catch (err) {
        const message = ErrorHandler(err as AxiosError);
        AlertModal("msg", message);
      }
    },
    [refreshData]
  );

  // 저장 버튼 클릭
  const handleClickSaveButton = useCallback(
    async (groupInfo: GroupInfoWithAchievement) => {
      const { goalIsSet } = groupInfo;
      if (goalIsSet) await patchChildrenGroupGoal(groupInfo);
      else await postChildrenGroupGoal(groupInfo);
    },
    [postChildrenGroupGoal, patchChildrenGroupGoal]
  );

  // DELETE 하위그룹 삭제
  const deleteChildrenGroup = useCallback(
    async (groupId: number) => {
      try {
        const params = {
          id: groupId,
        };
        await GroupApi.deleteGroupInfo(params);
        AlertModal("success", "선택하신 그룹이 삭제되었습니다.");
        refreshData();
      } catch (err) {
        const message = ErrorHandler(err as AxiosError);
        AlertModal("msg", message);
      }
    },
    [refreshData]
  );

  // 삭제 버튼 클릭
  const handleClickDeleteButton = useCallback(
    ({ id }: GroupInfo) => {
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 그룹이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed && id) {
          deleteChildrenGroup(id);
        }
      });
    },
    [deleteChildrenGroup]
  );
  return errorMessage.length > 0 ? (
    <Message text={errorMessage} />
  ) : rowList.length === 0 ? (
    <Grid container justifyContent="center" margin="8px">
      <CustomText type="subtitle" textAlign="center" color="#3D4861">
        하위 그룹을 추가해주세요.
      </CustomText>
    </Grid>
  ) : (
    <CommonEditMuiTable
      columnList={columnList}
      rowList={rowList}
      handleSave={handleClickSaveButton}
      handleDelete={handleClickDeleteButton}
    />
  );
};

export default AdminChildrenGroupTable;
