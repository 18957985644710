import client from "../ApiService";
import { _BasicApi } from "../BasicApi";
import { NotificationSendInfo } from "../types/Notification";

const listUri: string = "/notification";

class _NotificationApi extends _BasicApi {
  getMyNotification(
    type: string,
    page: number,
    size: number,
    isRead?: boolean | null
  ) {
    return this.client.get(
      `${listUri}/me/${type}?page=${page}&size=${size}&sort=isRead&sort=createdAt,desc`,
      {
        params: { isRead: isRead },
      }
    );
  }
  updateReadState(data: string[]) {
    return this.client.put(`${listUri}/read`, data);
  }
  sendNotification(data: NotificationSendInfo) {
    return this.client.post(`${listUri}/send`, data);
  }
  updateNotificationToken(fcmToken: string) {
    return this.client.post(`${listUri}/token`, {
      params: { fcmToken: fcmToken },
    });
  }
}

export const NotificationApi = new _NotificationApi(client);
