import { Dispatch, useEffect, useState } from "react";
import { TabContentBox } from "../../../../../styles/adminStyle";
import { CustomText } from "../../../../../styles/CustomText";
import { GRAYPALEBOX, GRAYPALETITLE } from "../../../../../styles/Color";
import MenuIcon from "@mui/icons-material/Menu";
import {
  DragDropContext,
  DropResult,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import { Grid, styled } from "@mui/material";
import { ContactTypeListInfo } from "../../../../../system/types/List";

interface ContactTypeSettingProps {
  data: ContactTypeListInfo[];
  setData: Dispatch<React.SetStateAction<ContactTypeListInfo[]>>;
}

function ContactTypeSetting({ data, setData }: ContactTypeSettingProps) {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    const newData = items.map((item, index) => ({
      ...item,
      priority: index,
    }));

    setData(newData);
  };

  return (
    <>
      <TabContentBox item xs={12}>
        <AdminGroupBox>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {data.map((item: ContactTypeListInfo, index) => (
                    <Draggable
                      key={item.sub}
                      draggableId={item.sub}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <AdminGroupListBorder container alignItems="center">
                            <MenuIcon
                              color="action"
                              style={{ marginRight: "5px" }}
                            />
                            <CustomText type="subtitle" color={GRAYPALETITLE}>
                              [{item.major}] {item.sub}
                            </CustomText>
                          </AdminGroupListBorder>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </AdminGroupBox>
      </TabContentBox>
    </>
  );
}
export default ContactTypeSetting;

const AdminGroupListBorder = styled(Grid)({
  padding: "17.5px 0px 17.5px 5px",
  borderTop: `1px solid ${GRAYPALEBOX}`,
  borderBottom: `1px solid ${GRAYPALEBOX}`,
});
const AdminGroupBox = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBOX}`,
  borderBottom: `1px solid ${GRAYPALEBOX}`,
});
