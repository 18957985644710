import {
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  DropDownListComponentBox,
  DropDownListComponentGridBox,
  InputBox,
  InputDisabledBox,
  ModalStyle,
  PointerItem,
  SmallBoxStyle,
} from "../../../styles/theme";
import { useRecoilState, useSetRecoilState } from "recoil";
import { MyInfoState } from "../../../system/atoms";
import { CustomText } from "../../../styles/CustomText";
import { AlertModal } from "../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../system/ApiService";
import { EmployeeApi } from "../../../system/api/employeeApi";
import uuid from "react-uuid";
import { useEffect, useState } from "react";
import { GRAYPALETITLE, GREENTITLE, REDTITLE } from "../../../styles/Color";
import { CheckCircle, X } from "@phosphor-icons/react";
import { JobApi } from "../../../system/api/jobApi";
import {
  CustomButtonDisabled,
  CustomButtonGreen,
  CustomButtonYellow,
} from "../../../styles/buttonStyle";
import { InitMyInfo, InitPasswordInfo } from "../../../system/types/initObject";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { MyInfo, PasswordInfo } from "../../../types/Employee/Employee";
import { LANGUAGE_OPTIONS_LIST } from "../../../types/Common/Multigual";

import { useTranslation } from "react-i18next";
import { GroupInfo, JobInfo } from "../../../types/Group/group";
import { localeStore } from "../../../stores/Common/commonStore";

function validation(data: MyInfo) {
  if (data.name === "") {
    AlertModal("check", "이름을 작성해 주세요.");
    return false;
  } else if (data.phone === "") {
    AlertModal("check", "연락처를 작성해 주세요.");
    return false;
  }
  return true;
}

function validationWrite(data: MyInfo) {
  if (data.phone === "") {
    return false;
  } else if (!data.jobPosition?.id) {
    return false;
  } else if (!data.jobTitle?.id) {
    return false;
  }
  return true;
}

function phoneValiation(data: string) {
  // eslint-disable-next-line
  const exptext = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/;
  if (exptext.test(data) === false) {
    return false;
  }
  return true;
}

function passwordValidation(data: PasswordInfo, checkPassword: string) {
  if (
    checkPassword.length < 8 ||
    data.newPassword.length < 8 ||
    checkPassword.length > 30 ||
    data.newPassword.length > 30
  ) {
    return false;
  } else if (checkPassword !== data.newPassword) {
    return false;
  }
  return true;
}

function MyInfoPageBox() {
  const [myData, setMyData] = useRecoilState(MyInfoState);
  const [data, setData] = useState<MyInfo>(InitMyInfo);
  const [jobTitleData, setJobTitleData] = useState<JobInfo[]>([]);
  const [jobPositionData, setJobPositionData] = useState<JobInfo[]>([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setData(myData);
    JobApi.searchJobPosition()
      .then((res) => {
        setJobPositionData(res.data.filter((item: JobInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
    JobApi.searchJobTitle()
      .then((res) => {
        setJobTitleData(res.data.filter((item: JobInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [myData]);

  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdown") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    }
  };

  const onChangePhone = (args: any) => {
    const targetValue = args.target.value
      // eslint-disable-next-line
      .replace(/[^0-9]/g, "")
      // eslint-disable-next-line
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      // eslint-disable-next-line
      .replace(/(\-{1,2})$/g, "");
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: targetValue,
    });
  };

  var submitcheck = false;
  function onSave() {
    if (validation(data)) {
      submitcheck = true;
      if (submitcheck === true) {
        EmployeeApi.updateMyInfo(data)
          .then(() => {
            AlertModal("success", "내 정보가 저장되었습니다.");
            setMyData(data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  }
  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };

  const titleFields = { text: "name", value: "id" };
  const positionFields = { text: "name", value: "id" };

  // 비밀번호 변경 모달
  const [passwordData, setPasswordData] =
    useState<PasswordInfo>(InitPasswordInfo);
  const [passwordCheck, setPasswordCheck] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setPasswordData(InitPasswordInfo);
  };
  const openModal = () => {
    setOpen(true);
  };

  const onChangePassword = (args: any) => {
    setPasswordData({
      ...passwordData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangePasswordCheck = (args: any) => {
    setPasswordCheck(args.target.value);
  };

  const onChangePasswordState = () => {
    submitcheck = true;
    if (submitcheck) {
      EmployeeApi.updatePassword({ ...passwordData })
        .then(() => {
          AlertModal("successMessage", "비밀번호가 변경되었습니다.");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    }
  };
  const handleOnKeyDownPassword = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onChangePasswordState();
    }
  };
  // 언어 설정
  const setLocaleState = useSetRecoilState(localeStore);
  const onChangeMultilingual = (event: SelectChangeEvent) => {
    const selectedLanguage = event.target
      .value as keyof typeof LANGUAGE_OPTIONS_LIST;
    setLocaleState(LANGUAGE_OPTIONS_LIST[selectedLanguage]);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <SmallBoxStyle>
      {!validationWrite(myData) && (
        <MessageComponent
          severity="Warning"
          variant="Outlined"
          content={t("Please fill out all your information and save it.")}
          style={{ marginBottom: "5px" }}
        />
      )}

      {data ? (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">{t("Name")}</Grid>
              {data.name === "" ? (
                <CustomText type="small" color={REDTITLE}>
                  * {t("Please enter your name.")}
                </CustomText>
              ) : (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              )}
            </Grid>
            <InputBox
              type="text"
              name="name"
              onChange={(e: any) => {
                onChange(e, "input");
              }}
              value={data.name || ""}
              placeholder="Name"
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">{t("Phone Number")}</Grid>
              {data.phone === "" ? (
                <CustomText type="small" color={REDTITLE}>
                  * {t("Please enter your phone number.")}
                </CustomText>
              ) : (
                <>
                  {phoneValiation(data.phone) ? (
                    <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                  ) : (
                    <CustomText type="small" color={REDTITLE}>
                      * {t("This is not a valid phone number format.")} ex.
                      010-0000-0000
                    </CustomText>
                  )}
                </>
              )}
            </Grid>
            <InputBox
              type="text"
              name="phone"
              value={data.phone || ""}
              onChange={(e: any) => {
                onChangePhone(e);
              }}
              placeholder="ex) 010-0000-0000"
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <Grid mr="5px">{t("Email Address")}</Grid>
          </Grid>
          <InputDisabledBox container alignItems="center">
            {data.email}
          </InputDisabledBox>
          <Grid container item xs={12} alignItems="center">
            <Grid mr="5px">{t("Groups")}</Grid>
          </Grid>
          <InputDisabledBox container alignItems="center">
            {data.groups.map((item: GroupInfo, idx: number) => (
              <Grid key={uuid()}>
                {idx !== 0 && ", "}
                {item.name}
              </Grid>
            ))}
            {data.groups.length === 0 && <Grid>-</Grid>}
          </InputDisabledBox>

          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">{t("Job Title")}</Grid>
              {data.jobTitle?.id ? (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              ) : (
                <CustomText type="small" color={REDTITLE} ml="5px">
                  * {t("Please select your job title.")}
                </CustomText>
              )}
            </Grid>
            <DropDownListComponentGridBox>
              <DropDownListComponentBox
                colorvalue={data.jobTitle?.id ? "#000000" : "#B2B4B8"}
                dataSource={jobTitleData}
                fields={titleFields}
                name="jobTitle"
                value={data.jobTitle?.id || ""}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={
                  data.jobTitle?.id ? data.jobTitle.name : t("Job Title")
                }
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
            </DropDownListComponentGridBox>
          </Grid>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">{t("Job position")}</Grid>
              {data.jobPosition?.id ? (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              ) : (
                <CustomText type="small" color={REDTITLE}>
                  * {t("Please select your job position.")}
                </CustomText>
              )}
            </Grid>

            <DropDownListComponentGridBox>
              <DropDownListComponentBox
                colorvalue={data.jobPosition?.id ? "#000000" : "#B2B4B8"}
                dataSource={jobPositionData}
                fields={positionFields}
                name="jobPosition"
                value={data.jobPosition?.id || ""}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={
                  data.jobPosition?.id
                    ? data.jobPosition.name
                    : t("Job position")
                }
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
            </DropDownListComponentGridBox>
          </Grid>
          <Grid container item xs={12} justifyContent="right" mt="20px">
            <CustomButtonYellow
              onClick={openModal}
              style={{ marginRight: "5PX" }}
            >
              {t("Password")}
            </CustomButtonYellow>
            <CustomButtonGreen onClick={onSave}>{t("Save")}</CustomButtonGreen>
          </Grid>
        </Grid>
      ) : (
        <Grid justifyContent="center" mt="30px">
          <CustomText type="subtitle" color="#3D4861">
            {t("No information available.")}
          </CustomText>
        </Grid>
      )}
      <Divider>
        <Chip label={t("Settings")} size="small" />
      </Divider>
      <Grid item xs={12}>
        <Grid container item xs={12} alignItems="center" mb="5px">
          <Grid mr="5px">{t("Select Language")}</Grid>
        </Grid>
        <FormControl fullWidth size="small">
          <Select value={i18n.language} onChange={onChangeMultilingual}>
            {Object.entries(LANGUAGE_OPTIONS_LIST).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {t(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title"> {t("Password")}</CustomText>

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
          </Grid>
          <Grid container mt="20px" mb="20px">
            <Grid container>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <CustomText type="subtitle2" bold500 color={GRAYPALETITLE}>
                    {t("Current Password")}
                  </CustomText>
                  {passwordData.oldPassword === "" ? (
                    <CustomText type="small" color={REDTITLE} ml="5px">
                      * {t("Please enter your current password.")}
                    </CustomText>
                  ) : (
                    <>
                      {passwordData.oldPassword.length > 30 ? (
                        <CustomText type="small" color={REDTITLE} ml="5px">
                          * .{t("비밀번호는 30자 이내로 입력해 주세요")}
                        </CustomText>
                      ) : (
                        <CheckCircle
                          size={20}
                          color={GREENTITLE}
                          weight="bold"
                        />
                      )}
                    </>
                  )}
                </Grid>

                <InputBox
                  type="password"
                  onChange={onChangePassword}
                  name="oldPassword"
                  placeholder={t("Please enter your current password.")}
                  onKeyDown={handleOnKeyDownPassword}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <CustomText type="subtitle2" bold500 color={GRAYPALETITLE}>
                    {t("New Password")}
                  </CustomText>
                  {passwordData.newPassword === "" ? (
                    <CustomText type="small" color={REDTITLE} ml="5px">
                      * {t("Please enter your new password.")}
                    </CustomText>
                  ) : (
                    <>
                      {passwordData.newPassword.length > 30 ||
                      passwordData.newPassword.length < 8 ? (
                        <CustomText type="small" color={REDTITLE} ml="5px">
                          *{" "}
                          {t(
                            "Please enter a password between 8 and 30 characters."
                          )}
                        </CustomText>
                      ) : (
                        <CheckCircle
                          size={20}
                          color={GREENTITLE}
                          weight="bold"
                        />
                      )}
                    </>
                  )}
                </Grid>

                <InputBox
                  type="password"
                  onChange={onChangePassword}
                  name="newPassword"
                  placeholder={t("New password (8 to 30 characters)")}
                  onKeyDown={handleOnKeyDownPassword}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <CustomText type="subtitle2" bold500 color={GRAYPALETITLE}>
                    {t("Confirm New Password")}
                  </CustomText>
                  {passwordCheck === "" ? (
                    <CustomText type="small" color={REDTITLE} ml="5px">
                      * {t("Please confirm your new password.")}
                    </CustomText>
                  ) : (
                    <>
                      {passwordCheck.length > 30 || passwordCheck.length < 8 ? (
                        <CustomText type="small" color={REDTITLE} ml="5px">
                          *{" "}
                          {t(
                            "Please enter a password between 8 and 30 characters."
                          )}
                        </CustomText>
                      ) : (
                        <>
                          {passwordValidation(passwordData, passwordCheck) ? (
                            <CheckCircle
                              size={20}
                              color={GREENTITLE}
                              weight="bold"
                            />
                          ) : (
                            <CustomText type="small" color={REDTITLE} ml="5px">
                              * {t("The passwords you entered do not match.")}
                            </CustomText>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <InputBox
                  type="password"
                  onChange={onChangePasswordCheck}
                  placeholder={t("New password (8 to 30 characters)")}
                  onKeyDown={handleOnKeyDownPassword}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} justifyContent="right" mt="20px">
            {passwordValidation(passwordData, passwordCheck) &&
            passwordCheck.length > 0 &&
            passwordCheck.length < 31 ? (
              <CustomButtonGreen onClick={onChangePasswordState}>
                {t("Save")}
              </CustomButtonGreen>
            ) : (
              <CustomButtonDisabled
                container
                justifyContent="center"
                alignItems="center"
              >
                {t("Save")}
              </CustomButtonDisabled>
            )}
          </Grid>
        </Box>
      </Modal>
    </SmallBoxStyle>
  );
}

export default MyInfoPageBox;
