import {
  RepoertHeaderWrapper,
  ReportHeaderRole,
} from "../styles/reportHeaderStyle";

import { useCallback } from "react";
import { useMovePage } from "../../../hooks/Router/useMovePage";
import { DisplayMode } from "../../../types/DashBoard/Portlet";
import { useQueryParams } from "../../../hooks/Router/useQueryParmas";
import {
  GroupDisplayIcon,
  ReportHeaderGroupText,
  ReportHeaderUserText,
  UserDisplayIcon,
} from "../styles/reportNavigatorStyle";

const ReportHeader = () => {
  const movePage = useMovePage();
  const displayModeFromParams =
    (useQueryParams("displayMode") as DisplayMode) ?? DisplayMode.FIRST;
  const handleReportDisplayMode = useCallback(
    (v: DisplayMode) => {
      movePage("/report", { displayMode: v });
    },
    [movePage]
  );

  return (
    <RepoertHeaderWrapper>
      <ReportHeaderRole
        onClick={() => handleReportDisplayMode(DisplayMode.FIRST)}
      >
        <GroupDisplayIcon displaymode={displayModeFromParams} />
        <ReportHeaderGroupText displaymode={displayModeFromParams}>
          그룹
        </ReportHeaderGroupText>
      </ReportHeaderRole>
      <ReportHeaderRole
        onClick={() => handleReportDisplayMode(DisplayMode.SECOND)}
      >
        <UserDisplayIcon displaymode={displayModeFromParams} />
        <ReportHeaderUserText displaymode={displayModeFromParams}>
          사용자
        </ReportHeaderUserText>
      </ReportHeaderRole>
    </RepoertHeaderWrapper>
  );
};

export default ReportHeader;
