import { CustomText } from "../../../styles/CustomText";
import AdminGroupList from "./components/AdminGroupList";

function AdminGroupPermissionPage() {
  return (
    <>
      <CustomText type="title">그룹 및 권한 관리</CustomText>
      <AdminGroupList />
    </>
  );
}

export default AdminGroupPermissionPage;
