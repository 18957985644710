import { EmployeeRole } from "../../../types/Employee/Employee";
import { DisplayMode } from "../../../types/DashBoard/Portlet";
import { SwitchRoleDisplayModeContentMap } from "../../../types/Common/SwitchComponent";

export const SwitchRoleDisplayModeComponent = ({
  switchContentMap,
  role,
  id,
  displayMode,
}: {
  switchContentMap: SwitchRoleDisplayModeContentMap<{
    id: number;
    displayMode: DisplayMode;
  }>;
  role: EmployeeRole;
  id: number;
  displayMode: DisplayMode;
}) => {
  if (role) {
    const Component = switchContentMap[role][displayMode]?.[id];
    return Component ? (
      <Component id={id} displayMode={displayMode} />
    ) : (
      <div>지정된 내용이 없습니다.</div>
    );
  }
  return null;
};

export default SwitchRoleDisplayModeComponent;
