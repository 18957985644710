import { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

interface ContentProps {
  children: ReactNode;
}

export const Mobile = ({ children }: ContentProps) => {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });
  return <>{isMobile && children}</>;
};

export const Tablet = ({ children }: ContentProps) => {
  const isTablet = useMediaQuery({
    minWidth: 769,
    maxWidth: 992,
  });
  return <>{isTablet && children}</>;
};

export const TabletPc = ({ children }: ContentProps) => {
  const isTabletPc = useMediaQuery({
    minWidth: 769,
  });
  return <>{isTabletPc && children}</>;
};

export const Pc = ({ children }: ContentProps) => {
  const isPc = useMediaQuery({
    minWidth: 993,
  });
  return <>{isPc && children}</>;
};

export const TabletMobile = ({ children }: ContentProps) => {
  const isTabletMobile = useMediaQuery({
    maxWidth: 992,
  });
  return <>{isTabletMobile && children}</>;
};

export const PcLogin992 = ({ children }: ContentProps) => {
  const isPcLogin992 = useMediaQuery({
    minWidth: 993,
    maxWidth: 1200,
  });
  return <>{isPcLogin992 && children}</>;
};

export const PcLogin1200 = ({ children }: ContentProps) => {
  const isPcLogin1200 = useMediaQuery({
    minWidth: 1201,
  });
  return <>{isPcLogin1200 && children}</>;
};
