export const expectedTimingConversion = (type: string | null) => {
  const todayDate = new Date();

  var daysToAdd = 0;
  if (type === "1개월이내") {
    daysToAdd = 30;
  } else if (type === "3개월이내") {
    daysToAdd = 90;
  } else if (type === "6개월이내") {
    daysToAdd = 180;
  } else if (type === "12개월이내") {
    daysToAdd = 365;
  } else {
    return null;
  }

  var futureDate = new Date(
    todayDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000
  );

  // 년, 월, 일을 각각 얻습니다.
  var year = futureDate.getFullYear();
  var month = futureDate.getMonth() + 1; // 월은 0부터 시작하므로 1을 더합니다.
  var day = futureDate.getDate();

  // 결과를 문자열로 반환합니다.
  return `${year}-${month >= 10 ? month : "0" + month}-${
    day >= 10 ? day : "0" + day
  }`;
};
