import { useState } from "react";
import {
  ActivitySectionStatesInfo,
  LeadCustomerDetailInfo,
} from "../../../../../system/types/Customer";
import {
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  LeadTableTitleHover,
  TableFixedHeight,
} from "../../../../../styles/theme";
import { CustomText } from "../../../../../styles/CustomText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { GRAYPALECONTENTS, GRAYPALETITLE } from "../../../../../styles/Color";

interface ActivityInfoPorps {
  data: LeadCustomerDetailInfo | null;
  sectionStates: ActivitySectionStatesInfo;
  toggleSection: (title: keyof ActivitySectionStatesInfo) => void;
}

function ActivityInfo({
  data,
  sectionStates,
  toggleSection,
}: ActivityInfoPorps) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <LeadTableCardBox mt="20px">
      <LeadTableTitleHover
        container
        item
        xs={12}
        onClick={() => toggleSection("ActivityInfo")}
        justifyContent="space-between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CustomText type="subtitle" lineSpace>
          가망고객 정보
        </CustomText>
        {isHovered ? (
          <>{sectionStates["ActivityInfo"] ? <ExpandLess /> : <ExpandMore />}</>
        ) : (
          ""
        )}
      </LeadTableTitleHover>
      {sectionStates["ActivityInfo"] && (
        <LeadTableContentsBox container item xs={12} alignItems="center">
          <TableFixedHeight container item xs={12} sm={6}>
            <LeadTableTitle item xs={4}>
              <CustomText
                type="subtitle"
                bold400
                lineSpace
                color={GRAYPALECONTENTS}
              >
                상호명
              </CustomText>
            </LeadTableTitle>
            <LeadTableContent item xs={8} pl="20px">
              <CustomText
                type="subtitle"
                bold400
                lineSpace
                color={GRAYPALETITLE}
              >
                {data?.customer?.address?.placeName
                  ? data?.customer?.address.placeName
                  : "-"}
              </CustomText>
            </LeadTableContent>
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6}>
            <LeadTableTitle item xs={4}>
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                고객 담당자
              </CustomText>
            </LeadTableTitle>
            <LeadTableContent item xs={8} pl="20px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                lineSpace
              >
                {data?.customer?.name ? data?.customer?.name : "-"}
              </CustomText>
            </LeadTableContent>
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6}>
            <LeadTableTitle item xs={4}>
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                고객 연락처(핸드폰)
              </CustomText>
            </LeadTableTitle>
            <LeadTableContent item xs={8} pl="20px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                lineSpace
              >
                {data?.customer?.phone ? data?.customer?.phone : "-"}
              </CustomText>
            </LeadTableContent>
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6}>
            <LeadTableTitle item xs={4}>
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                고객 연락처(내선)
              </CustomText>
            </LeadTableTitle>
            <LeadTableContent item xs={8} pl="20px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                lineSpace
              >
                {data?.customer?.officeNumber
                  ? data?.customer?.officeNumber
                  : "-"}
              </CustomText>
            </LeadTableContent>
          </TableFixedHeight>

          <TableFixedHeight container item xs={12} sm={6}>
            <LeadTableTitle item xs={4}>
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                고객 이메일
              </CustomText>
            </LeadTableTitle>
            <LeadTableContent item xs={8} pl="20px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                lineSpace
              >
                {data?.customer?.email ? data?.customer?.email : "-"}
              </CustomText>
            </LeadTableContent>
          </TableFixedHeight>
        </LeadTableContentsBox>
      )}
    </LeadTableCardBox>
  );
}

export default ActivityInfo;
