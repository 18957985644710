import { Grid } from "@mui/material";
import { ScheduleDetailInfo } from "../../system/types/Board";
import { CustomText } from "../../styles/CustomText";
import { TextareaPreLine } from "../../styles/theme";

interface ScheduleDetailProps {
  selectedData: ScheduleDetailInfo;
}

function ScheduleDetail({ selectedData }: ScheduleDetailProps) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={4} margin="5px 0px">
        <CustomText type="subtitle">일정 시작 시간</CustomText>
      </Grid>
      <Grid item xs={8} margin="5px 0px">
        <CustomText type="subtitle" bold400 lineSpace>
          {selectedData.startTime}
        </CustomText>
      </Grid>
      <Grid item xs={4} margin="5px 0px">
        <CustomText type="subtitle">일정 종료 시간</CustomText>
      </Grid>
      <Grid item xs={8} margin="5px 0px">
        <CustomText type="subtitle" bold400 lineSpace>
          {selectedData.endTime}
        </CustomText>
      </Grid>
      <Grid item xs={4} margin="5px 0px">
        <CustomText type="subtitle">제목</CustomText>
      </Grid>
      <Grid item xs={8} margin="5px 0px">
        <CustomText type="subtitle" bold400 lineSpace>
          {selectedData.subject}
        </CustomText>
      </Grid>
      <Grid item xs={4} margin="5px 0px">
        <CustomText type="subtitle">내용</CustomText>
      </Grid>
      <TextareaPreLine item xs={8} margin="5px 0px">
        <CustomText type="subtitle" bold400 lineSpace>
          {selectedData.contents}
        </CustomText>
      </TextareaPreLine>
      <Grid item xs={4} margin="5px 0px">
        <CustomText type="subtitle">작성자</CustomText>
      </Grid>
      <Grid item xs={8} margin="5px 0px">
        <CustomText type="subtitle" bold400 lineSpace>
          {selectedData.createdBy}
        </CustomText>
      </Grid>
    </Grid>
  );
}

export default ScheduleDetail;
