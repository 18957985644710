import Swal from "sweetalert2";
import { GREENCONTENTS } from "../../styles/Color";

export const messageModal = (message?: string) => {
  return Swal.fire({
    title:
      "<div style='font-family:Noto Sans KR;font-size:20px;margin-bottom:10px'>" +
      message +
      "</div>",
    icon: "error",
    confirmButtonText: "확인",
    confirmButtonColor: GREENCONTENTS,
  });
};
