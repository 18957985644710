import { Grid, styled } from "@mui/material";
import { LeadMyTableInfo } from "../../../../system/types/Customer";

import {
  BLUEBOX,
  BLUETITLE,
  GRAYPALEBACKGROUND1,
  GRAYPALEBORDER,
  GREENBOX,
  GREENTITLE,
  ORANGEBOX,
  ORANGEDISABLED,
  REDBOX,
  REDTITLE,
} from "../../../../styles/Color";
import CircleIcon from "@mui/icons-material/Circle";

import { useHistory } from "react-router-dom";

interface SplitterBoxProps {
  data: LeadMyTableInfo;
  onChangeFocus: (id: number) => void;
  detailedId: null | number;
}

function SplitterBox({ data, onChangeFocus, detailedId }: SplitterBoxProps) {
  const history = useHistory();

  function onMove(id: number) {
    history.push(`/list/detail/${id}`);
  }

  return (
    <>
      {data.status === null && (
        <TableTitletBox
          colortype={GRAYPALEBACKGROUND1}
          container
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            onChangeFocus(data.id);
          }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={10} container justifyContent="center">
            {data.placeName}
          </Grid>
          <Grid item xs={1}></Grid>
        </TableTitletBox>
      )}
      {data.status === "green" && (
        <TableTitletBox
          colortype={GREENBOX}
          container
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            onChangeFocus(data.id);
          }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={10} container justifyContent="center">
            {data.placeName}
          </Grid>
          <Grid item xs={1} container alignItems="center">
            <CircleIcon style={{ color: GREENTITLE }} />
          </Grid>
        </TableTitletBox>
      )}
      {data.status === "yellow" && (
        <TableTitletBox
          colortype={ORANGEBOX}
          container
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            onChangeFocus(data.id);
          }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={10} container justifyContent="center">
            {data.placeName}
          </Grid>
          <Grid item xs={1} container alignItems="center">
            <CircleIcon style={{ color: ORANGEDISABLED }} />
          </Grid>
        </TableTitletBox>
      )}
      {data.status === "red" && (
        <TableTitletBox
          colortype={REDBOX}
          container
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            onChangeFocus(data.id);
          }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={10} container justifyContent="center">
            {data.placeName}
          </Grid>
          <Grid item xs={1} container alignItems="center">
            <CircleIcon style={{ color: REDTITLE }} />
          </Grid>
        </TableTitletBox>
      )}
      {data.status === "blue" && (
        <TableTitletBox
          colortype={BLUEBOX}
          container
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            onChangeFocus(data.id);
          }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={10} container justifyContent="center">
            {data.placeName}
          </Grid>
          <Grid item xs={1} container alignItems="center">
            <CircleIcon style={{ color: BLUETITLE }} />
          </Grid>
        </TableTitletBox>
      )}
      {detailedId === data.id ? (
        <>
          <TableContentBox item xs={6}>
            주소
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.address ? data.address : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            제품군
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.productFamily ? data.productFamily : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            모델명
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.productName ? data.productName : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            업종
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.businessType ? data.businessType : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            판매방식
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.saleType ? data.saleType : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            영업방식
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.contactTypeMajor
              ? `[${data.contactTypeMajor}] ${data.contactTypeSub}`
              : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            영업 담당자
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.sellerName ? data.sellerName : "-"}
          </TableContentBox>

          <TableContentBox item xs={6}>
            구매 예상 시기
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.createdAt ? data.createdAt : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            미팅노트 작성일
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.followUpDate ? data.followUpDate : "-"}
          </TableContentBox>

          <TableContentBox item xs={6}>
            총 합계
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.totalPrice ? (
              <>{data.totalPrice.toLocaleString() + " 원"}</>
            ) : (
              "-"
            )}
          </TableContentBox>
          <TableMoveBox
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(data.id)}
          >
            자세히 보기
          </TableMoveBox>
        </>
      ) : (
        <>
          <TableContentBox item xs={6}>
            {data.sellerName ? data.sellerName : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.productName ? data.productName : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.followUpDate ? data.followUpDate : "-"}
          </TableContentBox>
          <TableContentBox item xs={6}>
            {data.totalPrice ? (
              <>{data.totalPrice.toLocaleString() + " 원"}</>
            ) : (
              "-"
            )}
          </TableContentBox>
        </>
      )}
    </>
  );
}

export default SplitterBox;

interface TableTitleBoxProps {
  colortype: string;
}

const TableTitletBox = styled(Grid)(({ colortype }: TableTitleBoxProps) => ({
  border: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: colortype,
  padding: "7px 0px",
  textAlign: "center",
  height: "40px",
  cursor: "pointer",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const TableContentBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  padding: "7px 0px",
  textAlign: "center",
  height: "40px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const TableMoveBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: `#F0FAF7`,
  color: "#47B998",
  textAlign: "center",
  height: "40px",
  cursor: "pointer",
  fontWeight: 600,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  "&:hover": {
    backgroundColor: "#E4F7F1",
    boxShadow: "none",
    color: "#47B998",
  },
});
