import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { SortStatus } from "../../../types/Common/CommonMuiTable";

type CommonMuiTableSortIconProps = {
  sortStatus: SortStatus;
};

const SwitchSortIcon = ({ sortStatus }: CommonMuiTableSortIconProps) => {
  switch (sortStatus) {
    case "asc":
      return (
        <ArrowUpwardIcon
          fontSize="small"
          sx={{
            transform: "translate(2px, 4px)",
          }}
        />
      );
    case "desc":
      return (
        <ArrowDownwardIcon
          fontSize="small"
          sx={{
            transform: "translate(2px, 4px)",
          }}
        />
      );
    case "none":
      return (
        <SwapVertIcon
          fontSize="small"
          sx={{
            transform: "translate(2px, 4px)",
            color: "gray", // 비활성화된 아이콘 색상
            opacity: 0.5, // 투명도를 낮춰 비활성화 효과
          }}
        />
      );
    case "disabled":
      return null;

    default:
      return null;
  }
};

const CommonMuiTableSortIcon = ({
  sortStatus,
}: CommonMuiTableSortIconProps) => {
  return <SwitchSortIcon sortStatus={sortStatus} />;
};

export default CommonMuiTableSortIcon;
