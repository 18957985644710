import { Grid } from "@mui/material";
import { SelectColorProps } from "../../../../types/Customers/Customers";
import { StatusWrapper } from "../../../../styles/List/Lead/leadPageStyle";
import {
  Blue100,
  Brand100,
  ErrorText,
  Gray100,
  Gray400,
  Green100,
  SuccessText,
  WaitingText,
  Yellow100,
  Yellow400,
} from "../../../../styles/Color";

const SelectColor = ({
  options,
  value,
  handleColorChange,
}: SelectColorProps) => {
  const getColor = (color: string) => {
    switch (color) {
      case "red":
        return value.includes(color) ? ErrorText : Brand100;
      case "yellow":
        return value.includes(color) ? Yellow400 : Yellow100;
      case "green":
        return value.includes(color) ? SuccessText : Green100;
      case "blue":
        return value.includes(color) ? WaitingText : Blue100;
      case "grey":
        return value.includes(color) ? Gray400 : Gray100;
      default:
        return "#FFFFFF";
    }
  };

  return (
    <Grid container>
      {options.map((color) => (
        <StatusWrapper
          key={color}
          color={getColor(color)}
          onClick={() => handleColorChange(color)}
        />
      ))}
    </Grid>
  );
};

export default SelectColor;
