import { Box, Pagination, TableBody, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import {
  CommonMuiTableProps,
  TableCellData,
  TableHeadCellData,
} from "../../../types/Common/CommonMuiTable";

import {
  CommonMuiTableBodyCell,
  CommonMuiTableHeadCell,
  CommonMuiTableHeadCellText,
  CommonMuiTableHeadCellTextWrapper,
  CommonMuiTableInnerWrapper,
  CommonMuiTableOutterWrapper,
  CommonMuiTableWrapper,
  PaginationInnerWrapper,
  PaginationOutterWrapper,
} from "../../../styles/Common/commonMuiTableStyle";
import { ellipsis } from "../../../styles/Common/util";
import { useDeviceType } from "../../../hooks/useDeviceType";
import Message from "../Message/message";
import { t } from "i18next";
import CommonMuiTableSortIcon from "./CommonMuiTableSortIcon";
import { checkEmptyRowList } from "../../../utils/Table/table";

const CommonMuiTable = <
  C extends TableHeadCellData[],
  R extends TableCellData[]
>({
  columnList,
  rowList,
  pageNumber = 1,
  totalPageNumber = 1,
  usingPagination = true,
  handleHeadCellClick,
  handleRowClick,
  handlePaginationChange,
}: CommonMuiTableProps<C, R>) => {
  const [keyList, setKeyList] = useState<string[]>([]);
  const deviceType = useDeviceType();
  useEffect(() => {
    setKeyList(columnList.map((el) => el.key));
  }, [columnList]);

  return (
    <CommonMuiTableOutterWrapper>
      <CommonMuiTableInnerWrapper>
        <CommonMuiTableWrapper>
          <TableHead>
            <TableRow>
              {columnList.map((column, cIdx) => (
                <CommonMuiTableHeadCell
                  key={`column${column.key}`}
                  onClick={() => handleHeadCellClick?.(column)}
                  sx={{
                    minWidth: column.width ? column.width : "auto",
                  }}
                >
                  <CommonMuiTableHeadCellTextWrapper>
                    {column.value.split("<br/>").map((str, idx) => (
                      <CommonMuiTableHeadCellText key={`headCell${str}${idx}`}>
                        {str}
                      </CommonMuiTableHeadCellText>
                    ))}
                  </CommonMuiTableHeadCellTextWrapper>

                  <CommonMuiTableSortIcon
                    sortStatus={column.sortStatus ?? "disabled"}
                  />
                </CommonMuiTableHeadCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {checkEmptyRowList(rowList) ? (
              <TableRow>
                <CommonMuiTableBodyCell
                  colSpan={columnList.length}
                  sx={{ height: "360px" }}
                >
                  <Message text={t("No data found")} />
                </CommonMuiTableBodyCell>
              </TableRow>
            ) : (
              rowList.map((row, rIdx) => {
                return (
                  <TableRow
                    key={`row${rIdx}`}
                    onClick={() => handleRowClick?.(row)}
                  >
                    {keyList.map((key, idx) => {
                      const column = columnList[idx];
                      const ellipsisStyle = column.usingEllipsis
                        ? { width: "100%", ...ellipsis(1) }
                        : { width: "100%" };
                      return (
                        <CommonMuiTableBodyCell
                          key={`item${key}${idx}`}
                          sx={{ textAlign: columnList[idx].textAlign }}
                          onClick={(event) => {
                            if (columnList[idx]?.handleBodyCellClick) {
                              event.stopPropagation();
                              columnList[idx].handleBodyCellClick?.(row);
                            }
                          }}
                        >
                          <Box sx={ellipsisStyle}>{row[key]}</Box>
                        </CommonMuiTableBodyCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </CommonMuiTableWrapper>
      </CommonMuiTableInnerWrapper>
      {usingPagination && rowList && (
        <PaginationOutterWrapper>
          <PaginationInnerWrapper>
            <Pagination
              count={totalPageNumber ? totalPageNumber : rowList.length}
              showFirstButton
              showLastButton
              page={pageNumber}
              boundaryCount={2}
              onChange={handlePaginationChange}
              size="small"
              sx={{ width: "100%" }}
            />
          </PaginationInnerWrapper>
        </PaginationOutterWrapper>
      )}
    </CommonMuiTableOutterWrapper>
  );
};

export default CommonMuiTable;
