import { Grid } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import SalesProtectionSetting from "./components/SalesProtectionSetting";

function AdminQAQCMain() {
  return (
    <>
      <CustomText type="title">조달검사검수</CustomText>
      <Grid item xs={12}></Grid>
      <Grid container item xs={12} justifyContent="center" mt="30px">
        <SalesProtectionSetting />
      </Grid>
    </>
  );
}

export default AdminQAQCMain;
