import client from "../ApiService";
import { _BasicApi } from "../BasicApi";

const listUri: string = "/sas/token";

class _TokenApi extends _BasicApi {
  getSasToken() {
    return this.client.get(`${listUri}`);
  }
}

export const TokenApi = new _TokenApi(client);
