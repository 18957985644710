import { Dispatch, useEffect, useState } from "react";
import {
  IntermediaryCompanyInfo,
  LeadCustomerCreateInfo,
} from "../../../../../system/types/Customer";
import * as gregorian from "../../../../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as numberingSystems from "../../../../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import {
  EditDropDownListComponentBox,
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  TableFixedHeight,
  TextareaLineBox,
} from "../../../../../styles/theme";
import { Grid } from "@mui/material";
import { CustomText } from "../../../../../styles/CustomText";
import { GRAYPALECONTENTS, REDTITLE } from "../../../../../styles/Color";
import { CustomerApi } from "../../../../../system/api/customerApi";
import { ErrorHandler } from "../../../../../system/ApiService";
import { AlertModal } from "../../../../../components/Common/AlertModal";
import {
  ContactTypeListInfo,
  DropdownListInfo,
  TagListInfo,
} from "../../../../../system/types/List";
import { ListApi } from "../../../../../system/api/listApi";
import { TagApi } from "../../../../../system/api/tagApi";
import { FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { expectedTimingConversion } from "../../../../../components/Common/expectedTimingConversion";
import { isoDateFormatter } from "../../../../../components/Common/isoDateFormatter";
import SelectTag from "../../../../../components/Common/SelectTag";
import { PURCHASEPROBABILITY_TYPE } from "../../../../../system/constants";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { GroupApi } from "../../../../../system/api/groupApi";
import { useRecoilValue } from "recoil";
import { MyInfoState } from "../../../../../system/atoms";
import { GroupInfo } from "../../../../../types/Group/group";
interface EditLeadEtcProps {
  leadId: number;
  data: LeadCustomerCreateInfo;
  setData: Dispatch<React.SetStateAction<LeadCustomerCreateInfo>>;
  onSave: () => void;
  directWritePlaceName: string;
  setDirectWritePlaceName: (directWritePlaceName: string) => void;
  directInput: boolean;
  setDirectInput: (directInput: boolean) => void;
  tagData: TagListInfo[];
  setTagData: (tagData: TagListInfo[]) => void;
  selectedTags: TagListInfo[];
  setSelectedTags: (selectedTags: TagListInfo[]) => void;
}
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({
  ko: {
    datepicker: { today: "오늘" },
    dropdowns: { noRecordsTemplate: "해당 데이터가 없습니다." },
  },
});

function EditLeadEtc({
  data,
  setData,
  onSave,
  directWritePlaceName,
  setDirectWritePlaceName,
  directInput,
  setDirectInput,
  tagData,
  setTagData,
  selectedTags,
  setSelectedTags,
}: EditLeadEtcProps) {
  const { role, groups } = useRecoilValue(MyInfoState);
  // 중개업체명
  const [intermediaryData, setIntermediaryData] = useState<
    IntermediaryCompanyInfo[]
  >([]);
  const intermediaryFields: Object = {
    value: "id",
    text: "address.placeName",
    child: "children",
  };

  useEffect(() => {
    CustomerApi.getIntermediaryInfo()
      .then((res) => {
        setIntermediaryData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onFilteringIntermediary = (
    args: FilteringEventArgs,
    queryData: any
  ) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("address.placeName", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  // 수주유형
  const [contractTypeData, setContractTypeData] = useState<DropdownListInfo[]>(
    []
  );
  const contractTypeFields: Object = {
    dataSource: contractTypeData,
    value: "id",
    text: "name",
    child: "children",
  };
  useEffect(() => {
    ListApi.getDropdownList("contractType")
      .then((res) => {
        setContractTypeData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 판매방식
  const [saleTypeData, setSaleTypeData] = useState<DropdownListInfo[]>([]);

  const saleTypeDataFields: Object = {
    dataSource: saleTypeData,
    value: "id",
    text: "name",
    child: "children",
  };
  useEffect(() => {
    ListApi.getDropdownList("salesType")
      .then((res) => {
        setSaleTypeData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 공급유형
  const [supplyTypeData, setSupplyTypeData] = useState<DropdownListInfo[]>([]);

  const supplyTypeDataFields: Object = {
    value: "id",
    text: "name",
  };
  useEffect(() => {
    ListApi.getDropdownList("supplyType")
      .then((res) => {
        setSupplyTypeData(
          res.data.filter((item: DropdownListInfo) => item.enabled)
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  // 영업방식
  const [contactTypeData, setContactTypeData] = useState<ContactTypeListInfo[]>(
    []
  );
  const contactTypeDataFields: Object = {
    groupBy: "major",
    value: "id",
    text: "sub",
  };

  useEffect(() => {
    const findTopGroupNameByName = (
      id: number,
      groupData: GroupInfo[]
    ): number | null => {
      for (const item of groupData) {
        if (item.id === id) {
          if (item.parentId) {
            return item.parentId;
          } else {
            return item.id;
          }
        } else if (item.children && item.children.length > 0) {
          const result: number | null = findTopGroupNameByName(
            id,
            item.children
          );
          if (result) {
            return result;
          }
        }
      }

      return null;
    };

    if (groups && groups.length > 0 && groups[0]?.name) {
      const myGroup = groups[0].id;

      GroupApi.getAllGroupInfo()
        .then((res) => {
          if (myGroup) {
            const topGroupId = findTopGroupNameByName(myGroup, res.data);

            if (topGroupId === 2) {
              ListApi.getContactTypeList()
                .then((res) => {
                  setContactTypeData(
                    res.data.filter(
                      (item: ContactTypeListInfo) =>
                        item.branchEditable && item.enabled
                    )
                  );
                })
                .catch((err) => {
                  let msg = ErrorHandler(err);
                  AlertModal("msg", msg);
                });
            } else {
              ListApi.getContactTypeList()
                .then((res) => {
                  setContactTypeData(
                    res.data.filter((item: DropdownListInfo) => item.enabled)
                  );
                })
                .catch((err) => {
                  let msg = ErrorHandler(err);
                  AlertModal("msg", msg);
                });
            }
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    } else {
      ListApi.getContactTypeList()
        .then((res) => {
          setContactTypeData(
            res.data.filter((item: DropdownListInfo) => item.enabled)
          );
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [role, groups]);

  // 태그
  const onChangeTag = (tagList: TagListInfo[]) => {
    setSelectedTags(tagList);
  };
  useEffect(() => {
    TagApi.getTagList()
      .then((res) => {
        setTagData(res.data.filter((item: DropdownListInfo) => item.enabled));
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdown") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    } else if (type === "dropdownStage") {
      if (args.target.itemData) {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: args.target.itemData,
          estimatedDate: expectedTimingConversion(args.target.itemData.name),
        });
      } else {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: null,
          estimatedDate: null,
        });
      }
    } else if (type === "date") {
      if (args.target.value) {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: isoDateFormatter(
            new Date(args.target.value)
          ),
        });
      } else {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: null,
        });
      }
    } else if (type === "inputdetail") {
      setData({
        ...data,
        address: { ...data.address, detail: args.target.value },
      });
    } else if (type === "inputName") {
      setDirectWritePlaceName(args.target.value);
    } else if (type === "intermediaryDropdown") {
      const targetData = args.target.itemData;
      if (targetData) {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: targetData.id,
        });
      } else {
        setData((prev: LeadCustomerCreateInfo) => ({ ...prev }));
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: null,
        });
      }
    }
  };

  const onFiltering = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("name", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  const onFilteringType = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("sub", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  function itemTemplate(data: { id: number; name: number }) {
    return <>{data.name.toString() + " %"}</>;
  }

  function valueTemplate(data: { id: number; name: number }) {
    return (
      <CustomText type="subtitle" bold400>
        {data.name + " %"}
      </CustomText>
    );
  }
  const itemFields = { text: "name", value: "name" };
  return (
    <LeadTableCardBox style={{ marginTop: "20px" }}>
      <Grid container item xs={12} padding="10px">
        <CustomText type="subtitle">기타 정보</CustomText>
      </Grid>
      <LeadTableContentsBox container item xs={12}>
        {data.customerType === "normal" && (
          <>
            <TableFixedHeight container item xs={12} sm={6} alignItems="center">
              <LeadTableTitle container justifyContent="right" item xs={4}>
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALECONTENTS}
                >
                  중개업체명
                </CustomText>
              </LeadTableTitle>
              <LeadTableContent container item xs={8} padding="0px 10px">
                <EditDropDownListComponentBox
                  locale="ko"
                  showClearButton={true}
                  dataSource={intermediaryData}
                  filtering={(args: any) =>
                    onFilteringIntermediary(args, intermediaryData)
                  }
                  allowFiltering={true}
                  fields={intermediaryFields}
                  name="intermediaryCompanyId"
                  value={data.intermediaryCompanyId}
                  onChange={(e: any) => {
                    onChange(e, "intermediaryDropdown");
                  }}
                  placeholder={"중개업체를 선택해주세요."}
                  autoComplete="new-password"
                />
              </LeadTableContent>
            </TableFixedHeight>
            <TableFixedHeight container item xs={12} sm={6} alignItems="center">
              <LeadTableTitle container justifyContent="right" item xs={4}>
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALECONTENTS}
                >
                  판매방식
                </CustomText>
                <CustomText fontSize="small" color={REDTITLE}>
                  *
                </CustomText>
              </LeadTableTitle>
              <LeadTableContent container item xs={8} padding="0px 10px">
                <EditDropDownListComponentBox
                  locale="ko"
                  showClearButton={true}
                  dataSource={saleTypeData}
                  filtering={(args: any) => onFiltering(args, saleTypeData)}
                  allowFiltering={true}
                  fields={saleTypeDataFields}
                  name="saleType"
                  value={data.saleType?.id}
                  onChange={(e: any) => {
                    onChange(e, "dropdown");
                  }}
                  placeholder={
                    data.saleType?.id
                      ? data.saleType.name
                      : "판매방식을 선택해주세요."
                  }
                  autoComplete="new-password"
                />
              </LeadTableContent>
            </TableFixedHeight>
          </>
        )}
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              영업방식
            </CustomText>
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8} padding="0px 10px">
            <EditDropDownListComponentBox
              locale="ko"
              filtering={(args: any) => onFilteringType(args, contactTypeData)}
              allowFiltering={true}
              showClearButton={true}
              dataSource={contactTypeData}
              fields={contactTypeDataFields}
              name="contactType"
              value={data.contactType?.id}
              onChange={(e: any) => {
                onChange(e, "dropdown");
              }}
              placeholder={
                data.contactType?.id
                  ? data.contactType.sub
                  : "영업방식을 선택해주세요."
              }
              autoComplete="new-password"
            />
          </LeadTableContent>
        </TableFixedHeight>
        {data.customerType === "normal" && (
          <>
            <TableFixedHeight container item xs={12} sm={6} alignItems="center">
              <LeadTableTitle container justifyContent="right" item xs={4}>
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALECONTENTS}
                >
                  수주유형
                </CustomText>
              </LeadTableTitle>
              <LeadTableContent container item xs={8} padding="0px 10px">
                <EditDropDownListComponentBox
                  locale="ko"
                  dataSource={contractTypeData}
                  filtering={(args: any) => onFiltering(args, contractTypeData)}
                  allowFiltering={true}
                  fields={contractTypeFields}
                  name="contractType"
                  value={data.contractType?.id}
                  showClearButton={true}
                  onChange={(e: any) => {
                    onChange(e, "dropdown");
                  }}
                  placeholder={
                    data.contractType?.id
                      ? data.contractType.name
                      : "수주유형을 선택해주세요."
                  }
                  autoComplete="new-password"
                />
              </LeadTableContent>
            </TableFixedHeight>
            <TableFixedHeight container item xs={12} sm={6} alignItems="center">
              <LeadTableTitle container justifyContent="right" item xs={4}>
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALECONTENTS}
                >
                  공급유형
                </CustomText>
              </LeadTableTitle>
              <LeadTableContent container item xs={8} padding="0px 10px">
                <EditDropDownListComponentBox
                  locale="ko"
                  showClearButton={true}
                  dataSource={supplyTypeData}
                  filtering={(args: any) => onFiltering(args, supplyTypeData)}
                  allowFiltering={true}
                  fields={supplyTypeDataFields}
                  name="supplyType"
                  value={data.supplyType?.id}
                  onChange={(e: any) => {
                    onChange(e, "dropdown");
                  }}
                  placeholder={
                    data.supplyType?.id
                      ? data.supplyType.name
                      : "공급유형을 선택해주세요."
                  }
                  autoComplete="new-password"
                />
              </LeadTableContent>
            </TableFixedHeight>
          </>
        )}

        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              태그
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8} padding="0px 10px">
            <SelectTag
              list={selectedTags}
              onChange={onChangeTag}
              tagList={tagData}
            />
          </LeadTableContent>
        </TableFixedHeight>
        {data.customerType === "normal" && (
          <>
            <TableFixedHeight container item xs={12} sm={6} alignItems="center">
              <LeadTableTitle container justifyContent="right" item xs={4}>
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALECONTENTS}
                >
                  구매 예상 시기
                </CustomText>
              </LeadTableTitle>
              <LeadTableContent container item xs={8} padding="0px 10px">
                <DatePickerComponent
                  name="estimatedDate"
                  format="yyyy-MM-dd"
                  locale="ko"
                  min={new Date()}
                  value={
                    data.estimatedDate
                      ? new Date(data.estimatedDate)
                      : undefined
                  }
                  onChange={(e: any) => {
                    onChange(e, "date");
                  }}
                  placeholder="구매 예상 시기를 선택해주세요."
                />
              </LeadTableContent>
            </TableFixedHeight>
            <TableFixedHeight container item xs={12} sm={6} alignItems="center">
              <LeadTableTitle container justifyContent="right" item xs={4}>
                <CustomText
                  type="subtitle"
                  bold400
                  lineSpace
                  color={GRAYPALECONTENTS}
                >
                  구매 가능성
                </CustomText>
              </LeadTableTitle>
              <LeadTableContent container item xs={8} padding="0px 10px">
                <EditDropDownListComponentBox
                  locale="ko"
                  showClearButton={true}
                  dataSource={PURCHASEPROBABILITY_TYPE}
                  name="purchaseProbability"
                  value={
                    data.purchaseProbability ? data.purchaseProbability : null
                  }
                  onChange={(e: any) => {
                    onChange(e, "input");
                  }}
                  placeholder={
                    data.purchaseProbability
                      ? data.purchaseProbability
                      : "구매 가능성을 입력해주세요."
                  }
                  autoComplete="new-password"
                  itemTemplate={itemTemplate}
                  valueTemplate={valueTemplate}
                  fields={itemFields}
                />
              </LeadTableContent>
            </TableFixedHeight>
          </>
        )}

        <TableFixedHeight container item xs={12} alignItems="center" mt="10px">
          <LeadTableTitle container justifyContent="right" item xs={4} sm={2}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              비고
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={10} padding="0px 10px">
            <TextareaLineBox
              name="description"
              onChange={(e: any) => {
                onChange(e, "input");
              }}
              value={data.description || ""}
              placeholder="비고를 작성해 주세요."
              autoComplete="new-password"
            />
          </LeadTableContent>
        </TableFixedHeight>
      </LeadTableContentsBox>
    </LeadTableCardBox>
  );
}

export default EditLeadEtc;
