import { CustomText } from "../../../styles/CustomText";
import MyTableList from "../My/components/MyTableList";

function FollowUpPage() {
  return (
    <>
      <CustomText type="title"> 장기 F/U 목록</CustomText>
      <MyTableList type="long-term" />
    </>
  );
}

export default FollowUpPage;
