import client from "../ApiService";
import { _BasicApi } from "../BasicApi";
import { LeadOrderCreateInfo } from "../types/Customer";

const listUri: string = "/order";

class _OrderApi extends _BasicApi {
  createOrder(data: LeadOrderCreateInfo) {
    return this.client.post(`${listUri}`, data);
  }
  updateOrder(orderId: number, data: LeadOrderCreateInfo) {
    return this.client.put(`${listUri}/${orderId}`, data);
  }
  deleteOrder(orderId: number) {
    return this.client.delete(`${listUri}/${orderId}`);
  }
}

export const OrderApi = new _OrderApi(client);
