import { useHistory } from "react-router-dom";
import { IntermediaryCompanyInfo } from "../../../../system/types/Customer";
import { useState } from "react";
import { Grid } from "@mui/material";
import {
  MobileTableContentBox,
  MobileTableContentDetailBox,
  MobileTableContentsBox,
  MobileTableTitleBox,
} from "../../../../styles/theme";
import { GRAYPALEDISABLED } from "../../../../styles/Color";
import { SortAscending } from "@phosphor-icons/react";
import uuid from "react-uuid";
import { CustomButtonGreen } from "../../../../styles/buttonStyle";

interface IntermediaryTableListMobileProps {
  data: IntermediaryCompanyInfo[];
}

function IntermediaryTableListMobile({
  data,
}: IntermediaryTableListMobileProps) {
  const history = useHistory();
  const [detailInfoId, setDetailInfoId] = useState(0);
  const [infoOpen, setInfoOpen] = useState(false);

  function onChangeDetailInfoId(id: number | undefined, index: number) {
    if (infoOpen && index !== detailInfoId) {
      setDetailInfoId(index);
    } else {
      setInfoOpen(!infoOpen);
      setDetailInfoId(index);
    }
  }

  function onMove(id: number | undefined) {
    if (id) {
      history.push(`/list/detail/${id}`);
    }
  }
  return (
    <Grid style={{ marginTop: "10px" }}>
      <MobileTableTitleBox container alignItems="center">
        <SortAscending size={32} style={{ color: GRAYPALEDISABLED }} />
      </MobileTableTitleBox>
      <Grid>
        {data.map((dt: IntermediaryCompanyInfo, index: number) => (
          <Grid key={uuid()}>
            <MobileTableContentsBox
              container
              onClick={() => onChangeDetailInfoId(dt.id, index)}
            >
              <MobileTableContentBox item xs={4}>
                {dt.address?.placeName}
              </MobileTableContentBox>
              <MobileTableContentBox item xs={5}>
                {dt.address?.detail ? dt.address?.detail : dt.address?.road}
              </MobileTableContentBox>
              <MobileTableContentBox item xs={3}>
                {dt.name}
              </MobileTableContentBox>
            </MobileTableContentsBox>
            {infoOpen && detailInfoId === index && (
              <Grid container>
                <MobileTableContentDetailBox item xs={4}>
                  상호
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={8}>
                  {dt.address?.placeName}
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={4}>
                  주소
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={8}>
                  {dt.address?.road}
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={4}>
                  상세주소
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={8}>
                  {dt.address?.detail}
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={4}>
                  영업 담당자
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox
                  item
                  xs={8}
                ></MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={4}>
                  영업 방식
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={8}>
                  [{dt.contactTypeMajor}] {dt.contactTypeSub}
                </MobileTableContentDetailBox>
                <MobileTableContentDetailBox item xs={12}>
                  <CustomButtonGreen onClick={() => onMove(dt.id)}>
                    자세히 보기
                  </CustomButtonGreen>
                </MobileTableContentDetailBox>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default IntermediaryTableListMobile;
