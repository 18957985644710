import dayjs, { Dayjs } from "dayjs";
export interface KeyValueItem<T = string, R = string> {
  key: T;
  value: R;
}
export type EnumType = { [key: string]: string };
export type KeyValueList<T = string, R = string> = KeyValueItem<T, R>[];

export enum LeadListClassification {
  MY_ITEMS = "My Items",
  GROUP_ITEMS = "Group Items",
  ALL_ITEMS = "All Items",
}

export enum LeadBoardType {
  List = "View List",
  KanBanBoard = "Kanban Board",
}

export enum ComparatorStringType {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  CONTAINS = "CONTAINS",
  NOT_CONTAINS = "NOT_CONTAINS",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  EMPTY = "EMPTY",
  NOT_EMPTY = "NOT_EMPTY",
}

export enum ComparatorListType {
  IN = "IN",
  NOT_IN = "NOT_IN",
  EMPTY = "EMPTY",
}

export enum StatusType {
  GREEN = "green",
  YELLOW = "yellow",
  RED = "red",
  BLUE = "blue",
  GREY = "grey",
}

export interface FilterObject {
  comparator: string;
  field: string;
  value?: boolean | string | null;
  values?: number[] | string[] | null | [string | null, string | null];
}

export interface GetDropdownListRequest {
  searchText: string;
}
export interface GetDropdownListResponse {
  id: number;
  name: string;
  description: string;
  dropDownType: string;
  enabled: boolean;
  priority: number;
}

export interface GetContactListResponse {
  id: number;
  major: string;
  sub: string;
  description: string;
  branchEditable: boolean;
  enabled: boolean;
  priority: number;
}

export type DropdownList = GetDropdownListResponse[];
export type ContactList = GetContactListResponse[];

export interface StatusWrapperProps {
  color: string;
}
