import {
  EditTableHeadCellData,
  TableHeadCellData,
} from "../../types/Common/CommonMuiTable";

export const useGenerateColumnList = (target: TableHeadCellData[]) => {
  const result: TableHeadCellData[] = target.map(
    ({
      key,
      value,
      width,
      usingEllipsis,
      sortStatus,
      sortKey,
      textAlign,
      handleBodyCellClick,
    }) => ({
      key,
      value,
      width: width ? width : "auto",
      usingEllipsis: usingEllipsis ? usingEllipsis : true,
      sortStatus: sortStatus ? sortStatus : "disabled",
      sortKey,
      textAlign: textAlign ? textAlign : "center",
      handleBodyCellClick: handleBodyCellClick,
    })
  );
  return result;
};

export const useGenerateEditColumnList = (target: EditTableHeadCellData[]) => {
  const result: EditTableHeadCellData[] = target.map(
    ({ key, value, isEdit, type = "text", width }) => ({
      key,
      value,
      width,
      isEdit: isEdit,
      type,
    })
  );
  return result;
};
