import { useEffect, useState } from "react";
import { CustomText } from "../../../styles/CustomText";
import { IntermediaryCompanyInfo } from "../../../system/types/Customer";
import { CustomerApi } from "../../../system/api/customerApi";
import { useRecoilState } from "recoil";
import { BoardPageNumber } from "../../../system/atoms";
import { ErrorHandler } from "../../../system/ApiService";
import { AlertModal } from "../../../components/Common/AlertModal";
import {
  Pc,
  TabletMobile,
} from "../../../components/Common/Responseive/ResponsiveScreen";
import IntermediaryTableListPc from "./components/IntermediaryTableListPc";
import { Grid, IconButton, InputBase, Pagination } from "@mui/material";
import IntermediaryTableListMobile from "./components/IntermediaryTableListMobile";
import { SearchBoxComponent } from "../../../styles/theme";
import SearchIcon from "@mui/icons-material/Search";

function IntermediaryPage() {
  const [data, setData] = useState<IntermediaryCompanyInfo[]>([]);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [pageNumber, setPageNumber] = useRecoilState(BoardPageNumber);
  const [preSearchWord, setPreSearchWord] = useState("");
  const [searchWord, setSearchWord] = useState("");
  useEffect(() => {
    CustomerApi.getIntermediaryInfo(searchWord)
      .then((res) => {
        setTotalPageNumber(res.data.totalPages);
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchWord]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const onChangeSearch = (e: any) => {
    setPreSearchWord(e.target.value);
  };
  const ClickSearch = () => {
    setSearchWord(preSearchWord);
  };

  const handleOnKeyDownSearch = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      ClickSearch();
    }
  };

  return (
    <>
      <CustomText type="title"> 중개업체 목록</CustomText>
      <Grid container justifyContent="right" mt="10px">
        <SearchBoxComponent>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="검색어를 입력하세요."
            inputProps={{ "aria-label": "검색어를 입력하세요." }}
            onChange={onChangeSearch}
            onKeyDown={handleOnKeyDownSearch}
            autoComplete="new-password"
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={ClickSearch}
          >
            <SearchIcon />
          </IconButton>
        </SearchBoxComponent>
      </Grid>

      <Pc>
        <IntermediaryTableListPc data={data} />
      </Pc>
      <TabletMobile>
        <IntermediaryTableListMobile data={data} />
      </TabletMobile>
      <Grid container justifyContent="center" alignItems="center" mt="20px">
        <Pagination
          count={totalPageNumber}
          showFirstButton
          showLastButton
          page={pageNumber}
          boundaryCount={2}
          onChange={handleChange}
          size="large"
        />
      </Grid>
    </>
  );
}

export default IntermediaryPage;
