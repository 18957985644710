import { Box, styled } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { DisplayMode } from "../../../types/DashBoard/Portlet";
import { Brand600, Gray600 } from "../../../styles/Color";

export const DahsboardHeaderWrapper = styled(Box)({
  display: "flex",
  // justifyContent: "space-between",
  alignItems: "center",
});

export const DahsboardHeaderTitle = styled("h1")({
  fontSize: "1.25rem",
  display: "flex",
  padding: "8px 0",
});

export const DashboardHeaderRoleWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  paddingTop: "2px",
});

export const DashboardHeaderRole = styled(Box)({
  display: "flex",
});

export const DashboardHeaderRoleText = styled("span")({
  marginRight: "4px",
  cursor: "pointer",
  fontWeight: 600,
});
export const DashboardHeaderRoleAdminText = styled(DashboardHeaderRoleText)(
  ({ displaymode }: { displaymode: DisplayMode }) => ({
    color: displaymode === DisplayMode.FIRST ? Brand600 : Gray600,
  })
);
export const DashboardHeaderRoleUserText = styled(DashboardHeaderRoleText)(
  ({ displaymode }: { displaymode: DisplayMode }) => ({
    color: displaymode === DisplayMode.SECOND ? Brand600 : Gray600,
  })
);

export const AdminDisplayIcon = styled(AdminPanelSettingsIcon)(
  ({ displaymode }: { displaymode: DisplayMode }) => ({
    cursor: "pointer",
    fill: displaymode === DisplayMode.FIRST ? Brand600 : Gray600,
  })
);

export const PersonDisplayIcon = styled(PersonIcon)(
  ({ displaymode }: { displaymode: DisplayMode }) => ({
    cursor: "pointer",
    fill: displaymode === DisplayMode.SECOND ? Brand600 : Gray600,
  })
);
