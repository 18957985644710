import { Grid } from "@mui/material";
import { ScheduleInfo } from "../../system/types/Board";
import { CustomText } from "../../styles/CustomText";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { isoDateFormatterAll } from "../../components/Common/isoDateFormatterAll";
import { Switch, SwitchProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  PRIMARY,
  REDSTROKE,
} from "../../styles/Color";
import { useEffect, useState } from "react";
import { Plus, X } from "@phosphor-icons/react";
import { HexColorPicker } from "react-colorful";
import { CustomButtonGreen, CustomButtonRed } from "../../styles/buttonStyle";
import { AlertModal } from "../../components/Common/AlertModal";
import { ScheduleApi } from "../../system/api/scheduleApi";
import { ErrorHandler } from "../../system/ApiService";
interface ScheduleEditProps {
  createData: ScheduleInfo;
  setCreateData: (createData: ScheduleInfo) => void;
  handleClose: () => void;
  refreshData: () => void;
  editStatus: number | null;
}

function validation(data: ScheduleInfo) {
  if (data.startTime === "") {
    AlertModal("check", "일정 시작 시간을 입력해 주세요.");
    return false;
  } else if (data.endTime === "") {
    AlertModal("check", "일정 종료 시간을 입력해 주세요.");
    return false;
  } else if (data.subject === "") {
    AlertModal("check", "제목을 작성해 주세요.");
    return false;
  } else if (data.categoryColor === "") {
    AlertModal("check", "색상을 선택해 주세요.");
    return false;
  }
  return true;
}

function ScheduleEdit({
  createData,
  setCreateData,
  handleClose,
  refreshData,
  editStatus,
}: ScheduleEditProps) {
  const onChangeStartDate = (args: any) => {
    if (args.target.name === "startTime") {
      setCreateData({
        ...createData,
        [(args.target as HTMLInputElement).name]: isoDateFormatterAll(
          args.target.value
        ),
        endTime: "",
      });
    } else {
      setCreateData({
        ...createData,
        [(args.target as HTMLInputElement).name]: isoDateFormatterAll(
          args.target.value
        ),
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreateData({
      ...createData,
      [event.target.name]: event.target.checked,
    });
  };
  const onChange = (args: any) => {
    setCreateData({
      ...createData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };
  // 색상 선택
  const [colorBoxOpen, setColorBoxOpen] = useState(false);
  const changeColor = (item: string) => {
    setCreateData({
      ...createData,
      categoryColor: item,
    });
  };

  const onChangeColorBoxOpen = () => {
    setColorBoxOpen(!colorBoxOpen);
  };
  const [color, setColor] = useState(
    createData ? createData.categoryColor : ""
  );

  useEffect(() => {
    if (color) {
      setCreateData({ ...createData, categoryColor: color });
    }
  }, [color]);
  var submitcheck = false;
  function onSave() {
    if (validation(createData) && submitcheck === false) {
      if (editStatus) {
        submitcheck = true;
        ScheduleApi.updateSchedule(editStatus, createData)
          .then(() => {
            handleClose();
            submitcheck = false;
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      } else {
        submitcheck = true;
        ScheduleApi.createSchedule(createData)
          .then(() => {
            handleClose();
            submitcheck = false;
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  }

  function onDelete() {
    if (editStatus) {
      ScheduleApi.deleteSchedule(editStatus)
        .then(() => {
          handleClose();
          AlertModal("success", "선택하신 일정이 삭제되었습니다.");
          refreshData();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    }
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={6} margin="5px 0px">
        <CustomText type="subtitle">일정 시작 시간</CustomText>
      </Grid>
      <Grid item xs={6} margin="5px 0px">
        <CustomText type="subtitle">하루 종일</CustomText>
      </Grid>
      <Grid container item xs={6} margin="5px 10px 5px 0px">
        <DateTimePickerComponent
          name="startTime"
          id="datetimepicker"
          format="yyyy-MM-dd aa hh:mm"
          locale="ko"
          onChange={onChangeStartDate}
          value={new Date(createData.startTime)}
          showClearButton={false}
        />
      </Grid>
      <Grid container item xs={5} margin="5px 0px">
        <InbodySwitch
          name="isAllDay"
          checked={createData.isAllDay}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} margin="5px 0px">
        <CustomText type="subtitle">일정 종료 시간</CustomText>
      </Grid>
      <Grid item xs={6} margin="5px 0px">
        <DateTimePickerComponent
          name="endTime"
          id="datetimepicker"
          format="yyyy-MM-dd aa hh:mm"
          locale="ko"
          onChange={onChangeStartDate}
          value={new Date(createData.endTime)}
          min={new Date(createData.startTime)}
          showClearButton={false}
        />
      </Grid>
      <Grid item xs={12} margin="5px 0px">
        <CustomText type="subtitle">제목</CustomText>
      </Grid>
      <Grid item xs={12} margin="5px 0px">
        <InputBox
          type="text"
          name="subject"
          value={createData.subject}
          onChange={onChange}
          placeholder="제목 추가"
        />
      </Grid>
      <Grid item xs={12} margin="5px 0px">
        <CustomText type="subtitle">내용</CustomText>
      </Grid>
      <Grid item xs={12} margin="5px 0px">
        <CustomText type="subtitle" bold400 lineSpace>
          <TextareaBox
            onChange={onChange}
            name="contents"
            placeholder="해당 일정에 대한 설명을 적어주세요."
            value={createData.contents}
          />
        </CustomText>
      </Grid>
      <Grid container item xs={12} margin="5px 0px">
        <CustomText type="subtitle" marginRight="5px">
          색상
        </CustomText>
        {createData.categoryColor ? (
          <>
            {createData.isAllDay ? (
              <ColorExample color={createData.categoryColor}>일정</ColorExample>
            ) : (
              <>
                <ColorExample2 color={createData.categoryColor}>
                  일정
                </ColorExample2>
              </>
            )}
          </>
        ) : null}
      </Grid>
      <Grid item xs={12} margin="5px 0px">
        <ColorBigBox>
          {ColorPalette.map((item, index) => {
            return (
              <ColorBox
                onClick={() => {
                  changeColor(item.color);
                }}
                key={index}
                style={{ backgroundColor: `${item.color}` }}
              ></ColorBox>
            );
          })}
        </ColorBigBox>

        <OpenColorBoxText onClick={onChangeColorBoxOpen}>
          {colorBoxOpen ? (
            <>
              <X size={18} weight="bold" /> 닫기
            </>
          ) : (
            <>
              <Plus weight="bold" size={18} /> 색상 더 보기
            </>
          )}
        </OpenColorBoxText>

        <CeoScheduleModalContentFlexBox>
          {colorBoxOpen && (
            <ColorBigBox>
              <HexColorPicker color={color} onChange={setColor} />
            </ColorBigBox>
          )}
        </CeoScheduleModalContentFlexBox>
      </Grid>
      <Grid container item xs={12} justifyContent="right">
        {editStatus && (
          <CustomButtonRed onClick={onDelete} style={{ marginRight: "5px" }}>
            삭제
          </CustomButtonRed>
        )}

        <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
      </Grid>
    </Grid>
  );
}

export default ScheduleEdit;

const InbodySwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? REDSTROKE : PRIMARY,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: PRIMARY,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const InputBox = styled("input")(({ theme }) => ({
  width: "100%",
  height: "2.4rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  paddingLeft: "10px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300" },
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
}));

const TextareaBox = styled("textarea")({
  width: "100%",
  height: "100px",
  border: "1px solid #DCDFE3",
  resize: "none",
  borderRadius: "8px",
  paddingLeft: "10px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300" },
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
});

const CeoScheduleModalContentFlexBox = styled("div")(() => ({
  marginBottom: "15px",
  display: "flex",
  alignItems: "flex-start",
}));

const ColorBigBox = styled("div")(() => ({
  width: "18rem",
  display: "flex",
  flexWrap: "wrap",
}));

const ColorBox = styled("div")(() => ({
  width: "2rem",
  height: "2rem",
  cursor: "pointer",
}));

const ColorExample = styled("span")(({ color }: any) => ({
  color: "#ffffff",
  height: "1.5rem",
  borderRadius: "5px",
  textAlign: "center",
  padding: "0px 5px",
  backgroundColor: `${color}`,
}));

const ColorExample2 = styled("span")(({ color }: any) => ({
  color: GRAYPALETITLE,
  border: `1px solid ${color}`,
  height: "1.5rem",
  borderRadius: "5px",
  textAlign: "center",
  padding: "0px 5px",
  fongtWeight: 700,
}));

const OpenColorBoxText = styled("div")(() => ({
  color: GRAYPALECONTENTS,
  cursor: "pointer",
}));

export const ColorPalette = [
  { id: 1, color: "#f44336" },
  { id: 2, color: "#e91e63" },
  { id: 3, color: "#9c27b0" },
  { id: 4, color: "#673ab7" },
  { id: 5, color: "#3f51b5" },
  { id: 6, color: "#2196f3" },
  { id: 7, color: "#03a9f4" },
  { id: 8, color: "#00bcd4" },
  { id: 9, color: "#009688" },
  { id: 10, color: "#4caf50" },
  { id: 11, color: "#8bc34a" },
  { id: 12, color: "#cddc39" },
  { id: 13, color: "#ffc107" },
  { id: 14, color: "#ff9800" },
  { id: 15, color: "#ff5722" },
  { id: 16, color: "#795548" },
  { id: 17, color: "#9e9e9e" },
  { id: 18, color: "#607d8b" },
];
