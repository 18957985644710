import { useTranslation } from "react-i18next";
import { AlertModal } from "../../../../components/Common/AlertModal";
import { FilterObject } from "../../../../types/Lead/List";
import { TFunction } from "i18next";

export const CustomerSearchValidation = (
  data: FilterObject[],
  t: TFunction
) => {
  const invalidValuesItem = data.find(
    (item) =>
      (item.comparator === "IN" || item.comparator === "NOT_IN") &&
      (!item.values || item.values.length === 0)
  );

  const invalidDateItem = data.find(
    (item) =>
      ((item.comparator === "IN" || item.comparator === "NOT_IN") &&
        (!item.values || item.values.length === 0)) ||
      (item.comparator === "BETWEEN" &&
        (!item.values || // values가 없는 경우
          (Array.isArray(item.values) &&
            item.values.length === 2 &&
            item.values[0] === null &&
            item.values[1] === null))) // values가 [null, null]인 경우
  );

  if (invalidValuesItem) {
    AlertModal(
      "check",
      t("Search Validation", {
        Text: t("Validation Text"),
        field: t(invalidValuesItem.field),
      })
    );
    return false;
  } else if (invalidDateItem) {
    AlertModal(
      "check",
      t("Search Validation", {
        Text: t("Validation Text"),
        field: t(invalidDateItem.field),
      })
    );
    return false;
  }

  return true;
};
