import CommonTextWithExtra from "../../components/Common/Table/CommonTextWithExtra";
import { LANGUAGE_OPTIONS_LIST } from "../../types/Common/Multigual";
import { OrderInfo } from "../../types/Customers/Customers";
import { t } from "i18next";
// 단위 변환
export const convertToHundredsMillion = (
  money: number,
  unit1: LANGUAGE_OPTIONS_LIST
) => {
  let unit = unit1 === LANGUAGE_OPTIONS_LIST.ko ? 100000000 : 1000000;

  return Number((money / unit).toFixed(2));
};

// 제품명 포매팅
export const formatProductLabel = (orders: OrderInfo[]) => {
  if (orders.length === 0) {
    return "";
  }

  // price가 가장 비싼 order 찾기
  const maxPriceOrder = orders.reduce((prev, current) =>
    prev.price > current.price ? prev : current
  );

  // productFamily와 productName의 null 여부에 따라 텍스트 처리
  const productFamilyText =
    maxPriceOrder.productFamily ?? t("Product Family") + t("not specified");
  const productNameText =
    maxPriceOrder.productName ?? t("Product Name") + t("not specified");

  // 둘 다 null인 경우 '지정되지 않음' 반환
  const labelText =
    !maxPriceOrder.productFamily && !maxPriceOrder.productName
      ? t("Not specified")
      : `[${productFamilyText}] ${productNameText}`;

  // orders가 2개 이상일 때만 '외 N 개'를 추가
  const additionalText =
    orders.length > 1
      ? " " + t("and") + " " + (orders.length - 1) + " " + t("others")
      : "";

  // 결과 형식으로 반환
  return `${labelText}${additionalText}`;
};

// CommonTextWithExtra를 사용하는 컴포넌트
export const ProductLabelWithTooltip = (orders: OrderInfo[]) => {
  const productLabel = formatProductLabel(orders);

  // orders가 2개 이상일 때만 list에 정보를 전달, productFamily와 productName이 모두 null일 경우 '지정되지 않음'으로 표시
  const tooltipList =
    orders.length > 1
      ? orders.map((order) => ({
          key: order.id,
          value:
            !order.productFamily && !order.productName
              ? t("Not specified")
              : `[${
                  order.productFamily ??
                  t("Product Family") + t("not specified")
                }] ${
                  order.productName ?? t("Product Name") + t("not specified")
                }`,
        }))
      : [];

  return <CommonTextWithExtra text={productLabel} list={tooltipList} />;
};
// 총 금액 계산
export const calculateTotalPrice = (orders: OrderInfo[]) => {
  if (orders.length === 0) return "";

  const totalAmount = orders.reduce((sum, order) => {
    return (
      sum + order.quantity * order.price * ((100 - order.discountRate) / 100)
    );
  }, 0);

  const currency = orders[0].currency;
  return `${currency} ${totalAmount.toLocaleString()}`;
};
