import { CustomText } from "../../../styles/CustomText";
import { AdminGroupDivider } from "../../../styles/adminStyle";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { ErrorHandler } from "../../../system/ApiService";
import { AlertModal } from "../../../components/Common/AlertModal";
import { InitGroupInfo } from "../../../system/types/initObject";
import {
  EmployeeInfo,
  GetAllEmployeeListResponse,
} from "../../../types/Employee/Employee";
import { GetGroupInfoResponse, GroupInfo } from "../../../types/Group/group";
import AdminEmployeeSection from "./components/Employee/AdminEmployeeSection";
import AdminChildrenGroupSection from "./components/ChildrenGroup/AdminChildrenGroupSection";
import AdminGroupInfoSection from "./components/Group/AdminGroupInfoSection";
import {
  EmployeeGoalsInfoList,
  GetGroupGoalsRequest,
  GetGroupGoalsResponse,
  GroupGoalInfo,
} from "../../../types/DashBoard/Achievement";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { EmployeeApi } from "../../../api/Employee/employeeApi";
import { GroupApi } from "../../../api/Group/groupApi";
import { GoalApi } from "../../../api/Goal/goalApi";

function AdminGroupDetailPage() {
  const { id } = useParams<{ id: string }>();
  const [groupData, setGroupData] = useState<GroupInfo>(InitGroupInfo);
  const [employeeList, setEmployeeList] = useState<EmployeeInfo[]>([]);
  const [groupGoalInfo, setGroupGoalInfo] = useState<GroupGoalInfo>(
    {} as GroupGoalInfo
  );
  const [allEmployeeGoalsInfoList, setAllEmployeeGoalsInfoList] =
    useState<EmployeeGoalsInfoList>([]);

  // all Group Info 재조회
  const refreshData = useCallback(async () => {
    if (id) {
      try {
        const { data } = await GroupApi.getGroupInfo<GetGroupInfoResponse>(
          Number(id)
        );
        setGroupData(data[0]);
      } catch (err) {
        const message = ErrorHandler(err as AxiosError);
        AlertModal("msg", message);
      }
    }
  }, [id]);

  // all Group Goal Info 재조회
  const getAllGroupAndEmployeeGoalInfo = useCallback(async () => {
    if (id) {
      try {
        const params = {
          year: dayjs().year(),
        };
        const { data } = await GoalApi.getGroupGoals<
          GetGroupGoalsRequest,
          GetGroupGoalsResponse
        >(Number(id), params);
        const { groupGoal, employeeGoals } = data[0];

        setGroupGoalInfo(groupGoal);
        setAllEmployeeGoalsInfoList(employeeGoals);
      } catch (err) {
        const msg = ErrorHandler(err as AxiosError);
        AlertModal("msg", msg);
      }
    }
  }, [id]);

  useEffect(() => {
    getAllGroupAndEmployeeGoalInfo();
  }, [getAllGroupAndEmployeeGoalInfo]);

  const getAvailableEmployeeList = useCallback(async () => {
    try {
      const { data } =
        await EmployeeApi.getAllEmployeeList<GetAllEmployeeListResponse>();
      setEmployeeList(data);
    } catch (err) {
      const message = ErrorHandler(err as AxiosError);
      AlertModal("msg", message);
    }
  }, []);

  useEffect(() => {
    refreshData();
    // employeeList 재조회
    getAvailableEmployeeList();
  }, [refreshData, getAvailableEmployeeList]);

  return (
    <>
      <CustomText type="title">설정 {id && "- " + groupData.name}</CustomText>
      <AdminGroupDivider container justifyContent="space-between">
        <Grid item xs={12} sm={5.9}>
          <AdminGroupInfoSection
            groupData={groupData}
            refreshData={refreshData}
            employeeList={employeeList}
            groupGoalInfo={groupGoalInfo}
          />
          <AdminChildrenGroupSection
            groupData={groupData}
            refreshData={refreshData}
            employeeList={employeeList}
          />
        </Grid>
        <Grid item xs={12} sm={5.9}>
          <AdminEmployeeSection
            id={Number(id)}
            list={employeeList}
            allEmployeeGoalList={allEmployeeGoalsInfoList}
            getAllGroupAndEmployeeGoalInfo={getAllGroupAndEmployeeGoalInfo}
          />
        </Grid>
      </AdminGroupDivider>
    </>
  );
}
export default AdminGroupDetailPage;
