import { Grid, IconButton, Tooltip } from "@mui/material";
import { MeetingNoteReplyBox } from "../../../../styles/byCaseStyle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EditIcon from "@mui/icons-material/Edit";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { CommentInfo } from "../../../../system/types/MeetingNote";
import CommentInputBox from "./CommentInputBox";
import { InitCommentInfo } from "../../../../system/types/initObject";
import { isoDateFormatter } from "../../../../components/Common/isoDateFormatter";
import { useRecoilValue } from "recoil";
import { MyInfoState } from "../../../../system/atoms";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../../styles/Color";
import { AlertModal } from "../../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../../system/ApiService";
import { CommentApi } from "../../../../system/api/commentApi";
interface CommentListProps {
  commentData: CommentInfo;
  replyData: CommentInfo;
  replyContent: string;
  setReplyContent: (replyContent: string) => void;
  onChangeComment: (dt: CommentInfo, type: string) => void;
  openReply: boolean;
  openEditReply: boolean;
  onSaveReply: () => void;
  commentType: string;
  getMeetingNoteList: () => void;
}

function CommentList({
  commentData,
  onChangeComment,
  replyContent,
  replyData,
  setReplyContent,
  openReply,
  onSaveReply,
  openEditReply,
  commentType,
  getMeetingNoteList,
}: CommentListProps) {
  const myInfoData = useRecoilValue(MyInfoState);
  var submitcheck = false;
  const onDeleteMeetingNote = (id: number | undefined | null) => {
    if (id && submitcheck === false) {
      submitcheck = true;

      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "삭제 시 밑에 달린 하위 댓글들도 모두 지워집니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          CommentApi.deleteComment(id)
            .then(() => {
              AlertModal("success", "선택하신 댓글이 삭제되었습니다.");
              getMeetingNoteList();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  };

  return (
    <Grid container>
      <MeetingNoteReplyBox container justifyContent="space-between">
        <Grid container item xs={11}>
          <Grid container>
            <Grid item paddingLeft={`${commentData.depth * 5}px`}>
              <SubdirectoryArrowRightIcon fontSize="small" />
            </Grid>
            <Grid
              item
              margin="5px"
              style={{
                overflow: "auto",
                overflowWrap: "anywhere",
                whiteSpace: "pre-line",
              }}
            >
              {commentData.content}
            </Grid>
          </Grid>

          <Grid container justifyContent="right">
            작성자 : {commentData.createBy?.name}
            {commentData.createdAt &&
              " 작성일 : " + isoDateFormatter(new Date(commentData.createdAt))}
          </Grid>
        </Grid>
        <Grid container justifyContent="right" alignItems="center" item xs={1}>
          <Grid>
            <Tooltip title="답변">
              <IconButton
                onClick={() =>
                  onChangeComment(
                    {
                      ...InitCommentInfo,
                      articleId: commentData.articleId,
                      depth: commentData.depth + 1,
                      parentId: commentData.id ? commentData.id : null,
                      commentType: commentType,
                    },
                    "add"
                  )
                }
              >
                <ChatBubbleOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {myInfoData.id === commentData?.createBy?.id && (
              <>
                <Tooltip title="수정">
                  <IconButton
                    onClick={() => onChangeComment(commentData, "edit")}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="삭제">
                  <IconButton
                    onClick={() => onDeleteMeetingNote(commentData.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Grid>
        </Grid>
      </MeetingNoteReplyBox>
      <Grid container>
        {openReply && commentData.id === replyData.parentId && (
          <>
            <CommentInputBox
              onSaveReply={onSaveReply}
              replyContent={replyContent}
              setReplyContent={setReplyContent}
            />
          </>
        )}
        {openEditReply && commentData.id === replyData.id && (
          <>
            <CommentInputBox
              onSaveReply={onSaveReply}
              replyContent={replyContent}
              setReplyContent={setReplyContent}
            />
          </>
        )}
      </Grid>
      {commentData.children &&
        commentData.children.length > 0 &&
        commentData.children.map((childrenDt: CommentInfo, index: number) => (
          <CommentList
            key={"commentData" + index}
            commentData={childrenDt}
            replyData={replyData}
            replyContent={replyContent}
            openReply={openReply}
            openEditReply={openEditReply}
            onChangeComment={onChangeComment}
            onSaveReply={onSaveReply}
            setReplyContent={setReplyContent}
            commentType={commentType}
            getMeetingNoteList={getMeetingNoteList}
          />
        ))}
    </Grid>
  );
}

export default CommentList;
