import { Grid } from "@mui/material";
import { InputBox, PointerItem } from "../../../styles/theme";

import { CustomText } from "../../../styles/CustomText";
import { X } from "@phosphor-icons/react";
import { QaQcInfo, QaQcListInfo } from "../../../system/types/Customer";
import { isoDateFormatter } from "../../../components/Common/isoDateFormatter";
import { QaQcApi } from "../../../system/api/qaQcApi";
import { AlertModal } from "../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../system/ApiService";
import {
  CustomButtonGreen,
  CustomButtonRed,
  CustomButtonYellow,
} from "../../../styles/buttonStyle";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { REDTITLE } from "../../../styles/Color";
import { useRecoilValue } from "recoil";
import { MyInfoState } from "../../../system/atoms";

interface QAQCModalProps {
  selectedQaQcData: QaQcListInfo;
  handleClose: () => void;
  qaqcStatus: string;
  editQaQcData: QaQcInfo;
  setEditQaQcData: (dt: QaQcInfo) => void;
  inputValue: string;
  setInputValue: (item: string) => void;
  refreshData: () => void;
}
function validation(data: QaQcInfo) {
  if (data.documentId === "") {
    AlertModal("check", "문서번호를 작성해 주세요.");
    return false;
  } else if (data.deliveryDate === "") {
    AlertModal("check", "납품일을 선택해 주세요.");
    return false;
  }
  return true;
}

function QAQCModal({
  selectedQaQcData,
  handleClose,
  qaqcStatus,
  editQaQcData,
  setEditQaQcData,
  inputValue,
  setInputValue,
  refreshData,
}: QAQCModalProps) {
  const myInfoData = useRecoilValue(MyInfoState);
  const onChange = (args: any) => {
    setEditQaQcData({
      ...editQaQcData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeDate = (args: any) => {
    setEditQaQcData({
      ...editQaQcData,
      [(args.target as HTMLInputElement).name]: isoDateFormatter(
        new Date(args.target.value)
      ),
    });
  };
  var submitcheck = false;
  const onSave = () => {
    if (validation(editQaQcData) && submitcheck === false) {
      submitcheck = true;

      QaQcApi.updateQaQc(selectedQaQcData.id, {
        ...editQaQcData,
        serialNumbers: inputValue.split(/,\s*|\s+/).filter((item) => item),
      })
        .then(() => {
          handleClose();
          AlertModal("success", "조달검사검수 요청이 수정되었습니다.");
          refreshData();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          submitcheck = true;
        });
    }
  };

  const onDelete = () => {
    QaQcApi.deleteQaQc(selectedQaQcData.id)
      .then(() => {
        handleClose();
        AlertModal("success", "조달검사검수 삭제가 완료되었습니다.");
        refreshData();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        submitcheck = true;
      });
  };

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };
  const handlePaste = (e: any) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    const formattedData = pasteData.split(/[\t\n]+/).join(", ");
    if (formattedData.endsWith(", ")) {
      const formattedEditData = formattedData.slice(0, -2);
      setInputValue(formattedEditData);
    } else {
      setInputValue(formattedData);
    }
  };

  const onCheckQaQc = () => {
    QaQcApi.checkQaQc(selectedQaQcData.id)
      .then(() => {
        handleClose();
        AlertModal("success", "조달검사검수 확인 요청이 처리되었습니다.");
        refreshData();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        submitcheck = true;
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        item
        xs={12}
      >
        {qaqcStatus === "edit" ? (
          <CustomText type="title">조달검사검수 수정</CustomText>
        ) : (
          <CustomText type="title">조달검사검수 상세</CustomText>
        )}

        <PointerItem onClick={handleClose}>
          <X size={32} />
        </PointerItem>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CustomText type="subtitle">요청자</CustomText>
        </Grid>
        <Grid item xs={12} mb="10px">
          {selectedQaQcData.createBy.name}
        </Grid>
        <Grid item xs={12}>
          <CustomText type="subtitle">요청일</CustomText>
        </Grid>
        <Grid item xs={12} mb="10px">
          {isoDateFormatter(new Date(selectedQaQcData.createdAt))}
        </Grid>
        <Grid item xs={12}>
          <CustomText type="subtitle">상호명</CustomText>
        </Grid>
        <Grid item xs={12} mb="10px">
          {selectedQaQcData.placeName}
        </Grid>
        <Grid item xs={12}>
          <CustomText type="subtitle">장비 정보</CustomText>
        </Grid>
        <Grid item xs={12} mb="10px">
          {"[" +
            selectedQaQcData.order.product.family +
            "] " +
            selectedQaQcData.order.product.name +
            " " +
            selectedQaQcData.order.quantity +
            "대"}
        </Grid>

        <Grid container item xs={12} alignItems="center">
          <CustomText type="subtitle">문서번호</CustomText>
          {qaqcStatus === "edit" && (
            <CustomText type="small" color={REDTITLE}>
              *
            </CustomText>
          )}
        </Grid>

        {qaqcStatus === "edit" ? (
          <Grid item xs={12} mb="5px">
            <InputBox
              type="text"
              name="documentId"
              placeholder="문서번호를 입력하세요."
              autoComplete="new-password"
              onChange={onChange}
              value={editQaQcData.documentId}
            />{" "}
          </Grid>
        ) : (
          <Grid item xs={12} mb="10px">
            {selectedQaQcData.documentId}
          </Grid>
        )}

        <Grid container item xs={12} alignItems="center">
          <CustomText type="subtitle">납품일</CustomText>
          {qaqcStatus === "edit" && (
            <CustomText type="small" color={REDTITLE}>
              *
            </CustomText>
          )}
        </Grid>

        {qaqcStatus === "edit" ? (
          <Grid item xs={12} mb="5px">
            <DatePickerComponent
              name="deliveryDate"
              placeholder="납품일을 선택하세요."
              format="yyyy-MM-dd"
              locale="ko"
              value={
                editQaQcData.deliveryDate
                  ? new Date(editQaQcData.deliveryDate)
                  : undefined
              }
              onChange={onChangeDate}
              showClearButton={false}
              max={new Date()}
            />{" "}
          </Grid>
        ) : (
          <Grid item xs={12} mb="10px">
            {selectedQaQcData.deliveryDate}{" "}
          </Grid>
        )}

        <Grid item xs={12}>
          <CustomText type="subtitle">
            시리얼번호 (
            {inputValue.split(/,\s*|\s+/).filter((item) => item).length}/
            {selectedQaQcData.order.quantity}개 작성)
          </CustomText>
        </Grid>
        {qaqcStatus === "edit" && (
          <Grid item xs={12}>
            <CustomText type="subtitle" bold400>
              엑셀 내용을 붙여넣기하시거나 ,(콤마)로 구분해서 작성해주세요.
            </CustomText>
          </Grid>
        )}

        {qaqcStatus === "edit" ? (
          <Grid item xs={12} mb="5px">
            <InputBox
              type="text"
              name="serialNumbers"
              placeholder="시리얼 번호를 입력하세요."
              autoComplete="new-password"
              value={inputValue}
              onChange={handleChange}
              onPaste={handlePaste}
            />{" "}
          </Grid>
        ) : (
          <Grid item xs={12} mb="98px">
            {selectedQaQcData?.serialNumbers &&
            selectedQaQcData?.serialNumbers.length > 0 ? (
              <>{inputValue}</>
            ) : (
              "-"
            )}
          </Grid>
        )}

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          item
          xs={12}
        >
          <CustomText type="title">확인 정보</CustomText>
        </Grid>
        <Grid item xs={12}>
          <CustomText type="subtitle">확인자</CustomText>
        </Grid>
        <Grid item xs={12} mb="20px">
          {selectedQaQcData.isChecked === true ? (
            <>{selectedQaQcData.checker?.name}</>
          ) : (
            "확인 진행 중"
          )}
        </Grid>

        {qaqcStatus === "detail" ? (
          <Grid container item xs={12} justifyContent="right">
            <CustomButtonGreen onClick={handleClose}>창 닫기</CustomButtonGreen>
          </Grid>
        ) : (
          <>
            {qaqcStatus === "edit" ? (
              <Grid container item xs={12} justifyContent="space-between">
                <CustomButtonRed onClick={onDelete}>삭제</CustomButtonRed>
                <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
              </Grid>
            ) : (
              <>
                <Grid container item xs={12} justifyContent="space-between">
                  <CustomButtonYellow onClick={onCheckQaQc}>
                    요청 확인
                  </CustomButtonYellow>
                  <CustomButtonGreen onClick={handleClose}>
                    창 닫기
                  </CustomButtonGreen>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default QAQCModal;
