import PortletList from "./components/Portlet/PortletList";
import DashboardHeader from "./components/DashboardHeader";

const DahsBoardPage = () => {
  return (
    <>
      <DashboardHeader />
      <PortletList />
    </>
  );
};

export default DahsBoardPage;
