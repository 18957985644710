import { atom } from "recoil";
import { TableHeadCellData } from "../../types/Common/CommonMuiTable";

type LeadStatusStoreType = {
  columnList: TableHeadCellData[];
  pageNumber: number;
};

export const LeadStatusStore = atom<LeadStatusStoreType>({
  key: "LeadStatusStore",
  default: {
    columnList: [],
    pageNumber: 1,
  },
});
