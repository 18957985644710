import client from "../../system/ApiService";
import { _BasicApi } from "../../system/BasicApi";

const baseUri: string = "/customers";

class _CustomersApi extends _BasicApi {
  postAllCustomersInfo<RQ, RP>(
    data: RQ,
    config?: { params: { page: number; size: number } }
  ) {
    return this.client.post<RQ, RP>(`${baseUri}/new/search`, data, {
      ...config,
    });
  }
  getIntermediaryInfo<RQ, RP>(params?: RQ) {
    return this.client.get<RP>(`${baseUri}/intermediary`, { params });
  }
}

export const CustomersApi = new _CustomersApi(client);
