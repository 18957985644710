import { useState } from "react";
import {
  ActivitySectionStatesInfo,
  LeadCustomerDetailInfo,
} from "../../../../../system/types/Customer";
import { PreviousSalesActivityInfo } from "../../../../../system/types/SalesActivity";
import {
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  LeadTableTitleHover,
  TableFixedHeight,
} from "../../../../../styles/theme";
import { CustomText } from "../../../../../styles/CustomText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { GRAYPALECONTENTS, GRAYPALETITLE } from "../../../../../styles/Color";
interface ActivityPreviousProps {
  sectionStates: ActivitySectionStatesInfo;
  toggleSection: (title: keyof ActivitySectionStatesInfo) => void;
  previousData: PreviousSalesActivityInfo | null;
  type: string;
}

function ActivityPrevious({
  sectionStates,
  toggleSection,
  previousData,
  type,
}: ActivityPreviousProps) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <LeadTableCardBox mt="20px">
      <LeadTableTitleHover
        container
        item
        xs={12}
        onClick={() =>
          toggleSection(
            type === "previous" ? "ActivityPrevious" : "ActivityNext"
          )
        }
        justifyContent="space-between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CustomText type="subtitle" lineSpace>
          {type === "previous" ? "이전" : "다음"} 작업
        </CustomText>
        {type === "previous" ? (
          <>
            {isHovered ? (
              <>
                {sectionStates["ActivityPrevious"] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {isHovered ? (
              <>
                {sectionStates["ActivityNext"] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </LeadTableTitleHover>
      {type === "previous" ? (
        <>
          {sectionStates["ActivityPrevious"] && (
            <ActivitydataContent data={previousData} />
          )}
        </>
      ) : (
        <>
          {sectionStates["ActivityNext"] && (
            <ActivitydataContent data={previousData} />
          )}
        </>
      )}
    </LeadTableCardBox>
  );
}

export default ActivityPrevious;

interface ActivitydataContentProps {
  data: PreviousSalesActivityInfo | null;
}

const ActivitydataContent: React.FC<ActivitydataContentProps> = ({ data }) => {
  return (
    <LeadTableContentsBox container item xs={12} alignItems="center">
      <TableFixedHeight container item xs={12} sm={6}>
        <LeadTableTitle item xs={4}>
          <CustomText
            type="subtitle"
            bold400
            lineSpace
            color={GRAYPALECONTENTS}
          >
            제목
          </CustomText>
        </LeadTableTitle>
        <LeadTableContent item xs={8} pl="20px">
          <CustomText type="subtitle" bold400 lineSpace color={GRAYPALETITLE}>
            {data?.title ? data?.title : "-"}
          </CustomText>
        </LeadTableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} sm={6}>
        <LeadTableTitle item xs={4}>
          <CustomText
            type="subtitle"
            bold400
            color={GRAYPALECONTENTS}
            lineSpace
          >
            작업일
          </CustomText>
        </LeadTableTitle>
        <LeadTableContent item xs={8} pl="20px">
          <CustomText type="subtitle" bold400 color={GRAYPALETITLE} lineSpace>
            {data?.activityDate ? data?.activityDate : "-"}
          </CustomText>
        </LeadTableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} sm={6}>
        <LeadTableTitle item xs={4}>
          <CustomText
            type="subtitle"
            bold400
            color={GRAYPALECONTENTS}
            lineSpace
          >
            유형
          </CustomText>
        </LeadTableTitle>
        <LeadTableContent item xs={8} pl="20px">
          <CustomText type="subtitle" bold400 color={GRAYPALETITLE} lineSpace>
            {data?.activityDate ? data?.activityDate : "-"}
          </CustomText>
        </LeadTableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} sm={6}>
        <LeadTableTitle item xs={4}>
          <CustomText
            type="subtitle"
            bold400
            color={GRAYPALECONTENTS}
            lineSpace
          >
            후속 조치 일자
          </CustomText>
        </LeadTableTitle>
        <LeadTableContent item xs={8} pl="20px">
          <CustomText type="subtitle" bold400 color={GRAYPALETITLE} lineSpace>
            {data?.followUpDate ? data?.followUpDate : "-"}
          </CustomText>
        </LeadTableContent>
      </TableFixedHeight>

      <TableFixedHeight container item xs={12} sm={6}>
        <LeadTableTitle item xs={4}>
          <CustomText
            type="subtitle"
            bold400
            color={GRAYPALECONTENTS}
            lineSpace
          >
            작업상태
          </CustomText>
        </LeadTableTitle>
        <LeadTableContent item xs={8} pl="20px">
          <CustomText type="subtitle" bold400 color={GRAYPALETITLE} lineSpace>
            {data?.status ? data?.status : "-"}
          </CustomText>
        </LeadTableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} sm={6}>
        <LeadTableTitle item xs={4}>
          <CustomText
            type="subtitle"
            bold400
            color={GRAYPALECONTENTS}
            lineSpace
          >
            우선순위
          </CustomText>
        </LeadTableTitle>
        <LeadTableContent item xs={8} pl="20px">
          <CustomText type="subtitle" bold400 color={GRAYPALETITLE} lineSpace>
            {data?.priority ? data?.priority : "-"}
          </CustomText>
        </LeadTableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} sm={6}>
        <LeadTableTitle item xs={4}>
          <CustomText
            type="subtitle"
            bold400
            color={GRAYPALECONTENTS}
            lineSpace
          >
            작성자
          </CustomText>
        </LeadTableTitle>
        <LeadTableContent item xs={8} pl="20px">
          <CustomText type="subtitle" bold400 color={GRAYPALETITLE} lineSpace>
            {data?.createBy?.name ? data?.createBy?.name : "-"}
          </CustomText>
        </LeadTableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} sm={6}>
        <LeadTableTitle item xs={4}>
          <CustomText
            type="subtitle"
            bold400
            color={GRAYPALECONTENTS}
            lineSpace
          >
            작성일
          </CustomText>
        </LeadTableTitle>
        <LeadTableContent item xs={8} pl="20px">
          <CustomText type="subtitle" bold400 color={GRAYPALETITLE} lineSpace>
            {data?.createdAt ? data?.createdAt : "-"}
          </CustomText>
        </LeadTableContent>
      </TableFixedHeight>
    </LeadTableContentsBox>
  );
};
