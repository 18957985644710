//react

import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Routes from "./route/Routes";
import UserRoute from "./route/UserRoute";

// recoil
import { RecoilRoot } from "recoil";
import GlobalStyle from "./styles/GlobalStyle";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: '"Noto Sans KR", "Noto Sans", sans-serif', // 모든 MUI 컴포넌트에 적용
  },
});

function App() {
  return (
    <>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyle />
          <Router>
            <UserRoute Component={Routes}></UserRoute>
          </Router>
        </ThemeProvider>
      </RecoilRoot>
    </>
  );
}

export default App;
