import client from "../../system/ApiService";
import { _BasicApi } from "../../system/BasicApi";

class _EmployeeApi extends _BasicApi {
  // 조회할 수 있는 임직원 계정 조회
  getAllEmployeeList<RP>() {
    return this.client.get<RP>(`employee/list`);
  }
}

export const EmployeeApi = new _EmployeeApi(client);
