import { AdminGroupItemBoxContents } from "../../../../../styles/adminStyle";
import { CustomText } from "../../../../../styles/CustomText";
import { AdminGroupInfo } from "../../../../../types/Admin/AdminGroup";

type AdminGroupInfoListItemProps = {
  info: AdminGroupInfo;
};
const AdminGroupInfoListItem = ({ info }: AdminGroupInfoListItemProps) => {
  return (
    <AdminGroupItemBoxContents container alignItems="center">
      <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
        {info.label}
      </CustomText>
      <CustomText type="subtitle" bold500 color="#3D4861">
        {info.value}
      </CustomText>
    </AdminGroupItemBoxContents>
  );
};

export default AdminGroupInfoListItem;
