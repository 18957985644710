import { isoDateFormatter } from "../../components/Common/isoDateFormatter";
import {
  EmployeeInfo,
  EmployeeTableInfo,
  MyInfo,
  PasswordInfo,
} from "../../types/Employee/Employee";
import { GroupEmployeeInfo, GroupInfo, JobInfo } from "../../types/Group/group";
import { NoticeInfo, ScheduleDetailInfo, ScheduleInfo } from "./Board";
import {
  AddressInfo,
  IntermediaryCompanyInfo,
  LeadCustomerCreateInfo,
  LeadOrderCreateInfo,
  LeadOrdersInfo,
  ListSearchInfo,
  QaQcInfo,
  QaQcListInfo,
} from "./Customer";

import { ContactTypeListInfo, DropdownListInfo, TagListInfo } from "./List";
import { CommentInfo, MeetingNoteInfo } from "./MeetingNote";
import {
  ProductDataInfo,
  ProductPricesInfo,
  SearchProductDataInfo,
} from "./Product";
import { QnaInfo } from "./Qna";
import { SalesActivityInfo } from "./SalesActivity";
import { SalesProtectionSettingInfo } from "./Setting";

export const InitJobInfo: JobInfo = {
  id: null,
  name: "",
  code: "",
  enabled: true,
  priority: 0,
  isSales: false,
};

export const InitEmployeeInfo: EmployeeInfo = {
  email: "",
  name: "",
  phone: "",
  picture: "",
  jobTitle: InitJobInfo,
  jobPosition: InitJobInfo,
  enabled: true,
  isSales: false,
};

export const InitEmployeeTableInfo: EmployeeTableInfo = {
  id: null,
  email: "",
  name: "",
  phone: "",
  picture: "",
  isResignation: true,
  group: "",
  jobTitle: InitJobInfo,
  jobPosition: InitJobInfo,
};

export const InitPasswordInfo: PasswordInfo = {
  oldPassword: "",
  newPassword: "",
};

export const InitGroupInfo: GroupInfo = {
  id: null,
  isSaleGroup: false,
  name: "",
  level: 0,
  manager: InitEmployeeInfo,
  parentId: null,
  children: [],
  enabled: true,
};

export const InitGroupEmployeeInfo: GroupEmployeeInfo = {
  groupId: null,
  employees: [],
};

export const InitMyInfo: MyInfo = {
  id: null,
  email: "",
  name: "",
  phone: "",
  picture: "",
  groups: [],
  jobTitle: InitJobInfo,
  jobPosition: InitJobInfo,
  timeZone: "",
};

export const InitDropdownListInfo: DropdownListInfo = {
  name: "",
  description: "",
  dropDownType: "",
  enabled: true,
  priority: 0,
};

export const InitTagListInfo: TagListInfo = {
  name: "",
  enabled: true,
  priority: 0,
};

export const InitContactTypeListInfo: ContactTypeListInfo = {
  major: "",
  sub: "",
  description: "",
  branchEditable: false,
  enabled: true,
  priority: 0,
};

// PRODUCT
export const InitSearchProductDataInfo: SearchProductDataInfo = {
  family: "",
  name: "",
  launchDate: "",
  discontinuanceDate: "",
  enabled: true,
};

// CUSTOMER

export const InitAddressInfo: AddressInfo = {
  placeName: "",
  road: "",
  category: null,
  bdNm: "",
};

export const InitIntermediaryCompanyInfo: IntermediaryCompanyInfo = {
  name: "",
  phone: "",
  email: "",
  address: InitAddressInfo,
  contactTypeMajor: "",
  contactTypeSub: "",
};

export const InitLeadCustomerCreateInfo: LeadCustomerCreateInfo = {
  name: "",
  email: "",
  phone: "",
  officeNumber: "",
  intermediaryCompanyId: null,
  customerType: "normal",
  step: null,
  contractType: null,
  saleType: null,
  supplyType: null,
  businessType: null,
  purchaseProbability: 0,
  department: null,
  estimatedDate: "",
  address: InitAddressInfo,
  groupId: null,
  sellerId: null,
  contactType: InitContactTypeListInfo,
  tagIds: [],
  description: "",
};

export const InitProductPriceInfoInfo: ProductPricesInfo = {
  retailPrice: 0,
  wholesalePrice: 0,
  partnerPrice: 0,
  currency: "",
};

export const InitProductDataInfo: ProductDataInfo = {
  family: "",
  name: "",
  image: "",
  launchDate: "",
  discontinuanceDate: null,
  enabled: true,
  prices: [],
};

export const InitLeadOrdersInfo: LeadOrdersInfo = {
  quantity: 1,
  currency: "",
  price: 0,
  discountRate: 0,
  product: InitProductDataInfo,
  seller: InitEmployeeTableInfo,
  qaQc: null,
};

export const InitLeadOrderCreateInfo: LeadOrderCreateInfo = {
  customerId: null,
  quantity: 1,
  currency: "KRW",
  price: 0,
  discountRate: 0,
  productId: null,
  sellerId: null,
};

export const InitMeetingNoteInfo: MeetingNoteInfo = {
  content: "",
  nextAction: "",
  meetingDate: isoDateFormatter(new Date()) || "",
  followUpDate: null,
  customerId: null,
};

export const InitCommentInfo: CommentInfo = {
  content: "",
  depth: 0,
  isChecked: false,
  parentId: null,
  articleId: null,
  commentType: "",
};

export const InitNotcieInfo: NoticeInfo = {
  title: "",
  content: "",
  attachmentFiles: [],
  mainStartDate: "",
  mainEndDate: "",
};

export const InitListSearchInfo: ListSearchInfo = {
  placeName: "",
  siDo: null,
  gunGu: null,
  status: null,
  startEstimatedDate: null,
  endEstimatedDate: null,
  businessTypeId: null,
  departmentId: null,
  productFamily: null,
  productName: null,
  groupId: null,
  sellerId: null,
  stepId: null,
  contactTypeId: null,
  salesProtection: null,
};

export const InitQnaInfo: QnaInfo = {
  title: "",
  qnaCls: "",
  attachmentFiles: [],
};

export const InitQaQcInfo: QaQcInfo = {
  id: null,
  orderId: null,
  serialNumbers: null,
  documentId: "",
  deliveryDate: "",
};

export const InitQaQcListInfo: QaQcListInfo = {
  id: 0,
  customerId: 0,
  placeName: "",
  order: InitLeadOrdersInfo,
  serialNumbers: null,
  documentId: "",
  deliveryDate: "",
  isChecked: false,
  checker: null,
  createdAt: "",
  updatedAt: null,
  createBy: InitEmployeeTableInfo,
  updateBy: null,
};
export const InitSalesProtectionSettingInfo: SalesProtectionSettingInfo = {
  salesEmployeeProtectionCount: 0,
  nonSalesEmployeeProtectionCount: 0,
};

export const InitSalesActivityInfo: SalesActivityInfo = {
  priority: 1,
  type: "",
  status: "inProgress",
  title: "",
  activityDate: "",
  followUpDate: "",
  content: "",
  nextAction: "",
  customerId: null,
  isChecked: false,
};

export const InitScheduleInfo: ScheduleInfo = {
  subject: "",
  contents: "",
  categoryColor: "",
  startTime: "",
  endTime: "",
  isAllDay: false,
  isAllGroup: true,
};

export const InitScheduleDetailInfo: ScheduleDetailInfo = {
  id: null,
  subject: "",
  contents: "",
  categoryColor: "",
  startTime: "",
  endTime: "",
  isAllDay: false,
  isAllGroup: true,
  teamName: "",
  groupId: null,
  createdAt: "",
  updatedAt: "",
  createdBy: "",
  updatedBy: "",
};
