import { Autocomplete, Box, Chip, Grid, ListSubheader } from "@mui/material";
import {
  BaseListProps,
  CommonSearchProps,
} from "../../../types/Common/CommonSearch";
import { CustomTextField } from "../../../styles/theme";

const CommonAutoComplete = ({
  list = [],
  onChange: _onChange,
  optionList = [],
  searchName,
  t,
}: CommonSearchProps) => {
  return (
    <Autocomplete
      sx={{
        "& .MuiInputBase-root": {
          "&.MuiInput-root:after": {
            borderBottom: "2px solid #000000",
          },
        },
      }}
      fullWidth
      multiple
      size="small"
      options={optionList}
      value={optionList.filter((option) => {
        // list가 number[]인지 string[]인지 확인 후 as로 명시적 타입 지정
        if (typeof option.id === "number" && Array.isArray(list)) {
          return (list as number[]).includes(option.id as number);
        } else if (typeof option.id === "string" && Array.isArray(list)) {
          return (list as string[]).includes(option.id as string);
        }
        return false;
      })}
      disableCloseOnSelect
      groupBy={(option: BaseListProps) => option.groupName || ""}
      renderGroup={(params) => (
        <div key={params.key}>
          <ListSubheader>
            {/* groupName을 기반으로 표시할 텍스트를 커스터마이징 */}
            {params.group === "" ? "" : `${t(params.group)}`}
          </ListSubheader>
          {params.children}
        </div>
      )}
      onChange={(event: any, newValue: BaseListProps[]) => {
        const selectedIds = newValue
          .map((item) => item.id)
          .filter((id) => id !== null) as number[];
        _onChange(selectedIds);
      }}
      isOptionEqualToValue={(option: BaseListProps, value: BaseListProps) =>
        option.id === value.id
      }
      getOptionLabel={(option: BaseListProps) => option.name}
      renderOption={(props, option: BaseListProps) => (
        <Box component="li" {...props}>
          <Grid item xs={12}>
            {option.name}
          </Grid>
        </Box>
      )}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          style={{}}
          variant="outlined"
          placeholder={t("Search Placeholder", {
            label: t(searchName),
            Search: t("Search"),
          })}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            size="small"
            label={option.name}
            {...getTagProps({ index })}
            variant="outlined"
          />
        ))
      }
    />
  );
};

export default CommonAutoComplete;
