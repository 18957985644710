import { SearchDatePickerProps } from "../../../types/Common/CommonSearch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CommonDatePicker = ({
  label,
  dateValue,
  onChange,
}: SearchDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={dateValue}
        onChange={
          onChange as (value: unknown, keyboardInputValue?: string) => void
        } // 타입 단언 추가
        renderInput={(params) => <DatePickerRenderInput {...params} />}
      />
    </LocalizationProvider>
  );
};

const DatePickerRenderInput = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-input": {
    display: "flex",
    alignItems: "center",
    padding: "8px",
  },
});

export default CommonDatePicker;
