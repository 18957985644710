export const EXPECTED_PURCHASE_STAGE = [
  "신규 거래",
  "1개월 이내",
  "3개월 이내",
  "6개월 이내",
  "12개월 이내",
  "장기F/U",
];
export const NOTI_TYPE = [
  { id: "all", name: "전체" },
  { id: "comment", name: "댓글" },
  { id: "meetingNote", name: "미팅노트" },
  { id: "customer", name: "Pipeline" },
  { id: "qna", name: "질문/답변" },
];

export const NOTI_CHECKTYPE = ["전체", "읽음", "안 읽음"];

export const ROLE_TYPE = ["USER", "CS", "ADMIN", "MANAGER", "QAQC"];

export const ENABLED_TYPE = [
  { name: "활성화", value: true },
  { name: "비활성화", value: false },
];

export const QNACLS_TYPE = ["기획", "Schedule", "기술", "기타"];

export const MONTH_LIST = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const PURCHASEPROBABILITY_TYPE = [
  { id: 0, name: 25 },
  { id: 1, name: 50 },
  { id: 2, name: 75 },
  { id: 3, name: 90 },
];

export const STEP_TYPE = [
  "leadGeneration",
  "customerEngagement",
  "inquiryAndProposal",
  "negotiation",
  "contractSigning/dealFailure",
  "postSalesSupport",
];

export const SALESACTIVITY_TYPE = [
  "call",
  "email",
  "dm",
  "visit",
  "webinar",
  "demo",
  "supply",
  "cs",
  "etc",
];
export const SALESACTIVITY_STATUS = [
  "awaitingInput",
  "delayed",
  "inProgress",
  "completed",
  "canceled",
];

export const SALESACTIVITY_PRIORITY = [
  { id: 1, name: "매우 높음" },
  { id: 2, name: "높음" },
  { id: 3, name: "보통" },
  { id: 4, name: "낮음" },
  { id: 5, name: "매우 낮음" },
];
