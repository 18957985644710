import {
  Box,
  Button,
  TableBody,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  CommonMuiTableBodyCell,
  CommonMuiTableHeadCell,
  CommonMuiTableHeadCellText,
  CommonMuiTableOutterWrapper,
  CommonMuiTableWrapper,
} from "../../../styles/Common/commonMuiTableStyle";
import {
  CommonEditMuiTableProps,
  EditTableHeadCellData,
  TableCellData,
} from "../../../types/Common/CommonMuiTable";
import { useEffect, useState } from "react";
import { ellipsis } from "../../../styles/Common/util";
import Message from "../Message/message";
import { t } from "i18next";
import { checkEmptyRowList } from "../../../utils/Table/table";

const CommonEditMuiTable = <
  C extends EditTableHeadCellData[],
  R extends TableCellData[]
>({
  columnList,
  rowList,
  handleSave,
  handleDelete,
}: CommonEditMuiTableProps<C, R>) => {
  const [keyList, setKeyList] = useState<string[]>([]);
  const [rowInputList, setRowInputList] = useState<TableCellData[]>([]);

  const handleInputChange = (rIdx: number, key: string, value: string) => {
    setRowInputList((prevList) =>
      prevList.map((row, idx) =>
        idx === rIdx ? { ...row, [key]: value } : row
      )
    );
  };

  useEffect(() => {
    setKeyList(columnList.map((el) => el.key));
  }, [columnList]);

  useEffect(() => {
    setRowInputList([...rowList]);
  }, [rowList]);

  return (
    <CommonMuiTableOutterWrapper>
      <CommonMuiTableWrapper>
        <TableHead>
          <TableRow>
            {columnList.map((column, cIdx) => (
              <CommonMuiTableHeadCell
                key={`column${column.key}`}
                // onClick={() => handleHeadCellClick?.(column)}
                sx={{
                  minWidth: column.width ? column.width : "auto",
                }}
              >
                {column.value.split("<br/>").map((str, idx) => {
                  return (
                    <CommonMuiTableHeadCellText key={`tHeadCell${str}${idx}`}>
                      {str}
                    </CommonMuiTableHeadCellText>
                  );
                })}
              </CommonMuiTableHeadCell>
            ))}
            <CommonMuiTableHeadCell>
              <CommonMuiTableHeadCellText>
                {t("Save")}
              </CommonMuiTableHeadCellText>
            </CommonMuiTableHeadCell>
            <CommonMuiTableHeadCell>
              <CommonMuiTableHeadCellText>
                {t("Delete")}
              </CommonMuiTableHeadCellText>
            </CommonMuiTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checkEmptyRowList(rowInputList) ? (
            <TableRow>
              <CommonMuiTableBodyCell colSpan={columnList.length}>
                <Message text={t("No data found")} />
              </CommonMuiTableBodyCell>
            </TableRow>
          ) : (
            rowInputList.map((row, rIdx) => {
              return (
                <TableRow key={`row${rIdx}`}>
                  {keyList.map((key, idx) => {
                    const column = columnList[idx];
                    return (
                      <CommonMuiTableBodyCell key={`item${key}${idx}`}>
                        {column.isEdit ? (
                          column.type === "number" ? (
                            <TextField
                              type="number"
                              size="small"
                              value={row[key]}
                              onChange={(e) =>
                                handleInputChange(rIdx, key, e.target.value)
                              }
                            />
                          ) : (
                            <TextField
                              type="text"
                              size="small"
                              value={row[key]}
                              onChange={(e) =>
                                handleInputChange(rIdx, key, e.target.value)
                              }
                            />
                          )
                        ) : (
                          <Box sx={{ ...ellipsis(2) }}>{row[key]}</Box>
                        )}
                      </CommonMuiTableBodyCell>
                    );
                  })}
                  <CommonMuiTableBodyCell>
                    <Button onClick={() => handleSave(rowInputList[rIdx])}>
                      {t("Save")}
                    </Button>
                  </CommonMuiTableBodyCell>
                  <CommonMuiTableBodyCell>
                    <Button onClick={() => handleDelete(rowList[rIdx])}>
                      {t("Delete")}
                    </Button>
                  </CommonMuiTableBodyCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </CommonMuiTableWrapper>
    </CommonMuiTableOutterWrapper>
  );
};

export default CommonEditMuiTable;
