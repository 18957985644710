import {
  ChangeEventArgs,
  DropDownTreeComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { t } from "i18next";
import { FilterObject } from "../../../types/Lead/List";

type CommonDorpdownTreeComponentProps = {
  dropdownList: { [key: string]: Object }[];
  filterObjects: FilterObject[];
  filterTarget: string;
  placeholder: string;
  handleChange: (filterTarget: string, args: ChangeEventArgs) => void;
  showCheckBox: boolean;
};

const CommonDropdownTreeComponent = ({
  dropdownList,
  filterObjects,
  filterTarget,
  placeholder,
  handleChange,
  showCheckBox,
  ...props
}: CommonDorpdownTreeComponentProps) => {
  // syncfusion에 필요한 데이터
  const groupFields = {
    dataSource: dropdownList ?? [],
    value: "id",
    text: "name",
    parentValue: "parentId",
    hasChildren: "hasChild",
  };

  return (
    <DropDownTreeComponent
      fields={groupFields}
      value={
        Array.isArray(
          filterObjects.find((obj) => obj.field === filterTarget)?.values
        )
          ? (filterObjects.find((obj) => obj.field === filterTarget)
              ?.values as string[])
          : []
      }
      showCheckBox={true}
      placeholder={t(placeholder)}
      change={(args: ChangeEventArgs) => handleChange(filterTarget, args)} // 선택 시 처리
      {...props}
    />
  );
};

export default CommonDropdownTreeComponent;
