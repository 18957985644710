import { MenuItem, Select } from "@mui/material";
import { SearchCommonDropdownProps } from "../../../types/Common/CommonSearch";

const CommonDropdown = ({
  value,
  options,
  handleChange,
  disabled,
  ...props
}: SearchCommonDropdownProps) => {
  return (
    <Select
      value={value}
      onChange={handleChange}
      fullWidth
      disabled={disabled}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 160, // 최대 높이를 200px로 설정
            overflow: "auto", // 내용이 넘치면 스크롤 추가
          },
        },
      }}
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option.key} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CommonDropdown;
