import CommonEditMuiTable from "../../../../../components/Common/Table/CommonEditMuiTable";
import Message from "../../../../../components/Common/Message/message";
import { Grid } from "@mui/material";
import { CustomText } from "../../../../../styles/CustomText";
import { useCallback } from "react";
import {
  EmployeeInfo,
  EmployeeInfoWithAchievement,
} from "../../../../../types/Employee/Employee";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../../../styles/Color";
import { AlertModal } from "../../../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../../../system/ApiService";
import { AxiosError } from "axios";
import dayjs from "dayjs";

import { GoalApi } from "../../../../../api/Goal/goalApi";
import { EditTableHeadCellData } from "../../../../../types/Common/CommonMuiTable";
import { GroupApi } from "../../../../../api/Group/groupApi";

type AdminEmployeeTableProps = {
  groupId: number;
  columnList: EditTableHeadCellData[];
  rowList: any;
  errorMessage: string;
  fetchEmployeeList: () => void;
};
const AdminEmployeeTable = ({
  groupId,
  columnList,
  rowList,
  errorMessage,
  fetchEmployeeList,
}: AdminEmployeeTableProps) => {
  // PATCH - 구성원 목표액 수정
  const patchGroupEmployeeAchievement = useCallback(
    async (employee: EmployeeInfoWithAchievement) => {
      try {
        const {
          id,
          goalId,
          name,
          salesAmount,
          newLeadsInbound,
          newLeadsOutbound,
          inboundLeadConversion,
          outboundLeadConversion,
        } = employee;
        const params = {
          year: dayjs().year(),
          salesAmount: Number(salesAmount),
          newLeadsInbound: Number(newLeadsInbound),
          newLeadsOutbound: Number(newLeadsOutbound),
          inboundLeadConversion: Number(inboundLeadConversion),
          outboundLeadConversion: Number(outboundLeadConversion),
          employee: {
            id: id,
            name: name,
          },
        };
        const response = await GoalApi.patchEmployeeGoal(
          goalId as number,
          params
        );
        if (response) {
          fetchEmployeeList();
        }
      } catch (err) {
        const msg = ErrorHandler(err as AxiosError);
        AlertModal("msg", msg);
      }
    },
    [fetchEmployeeList]
  );

  // POST - 그룹원 목표액 생성
  const postGroupEmployeeAchievement = useCallback(
    async (employee: EmployeeInfoWithAchievement) => {
      try {
        const {
          id,
          name,
          salesAmount,
          newLeadsInbound,
          newLeadsOutbound,
          inboundLeadConversion,
          outboundLeadConversion,
        } = employee;
        const params = {
          year: dayjs().year(),
          salesAmount: Number(salesAmount),
          newLeadsInbound: Number(newLeadsInbound),
          newLeadsOutbound: Number(newLeadsOutbound),
          inboundLeadConversion: Number(inboundLeadConversion),
          outboundLeadConversion: Number(outboundLeadConversion),
          employee: {
            id: id,
            name: name,
          },
        };
        await GoalApi.postEmployeeGoal(params);
        await fetchEmployeeList();
      } catch (err) {
        const message = ErrorHandler(err as AxiosError);
        AlertModal("msg", message);
      }
    },
    [fetchEmployeeList]
  );

  // 저장 버튼 클릭
  const handleClickSaveButton = useCallback(
    (employee: EmployeeInfoWithAchievement) => {
      const { goalIsSet } = employee;
      if (goalIsSet) patchGroupEmployeeAchievement(employee);
      else postGroupEmployeeAchievement(employee);
    },
    [postGroupEmployeeAchievement, patchGroupEmployeeAchievement]
  );

  // DELETE - 그룹내 팀원 삭제
  const deleteGroupEmployee = useCallback(
    async (member: EmployeeInfo) => {
      try {
        const deleteEmployeeList = { groupId: groupId, employees: [member] };
        await GroupApi.deleteGroupEmployee(deleteEmployeeList);
        AlertModal("success", "선택하신 구성원이 삭제되었습니다.");
        await fetchEmployeeList();
      } catch (err) {
        const message = ErrorHandler(err as AxiosError);
        AlertModal("msg", message);
      }
    },
    [groupId, fetchEmployeeList]
  );

  // 삭제 버튼 클릭
  const handleClickDeleteButton = useCallback(
    (member: EmployeeInfo) => {
      // const deleteEmployeeList = { groupId: groupId, employees: [member] };
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 구성원이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          deleteGroupEmployee(member);
        }
      });
    },
    [deleteGroupEmployee]
  );

  return errorMessage.length > 0 ? (
    <Message text={errorMessage} />
  ) : rowList.length === 0 ? (
    <Grid container justifyContent="center" margin="10px">
      <CustomText type="subtitle" textAlign="center" color="#3D4861">
        구성원을 추가해주세요.
      </CustomText>
    </Grid>
  ) : (
    <CommonEditMuiTable
      columnList={columnList}
      rowList={rowList}
      handleSave={handleClickSaveButton}
      handleDelete={handleClickDeleteButton}
    />
  );
};

export default AdminEmployeeTable;
