import { Box, Grid, Modal } from "@mui/material";
import { ModalStyle, PointerItem } from "../../../../../styles/theme";
import { CustomText } from "../../../../../styles/CustomText";
import { X } from "@phosphor-icons/react";
import SelectMultiEmployee from "../../../../../components/Common/SelectMultiEmployee";
import { CustomButtonGreen } from "../../../../../styles/buttonStyle";
import { EmployeeInfo } from "../../../../../types/Employee/Employee";
import { useCallback, useState } from "react";
import { GroupEmployeeInfo } from "../../../../../types/Group/group";
import { ErrorHandler } from "../../../../../system/ApiService";
import { AlertModal } from "../../../../../components/Common/AlertModal";
import { InitGroupEmployeeInfo } from "../../../../../system/types/initObject";
import { GroupApi } from "../../../../../api/Group/groupApi";
import { AxiosError } from "axios";

type AdminEmployeeAddModalProps = {
  isOpen: boolean;
  handleAddModal: (status: boolean) => void;
  id: number;
  employeeList: any;
  groupEmployeeList: any;
  fetchEmployeeList: any;
};

const AdminEmployeeAddModal = ({
  isOpen,
  handleAddModal,
  id,
  employeeList,
  groupEmployeeList,
  fetchEmployeeList,
}: AdminEmployeeAddModalProps) => {
  const [addGroupEmployee, setAddGroupEmployee] = useState<GroupEmployeeInfo>({
    ...InitGroupEmployeeInfo,
    groupId: id,
  });

  // 추가 목록 변경
  const handleChangeEmployeeList = useCallback(
    (memberList: EmployeeInfo[]) => {
      setAddGroupEmployee({ ...addGroupEmployee, employees: memberList });
    },
    [addGroupEmployee]
  );

  // 이미 존재하는 사람인지 체크.
  const checkDuplicateEmployee = useCallback(() => {
    // `groupEmployeeList`의 ID로 체크하기 위해 Set으로 변환
    const groupEmployeeIds = new Set(
      groupEmployeeList.map((employee: EmployeeInfo) => employee.id)
    );

    // `addGroupList`에서 중복되지 않은 사람들만 필터링
    const { employees } = addGroupEmployee;
    const filteredList = employees.filter(
      (employee) => !groupEmployeeIds.has(employee.id)
    );

    return filteredList;
  }, [addGroupEmployee, groupEmployeeList]);

  // Employee 추가
  const postEmployeeList = useCallback(async () => {
    try {
      const params = { groupId: id, employees: checkDuplicateEmployee() };
      await GroupApi.createGroupEmployee<GroupEmployeeInfo, any>(params);
      fetchEmployeeList();
      handleAddModal(false);
    } catch (err) {
      const msg = ErrorHandler(err as AxiosError);
      AlertModal("msg", msg);
    }
  }, [id, handleAddModal, fetchEmployeeList, checkDuplicateEmployee]);

  return (
    <Modal
      open={isOpen}
      onClose={() => handleAddModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <Grid container spacing={2}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            item
            xs={12}
          >
            <CustomText type="title"> 구성원 추가</CustomText>

            <PointerItem onClick={() => handleAddModal(false)}>
              <X size={32} />
            </PointerItem>
          </Grid>
          <Grid item xs={12} mt={9} mb={6}>
            <SelectMultiEmployee
              list={addGroupEmployee.employees}
              onChange={handleChangeEmployeeList}
              employeeList={employeeList}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="right" mt="20px">
          <CustomButtonGreen onClick={postEmployeeList}>저장</CustomButtonGreen>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AdminEmployeeAddModal;
