import React, { Component } from "react";
import {
  HtmlEditor,
  Inject,
  ToolbarType,
  ToolbarSettingsModel,
  RichTextEditorComponent,
  Toolbar,
  Table,
  Link,
  Image,
  QuickToolbar,
  PasteCleanup,
} from "@syncfusion/ej2-react-richtexteditor";

interface Props {
  content: string;
}
interface State {
  value: string;
}

export default class Editor extends Component<Props, State> {
  state: State = {
    value: this.props.content,
  };

  private editorRef = React.createRef<RichTextEditorComponent>();

  // Text editor
  private editorToolbarSetting: ToolbarSettingsModel = {
    items: [
      "CreateTable",
      "|",
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "SubScript",
      "SuperScript",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "Image",
      "CreateLink",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "|",
      "Cut",
      "Copy",
      "|",
      "Undo",
      "Redo",
    ],
    type: ToolbarType.MultiRow,
  };

  public getContent() {
    return this.editorRef.current?.value || "";
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.content !== prevProps.content) {
      this.setState({
        value: this.props.content,
      });
    }
  }

  public render() {
    const _content = this.state.value ? this.state.value : undefined;
    return (
      <RichTextEditorComponent
        id="rich-text-editor"
        toolbarSettings={this.editorToolbarSetting}
        height={400}
        ref={this.editorRef}
        value={_content}
        enableXhtml={true}
        placeholder="내용을 입력해 주세요."
        quickToolbarSettings={{
          table: [
            "TableHeader",
            "TableRows",
            "TableColumns",
            "BackgroundColor",
            "-",
            "TableRemove",
            "Alignments",
            "TableCellVerticalAlign",
            "Styles",
            "TableCell",
          ],
        }}
        insertImageSettings={{ saveFormat: "Base64" }}
      >
        <Inject
          services={[
            Toolbar,
            PasteCleanup,
            Image,
            Link,
            QuickToolbar,
            HtmlEditor,
            Table,
          ]}
        />
      </RichTextEditorComponent>
    );
  }
}
