import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FilteringWrapper,
  FilteringInnerWrapper,
  FilteringColumnWrapper,
} from "../../../../styles/List/Lead/FilteringListStyle";
import {
  BrandButton,
  WaitingButton,
} from "../../../../styles/Button/buttonStyle";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { useCallback, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  ComparatorListType,
  ComparatorStringType,
  EnumType,
  FilterObject,
  StatusType,
} from "../../../../types/Lead/List";
import CommonInput from "../../../../components/Common/Form/CommonInput";
import GetFilterData from "./GetFilterData";
import CommonAutoComplete from "../../../../components/Common/Form/CommonAutoComplete";
import CommonDropdown from "../../../../components/Common/Form/CommonDropdown";
import { FilteringListProps } from "../../../../types/Customers/Customers";
import SelectColor from "./SelectColor";
import DateRangeSearch from "./DateRangeSearch";
import CommonDropdownTreeComponent from "../../../../components/Common/Form/CommonDropdownTreeComponent";
function FilteringList({
  filterObjects,
  setFilterObjects,
  postAllCustomersInfo,
  selectedColumns,
  setSelectedColumns,
  dropdownData,
  setDropdownData,
  inputValues,
  setInputValues,
}: FilteringListProps) {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const InitFilterList = [
    {
      key: "salesProtection",
      filterKey: "Sales Protection",
      filterType: "checkbox",
    },
    { key: "placeName", filterKey: "placeName", filterType: "string" },
    { key: "address", filterKey: "address", filterType: "string" },
    {
      key: "estimatedDate",
      filterKey: "estimatedDate",
      filterType: "dateRangePicker",
    },
    { key: "status", filterKey: "status", filterType: "color" },
    {
      key: "businessTypeId",
      filterKey: "businessType",
      filterType: "autocomplete",
    },
    {
      key: "departmentId",
      filterKey: "department",
      filterType: "autocomplete",
    },
    {
      key: "productFamily",
      filterKey: "Product Family",
      filterType: "autocomplete",
    },
    { key: "productId", filterKey: "Product Name", filterType: "autocomplete" },
    { key: "groupId", filterKey: "Group", filterType: "tree" },
    { key: "sellerId", filterKey: "Seller", filterType: "autocomplete" },
    { key: "stepId", filterKey: "step", filterType: "autocomplete" },
    {
      key: "contactTypeId",
      filterKey: "contactType",
      filterType: "autocomplete",
    },
    {
      key: "intermediaryCompanyId",
      filterKey: "Intermediary",
      filterType: "autocomplete",
    },
    {
      key: "salesTypeId",
      filterKey: "salesType",
      filterType: "autocomplete",
    },
    {
      key: "supplyTypeId",
      filterKey: "supplyType",
      filterType: "autocomplete",
    },
    {
      key: "contractTypeId",
      filterKey: "contractType",
      filterType: "autocomplete",
    },
    { key: "tagId", filterKey: "tags", filterType: "autocomplete" },
    {
      key: "purchaseProbability",
      filterKey: "PurchaseProbability",
      filterType: "autocomplete",
    },
    {
      key: "activityDate",
      filterKey: "activityDate",
      filterType: "dateRangePicker",
    },
    {
      key: "followUpDate",
      filterKey: "followUpDate",
      filterType: "dateRangePicker",
    },
    { key: "createdAt", filterKey: "CreatedAt", filterType: "dateRangePicker" },
  ];

  const [searchWord, setSearchWord] = useState("");

  const handleToggle = useCallback(
    async (key: string, type: string, value: string) => {
      // 필터링 목록 선택
      setSelectedColumns((prevSelected) =>
        prevSelected.includes(key)
          ? prevSelected.filter((col) => col !== key)
          : [...prevSelected, key]
      );
      // 선택 시 filterObjects에 object 생성. string, checkbox일 때 value, 나머지 values 초기화.
      setFilterObjects((prev) =>
        prev.some((obj) => obj.field === key)
          ? prev.filter((obj) => obj.field !== key)
          : [
              ...prev,
              {
                comparator:
                  type === "dateRangePicker"
                    ? "BETWEEN"
                    : type === "string" || type === "checkbox"
                    ? "EQUAL"
                    : "IN",
                field: key,
                ...(type === "string" || type === "checkbox"
                  ? { value: type === "checkbox" ? true : value }
                  : type === "autocomplete" ||
                    type === "dropdown" ||
                    type === "tree"
                  ? { values: [] }
                  : type === "daterangepicker"
                  ? { dateValue: null }
                  : null),
              },
            ]
      );
      // dropdown data 받아오기
      if (!dropdownData[key]) {
        const data = await GetFilterData(value);
        setDropdownData((prev) => ({ ...prev, [key]: data as any[] }));
      }
    },
    [dropdownData, GetFilterData]
  );
  const handleSearchWordInputChange = useCallback((value: string) => {
    setSearchWord(value);
  }, []);

  // searchWord가 포함된 InitFilterList의 항목만 필터링
  const filteredList = InitFilterList.filter((column) =>
    column.filterKey.includes(searchWord)
  );

  const handleInputChange = useCallback((key: string, value: string) => {
    setInputValues((prev) => ({ ...prev, [key]: value }));
    setFilterObjects((prev) =>
      prev.map((obj) => (obj.field === key ? { ...obj, value } : obj))
    );
  }, []);

  // 날짜 범위 변경 핸들러
  const handleDateRangeChange = useCallback(
    (key: string, newValue: [Dayjs | null, Dayjs | null]) => {
      const formattedDates: [string | null, string | null] = [
        newValue[0] ? newValue[0].format("YYYY-MM-DD") : null,
        newValue[1] ? newValue[1].format("YYYY-MM-DD") : null,
      ];

      setFilterObjects((prev) =>
        prev.map((obj) =>
          obj.field === key ? { ...obj, values: formattedDates } : obj
        )
      );
    },
    []
  );

  // type이 autocomplete일 때 CommonAutoComplete에 filterObjects 해당 항목을 업데이트
  const handleAutoCompleteChange = useCallback(
    (key: string, selectedIds: number[] | string[]) => {
      setFilterObjects((prev) =>
        prev.map((obj) =>
          obj.field === key ? { ...obj, values: selectedIds } : obj
        )
      );
    },
    []
  );
  // enum 객체를 드롭다운 컴포넌트에서 사용할 옵션 배열로 변환
  const createOptions = useCallback(
    (enumObj: EnumType) => {
      return Object.entries(enumObj).map(([key, value]) => ({
        key,
        value,
        label: t(value),
      }));
    },
    [t]
  );
  // filterObjects comparator 값 입력
  const handleChange = useCallback(
    (key: string, event: SelectChangeEvent<string>) => {
      setFilterObjects((prev) =>
        prev.map((obj) =>
          obj.field === key
            ? {
                ...obj,
                comparator: event.target.value as
                  | ComparatorStringType
                  | ComparatorListType,
              }
            : obj
        )
      );
    },
    []
  );

  //
  const handleTreeComponent = useCallback((key: string, args: any) => {
    setFilterObjects((prev) =>
      prev.map((obj) =>
        obj.field === key ? { ...obj, values: args.value } : obj
      )
    );
  }, []);

  // status 색상 변경
  const handleColorChange = useCallback((key: string, value: string) => {
    setFilterObjects(
      (prev) =>
        prev.map((obj) => {
          if (obj.field === key) {
            // values가 string[]일 경우
            if (Array.isArray(obj.values)) {
              const stringValues = obj.values as string[]; // 명시적 캐스팅

              return {
                ...obj,
                values: stringValues.includes(value)
                  ? stringValues.filter((v) => v !== value) // value가 이미 있으면 제거
                  : [...stringValues, value], // value가 없으면 추가
              };
            }

            // values가 null이거나 string[]가 아닐 경우
            return {
              ...obj,
              values: [value], // values가 null이거나 string[]가 아닐 경우 초기화
            };
          }
          return obj;
        }) as FilterObject[]
    );
  }, []);

  const handleApplyFilter = () => {
    postAllCustomersInfo(filterObjects);
  };

  const resetFilters = () => {
    setSelectedColumns([]);
    setFilterObjects([]);
  };

  return (
    <Grid container item xs={12}>
      <FilteringWrapper>
        <TextField
          fullWidth
          value={searchWord}
          onChange={(e) => handleSearchWordInputChange(e.target.value)}
          size="small"
          variant="outlined"
          placeholder={t("Search")}
          margin="dense"
        />
        <FilteringInnerWrapper>
          <FormGroup>
            {filteredList.map((column) => {
              const filterObj = filterObjects.find(
                (obj) => obj.field === column.key
              );
              const isComparatorEmptyOrNotEmpty =
                filterObj?.comparator === "EMPTY" ||
                filterObj?.comparator === "NOT_EMPTY";

              return (
                <FilteringColumnWrapper key={column.key}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedColumns.includes(column.key)}
                        onChange={() =>
                          handleToggle(
                            column.key,
                            column.filterType,
                            column.filterKey
                          )
                        }
                      />
                    }
                    label={t(column.filterKey)}
                  />
                  {selectedColumns.includes(column.key) && (
                    <>
                      {column.filterType === "string" && (
                        <Grid>
                          <CommonDropdown
                            value={filterObj?.comparator || "EQUAL"}
                            options={createOptions(ComparatorStringType).map(
                              (option) => ({
                                key: option.key,
                                value: option.value,
                                label: option.label,
                              })
                            )}
                            handleChange={(event) =>
                              handleChange(column.key, event)
                            }
                            sx={{ height: "36px", marginBottom: "4px" }}
                          />
                          {!isComparatorEmptyOrNotEmpty && (
                            <CommonInput
                              label={column.filterKey}
                              value={inputValues[column.key] || ""}
                              onChange={(value) =>
                                handleInputChange(column.key, value)
                              }
                              t={t}
                            />
                          )}
                        </Grid>
                      )}
                      {column.filterType === "dateRangePicker" && (
                        <DateRangeSearch
                          value={[
                            filterObj?.values?.[0]
                              ? dayjs(filterObj.values[0])
                              : null,
                            filterObj?.values?.[1]
                              ? dayjs(filterObj.values[1])
                              : null,
                          ]}
                          handleChange={(newRange) =>
                            handleDateRangeChange(column.key, newRange)
                          }
                        />
                      )}
                      {column.filterType === "autocomplete" && (
                        <Grid>
                          <CommonDropdown
                            value={filterObj?.comparator || "IN"}
                            options={createOptions(ComparatorListType).map(
                              (option) => ({
                                key: option.key,
                                value: option.value,
                                label: option.label,
                              })
                            )}
                            handleChange={(event) =>
                              handleChange(column.key, event)
                            }
                            sx={{ height: "36px", marginBottom: "4px" }}
                          />
                          {!isComparatorEmptyOrNotEmpty && (
                            <CommonAutoComplete
                              list={filterObj?.values || []}
                              optionList={dropdownData[column.key]}
                              onChange={(selectedIds) =>
                                handleAutoCompleteChange(
                                  column.key,
                                  selectedIds
                                )
                              }
                              searchName={column.filterKey}
                              t={t}
                            />
                          )}
                        </Grid>
                      )}
                      {column.filterType === "tree" &&
                        dropdownData[column.key] && (
                          <CommonDropdownTreeComponent
                            dropdownList={dropdownData["groupId"]}
                            filterObjects={filterObjects}
                            filterTarget={column.key}
                            handleChange={(filterTarget, args) =>
                              handleTreeComponent(filterTarget, args)
                            }
                            placeholder={"Select Groups"}
                            showCheckBox={true}
                          />
                        )}
                      {column.filterType === "color" && (
                        <SelectColor
                          options={Object.values(StatusType)}
                          value={
                            Array.isArray(filterObj?.values)
                              ? (filterObj?.values as string[])
                              : []
                          }
                          handleColorChange={(color) =>
                            handleColorChange(column.key, color)
                          }
                        />
                      )}
                    </>
                  )}
                </FilteringColumnWrapper>
              );
            })}
          </FormGroup>
        </FilteringInnerWrapper>
        <Grid container item xs={12} justifyContent="right" mt="20px">
          <WaitingButton
            deviceType={deviceType}
            style={{ marginRight: "5px" }}
            onClick={handleApplyFilter}
          >
            {t("Apply Filter")}
          </WaitingButton>
          <BrandButton deviceType={deviceType} onClick={resetFilters}>
            {t("Reset")}
          </BrandButton>
        </Grid>
      </FilteringWrapper>
    </Grid>
  );
}

export default FilteringList;
