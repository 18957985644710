import { CustomText } from "../../../styles/CustomText";
import MyTableList from "./components/MyTableList";

function MyBranchPage() {
  return (
    <>
      <CustomText type="title"> 내 지사 거래 목록</CustomText>
      <MyTableList type="myGroup" />
    </>
  );
}

export default MyBranchPage;
