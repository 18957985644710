import { Chip, Grid } from "@mui/material";
import { AdminGroupItemBoxTitle } from "../../../../../styles/adminStyle";
import { CustomText } from "../../../../../styles/CustomText";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { CustomButtonSmallCommon } from "../../../../../styles/buttonStyle";
import { GroupInfo } from "../../../../../types/Group/group";

type AdminChildrenGroupSectionTitleProps = {
  groupData: GroupInfo;
  handleAddModal: (status: boolean) => void;
};
const AdminChildrenGroupSectionTitle = ({
  groupData,
  handleAddModal,
}: AdminChildrenGroupSectionTitleProps) => {
  return (
    <AdminGroupItemBoxTitle
      paddingnumber="6px 10px"
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid container item xs={6}>
        <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
          하위 그룹
        </CustomText>
        <Chip
          icon={<PeopleOutlineIcon />}
          size="small"
          label={groupData.children.length}
          variant="outlined"
        />
      </Grid>
      <Grid container item justifyContent="right" xs={6}>
        <CustomButtonSmallCommon onClick={() => handleAddModal(true)}>
          추가
        </CustomButtonSmallCommon>
      </Grid>
    </AdminGroupItemBoxTitle>
  );
};

export default AdminChildrenGroupSectionTitle;
