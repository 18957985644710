import { Box, styled, Table, TableCell } from "@mui/material";
import { Gray100, Gray25 } from "../Color";

export const CommonMuiTableOutterWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  width: "100%",
  minHeight: "360px",
});

export const CommonMuiTableInnerWrapper = styled(Box)({
  display: "flex",
  alignSelf: "flex-start",
  overflowX: "auto",
  width: "100%",
});

export const CommonMuiTableWrapper = styled(Table)({
  width: "100%",
});

export const CommonMuiTableHeadCell = styled(TableCell)({
  cursor: "pointer",
  borderTop: `1px solid ${Gray100}`,
  borderBottom: `1px solid ${Gray100}`,
  padding: "4px 0",
  textAlign: "center",
  backgroundColor: Gray25,
});
export const CommonMuiTableHeadCellTextWrapper = styled("span")({
  display: "inline-block",
});
export const CommonMuiTableHeadCellText = styled("span")({
  display: "block",
  fontWeight: 600,
  fontSize: "1rem",
});
export const CommonMuiTableBodyCell = styled(TableCell)({
  borderTop: `1px solid ${Gray100}`,
  borderBottom: `1px solid ${Gray100}`,
  fontSize: "1rem",
  padding: "4px 4px",
  cursor: "pointer",
});

export const PaginationWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "flex-end",
  marginTop: "8px",
});
export const PaginationInnerWrapper = styled(Box)({
  display: "flex",
});
export const PaginationOutterWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "flex-end",
  marginTop: "8px",
});
