import {
  LoginPageRightBox,
  LoginPageRightModalBox,
} from "../../../styles/mainStyle";
import LoginBox from "./LoginBox";

function RightBox() {
  return (
    <LoginPageRightBox container justifyContent="center" alignItems="center">
      <LoginPageRightModalBox container alignItems="center">
        <LoginBox />
      </LoginPageRightModalBox>
    </LoginPageRightBox>
  );
}

export default RightBox;
