import dayjs from "dayjs";
import SecurityIcon from "@mui/icons-material/Security";
import {
  ErrorText,
  Gray400,
  SuccessText,
  WaitingText,
  WarningText,
  Yellow400,
} from "../../../../styles/Color";
import ConvertToChip from "../../../../components/Common/Chip/CovertToChip";
import { TFunction } from "i18next";
import { CustomerInfo } from "../../../../types/Customers/Customers";

import { StatusWrapper } from "../../../../styles/List/Lead/leadPageStyle";

import {
  calculateTotalPrice,
  ProductLabelWithTooltip,
} from "../../../../utils/Money/money";

const ConvertToTableData = (customers: CustomerInfo[], t: TFunction) => {
  const getColor = (color: string) => {
    switch (color) {
      case "red":
        return ErrorText;
      case "yellow":
        return Yellow400;
      case "green":
        return SuccessText;
      case "blue":
        return WaitingText;
      case "grey":
        return Gray400;
      default:
        return "#FFFFFF";
    }
  };
  return (
    customers &&
    customers.map((customer) => ({
      ...customer,
      contactType: customer.contactTypeMajor && customer.contactTypeSub && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {customer.contactTypeMajor === "inbound" ? (
            <ConvertToChip label={t("in")} color={SuccessText} width="50px" />
          ) : (
            <ConvertToChip label={t("out")} color={WarningText} width="50px" />
          )}
          <span
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
            }}
          >
            {customer.contactTypeSub}
          </span>
        </div>
      ),
      salesProtection: customer.salesProtection ? (
        <SecurityIcon sx={{ fontSize: 16 }} style={{ marginTop: "4px" }} />
      ) : null, // salesProtection이 true일 때 SecurityIcon 추가
      lastActivityDate: customer.lastActivityDate
        ? dayjs(customer.lastActivityDate).add(9, "hour").format("YYYY-MM-DD")
        : null,
      createdAt: customer.createdAt
        ? dayjs(customer.createdAt).add(9, "hour").format("YYYY-MM-DD")
        : null,
      status: customer.status ? (
        <StatusWrapper color={getColor(customer.status)} />
      ) : (
        ""
      ),
      purchaseProbability: customer.purchaseProbability
        ? customer.purchaseProbability + " %"
        : "",
      intermediaryCompany:
        customer.intermediaryCompany && customer.intermediaryCompany?.name
          ? customer.intermediaryCompany?.name
          : "",
      tags: customer.tags
        ? customer.tags.map((item) => item.name).join(",")
        : "",
      productName: ProductLabelWithTooltip(customer.orders),
      totalPrice: calculateTotalPrice(customer.orders),
    }))
  );
};

export default ConvertToTableData;
