import { Grid } from "@mui/material";
import {
  EmptyTableBolder,
  TableContentBox,
  TableTitleBox,
  TextOverflowBox,
} from "../../../../styles/theme";
import { NotificationInfo } from "../../../../system/types/Notification";
import { useHistory } from "react-router-dom";
import { notiTypeFormatter } from "../../../../components/Common/notiTypeFormatter";
import { isoDateFormatter } from "../../../../components/Common/isoDateFormatter";

interface NotiPcListProps {
  notiData: NotificationInfo[];
}

function NotiPcList({ notiData }: NotiPcListProps) {
  const history = useHistory();
  function onMove(path: string) {
    history.push(`/${path}`);
  }
  return (
    <Grid container>
      <TableTitleBox
        container
        alignItems="center"
        justifyContent="center"
        item
        xs={1}
      >
        유형
      </TableTitleBox>
      <TableTitleBox
        container
        alignItems="center"
        justifyContent="center"
        item
        xs={4.5}
      >
        알림내역
      </TableTitleBox>
      <TableTitleBox
        container
        alignItems="center"
        justifyContent="center"
        item
        xs={4.5}
      >
        작성된 내용
      </TableTitleBox>
      <TableTitleBox
        container
        alignItems="center"
        justifyContent="center"
        item
        xs={1}
      >
        작성 날짜
      </TableTitleBox>
      <TableTitleBox
        container
        alignItems="center"
        justifyContent="center"
        item
        xs={1}
      >
        확인 여부
      </TableTitleBox>
      {notiData.map((dt: NotificationInfo, index: number) => (
        <Grid
          container
          style={{ cursor: "pointer" }}
          onClick={() => onMove(dt.path)}
          key={dt.messageId}
        >
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
          >
            {notiTypeFormatter(dt.type)}
          </TableContentBox>
          <TableContentBox
            item
            xs={4.5}
            container
            justifyContent="center"
            alignItems="center"
          >
            {dt.title}
          </TableContentBox>
          <TableContentBox item xs={4.5} container alignItems="center">
            <TextOverflowBox ml="10px">{dt.body}</TextOverflowBox>
          </TableContentBox>
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
          >
            {isoDateFormatter(new Date(dt.createdAt))}
          </TableContentBox>
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
          >
            {dt.isRead ? "읽음" : "안 읽음"}
          </TableContentBox>
        </Grid>
      ))}
      {notiData.length === 0 && (
        <EmptyTableBolder container justifyContent="center" alignItems="center">
          조회된 알림 내역이 없습니다.
        </EmptyTableBolder>
      )}
    </Grid>
  );
}

export default NotiPcList;
