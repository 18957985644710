import client from "../ApiService";
import { _BasicApi } from "../BasicApi";
import { ScheduleInfo } from "../types/Board";

class _ScheduleApi extends _BasicApi {
  getSchedule(year: number, month: string) {
    return this.client.get(`/?year=${year}&month=${month}`);
  }
  createSchedule(scheduleData: ScheduleInfo) {
    return this.client.post(`/`, scheduleData);
  }

  updateSchedule(scheduleId: number, scheduleData: ScheduleInfo) {
    return this.client.put(`/${scheduleId}`, scheduleData);
  }

  deleteSchedule(scheduleId: number) {
    return this.client.delete(`/${scheduleId}`);
  }
}

export const ScheduleApi = new _ScheduleApi(client);
