export enum Urls {
  password = "/password",
  login = "/login",
  // 조회
  leadList = "/list",
  my = "/list/my",
  myBranch = "/list/mybranch",
  new = "/list/new",
  followUp = "/list/followup",
  answer = "/list/answer",
  leadDetail = "/list/detail/:id",
  intermediary = "/list/intermediary",
  noti = "/list/noti",
  // 등록
  registerByCase = "/register/edit/:id?",
  registerWithExcel = "/register/excel",

  // 게시판
  notice = "/notice",
  noticeEdit = "/notice/edit/:id?",
  noticeDetail = "/notice/detail/:id",
  qna = "/qna",
  qnaEdit = "/qna/edit/:id?",
  qnaDetail = "/qna/detail/:id",
  schedule = "/schedule",
  // 현황
  // statusGraph = "/statusGraph",
  report = "/report",

  // 조달검사검수
  qaqc = "/qaqc",

  // 설정
  myInfo = "/myInfo",

  // 관리자 페이지
  adminUser = "/admin/user",
  adminGroupPermission = "/admin/grouppermission",
  adminGroupPermissionDetail = "/admin/grouppermission/detail/:id?",
  adminInformation = "/admin/information",
  adminProductRegister = "/admin/ProductRegister",
  adminSalesProtection = "/admin/salesprotection",

  // 대시보드
  dashBoard = "/",
}

export interface MenuItem {
  key: string;
  label: string;
  children?: MenuItem[];
  toUrl?: string;
  external?: boolean;
  requiresAdmin?: boolean;
}
export type MenuList = MenuItem[];

export const menuList: MenuList = [
  {
    key: "board",
    label: "Board",
    children: [
      { key: "notice", label: "Notice", toUrl: Urls.notice },
      { key: "qna", label: "Q&A", toUrl: Urls.qna },
      { key: "schedule", label: "Schedule", toUrl: Urls.schedule },
      { key: "qaqc", label: "Procurement", toUrl: Urls.qaqc },
    ],
  },
  {
    key: "list",
    label: "List",
    children: [
      { key: "leadList", label: "Lead", toUrl: Urls.leadList },
      { key: "intermediary", label: "Intermediary", toUrl: Urls.intermediary },
    ],
  },

  // {
  //   key: "statusGraph",
  //   label: "status",
  //   children: [
  //     { key: "statusReport", label: "Report", toUrl: Urls.statusGraph },
  //   ],
  //   requiresAdmin: true,
  // },
  {
    key: "report",
    label: "status",
    children: [{ key: "report", label: "Report", toUrl: Urls.report }],
    requiresAdmin: false,
  },
  {
    key: "sitelink",
    label: "Work Sites",
    children: [
      {
        key: "groupware",
        label: "Groupware",
        toUrl: "https://groupware.weareinbody.com/",
        external: true,
      },
      {
        key: "mail",
        label: "Mail",
        toUrl: "https://outlook.office.com/mail/",
        external: true,
      },
      {
        key: "erp",
        label: "ERP",
        toUrl: "https://inbody.operations.dynamics.com/",
        external: true,
      },
      {
        key: "order",
        label: "Product Orders",
        toUrl: "https://order.weareinbody.com/",
        external: true,
      },
      {
        key: "crm",
        label: "CRM",
        toUrl: "https://crm.weareinbody.com/",
        external: true,
      },
      {
        key: "mar",
        label: "MAR",
        toUrl: "https://mar.weareinbody.com/",
        external: true,
      },
      {
        key: "library",
        label: "Image Pool",
        toUrl: "https://lib.weareinbody.com/",
        external: true,
      },
      {
        key: "certification",
        label: "Certification",
        toUrl: "https://inbodykr.sharepoint.com/sites/certification2",
        external: true,
      },
      {
        key: "homepage",
        label: "InBody Website",
        toUrl: "https://www.inbody.co.kr/",
        external: true,
      },
      {
        key: "store",
        label: "InBody E-commerce",
        toUrl: "https://store.inbody.com/",
        external: true,
      },
      {
        key: "gm",
        label: "InBody GM",
        toUrl: "https://gm.weareinbody.com/",
        external: true,
      },
    ],
  },
  {
    key: "admin",
    label: "Settings",
    children: [
      { key: "myInfo", label: "My Info", toUrl: Urls.myInfo },
      {
        key: "adminUser",
        label: "User",
        toUrl: Urls.adminUser,
        requiresAdmin: true,
      },
      {
        key: "adminGroupPermission",
        label: "Group and Permission",
        toUrl: Urls.adminGroupPermission,
        requiresAdmin: true,
      },
      {
        key: "adminInformation",
        label: "Field Item List",
        toUrl: Urls.adminInformation,
        requiresAdmin: true,
      },
      {
        key: "adminProductRegister",
        label: "Product",
        toUrl: Urls.adminProductRegister,
        requiresAdmin: true,
      },
      {
        key: "adminSalesProtection",
        label: "Sales Protection",
        toUrl: Urls.adminSalesProtection,
        requiresAdmin: true,
      },
    ],
  },
];
