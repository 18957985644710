import { GREENTITLE, ORANGEDISABLED, REDTITLE } from "../../styles/Color";

import CircleIcon from "@mui/icons-material/Circle";

export const ColorStateConversion = (type: string | null) => {
  if (type === "green") {
    return <CircleIcon style={{ color: GREENTITLE }} />;
  } else if (type === "yellow") {
    return <CircleIcon style={{ color: ORANGEDISABLED }} />;
  } else if (type === "red") {
    return <CircleIcon style={{ color: REDTITLE }} />;
  } else {
    return null;
  }
};
