import { Box, Grid, IconButton, InputBase, Modal } from "@mui/material";
import {
  DropDownListComponentBox,
  InputBox,
  InputGridBox,
  ModalStyle,
  PointerItem,
  SearchBoxComponent,
} from "../../../../styles/theme";
import {
  CustomButtonGray,
  CustomButtonGreen,
  CustomButtonRed,
  CustomButtonYellow,
} from "../../../../styles/buttonStyle";
import { CustomText } from "../../../../styles/CustomText";
import SearchIcon from "@mui/icons-material/Search";
import { useCallback, useEffect, useState } from "react";
import { SearchProductDataInfo } from "../../../../system/types/Product";
import { InitSearchProductDataInfo } from "../../../../system/types/initObject";
import Swal from "sweetalert2";
import {
  GRAYPALETITLE,
  GREENCONTENTS,
  GREENTITLE,
  REDTITLE,
} from "../../../../styles/Color";
import { ProductApi } from "../../../../system/api/productApi";
import { ErrorHandler } from "../../../../system/ApiService";
import { AlertModal } from "../../../../components/Common/AlertModal";
import uuid from "react-uuid";
import { AdminGroupListBorder } from "../../../../styles/adminStyle";
import { CheckCircle, X } from "@phosphor-icons/react";
import Radio from "@mui/material/Radio";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { isoDateFormatter } from "../../../../components/Common/isoDateFormatter";
import * as gregorian from "../../../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as numberingSystems from "../../../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import AdminProductSetting from "./AdminProductSetting";

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({
  ko: {
    datepicker: { today: "오늘" },
  },
});

function validation(data: SearchProductDataInfo) {
  if (data.name === "") {
    AlertModal("check", "제품명을 작성해 주세요.");
    return false;
  }
  return true;
}

function AdminProductRegister() {
  const [data, setData] = useState<SearchProductDataInfo[]>([]);
  const [productData, setProductData] = useState<SearchProductDataInfo>(
    InitSearchProductDataInfo
  );
  const [preSearchWord, setPreSearchWord] = useState("");
  const [searchData, setSearchData] = useState({
    familyName: "",
    productName: "",
  });
  var submitcheck = false;

  // 제품군
  const [productFamilyList, setProductFamilyList] = useState<string[]>([]);
  const productFamilyListFields: Object = {
    dataSource: productFamilyList,
    value: "name",
    text: "name",
  };
  useEffect(() => {
    ProductApi.searchProductFamily()
      .then((res) => {
        setProductFamilyList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const refreshJobTitleData = useCallback(() => {
    ProductApi.searchProduct(searchData.familyName, searchData.productName)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchData.familyName, searchData.productName]);

  useEffect(() => {
    refreshJobTitleData();
  }, [refreshJobTitleData]);

  const deleteGroupMember = (id: number | null | undefined) => {
    if (submitcheck === false) {
      submitcheck = true;
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 직책이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed && id) {
          ProductApi.deleteProduct(id)
            .then(() => {
              AlertModal("success", "선택하신 직책이 삭제되었습니다.");
              refreshJobTitleData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  };

  const onChangeSearch = (e: any) => {
    setPreSearchWord(e.target.value);
  };
  const ClickSearch = () => {
    setSearchData({ ...searchData, productName: preSearchWord });
  };
  const handleOnKeyDownSearch = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      ClickSearch();
    }
  };

  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const openModal = (dt: SearchProductDataInfo) => {
    setOpen(true);
    setProductData(dt);
  };
  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };
  const onChange = (args: any) => {
    setProductData({
      ...productData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };
  const onChangeBoolean = (args: any) => {
    setProductData({
      ...productData,
      [(args.target as HTMLInputElement).name]:
        args.target.value === "true" ? true : false,
    });
  };

  const onChangeDate = (args: any) => {
    setProductData({
      ...productData,
      [(args.target as HTMLInputElement).name]: isoDateFormatter(
        new Date(args.target.value)
      ),
    });
  };

  const controlProps = (item: boolean) => ({
    checked: productData.enabled === item,
    onChange: onChangeBoolean,
    value: item,
    name: "enabled",
  });

  const onSave = () => {
    if (validation(productData)) {
      submitcheck = true;
      if (submitcheck === true) {
        if (productData.id) {
          ProductApi.updateProduct(productData)
            .then(() => {
              handleClose();
              refreshJobTitleData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        } else {
          ProductApi.createProduct(productData)
            .then(() => {
              handleClose();
              refreshJobTitleData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
              submitcheck = false;
            });
        }
      }
    }
  };
  // 가격
  interface PriceSettingOpenMap {
    [index: number]: boolean;
  }
  const [priceSettingOpen, setPriceSettingOpen] = useState<PriceSettingOpenMap>(
    {}
  );
  const onChangePriceOpen = (index: number) => {
    setPriceSettingOpen((prev: PriceSettingOpenMap) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const onChangeDropdown = (args: any) => {
    setSearchData({
      ...searchData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };
  return (
    <Grid item xs={12}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        margin="10px 0px"
        alignItems="center"
      >
        <Grid container item xs={12} sm={6}>
          <SearchBoxComponent>
            <DropDownListComponentBox
              locale="ko"
              showClearButton={true}
              dataSource={productFamilyList}
              fields={productFamilyListFields}
              name="familyName"
              value={searchData.familyName}
              onChange={onChangeDropdown}
              placeholder="제품군을 선택해주세요."
              autoComplete="new-password"
            />
          </SearchBoxComponent>

          <SearchBoxComponent>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="제품명을 입력하세요."
              inputProps={{ "aria-label": "검색어를 입력하세요." }}
              onChange={onChangeSearch}
              onKeyDown={handleOnKeyDownSearch}
              autoComplete="new-password"
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={ClickSearch}
            >
              <SearchIcon />
            </IconButton>
          </SearchBoxComponent>
        </Grid>
        <Grid container item xs={12} sm={6} justifyContent="right">
          <CustomButtonGreen
            onClick={() => {
              openModal(InitSearchProductDataInfo);
            }}
          >
            {" "}
            <CustomText type="subtitle" bold600 cursorPointer>
              추가
            </CustomText>
          </CustomButtonGreen>
        </Grid>
      </Grid>
      {data.map((child: SearchProductDataInfo, index: number) => (
        <Grid container item xs={12} key={uuid()}>
          <AdminGroupListBorder container item xs={12} alignItems="center">
            <Grid
              item
              xs={12}
              sm={6}
              style={{ color: GRAYPALETITLE, fontWeight: 500 }}
            >
              [{child.family}] {child.name}
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={6}
              alignItems="center"
              justifyContent="right"
            >
              {priceSettingOpen[index] ? (
                <CustomButtonGray onClick={() => onChangePriceOpen(index)}>
                  설정 닫기
                </CustomButtonGray>
              ) : (
                <CustomButtonYellow onClick={() => onChangePriceOpen(index)}>
                  설정
                </CustomButtonYellow>
              )}

              <CustomButtonRed
                onClick={() => deleteGroupMember(child.id)}
                style={{ marginLeft: "5px" }}
              >
                <CustomText type="subtitle" bold600 cursorPointer>
                  삭제
                </CustomText>
              </CustomButtonRed>
            </Grid>
          </AdminGroupListBorder>
          {priceSettingOpen[index] && (
            <AdminProductSetting data={child} openModal={openModal} />
          )}
        </Grid>
      ))}

      {data.length === 0 && (
        <Grid container item xs={12} key={uuid()}>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            mt="50px"
          >
            조회된 데이터가 없습니다.
          </Grid>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              {productData.id ? (
                <CustomText type="title"> 정보 수정</CustomText>
              ) : (
                <CustomText type="title"> 제품 추가</CustomText>
              )}

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">제품군</Grid>
                {productData.family === "" ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 제품군을 입력해 주세요.
                  </CustomText>
                ) : (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                )}
              </Grid>
              <InputBox
                type="text"
                name="family"
                onChange={(e: any) => {
                  onChange(e);
                }}
                value={productData.family}
                placeholder={productData.family ? productData.family : "제품군"}
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">제품명</Grid>
                {productData.name === "" ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 제품명을 입력해 주세요.
                  </CustomText>
                ) : (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                )}
              </Grid>
              <InputBox
                type="text"
                name="name"
                onChange={(e: any) => {
                  onChange(e);
                }}
                value={productData.name || ""}
                placeholder="제품명"
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">출시일</Grid>
                {productData.launchDate === "" ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 출시일을 선택해주세요.
                  </CustomText>
                ) : (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                )}
              </Grid>
              <InputGridBox>
                <DatePickerComponent
                  name="launchDate"
                  placeholder="출시일"
                  onChange={onChangeDate}
                  format="yyyy-MM-dd"
                  locale="ko"
                  value={new Date(productData.launchDate)}
                />
              </InputGridBox>
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">단종일</Grid>
                {productData.discontinuanceDate === "" ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 단종일을 선택해주세요.
                  </CustomText>
                ) : (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                )}
              </Grid>
              <InputGridBox>
                <DatePickerComponent
                  name="discontinuanceDate"
                  placeholder="단종일"
                  onChange={onChangeDate}
                  format="yyyy-MM-dd"
                  locale="ko"
                  value={
                    productData.discontinuanceDate
                      ? new Date(productData.discontinuanceDate)
                      : undefined
                  }
                />
              </InputGridBox>
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Grid mr="5px">사용 가능 여부</Grid>
              </Grid>
              <Radio {...controlProps(true)} color="default" /> 가능
              <Radio {...controlProps(false)} color="default" /> 불가
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="right" mt="20px">
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default AdminProductRegister;
