import { Box, styled } from "@mui/material";

const MessageWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
});

const Message = ({ text }: { text: string }) => {
  return <MessageWrapper>{text}</MessageWrapper>;
};

export default Message;
