import { useMediaQuery, useTheme } from "@mui/material";
import { DeviceStatusType } from "../types/Common/MediaType";

export const useDeviceType = (): DeviceStatusType => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(min-width: 360px) and (max-width: 599px)");
  const isTablet = useMediaQuery("(min-width: 600px) and (max-width: 1199px)");
  const isPc = useMediaQuery("(min-width: 1200px) and (max-width: 1599px)");

  if (isMobile) return "mobile";
  if (isTablet) return "tablet";
  return "pc";
};
