import client from "../../system/ApiService";
import { _BasicApi } from "../../system/BasicApi";

const baseUri: string = "/group";

class _GroupApi extends _BasicApi {
  // 전체 그룹 조회
  getAllGroupInfo<RP>() {
    return this.client.get<RP>(`group`);
  }
  // 그룹 조회
  getGroupInfo<RP>(groupId?: number) {
    return this.client.get<RP>(`group/${groupId}`);
  }

  // 그룹 정보 수정
  updateGroupInfo<RQ, RP>(data: RQ) {
    return this.client.put<RQ, RP>(`${baseUri}`, data);
  }

  // 그룹 삭제
  deleteGroupInfo<RQ, RP>(params: RQ) {
    return this.client.delete<RP>(`${baseUri}`, { params });
  }

  // 그룹에 속한 Employee 조회
  findGroupEmployee<RQ, RP>(params: RQ) {
    return this.client.get<RP>(`group/employee`, { params });
  }

  // Group에 Employee추가
  createGroupEmployee<RQ, RP>(data: RQ) {
    return this.client.post<RQ, RP>(`${baseUri}/employee`, data);
  }

  // 삭제
  deleteGroupEmployee<RQ, RP>(data: RQ) {
    return this.client.put<RQ, RP>(`${baseUri}/employee`, data);
  }

  // 선택 가능한 그룹 찾기
  getManagedGroupInfo<RP>() {
    return this.client.get<RP>(`${baseUri}/managed`);
  }
}

export const GroupApi = new _GroupApi(client);
