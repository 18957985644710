export const notiTypeFormatter = (type: string) => {
  if (type === "all") {
    return "전체";
  } else if (type === "comment") {
    return "댓글";
  } else if (type === "meetingNote") {
    return "미팅노트";
  } else if (type === "customer") {
    return "Pipeline";
  } else if (type === "qna") {
    return "질문/답변";
  } else {
    return "기타";
  }
};
