import { LeadMyTableInfo } from "../../../../system/types/Customer";
import { CustomerApi } from "../../../../system/api/customerApi";
import { ErrorHandler } from "../../../../system/ApiService";
import { AlertModal } from "../../../../components/Common/AlertModal";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Tooltip,
} from "@mui/material";
import {
  CustomButtonGreen,
  CustomButtonYellow,
} from "../../../../styles/buttonStyle";
import MyTableListPc from "../components/MyTableListPc";
import { Fragment, useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import {
  TabletMobile,
  Pc,
} from "../../../../components/Common/Responseive/ResponsiveScreen";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  BoardPageNumber,
  ListTypeState,
  LoadingAtom,
  ManagedGroupDataState,
  MyInfoState,
  SearchDataAtom,
} from "../../../../system/atoms";
import ListSearchBar from "../../../../components/ListSearchBar";
import { AxiosResponse } from "axios";
import { GroupApi } from "../../../../system/api/groupApi";

import {
  SquareHalfBottom,
  SquareSplitHorizontal,
  X,
} from "@phosphor-icons/react";
import { GRAYPALEDISABLED } from "../../../../styles/Color";
import MySplitterList from "./MySplitterList";
import SecurityIcon from "@mui/icons-material/Security";
import { CustomText } from "../../../../styles/CustomText";
import uuid from "react-uuid";
import { PointerItem } from "../../../../styles/theme";
import {
  GroupInfo,
  SalesProtectionStatisticsInfo,
} from "../../../../types/Group/group";
import { EmployeeRole } from "../../../../types/Employee/Employee";
interface MyTableListProps {
  type: string;
}

function MyTableList({ type }: MyTableListProps) {
  const [data, setData] = useState<LeadMyTableInfo[]>([]);
  const [splitterData, setSplitterData] = useState<LeadMyTableInfo[]>([]);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const { role } = useRecoilValue(MyInfoState);
  const [pageNumber, setPageNumber] = useRecoilState(BoardPageNumber);
  const [searchDt, setSearchDt] = useRecoilState(SearchDataAtom);
  const [groupData, setGroupData] = useState<GroupInfo[]>([]);
  const [listType, setListType] = useRecoilState(ListTypeState);
  const [, setLoadingSpinnerState] = useRecoilState(LoadingAtom);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };
  const managedGroupData = useRecoilValue(ManagedGroupDataState);

  const refreshData = () => {
    CustomerApi.getCustomerMyData(searchDt, type, pageNumber - 1, 10, false)
      .then((res) => {
        setTotalPageNumber(res.data.totalPages);
        setData(res.data.customers);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    if (type) {
      refreshData();
    }
  }, [type, pageNumber, searchDt]);

  useEffect(() => {
    GroupApi.getAllGroupInfo()
      .then((res) => {
        setGroupData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [setGroupData]);

  useEffect(() => {
    if (listType === "splitter") {
      CustomerApi.getCustomerMyData(searchDt, type, 0, 200000000, false)
        .then((res) => {
          setSplitterData(res.data.customers);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [listType, searchDt, type]);

  const downloadExcel = (typeInfo: string) => {
    setLoadingSpinnerState(true);
    CustomerApi.getCustomerMyData(searchDt, typeInfo, 0, 10000, true)
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.style.display = "none";
        const injectFilename = (res: AxiosResponse<any, any>) => {
          var filename = "";
          var disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return decodeURI(filename);
        };
        link.download = injectFilename(res);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        AlertModal("success", "엑셀 다운로드가 완료되었습니다.");
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        setLoadingSpinnerState(false);
      });
  };

  const onChangeListType = () => {
    if (listType === "list") {
      setListType("splitter");
    } else {
      setListType("list");
    }
  };

  const onChangeProtection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDt({
      ...searchDt,
      salesProtection: event.target.checked === true ? true : null,
    });
  };
  const [salesProtectionData, setSalesProtectionData] = useState<
    SalesProtectionStatisticsInfo[]
  >([]);

  const refreshProtection = () => {
    GroupApi.getSalesProtectionStatistics()
      .then((res) => {
        setSalesProtectionData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    refreshProtection();
  }, []);

  const totalProtectionCount = salesProtectionData.reduce(
    (accumulator, current) => {
      return accumulator + current.totalProtectionCount;
    },
    0
  );

  const protectionCount = salesProtectionData.reduce((accumulator, current) => {
    return accumulator + current.protectionCount;
  }, 0);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListSearchBar
        searchType={type}
        setPageNumber={setPageNumber}
        groupData={groupData}
        managedGroupData={managedGroupData}
      />
      {type && (
        <>
          {(type === "my" || type === "myGroup") && (
            <Grid container justifyContent="space-between" item xs={12}>
              <Grid container item xs={12} sm={6} alignItems="center">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={
                          searchDt.salesProtection === true ? true : false
                        }
                        onChange={onChangeProtection}
                      />
                    }
                    label={
                      <CustomText type="subtitle" bold400 marginTop="-5px">
                        영업 보호권 적용만 보기
                      </CustomText>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                alignItems="center"
                justifyContent="right"
              >
                <Tooltip
                  arrow
                  title={
                    <Fragment>
                      <CustomText type="body">
                        [지사명 : 사용 개수 / 지사 내 영업 보호권 개수]
                      </CustomText>
                      {salesProtectionData.map(
                        (dt: SalesProtectionStatisticsInfo) => (
                          <CustomText type="body" key={uuid()}>
                            {dt.groupName +
                              " : " +
                              dt.protectionCount +
                              "/" +
                              dt.totalProtectionCount}
                          </CustomText>
                        )
                      )}
                    </Fragment>
                  }
                >
                  <Chip
                    icon={<SecurityIcon />}
                    label={protectionCount + "/" + totalProtectionCount}
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          )}

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Pc>
                <>
                  {(type === "my" || type === "myGroup") && (
                    <>
                      {listType === "list" && (
                        <>
                          <SquareHalfBottom size={32} />
                          <SquareSplitHorizontal
                            size={32}
                            color={GRAYPALEDISABLED}
                            cursor="pointer"
                            onClick={onChangeListType}
                          />
                        </>
                      )}
                      {listType === "splitter" && (
                        <>
                          <SquareHalfBottom
                            size={32}
                            color={GRAYPALEDISABLED}
                            cursor="pointer"
                            onClick={onChangeListType}
                          />
                          <SquareSplitHorizontal size={32} />
                        </>
                      )}
                    </>
                  )}
                </>
              </Pc>
            </Grid>

            <Grid container justifyContent="right" item xs={6}>
              {role === EmployeeRole.ADMIN && (
                <Grid>
                  <CustomButtonYellow onClick={() => downloadExcel("all")}>
                    전체 다운로드
                  </CustomButtonYellow>
                </Grid>
              )}

              <Grid ml="5px">
                <CustomButtonGreen onClick={() => downloadExcel(type)}>
                  엑셀 다운로드
                </CustomButtonGreen>
              </Grid>
            </Grid>
          </Grid>

          <Pc>
            <>
              {listType === "list" ? (
                <MyTableListPc
                  data={data}
                  refreshData={refreshData}
                  refreshProtection={refreshProtection}
                />
              ) : (
                <MySplitterList data={splitterData} />
              )}
            </>
          </Pc>

          {listType === "list" && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              mt="20px"
            >
              <Pagination
                count={totalPageNumber}
                showFirstButton
                showLastButton
                boundaryCount={2}
                page={pageNumber}
                onChange={handleChange}
                size="large"
              />
            </Grid>
          )}
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title"> 영업 보호권 현황</CustomText>

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid item xs={12}>
              <CustomText type="subtitle">
                [지사명 : 사용 개수 / 지사 내 영업 보호권 개수]
              </CustomText>
              {salesProtectionData.map((dt: SalesProtectionStatisticsInfo) => (
                <CustomText
                  type="subtitle"
                  bold400
                  key={uuid()}
                  marginTop="10px"
                >
                  {dt.groupName +
                    " : " +
                    dt.protectionCount +
                    "/" +
                    dt.totalProtectionCount}
                </CustomText>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default MyTableList;

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "30rem",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  borderRadius: "5px",
};
