import { Grid, IconButton, InputBase, Pagination } from "@mui/material";
import { CustomText } from "../../styles/CustomText";
import { CustomButtonGreen } from "../../styles/buttonStyle";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { BoardPageNumber } from "../../system/atoms";
import { QnaInfo } from "../../system/types/Qna";
import { QnaApi } from "../../system/api/qnaApi";
import { ErrorHandler } from "../../system/ApiService";
import { AlertModal } from "../../components/Common/AlertModal";
import QnaTable from "./components/QnaTable";
import SearchIcon from "@mui/icons-material/Search";
import { SearchBoxComponent } from "../../styles/theme";

function QnaPage() {
  const [data, setData] = useState<QnaInfo[]>([]);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [pageNumber, setPageNumber] = useRecoilState(BoardPageNumber);
  const history = useHistory();
  const [preSearchWord, setPreSearchWord] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const onChangeSearch = (e: any) => {
    setPreSearchWord(e.target.value);
  };
  const ClickSearch = () => {
    setSearchWord(preSearchWord);
  };

  const handleOnKeyDownSearch = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      ClickSearch();
    }
  };
  function onMoveEdit() {
    history.push(`/qna/edit/`);
  }
  useEffect(() => {
    QnaApi.getQna(pageNumber - 1, 10, "createdAt,desc", searchWord)
      .then((res) => {
        setTotalPageNumber(res.data.totalPages);
        setData(res.data.qna);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [pageNumber, searchWord]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  return (
    <>
      <CustomText type="title"> 질의응답</CustomText>
      <Grid container justifyContent="right" mt="10px">
        <Grid item>
          <SearchBoxComponent>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="검색어를 입력하세요."
              inputProps={{ "aria-label": "검색어를 입력하세요." }}
              onChange={onChangeSearch}
              onKeyDown={handleOnKeyDownSearch}
              autoComplete="new-password"
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={ClickSearch}
            >
              <SearchIcon />
            </IconButton>
          </SearchBoxComponent>
        </Grid>
        <Grid item>
          <CustomButtonGreen onClick={onMoveEdit}>질문 등록</CustomButtonGreen>
        </Grid>
      </Grid>
      <QnaTable data={data} />

      <Grid container justifyContent="center" alignItems="center" mt="20px">
        <Pagination
          count={totalPageNumber}
          showFirstButton
          showLastButton
          page={pageNumber}
          boundaryCount={2}
          onChange={handleChange}
          size="large"
        />
      </Grid>
    </>
  );
}

export default QnaPage;
