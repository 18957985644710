import client from "../ApiService";
import { _BasicApi } from "../BasicApi";
import { SettingInfo } from "../types/Setting";

const listUri: string = "/setting/list/priority";

class _SettingApi extends _BasicApi {
  changePriority(data: SettingInfo) {
    return this.client.post(`${listUri}`, data);
  }
}

export const SettingApi = new _SettingApi(client);
