import { Grid } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import { LoginBoxMainBox } from "../../../styles/mainStyle";
import { GRAYPALETITLE, GREENTITLE, REDTITLE } from "../../../styles/Color";
import { InputBox } from "../../../styles/theme";
import {
  CustomButtonDisabledFullWidth,
  CustomButtonPrimary,
} from "../../../styles/buttonStyle";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { loginAccountState, passwordState } from "../../../system/atoms";
import { useHistory } from "react-router-dom";
import { Urls } from "../../../route/Urls";
import { EmployeeApi } from "../../../system/api/employeeApi";
import { InitPasswordInfo } from "../../../system/types/initObject";
import { ErrorHandler } from "../../../system/ApiService";
import { AlertModal } from "../../../components/Common/AlertModal";
import { CheckCircle } from "@phosphor-icons/react";
import { PasswordInfo } from "../../../types/Employee/Employee";

function validation(data: PasswordInfo) {
  if (
    data.oldPassword.length < 7 ||
    data.newPassword.length < 7 ||
    data.oldPassword.length > 30 ||
    data.newPassword.length > 30
  ) {
    return false;
  } else if (data.oldPassword !== data.newPassword) {
    return false;
  }
  return true;
}

function PasswordBox() {
  const history = useHistory();
  const [passwordCheck, setPasswordCheck] =
    useState<PasswordInfo>(InitPasswordInfo);
  const [saveButtonActive, setSaveButtonActive] = useState<boolean>(false);
  const [, setPasswordStatus] = useRecoilState(passwordState);

  const [email, setEmail] = useRecoilState(loginAccountState);
  var submitcheck = false;

  const onChange = (args: any) => {
    setPasswordCheck({
      ...passwordCheck,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeLoginState = () => {
    submitcheck = true;
    if (submitcheck) {
      EmployeeApi.updatePassword({ ...passwordCheck, oldPassword: "0000" })
        .then(() => {
          localStorage.clear();
          setPasswordStatus(false);
          history.push({
            pathname: `${Urls.login}`,
          });
          AlertModal(
            "successMessage",
            "비밀번호 변경 완료.",
            "다시 로그인해주세요."
          );
          setEmail("");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    }
  };
  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onChangeLoginState();
    }
  };

  useEffect(() => {
    if (validation(passwordCheck)) {
      setSaveButtonActive(true);
    } else {
      setSaveButtonActive(false);
    }
  }, [passwordCheck]);

  return (
    <Grid container justifyContent="center">
      <LoginBoxMainBox item>
        <Grid container mt="20" mb="20">
          <Grid container>
            {" "}
            <img src="/images/inbody_logo.png" width="100px" alt="title"></img>
          </Grid>
          <Grid container>
            <CustomText type="superTitle" bold500>
              비밀번호 변경
            </CustomText>
          </Grid>
        </Grid>
        <Grid container mt="20" mb="20">
          <Grid container>
            <Grid item xs={12}>
              <CustomText type="subtitle2" bold500 color={GRAYPALETITLE}>
                계정
              </CustomText>

              <CustomText
                type="subtitle2"
                mb="10px"
                bold500
                color={GRAYPALETITLE}
              >
                {email}
              </CustomText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <CustomText type="subtitle2" bold500 color={GRAYPALETITLE}>
                  새 비밀번호
                </CustomText>
                {passwordCheck.newPassword === "" ? (
                  <CustomText type="small" color={REDTITLE} ml="5px">
                    * 비밀번호를 입력해 주세요.
                  </CustomText>
                ) : (
                  <>
                    {passwordCheck.newPassword.length > 30 ? (
                      <CustomText type="small" color={REDTITLE} ml="5px">
                        * 비밀번호는 30자 이내로 입력해 주세요.
                      </CustomText>
                    ) : (
                      <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                    )}
                  </>
                )}
              </Grid>

              <InputBox
                type="password"
                onChange={onChange}
                name="newPassword"
                placeholder="새 비밀번호를 입력하세요. (8자 이상 30자 이하)"
                onKeyDown={handleOnKeyDown}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <CustomText type="subtitle2" bold500 color={GRAYPALETITLE}>
                  새 비밀번호 확인
                </CustomText>
                {passwordCheck.oldPassword === "" ? (
                  <CustomText type="small" color={REDTITLE} ml="5px">
                    * 비밀번호를 입력해 주세요.
                  </CustomText>
                ) : (
                  <>
                    {passwordCheck.oldPassword.length > 30 ? (
                      <CustomText type="small" color={REDTITLE} ml="5px">
                        * 비밀번호는 30자 이내로 입력해 주세요.
                      </CustomText>
                    ) : (
                      <>
                        {validation(passwordCheck) ? (
                          <CheckCircle
                            size={20}
                            color={GREENTITLE}
                            weight="bold"
                          />
                        ) : (
                          <CustomText type="small" color={REDTITLE} ml="5px">
                            * 입력해주신 비밀번호가 동일하지 않습니다.
                          </CustomText>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
              <InputBox
                type="password"
                onChange={onChange}
                name="oldPassword"
                placeholder="새 비밀번호를 한번 더 입력하세요."
                onKeyDown={handleOnKeyDown}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container mt="20" mb="20">
          <Grid item xs={12}>
            {saveButtonActive ? (
              <CustomButtonPrimary
                container
                alignItems="center"
                justifyContent="center"
                onClick={onChangeLoginState}
              >
                <CustomText type="subtitle2" bold400>
                  저장
                </CustomText>
              </CustomButtonPrimary>
            ) : (
              <CustomButtonDisabledFullWidth
                container
                alignItems="center"
                justifyContent="center"
              >
                {" "}
                <CustomText type="subtitle2" bold400>
                  저장
                </CustomText>
              </CustomButtonDisabledFullWidth>
            )}
          </Grid>
        </Grid>
      </LoginBoxMainBox>
    </Grid>
  );
}
export default PasswordBox;
