import {
  SortStatus,
  TableHeadCellData,
} from "../../types/Common/CommonMuiTable";

// sort의 상태를 변경 asc -> desc -> none -> asc -> ...
export const changeSortStatus = (status?: SortStatus) => {
  switch (status) {
    case "asc":
      return "desc";
    case "desc":
      return "none";
    case "none":
      return "asc";
    default:
      return "disabled";
  }
};

// columnList에서 정렬상태가 있는 것만 파라미터에 들어가는 형태로 리턴
export const getSortColumnList = (columnList: TableHeadCellData[]) => {
  const filteredColumnList = columnList.filter(
    ({ sortStatus }) =>
      (sortStatus === "asc" || sortStatus === "desc") &&
      sortStatus !== undefined
  );
  const result = filteredColumnList
    .map(
      ({ key, sortKey, sortStatus }) =>
        `${sortKey !== undefined ? sortKey : key},${sortStatus}`
    )
    .join("");
  return result;
};

// 다른 status변경 시 초기 status로 초기화
const changeInitColumnStatus = (status?: SortStatus) => {
  switch (status) {
    case "asc":
    case "desc":
    case "none":
      return "none";
    default:
      return "disabled";
  }
};

// 선택한 HeadCell만 sortStatus를 변환
export const getChangedColumnSortStatus = (
  headCell: TableHeadCellData,
  columnList: TableHeadCellData[]
) => {
  const target: TableHeadCellData = {
    ...headCell,
    sortStatus: changeSortStatus(headCell.sortStatus),
  };

  const result = columnList.map((column) => ({
    ...column,
    sortStatus:
      column.key === target.key
        ? target.sortStatus
        : changeInitColumnStatus(column.sortStatus),
  }));
  return result;
};

// 비어있는지 검사
export const checkEmptyRowList = (rowList: unknown) => {
  return (
    rowList === null ||
    rowList === undefined ||
    (Array.isArray(rowList) && rowList.length === 0)
  );
};
