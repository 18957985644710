import { Box, Grid, styled } from "@mui/material";
import { Gray200 } from "../../../../styles/Color";
import {
  MediaStyleProperties,
  StyledDeviceTypeProps,
} from "../../../../types/Common/MediaType";
import { styledWithoutProps } from "../../../../styles/deviceTypeStyle";

// PortletList
export const PortletListWrapper = styled(Grid)({
  flexWrap: "wrap",
  marginTop: "20px",
  justifyContent: "space-between",
});

// PortletListItem
const PortletItemWrapperPadding: MediaStyleProperties = {
  pc: "8px",
  tablet: "8px",
  mobile: "4px",
};
export const PortletItemWrapper = styledWithoutProps(Grid)(
  ({ deviceType }: StyledDeviceTypeProps) => ({
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    border: `1px solid ${Gray200}`,
    borderRadius: "4px",
    margin: "4px 0",
    padding: PortletItemWrapperPadding[deviceType],
  })
);

export const PortletItemTitle = styled(Box)({
  fontSize: "1.125rem",
  fontWeight: 600,
});

const PortletItemHeaderWrapperMarginBottom: MediaStyleProperties = {
  pc: "12px",
  tablet: "12px",
  mobile: "8px",
};
export const PortletItemHeaderWrapper = styledWithoutProps(Box)(
  ({ deviceType }: StyledDeviceTypeProps) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: PortletItemHeaderWrapperMarginBottom[deviceType],
  })
);

export const PortletItemContentWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  width: "100%",
  overflowX: "auto",
});
