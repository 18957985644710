import { ErrorHandler } from "../../../../system/ApiService";
import { AxiosError } from "axios";
import { ListApi } from "../../../../api/List/listApi";
import {
  ContactList,
  DropdownList,
  StatusType,
} from "../../../../types/Lead/List";
import { AlertModal } from "../../../../components/Common/AlertModal";
import {
  FlatGroupInfo,
  IntermediaryList,
  TagsList,
} from "../../../../types/Customers/Customers";
import { CustomersApi } from "../../../../api/Customers/customersApi";
import { TagApi } from "../../../../api/Tag/TagApi";
import { GroupApi } from "../../../../api/Group/groupApi";
import { GroupInfo } from "../../../../types/Group/group";
import { EmployeeApi } from "../../../../api/Employee/employeeApi";
import { EmployeeInfo } from "../../../../types/Employee/Employee";
import { ProductApi } from "../../../../api/Product/productApi";
import { GetProductResponse } from "../../../../types/Product/Product";

function flattenGroupInfoList(
  data: GroupInfo[],
  parentId: number | null = null
): FlatGroupInfo[] {
  return data.flatMap((node) => {
    const hasChild = node.children && node.children.length > 0;

    const currentNode: FlatGroupInfo = {
      id: node.id,
      name: node.name,
      expanded: node.level === 0 ? true : false,
      hasChild,
      parentId,
    };

    const childNodes = hasChild
      ? flattenGroupInfoList(node.children, node.id)
      : [];

    return [currentNode, ...childNodes];
  });
}

const GetFilterData = async (type: string) => {
  try {
    switch (type) {
      case "contractType":
      case "salesType":
      case "supplyType":
      case "department":
      case "step":
      case "businessType":
        const { data } = await ListApi.getDropdownInfo<void, DropdownList>(
          type
        );
        return data;
      case "contactType":
        const contactData = await ListApi.getContactInfo<void, ContactList>();
        const sortedContactData = contactData.data
          .map((item) => ({
            id: item.id,
            groupName: item.major,
            name: item.sub,
          }))
          .sort((a, b) => {
            const groupA = a.groupName || "";
            const groupB = b.groupName || "";
            return groupA.localeCompare(groupB);
          });

        // 이제 sortedContactData를 사용할 수 있습니다.
        return sortedContactData;
      case "Intermediary":
        const intermediaryData = await CustomersApi.getIntermediaryInfo<
          void,
          IntermediaryList
        >();
        return intermediaryData.data.map((item) => ({
          id: item.id,
          name: item.address?.placeName,
        }));
      case "tags":
        const tagsData = await TagApi.getTagInfo<void, TagsList>();
        return tagsData.data.map((item) => ({
          id: item.id,
          name: item.name,
        }));
      case "Group":
        const groupData = await GroupApi.getAllGroupInfo<GroupInfo[]>();
        const transformedTree = flattenGroupInfoList(groupData.data);
        return transformedTree;
      case "Seller":
        const sellerData = await EmployeeApi.getAllEmployeeList<
          EmployeeInfo[]
        >();
        const sortedSellerData = sellerData.data
          .map((item) => ({
            id: item.id,
            groupName: item.group,
            name: item.name,
          }))
          .sort((a, b) => {
            const groupA = a.groupName || "";
            const groupB = b.groupName || "";
            return groupA.localeCompare(groupB);
          });
        return sortedSellerData;
      case "Product Family":
        const productFamilyData = await ProductApi.getProductFamilytInfo<
          void,
          string[]
        >();
        const productFamilyObjectList = productFamilyData.data.map((item) => ({
          id: item,
          name: item,
        }));
        return productFamilyObjectList;
      case "Product Name":
        const productData = await ProductApi.getProductInfo<
          void,
          GetProductResponse[]
        >();
        const sortedProductData = productData.data
          .map((item) => ({
            id: item.id,
            groupName: item.family,
            name: item.name,
          }))
          .sort((a, b) => {
            const groupA = a.groupName || "";
            const groupB = b.groupName || "";
            return groupA.localeCompare(groupB);
          });
        return sortedProductData;
      case "PurchaseProbability":
        const percentages = [25, 50, 75, 90];

        const formattedList = percentages.map((value) => ({
          id: value,
          name: `${value}%`,
        }));
        return formattedList;
      case "status":
        const statusValues: string[] = Object.values(StatusType);
        return statusValues;

      default:
        return [];
    }
  } catch (err) {
    const message = ErrorHandler(err as AxiosError);
    AlertModal("msg", message);
    return [];
  }
};

export default GetFilterData;
