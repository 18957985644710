import { CustomText } from "../../../styles/CustomText";
import AdminProductRegister from "./components/AdminProductRegister";

function ProductRegisterPage() {
  return (
    <>
      <CustomText type="title">제품 관리</CustomText>
      <AdminProductRegister />
    </>
  );
}

export default ProductRegisterPage;
