import { Box, Grid, Modal } from "@mui/material";
import {
  DropDownListComponentBox,
  DropDownListComponentGridBox,
  InputBox,
  ModalStyle,
  PointerItem,
} from "../../../../../styles/theme";
import { CustomText } from "../../../../../styles/CustomText";
import { CheckCircle, X } from "@phosphor-icons/react";
import { GREENTITLE, REDTITLE } from "../../../../../styles/Color";
import { ENABLED_TYPE } from "../../../../../system/constants";
import { CustomButtonGreen } from "../../../../../styles/buttonStyle";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { GroupInfo } from "../../../../../types/Group/group";
import { AlertModal } from "../../../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../../../system/ApiService";

import { EmployeeInfo } from "../../../../../types/Employee/Employee";
import { GroupApi } from "../../../../../api/Group/groupApi";
import { AxiosError } from "axios";

// DropDown Item Template
const itemTemplate = (data: EmployeeInfo) => {
  return (
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={4}>
        {data.name}
      </Grid>
      <Grid item container xs={8} justifyContent="right">
        {data.jobTitle?.name}{" "}
        {data.jobTitle?.name && data.jobPosition?.name && "/"}{" "}
        {data.jobPosition?.name}
      </Grid>
    </Grid>
  );
};

// DropDown Value Template
const valueTemplate = (data: EmployeeInfo) => {
  return (
    <CustomText type="subtitle" bold400 color="#000000">
      {data.name}
    </CustomText>
  );
};

type AdminGroupInfoUpdateModalProps = {
  isOpen: boolean;
  refreshData: () => void;
  handleUpdateModal: (status: boolean) => void;
  groupInfo: GroupInfo;
  employeeList: EmployeeInfo[];
};

const AdminGroupInfoUpdateModal = ({
  isOpen,
  handleUpdateModal,
  groupInfo,
  refreshData,
  employeeList,
}: AdminGroupInfoUpdateModalProps) => {
  const [editGroupInfo, setEditGroupInfo] = useState<GroupInfo>({
    ...groupInfo,
  });

  // 이름값이 비어있는지 체크.
  const checkNameValidate = useCallback((data: GroupInfo) => {
    if (data.name === "") {
      AlertModal("check", "이름을 작성해 주세요.");
      return false;
    }
    return true;
  }, []);

  const enabledFields = { text: "name", value: "value" };
  const positionFields = { text: "name", value: "id" };

  // input change
  const handleInputChange = useCallback(
    (event) => {
      setEditGroupInfo({
        ...editGroupInfo,
        [(event.target as HTMLInputElement).name]: event.target.value,
      });
    },
    [editGroupInfo]
  );
  // dropdown change
  const handleDropdownChange = useCallback(
    (event) => {
      setEditGroupInfo({
        ...editGroupInfo,
        [event.target.name]: event.target.itemData,
      });
    },
    [editGroupInfo]
  );

  // GroupInfo Update
  const handleUpdateGroupInfo = useCallback(async () => {
    if (checkNameValidate(editGroupInfo)) {
      try {
        await GroupApi.updateGroupInfo<GroupInfo, unknown>(editGroupInfo);
        refreshData();
        handleUpdateModal(false);
      } catch (err) {
        const message = ErrorHandler(err as AxiosError);
        AlertModal("msg", message);
      }
    }
  }, [editGroupInfo, checkNameValidate, handleUpdateModal, refreshData]);

  // Enter시 저장 수행
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        handleUpdateGroupInfo();
      }
    },
    [handleUpdateGroupInfo]
  );

  // changeEnabled
  const onChangeEnabled = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      if (event.target.value === true) {
        setEditGroupInfo((prev) => ({ ...prev, isSaleGroup: true }));
      } else {
        setEditGroupInfo((prev) => ({ ...prev, isSaleGroup: false }));
      }
    },
    []
  );

  useEffect(() => {
    setEditGroupInfo(groupInfo);
  }, [groupInfo]);

  return (
    <Modal open={isOpen} onClose={() => handleUpdateModal(false)}>
      <Box sx={ModalStyle}>
        <Grid container spacing={2}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            item
            xs={12}
          >
            <CustomText type="title"> 정보 수정</CustomText>

            <PointerItem onClick={() => handleUpdateModal(false)}>
              <X size={32} />
            </PointerItem>
          </Grid>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center">
              <Grid mr="5px">이름</Grid>
              {editGroupInfo.name === "" ? (
                <CustomText type="small" color={REDTITLE}>
                  * 이름을 입력해 주세요.
                </CustomText>
              ) : (
                <CheckCircle size={20} color={GREENTITLE} weight="bold" />
              )}
            </Grid>
            <InputBox
              type="text"
              name="name"
              onChange={(e) => {
                handleInputChange(e);
              }}
              value={editGroupInfo.name || ""}
              placeholder="이름"
              onKeyDown={handleOnKeyDown}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid>그룹 관리자</Grid>
            <DropDownListComponentGridBox>
              <DropDownListComponentBox
                fields={positionFields}
                dataSource={employeeList}
                name="manager"
                value={editGroupInfo.manager?.id}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  handleDropdownChange(e);
                }}
              />
            </DropDownListComponentGridBox>
          </Grid>
          <Grid item xs={12}>
            <Grid>영업 그룹 여부</Grid>
            <DropDownListComponentGridBox>
              <DropDownListComponentBox
                fields={enabledFields}
                dataSource={ENABLED_TYPE}
                value={editGroupInfo.isSaleGroup}
                onChange={onChangeEnabled}
                placeholder="그룹 관리자를 선택해주세요."
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                autoComplete="new-password"
              />
            </DropDownListComponentGridBox>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="right" mt="20px">
          <CustomButtonGreen onClick={handleUpdateGroupInfo}>
            저장
          </CustomButtonGreen>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AdminGroupInfoUpdateModal;
