import { Grid } from "@mui/material";
import { CustomText } from "../../styles/CustomText";
import QAQCTable from "./components/QAQCTable";

function QAQCMain() {
  return (
    <>
      <CustomText type="title">조달검사검수 게시판</CustomText>
      <Grid item xs={12}></Grid>
      <Grid container item xs={12} justifyContent="center" mt="30px">
        <QAQCTable />
      </Grid>
    </>
  );
}

export default QAQCMain;
