import Swal from "sweetalert2";

export const checkModal = (message?: string) => {
  return Swal.fire({
    title:
      "<div style='font-family:Noto Sans KR;font-size:20px;margin-bottom:10px'>" +
      message +
      "</div>",
    icon: "warning",
    showConfirmButton: false,
    timer: 1500,
  });
};
