import { useRecoilState, useRecoilValue } from "recoil";
import {
  AdminDisplayIcon,
  DahsboardHeaderWrapper,
  DashboardHeaderRole,
  DashboardHeaderRoleAdminText,
  DashboardHeaderRoleUserText,
  DashboardHeaderRoleWrapper,
  PersonDisplayIcon,
} from "../styles/DashboardHeaderStyle";
import { MyInfoState } from "../../../system/atoms";
import { portletModeStore } from "../../../stores/DashBoard/portletStore";

import { DisplayMode } from "../../../types/DashBoard/Portlet";
import { EmployeeRole } from "../../../types/Employee/Employee";

const DashboardHeader = () => {
  const { role } = useRecoilValue(MyInfoState);
  const [portletDisplayMode, setPortletDisplayMode] =
    useRecoilState(portletModeStore);
  return (
    <DahsboardHeaderWrapper>
      {role === EmployeeRole.MANAGER && (
        <DashboardHeaderRoleWrapper>
          <DashboardHeaderRole
            onClick={() => {
              setPortletDisplayMode(DisplayMode.FIRST);
            }}
          >
            <AdminDisplayIcon displaymode={portletDisplayMode} />
            <DashboardHeaderRoleAdminText displaymode={portletDisplayMode}>
              관리자
            </DashboardHeaderRoleAdminText>
          </DashboardHeaderRole>
          <DashboardHeaderRole
            onClick={() => {
              setPortletDisplayMode(DisplayMode.SECOND);
            }}
          >
            <PersonDisplayIcon displaymode={portletDisplayMode} />
            <DashboardHeaderRoleUserText displaymode={portletDisplayMode}>
              사용자
            </DashboardHeaderRoleUserText>
          </DashboardHeaderRole>
        </DashboardHeaderRoleWrapper>
      )}
    </DahsboardHeaderWrapper>
  );
};

export default DashboardHeader;
