import { AxiosRequestConfig } from "axios";
import client from "../ApiService";
import {
  FileValidInfo,
  LeadCustomerCreateInfo,
  LeadCustomerInfo,
  ListSearchInfo,
} from "../types/Customer";
import { _BasicApi } from "../BasicApi";

const baseUri: string = "/customers";

class _CustomerApi extends _BasicApi {
  getCustomerInfo(
    searchDt: ListSearchInfo,
    page: number,
    size: number,
    isFile?: boolean
  ) {
    return this.client.post(
      `${baseUri}/search?isFile=${isFile}&page=${page}&size=${size}&customers.createdAt, desc`,
      searchDt,
      isFile ? { responseType: "blob" } : {}
    );
  }
  createCustoemrInfo(customerDt: LeadCustomerCreateInfo) {
    return this.client.post(`${baseUri}`, customerDt);
  }
  updateCustomerInfo(customerDt: LeadCustomerInfo) {
    return this.client.put(`${baseUri}`, customerDt);
  }
  updateCustomerSingleFieldInfo(
    customerId: number,
    fieldName: string,
    changeDt: any
  ) {
    return this.client.put(
      `${baseUri}/${customerId}/field?fieldName=${fieldName}`,
      JSON.stringify(changeDt),
      {
        headers: {
          "Content-Type": "application/json", // 헤더에 Content-Type 설정
        },
      }
    );
  }
  deleteCustomerInfo(id: number) {
    return this.client.delete(`${baseUri}/${id}`);
  }
  getCustomerDetailInfo(id: number) {
    return this.client.get(`${baseUri}/detail/${id}`);
  }
  getIntermediaryInfo(searchText?: string) {
    return this.client.get(`${baseUri}/intermediary`, {
      params: { searchText: searchText },
    });
  }
  getCustomerMyData(
    searchDt: ListSearchInfo,
    type: string,
    page: number,
    size: number,
    isFile: boolean
  ) {
    return this.client.post(
      `${baseUri}/my/${type}?isFile=${isFile}&page=${page}&size=${size}&sort=createdAt,desc`,
      searchDt,
      isFile ? { responseType: "blob" } : {}
    );
  }
  getAdminCustomerFile() {
    return this.client.get(`${baseUri}/file/admin`, {
      responseType: "arraybuffer",
    });
  }

  getFileTemplate(type: string) {
    return this.client.get(`${baseUri}/file/template`, {
      params: { customerType: type },
      responseType: "arraybuffer",
    });
  }

  getFileValidation(formData: FormData, responseType: string) {
    const config: AxiosRequestConfig = {
      params: { responseType: responseType },
      responseType: responseType === "file" ? "arraybuffer" : undefined,
    };
    return this.client.post(`${baseUri}/file/valid`, formData, config);
  }
  createMultipleCustomer(data: FileValidInfo[]) {
    return this.client.post(`${baseUri}/list`, data);
  }
  updateSalesProtection(customerId: number, isProtection: boolean) {
    return this.client.put(
      `${baseUri}/${customerId}/sales/protection?isProtection=${isProtection}`
    );
  }
}

export const CustomerApi = new _CustomerApi(client);
