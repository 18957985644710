import { Box, Grid, Modal } from "@mui/material";
import uuid from "react-uuid";
import { CustomText } from "../../../../styles/CustomText";
import {
  AdminGroupListBorder,
  AdminGroupListHoverText,
} from "../../../../styles/adminStyle";
import { GRAYPALETITLE, GREENTITLE, REDTITLE } from "../../../../styles/Color";
import {
  CustomButtonGreen,
  CustomButtonYellow,
} from "../../../../styles/buttonStyle";
import { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import { useHistory } from "react-router-dom";
import { GroupApi } from "../../../../system/api/groupApi";
import { ErrorHandler } from "../../../../system/ApiService";
import { AlertModal } from "../../../../components/Common/AlertModal";
import { InitGroupInfo } from "../../../../system/types/initObject";
import { CheckCircle, X } from "@phosphor-icons/react";
import {
  DropDownListComponentBox,
  DropDownListComponentGridBox,
  InputBox,
  ModalStyle,
  PointerItem,
} from "../../../../styles/theme";
import { EmployeeApi } from "../../../../system/api/employeeApi";
import { EmployeeInfo } from "../../../../types/Employee/Employee";
import { GroupInfo } from "../../../../types/Group/group";

function validation(data: GroupInfo) {
  if (data.name === "") {
    AlertModal("check", "이름을 작성해 주세요.");
    return false;
  }
  return true;
}

interface GroupDataListProps {
  group: GroupInfo;
  refreshData: () => void;
}

const GroupDataList = ({ group, refreshData }: GroupDataListProps) => {
  const [isExpanded, setExpanded] = useState(true);
  const history = useHistory();
  const handleToggle = () => {
    setExpanded(!isExpanded);
  };

  const moveToDetailPage = (data: GroupInfo) => {
    history.push(`/admin/grouppermission/detail/${data.id}`);
  };

  return (
    <Grid item xs={12}>
      <AdminGroupListBorder
        container
        justifyContent="space-between"
        alignItems="center"
      >
        {group.children.length > 0 ? (
          <>
            <AdminGroupListHoverText
              item
              onClick={handleToggle}
              style={{ paddingLeft: `${group.level * 20}px` }}
              xs={12}
              sm={6}
            >
              <CustomText type="subtitle" color={GRAYPALETITLE} cursorPointer>
                {isExpanded ? <RemoveIcon /> : <KeyboardArrowRightIcon />}

                {group.name}
              </CustomText>
            </AdminGroupListHoverText>
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="center" justifyContent="right">
                <CustomButtonYellow
                  onClick={() => {
                    moveToDetailPage(group);
                  }}
                >
                  그룹 관리
                </CustomButtonYellow>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              onClick={handleToggle}
              style={{ paddingLeft: `${group.level * 20}px` }}
              xs={12}
              sm={6}
            >
              <CustomText type="subtitle" color={GRAYPALETITLE}>
                {group.name}
              </CustomText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="center" justifyContent="right">
                <CustomButtonYellow
                  onClick={() => {
                    moveToDetailPage(group);
                  }}
                >
                  그룹 관리
                </CustomButtonYellow>
              </Grid>
            </Grid>
          </>
        )}
      </AdminGroupListBorder>
      {isExpanded && group.children && (
        <Grid>
          {group.children.map((childGroup: any, index: number) => (
            <GroupDataList
              key={index}
              group={childGroup}
              refreshData={refreshData}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

function AdminGroupList() {
  const [data, setData] = useState<GroupInfo[]>([]);
  useEffect(() => {
    refreshData();
    EmployeeApi.GetEmployee()
      .then((res) => {
        setEmployeeList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  function refreshData() {
    GroupApi.getAllGroupInfo()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }
  // 모달
  const [open, setOpen] = useState(false);
  const [groupData, setGroupData] = useState(InitGroupInfo);
  const [employeeList, setEmployeeList] = useState<EmployeeInfo[]>([]);
  const handleClose = () => {
    setOpen(false);
    setGroupData(InitGroupInfo);
  };
  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setGroupData({
        ...groupData,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else {
      setGroupData({
        ...groupData,
        [(args.target as HTMLInputElement).name]: args.target.itemData,
      });
    }
  };
  const positionFields = { text: "name", value: "id" };
  var submitcheck = false;
  const onSave = () => {
    if (validation(groupData)) {
      if (submitcheck === false) {
        submitcheck = true;
        GroupApi.createGroupInfo(groupData)
          .then(() => {
            refreshData();
            handleClose();
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  };
  const itemTemplate = (data: EmployeeInfo) => {
    return (
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={4}>
          {data.name}
        </Grid>
        <Grid item container xs={8} justifyContent="right">
          {data.jobTitle?.name}{" "}
          {data.jobTitle?.name && data.jobPosition?.name && "/"}{" "}
          {data.jobPosition?.name}
        </Grid>
      </Grid>
    );
  };

  const valueTemplate = (data: EmployeeInfo) => {
    return (
      <CustomText type="subtitle" bold400 color="#000000">
        {data.name}
      </CustomText>
    );
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
  };
  return (
    <Grid container mt="20px">
      {/* <Grid container item justifyContent="right" xs={12}>
        <CustomButtonGreen onClick={openCreateModal}>
          그룹 추가
        </CustomButtonGreen>
      </Grid> */}
      <Grid item xs={12}>
        {data.map((item) => {
          return (
            <GroupDataList
              key={uuid()}
              group={item}
              refreshData={refreshData}
            />
          );
        })}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title">최상위 그룹 생성</CustomText>

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">이름</Grid>
                {groupData.name === "" ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 이름을 입력해 주세요.
                  </CustomText>
                ) : (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                )}
              </Grid>
              <InputBox
                type="text"
                name="name"
                onChange={(e: any) => {
                  onChange(e, "input");
                }}
                value={groupData.name || ""}
                placeholder="이름"
                onKeyDown={handleOnKeyDown}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12} alignItems="center">
                <Grid mr="5px">그룹 관리자</Grid>
                {groupData.manager?.name === "" ? (
                  <CustomText type="small" color={REDTITLE}>
                    * 그룹 관리자를 선택해주세요.
                  </CustomText>
                ) : (
                  <CheckCircle size={20} color={GREENTITLE} weight="bold" />
                )}
              </Grid>
              <DropDownListComponentGridBox>
                <DropDownListComponentBox
                  dataSource={employeeList}
                  fields={positionFields}
                  name="manager"
                  onChange={(e: any) => {
                    onChange(e, "dropdown");
                  }}
                  value={groupData.manager?.id}
                  placeholder="그룹 관리자를 선택해주세요."
                  itemTemplate={itemTemplate}
                  valueTemplate={valueTemplate}
                />
              </DropDownListComponentGridBox>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="right" mt="20px">
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default AdminGroupList;
