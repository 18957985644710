import { lazy, Suspense, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import PortletItem from "./PortletItem";
import { PortletListWrapper } from "../../styles/Portlet/portletStyle";
import {
  initialAdminPortletStatus,
  initialManagerPortletStatus,
  initialUserPortletStatus,
  portletModeStore,
  portletStatusStore,
} from "../../../../stores/DashBoard/portletStore";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { MyInfoState } from "../../../../system/atoms";
import { EmployeeRole } from "../../../../types/Employee/Employee";
import {
  DisplayMode,
  PortletStatusList,
  SwitchPortletContentMapProps,
} from "../../../../types/DashBoard/Portlet";
import SwitchRoleDisplayModeComponent from "../../../../components/Common/Switch/SwitchRoleDisplayModeComponent";
import { SwitchRoleDisplayModeContentMap } from "../../../../types/Common/SwitchComponent";

const TodoColumnChart = lazy(() => import("../Chart/TodoColumnChart"));
const InOutboundComboChart = lazy(
  () => import("../Chart/InOutboundComoboChart")
);
const SwitchAchievementRateList = lazy(
  () => import("./AchievementRate/SwitchAchievementRateList")
);
const SwitchForecastList = lazy(() => import("./Forecast/SwitchForecastList"));
const SwitchTodoStatusList = lazy(() => import("./Todo/SwitchTodoStatusList"));
const TodoTable = lazy(() => import("./Todo/TodoTable"));

const SwitchPortletContentMap: SwitchRoleDisplayModeContentMap<SwitchPortletContentMapProps> =
  {
    [EmployeeRole.ADMIN]: {
      [DisplayMode.FIRST]: {
        1: SwitchAchievementRateList,
        2: SwitchForecastList,
        3: SwitchTodoStatusList,
        4: TodoTable,
        5: TodoColumnChart,
        6: InOutboundComboChart,
      },
      [DisplayMode.SECOND]: {},
    },
    [EmployeeRole.MANAGER]: {
      [DisplayMode.FIRST]: {
        1: SwitchAchievementRateList,
        2: SwitchForecastList,
        3: SwitchTodoStatusList,
        4: TodoTable,
        5: TodoColumnChart,
        6: InOutboundComboChart,
      },
      [DisplayMode.SECOND]: {
        1: SwitchAchievementRateList,
        2: SwitchForecastList,
        3: SwitchTodoStatusList,
        4: TodoTable,
      },
    },
    [EmployeeRole.USER]: {
      [DisplayMode.FIRST]: {
        1: SwitchAchievementRateList,
        2: SwitchForecastList,
        3: SwitchTodoStatusList,
        4: TodoTable,
      },
      [DisplayMode.SECOND]: {},
    },
    [EmployeeRole.QAQC]: { [DisplayMode.FIRST]: {}, [DisplayMode.SECOND]: {} },
    [EmployeeRole.CS]: { [DisplayMode.FIRST]: {}, [DisplayMode.SECOND]: {} },
  };

const PortletList = () => {
  const [portletList, setPortletList] = useRecoilState(portletStatusStore);
  const deviceType = useDeviceType();
  const { role } = useRecoilValue(MyInfoState);
  const portletMode = useRecoilValue(portletModeStore);

  const switchInitialStatus = useMemo(() => {
    switch (role) {
      case EmployeeRole.ADMIN:
        return initialAdminPortletStatus[portletMode];
      case EmployeeRole.MANAGER:
        return initialManagerPortletStatus[portletMode];
      case EmployeeRole.USER:
        return initialUserPortletStatus[portletMode];
      default:
        return [];
    }
  }, [role, portletMode]);

  useEffect(() => {
    let result: PortletStatusList = [];
    if (deviceType === "pc") {
      result = switchInitialStatus;
    } else {
      result = switchInitialStatus.map((el) => ({ ...el, width: "100%" }));
    }
    setPortletList(result);
  }, [deviceType, switchInitialStatus]);

  return (
    <PortletListWrapper container>
      {portletList.map((portlet, idx) => {
        return (
          <PortletItem
            key={`portlet${role}${portletMode}${portlet.id}`}
            info={portlet}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <SwitchRoleDisplayModeComponent
                switchContentMap={SwitchPortletContentMap}
                role={role as EmployeeRole}
                id={portlet.id}
                displayMode={portletMode}
              />
            </Suspense>
          </PortletItem>
        );
      })}
    </PortletListWrapper>
  );
};

export default PortletList;
