import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  GRAYPALEBACKGROUND1,
  GRAYPALEBACKGROUND2,
  GRAYPALEBORDER,
  GRAYPALESTROKE,
} from "./Color";

export const ByCaseMainTitleBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: GRAYPALEBACKGROUND1,
  padding: "7px 0px",
  marginBottom: "5px",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
});

export const ByCaseContentEmptyBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  overflow: "auto",
  cursor: "pointer",
  backgroundColor: `#F0FAF7`,
  color: "#47B998",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#E4F7F1",
    boxShadow: "none",
    color: "#47B998",
  },
});

export const ByCaseCommentBox = styled(Grid)({
  marginTop: "10px",
  backgroundColor: GRAYPALEBACKGROUND2,
  padding: "7px",
  borderRadius: "8px",
});

export const MeetingNoteReplyBox = styled(Grid)({
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  padding: "5px",
});

export const MeetingNoteTextarea = styled("textarea")({
  width: "100%",
  height: "100px",
  resize: "none",
  borderRadius: "8px",
  paddingLeft: "10px",
  border: `1px solid ${GRAYPALEBORDER}`,
  outlineColor: GRAYPALESTROKE,
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300" },
});

export const AttachmentFileBox = styled(Grid)({
  padding: "7px 10px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: GRAYPALEBACKGROUND1,
  },
});

export const AttachmentFileDefaultBox = styled(Grid)({
  padding: "7px 10px",
  "&:hover": {
    backgroundColor: GRAYPALEBACKGROUND1,
  },
});
