import { Box, Grid, styled } from "@mui/material";
import {
  Gray700,
  GRAYPALEBORDER,
  WaitingBackground,
  WaitingText,
} from "../../Color";
import { styledWithoutProps } from "../../deviceTypeStyle";
import { StatusWrapperProps } from "../../../types/Lead/List";

interface StyledDeviceTypeProps {
  deviceType: "pc" | "tablet" | "mobile";
}

const CommonButtonWidth: Record<"pc" | "tablet" | "mobile", string> = {
  pc: "100px",
  tablet: "90px",
  mobile: "80px",
};

export const FilteringActivateButton = styledWithoutProps(
  "button"
)<StyledDeviceTypeProps>(({ deviceType }: StyledDeviceTypeProps) => ({
  border: "none",
  width: CommonButtonWidth[deviceType], // 타입이 올바르게 매칭되어야 합니다.
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1rem",
  transition: "all 0.2s",
  borderRadius: "4px",
  cursor: "pointer",
  backgroundColor: WaitingText,
  color: WaitingBackground,
}));

export const PipelineFilterBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  marginTop: "10px",
});

export const SalesProtectionTooltipStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "30rem",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  borderRadius: "5px",
};

export const StatusWrapper = styled("div")<StatusWrapperProps>(({ color }) => ({
  color: color,
  cursor: "pointer",
  backgroundColor: color,
  width: "16px",
  height: "16px",
  borderRadius: "12px",
  marginRight: "4px",
}));

export const LeadDropdownWrapper = styled(Box)({
  width: "120px",
  marginRight: "4px",
});
