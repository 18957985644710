import client from "../../system/ApiService";
import { _BasicApi } from "../../system/BasicApi";

class _TagApi extends _BasicApi {
  getTagInfo<RQ, RP>(params?: RQ) {
    return this.client.get<RP>(`/tag`, { params });
  }
}

export const TagApi = new _TagApi(client);
