import { Box, Tooltip } from "@mui/material";

type CommonTextWithExtraProps = {
  text: string | number;
  list: { key: string | number; value: string }[];
};
const CommonTextWithExtra = ({ text, list }: CommonTextWithExtraProps) => {
  const tooltipContent = list.map((item, index) => (
    <Box
      key={`text_tooltip_${item.key}${index}`}
      sx={{ color: "#fff", width: "100%" }}
    >
      {item.value}
    </Box>
  ));
  return (
    <>
      {list.length > 1 ? (
        <Tooltip title={<div>{tooltipContent}</div>}>
          <Box>{text}</Box>
        </Tooltip>
      ) : (
        <Box>{text}</Box>
      )}
    </>
  );
};

export default CommonTextWithExtra;
