import { useHistory } from "react-router-dom";
import { LeadMyTableInfo } from "../../../../system/types/Customer";
import { Grid } from "@mui/material";
import {
  EmptyTableBolder,
  TableBorderBox,
  TableContentBox,
  TableContentBoxPointer,
  TableTitleBox,
} from "../../../../styles/theme";
import { isoDateFormatter } from "../../../../components/Common/isoDateFormatter";
import { ColorStateConversion } from "../../../../components/Common/ColorStateConversion";
import { nameFormatter } from "../../../../components/Common/nameFormatter";
import SecurityIcon from "@mui/icons-material/Security";
import { CustomerApi } from "../../../../system/api/customerApi";
import { ErrorHandler } from "../../../../system/ApiService";
import { AlertModal } from "../../../../components/Common/AlertModal";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../../styles/Color";
interface MyTableListPcProps {
  data: LeadMyTableInfo[];
  refreshData: () => void;
  refreshProtection: () => void;
}

function MyTableListPc({
  data,
  refreshData,
  refreshProtection,
}: MyTableListPcProps) {
  const history = useHistory();
  function onMove(id: number) {
    history.push(`/list/detail/${id}`);
  }

  const updateProtection = (dt: LeadMyTableInfo) => {
    if (dt?.id) {
      Swal.fire({
        title: "영업보호 설정을 해제하시겠습니까?",
        text: "선택하신 가망고객의 영업보호권이 해제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          CustomerApi.updateSalesProtection(dt?.id, !dt.salesProtection)
            .then(() => {
              refreshData();
              refreshProtection();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  };
  return (
    <TableBorderBox container mt="10px">
      <Grid container>
        <TableTitleBox item xs={0.5}>
          상태
        </TableTitleBox>
        <TableTitleBox item xs={0.7}>
          영업 보호권
        </TableTitleBox>
        <TableTitleBox item xs={1.5}>
          상호
        </TableTitleBox>
        <TableTitleBox item xs={2.1}>
          주소
        </TableTitleBox>
        <TableTitleBox item xs={0.5}>
          제품군
        </TableTitleBox>
        <TableTitleBox item xs={1}>
          모델명
        </TableTitleBox>
        <TableTitleBox item xs={1}>
          업종
        </TableTitleBox>
        <TableTitleBox item xs={1}>
          과/팀/센터
        </TableTitleBox>
        <TableTitleBox item xs={1}>
          영업방식
        </TableTitleBox>
        <TableTitleBox item xs={0.7}>
          영업 담당자
        </TableTitleBox>
        <TableTitleBox item xs={1}>
          구매예상시기
        </TableTitleBox>
        <TableTitleBox item xs={1}>
          문서작성일
        </TableTitleBox>
      </Grid>
      {data.map((dt: LeadMyTableInfo, index: number) => (
        <Grid container key={index}>
          <TableContentBoxPointer
            item
            xs={0.5}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {ColorStateConversion(dt.status)}
          </TableContentBoxPointer>
          {dt?.salesProtection === true ? (
            <TableContentBoxPointer
              item
              xs={0.7}
              container
              justifyContent="center"
              alignItems="center"
              onClick={() => updateProtection(dt)}
            >
              <SecurityIcon />
            </TableContentBoxPointer>
          ) : (
            <TableContentBox
              item
              xs={0.7}
              container
              justifyContent="center"
              alignItems="center"
            ></TableContentBox>
          )}

          <TableContentBoxPointer
            item
            xs={1.5}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {dt.placeName}
          </TableContentBoxPointer>
          <TableContentBoxPointer
            item
            xs={2.1}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {dt.address}
          </TableContentBoxPointer>
          <TableContentBoxPointer
            item
            xs={0.5}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {dt.productFamily}
          </TableContentBoxPointer>
          <TableContentBoxPointer
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {dt.productName}
          </TableContentBoxPointer>
          <TableContentBoxPointer
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {dt?.businessType}
          </TableContentBoxPointer>
          <TableContentBoxPointer
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {dt.department}
          </TableContentBoxPointer>
          <TableContentBoxPointer
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            <Grid> [{dt.contactTypeMajor}]</Grid>
            <Grid> {dt.contactTypeSub}</Grid>
          </TableContentBoxPointer>

          <TableContentBoxPointer
            item
            xs={0.7}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {nameFormatter(dt.sellerName)}
          </TableContentBoxPointer>
          <TableContentBoxPointer
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {dt.estimatedDate
              ? isoDateFormatter(new Date(dt.estimatedDate))
              : null}
          </TableContentBoxPointer>
          <TableContentBoxPointer
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            onClick={() => onMove(dt.id)}
          >
            {dt.createdAt ? isoDateFormatter(new Date(dt.createdAt)) : null}
          </TableContentBoxPointer>
        </Grid>
      ))}
      {data.length === 0 && (
        <EmptyTableBolder container justifyContent="center" alignItems="center">
          조회된 데이터가 없습니다.
        </EmptyTableBolder>
      )}
    </TableBorderBox>
  );
}

export default MyTableListPc;
