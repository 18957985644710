import { useCallback, useState } from "react";
import { AdminGroupItemBox } from "../../../../../styles/adminStyle";

import AdminGroupInfoSectionTitle from "./AdminGroupInfoSectionTitle";
import AdminGroupInfo from "./AdminGroupInfo";
import AdminGroupInfoUpdateModal from "./AdminGroupInfoUpdateModal";
import { GroupInfo } from "../../../../../types/Group/group";
import { GroupGoalResponse } from "../../../../../types/DashBoard/Achievement";
import { EmployeeInfo } from "../../../../../types/Employee/Employee";

type AdminGroupinfoSectionProps = {
  groupData: GroupInfo;
  refreshData: () => Promise<void>;
  employeeList: EmployeeInfo[];
  groupGoalInfo: GroupGoalResponse;
};
const AdminGroupInfoSection = ({
  groupData,
  refreshData,
  employeeList,
  groupGoalInfo,
}: AdminGroupinfoSectionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleUpdateModal = useCallback((status: boolean) => {
    setIsOpen(status);
  }, []);

  return (
    <AdminGroupItemBox container>
      <AdminGroupInfoSectionTitle handleUpdateModal={handleUpdateModal} />

      <AdminGroupInfo
        groupInfo={groupData}
        errorMessage={errorMessage}
        groupGoalInfo={groupGoalInfo}
      />

      <AdminGroupInfoUpdateModal
        isOpen={isOpen}
        handleUpdateModal={handleUpdateModal}
        groupInfo={groupData}
        refreshData={refreshData}
        employeeList={employeeList}
      />
    </AdminGroupItemBox>
  );
};

export default AdminGroupInfoSection;
