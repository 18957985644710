import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./english/en.json";
import ko from "./korean/ko.json";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources: Resource = {
  en: {
    translation: {
      ...en,
    },
  },
  ko: {
    translation: {
      ...ko,
    },
  },
} as const;

i18n
  .use(LanguageDetector) // 자동 언어 감지
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ko", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    debug: true, //로드가 작동하지 않는 문제를 찾는데 도움을 줌(기본값: false)
    fallbackLng: {
      en: ["en"], // ko 없으면 en로 전환
      default: ["ko"],
    },
    keySeparator: false, // char를 사용하여 키를 구분한다. flat 한 json으로 작업하는 경우 false 로 사용하는 것이 좋음, we use content as keys
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
