import { Grid } from "@mui/material";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import TitleSetting from "./Common/TitleSetting";
import PositionSetting from "./Common/PositionSetting";
import OrderTypeSetting from "./Common/OrderTypeSetting";
import SaleTypeSetting from "./Common/SaleTypeSetting";
import SupplyTypeSetting from "./Common/SupplyTypeSetting";
import ClassTeamCenterSetting from "./Common/ClassTeamCenterSetting";
import SaleStageSetting from "./Common/SaleStageSetting";
import IndustryClassificationSetting from "./Common/IndustryClassificationSetting";
import SalesMethodSetting from "./Common/SalesMethodSetting";
import TagSetting from "./Common/TagSetting";
function CommonSetting() {
  let headerText: any = [
    { text: "직책" },
    { text: "직위" },
    { text: "수주유형" },
    { text: "판매방식" },
    { text: "공급유형" },
    { text: "과/팀/센터" },
    { text: "영업단계" },
    { text: "업종구분" },
    { text: "영업방식" },
    { text: "태그" },
  ];

  const onChange = (type: string) => {
    switch (type) {
      case "jobTitle":
        return <TitleSetting />;
      case "jobPosition":
        return <PositionSetting />;
      case "orderType":
        return <OrderTypeSetting />;
      case "saleType":
        return <SaleTypeSetting />;
      case "supplyType":
        return <SupplyTypeSetting />;
      case "classTeamCenter":
        return <ClassTeamCenterSetting />;
      case "saleStage":
        return <SaleStageSetting />;
      case "industryClassification":
        return <IndustryClassificationSetting />;
      case "salesMethod":
        return <SalesMethodSetting />;

      case "tag":
        return <TagSetting />;
      default:
        return null;
    }
  };

  return (
    <Grid mt="10px">
      <TabComponent heightAdjustMode="Auto">
        <TabItemsDirective>
          <TabItemDirective
            header={headerText[0]}
            content={() => onChange("jobTitle")}
          ></TabItemDirective>
          <TabItemDirective
            header={headerText[1]}
            content={() => onChange("jobPosition")}
          ></TabItemDirective>
          <TabItemDirective
            header={headerText[2]}
            content={() => onChange("orderType")}
          ></TabItemDirective>
          <TabItemDirective
            header={headerText[3]}
            content={() => onChange("saleType")}
          ></TabItemDirective>
          <TabItemDirective
            header={headerText[4]}
            content={() => onChange("supplyType")}
          ></TabItemDirective>
          <TabItemDirective
            header={headerText[5]}
            content={() => onChange("classTeamCenter")}
          ></TabItemDirective>
          <TabItemDirective
            header={headerText[6]}
            content={() => onChange("saleStage")}
          ></TabItemDirective>
          <TabItemDirective
            header={headerText[7]}
            content={() => onChange("industryClassification")}
          ></TabItemDirective>
          <TabItemDirective
            header={headerText[8]}
            content={() => onChange("salesMethod")}
          ></TabItemDirective>{" "}
          <TabItemDirective
            header={headerText[9]}
            content={() => onChange("tag")}
          ></TabItemDirective>
        </TabItemsDirective>
      </TabComponent>
    </Grid>
  );
}

export default CommonSetting;
