import client from "../ApiService";
import { _BasicApi } from "../BasicApi";
import { LeadAttachmentFilesInfo } from "../types/Customer";

const listUri: string = "/attachment-file";

class _AttachmentFileApi extends _BasicApi {
  getAttachmentFile(type: string, targetId: string) {
    return this.client.get(`${listUri}/${type}/${targetId}`);
  }
  createAttachmentFile(
    type: string,
    targetId: number,
    data: LeadAttachmentFilesInfo[]
  ) {
    return this.client.post(`${listUri}/${type}/${targetId}`, data);
  }
  deleteAttachmentFile(
    type: string,
    targetId: number,
    attachmentFileId: number
  ) {
    return this.client.delete(
      `${listUri}/${type}/${targetId}/${attachmentFileId}`
    );
  }
}

export const AttachmentFileApi = new _AttachmentFileApi(client);
