import { checkModal } from "../modal/checkModal";
import { memoModal } from "../modal/memoModal";
import { messageModal } from "../modal/messageModal";
import { successMessageModal } from "../modal/successMessageModal";
import { successModal } from "../modal/successModal";

export type AlertModalType =
  | "success"
  | "successMessage"
  | "msg"
  | "check"
  | "memo";

const SwitchModal = (
  type: AlertModalType,
  message?: string,
  content?: string
) => {
  switch (type) {
    case "success":
      return successModal(message);
    case "successMessage":
      return successMessageModal(message, content);
    case "msg":
      return messageModal(message);
    case "check":
      return checkModal(message);
    case "memo":
      return memoModal(message, content);
    default:
      return successModal(message);
  }
};

export const AlertModal = (
  type: AlertModalType,
  message?: string,
  content?: string
) => SwitchModal(type, message, content);
