import { Chip, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import {
  LeadReplyBox,
  LeadTableCardBox,
  LeadTableContentsBox,
  TextareaLineBox,
  TextareaPreLine,
} from "../../../../../styles/theme";
import { CustomText } from "../../../../../styles/CustomText";
import { CommentInfo } from "../../../../../system/types/MeetingNote";
import { InitCommentInfo } from "../../../../../system/types/initObject";
import { useState } from "react";
import { SalesActivityDetailInfo } from "../../../../../system/types/SalesActivity";
import {
  CustomButtonGray,
  CustomButtonGreen,
} from "../../../../../styles/buttonStyle";
import { CommentApi } from "../../../../../system/api/commentApi";
import { ErrorHandler } from "../../../../../system/ApiService";
import { AlertModal } from "../../../../../components/Common/AlertModal";
import {
  GRAYPALECONTENTS,
  GREENCONTENTS,
  REDTITLE,
} from "../../../../../styles/Color";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Swal from "sweetalert2";

interface ActivityCommentProps {
  selectedSalesActivity: SalesActivityDetailInfo;
  refreshActivityData: () => void;
}

function ActivityComment({
  selectedSalesActivity,
  refreshActivityData,
}: ActivityCommentProps) {
  const [commentData, setCommentData] = useState<CommentInfo>({
    ...InitCommentInfo,
    articleId: selectedSalesActivity.id,
    commentType: "salesActivity",
  });
  const [replyData, setReplyData] = useState<CommentInfo>({
    ...InitCommentInfo,
    depth: 1,
    articleId: selectedSalesActivity.id,
    commentType: "salesActivity",
  });
  const [editNumber, setEditNumber] = useState<number | null>(null);
  const [editData, setEditData] = useState<CommentInfo>({
    ...InitCommentInfo,
    articleId: selectedSalesActivity.id,
    commentType: "salesActivity",
  });
  const onChange = (args: any) => {
    setCommentData({
      ...commentData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };
  const onChangeEdit = (args: any) => {
    setEditData({
      ...editData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };
  function onSave() {
    if (editNumber) {
      CommentApi.updateComment(editNumber, editData)
        .then(() => {
          AlertModal("success", "댓글이 수정되었습니다.");
          refreshActivityData();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    } else {
      CommentApi.createComment(replyData?.parentId ? replyData : commentData)
        .then(() => {
          AlertModal("success", "댓글이 생성되었습니다.");
          refreshActivityData();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }

  function deleteCustomer(id: number | null | undefined) {
    if (id) {
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 댓글이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          CommentApi.deleteComment(id)
            .then(() => {
              AlertModal("success", "선택하신 댓글이 삭제되었습니다.");
              refreshActivityData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  }
  const onCancel = () => {
    setEditNumber(null);
    setEditData({
      ...InitCommentInfo,
      articleId: selectedSalesActivity.id,
      commentType: "salesActivity",
    });
    setReplyData({
      ...InitCommentInfo,
      articleId: selectedSalesActivity.id,
      commentType: "salesActivity",
      depth: 1,
    });
  };

  const onEdit = (dt: CommentInfo, depth: number) => {
    if (dt?.id) {
      setEditNumber(dt.id);
      setEditData({
        ...editData,
        content: dt.content,
        depth: depth,
        parentId: dt.parentId,
      });
      setReplyData({
        ...InitCommentInfo,
        articleId: selectedSalesActivity.id,
        commentType: "salesActivity",
        depth: 1,
      });
    }
  };
  const openReply = (id: number | null | undefined) => {
    if (id) {
      setReplyData({ ...replyData, parentId: id });
      setEditNumber(null);
    }
  };

  const onChangeReply = (args: any) => {
    setReplyData({
      ...replyData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  return (
    <LeadTableCardBox mt="20px">
      <Grid container item xs={12} alignItems="center" padding="10px">
        <CustomText type="subtitle" lineSpace>
          댓글 {selectedSalesActivity.comments.length}개
        </CustomText>
      </Grid>
      <LeadTableContentsBox container item xs={12}>
        <Grid container item xs={12}>
          {selectedSalesActivity?.comments &&
          selectedSalesActivity?.comments.length > 0 ? (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              padding="0px 5px"
            >
              {selectedSalesActivity.comments.map(
                (dt: CommentInfo, idx: number) => (
                  <Grid container item xs={12} key={idx} mb="15px">
                    <Grid container item xs={12} alignItems="center">
                      <Grid container item xs={12} sm={8}>
                        <CustomText type="subtitle" marginRight="10px">
                          {dt?.createBy?.name}
                        </CustomText>
                        <CustomText
                          type="subtitle"
                          bold400
                          color={GRAYPALECONTENTS}
                        >
                          {dt?.createdAt}
                        </CustomText>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={4}
                        justifyContent="right"
                      >
                        <Tooltip title="답글">
                          <IconButton onClick={() => openReply(dt.id)}>
                            <ReplyIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="수정">
                          <IconButton onClick={() => onEdit(dt, 0)}>
                            <EditNoteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="삭제">
                          <IconButton onClick={() => deleteCustomer(dt.id)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    {editNumber === dt?.id ? (
                      <Grid container item xs={12}>
                        <Grid container item xs={12} padding="5px">
                          <TextareaLineBox
                            name="content"
                            onChange={onChangeEdit}
                            value={editData.content || ""}
                            placeholder="댓글을 작성해 주세요."
                            autoComplete="new-password"
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent="right"
                          pr="5px"
                        >
                          <CustomButtonGray
                            onClick={onCancel}
                            style={{ marginRight: "5px" }}
                          >
                            취소
                          </CustomButtonGray>
                          <CustomButtonGreen onClick={onSave}>
                            댓글 수정
                          </CustomButtonGreen>
                        </Grid>
                      </Grid>
                    ) : (
                      <TextareaPreLine item xs={12}>
                        {dt.content}
                      </TextareaPreLine>
                    )}

                    {replyData.parentId === dt?.id && (
                      <Grid container item xs={12} mt="5px">
                        <Grid item xs={12}>
                          <TextareaLineBox
                            name="content"
                            onChange={onChangeReply}
                            value={replyData.content || ""}
                            placeholder="댓글을 작성해 주세요."
                            autoComplete="new-password"
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent="right"
                          pr="5px"
                        >
                          <CustomButtonGray
                            onClick={onCancel}
                            style={{ marginRight: "5px" }}
                          >
                            취소
                          </CustomButtonGray>
                          <CustomButtonGreen onClick={onSave}>
                            댓글 저장
                          </CustomButtonGreen>
                        </Grid>
                      </Grid>
                    )}
                    {/* children data */}
                    {dt?.children && dt?.children.length > 0 && (
                      <LeadReplyBox container item xs={12}>
                        {dt?.children.map(
                          (child: CommentInfo, index: number) => (
                            <Grid key={`child_${index}`} container item xs={12}>
                              <Grid container item xs={12} alignItems="center">
                                <Grid container item xs={12} sm={8}>
                                  <CustomText
                                    type="subtitle"
                                    marginRight="10px"
                                  >
                                    {child?.createBy?.name}
                                  </CustomText>
                                  <CustomText
                                    type="subtitle"
                                    bold400
                                    color={GRAYPALECONTENTS}
                                  >
                                    {child?.createdAt}
                                  </CustomText>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  sm={4}
                                  justifyContent="right"
                                >
                                  <Tooltip title="수정">
                                    <IconButton
                                      onClick={() => onEdit(child, 1)}
                                    >
                                      <EditNoteIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="삭제">
                                    <IconButton
                                      onClick={() => deleteCustomer(child.id)}
                                    >
                                      <DeleteOutlineIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12}>
                                {editNumber === child?.id ? (
                                  <Grid container item xs={12}>
                                    <Grid container item xs={12} padding="5px">
                                      <TextareaLineBox
                                        name="content"
                                        onChange={onChangeEdit}
                                        value={editData.content || ""}
                                        placeholder="댓글을 작성해 주세요."
                                        autoComplete="new-password"
                                      />
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      justifyContent="right"
                                      pr="5px"
                                    >
                                      <CustomButtonGray
                                        onClick={onCancel}
                                        style={{ marginRight: "5px" }}
                                      >
                                        취소
                                      </CustomButtonGray>
                                      <CustomButtonGreen onClick={onSave}>
                                        댓글 수정
                                      </CustomButtonGreen>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <TextareaPreLine item xs={12}>
                                    {child.content}
                                  </TextareaPreLine>
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}{" "}
                      </LeadReplyBox>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              mt="5px"
              mb="10px"
            >
              작성된 댓글이 없습니다.
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider>
            <Chip label="댓글 작성" size="small" />
          </Divider>
        </Grid>
        <Grid container item xs={12} padding="5px">
          <TextareaLineBox
            name="content"
            onChange={onChange}
            value={commentData.content || ""}
            placeholder="댓글을 작성해 주세요."
            autoComplete="new-password"
          />
        </Grid>
        <Grid container item xs={12} justifyContent="right" pr="5px">
          <CustomButtonGreen onClick={onSave}>댓글 저장</CustomButtonGreen>
        </Grid>
      </LeadTableContentsBox>
    </LeadTableCardBox>
  );
}

export default ActivityComment;
