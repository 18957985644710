import { useMemo } from "react";
import { GroupGoalInfo } from "../../../../../types/DashBoard/Achievement";
import { GroupInfo } from "../../../../../types/Group/group";
import AdminGroupInfoListItem from "./AdminGroupInfoListItem";
import { AdminGroupInfo } from "../../../../../types/Admin/AdminGroup";

type AdminGroupInfoListProps = {
  groupInfo: GroupInfo;
  groupGoalInfo: GroupGoalInfo;
};

const AdminGroupInfoList = ({
  groupInfo,
  groupGoalInfo,
}: AdminGroupInfoListProps) => {
  const adminGroupInfo = useMemo<AdminGroupInfo[]>(() => {
    if (groupGoalInfo !== undefined) {
      const {
        salesAmount,
        inboundLeadConversion,
        outboundLeadConversion,
        newLeadsInbound,
        newLeadsOutbound,
      } = groupGoalInfo;
      return [
        { label: "이름", value: groupInfo.name },
        {
          label: "그룹 관리자",
          value: groupInfo.manager?.name ? groupInfo.manager.name : "없음",
        },
        {
          label: "영업 그룹 여부",
          value: groupInfo.isSaleGroup ? "활성화" : "비활성화",
        },
        { label: "목표매출액", value: salesAmount },
        { label: "신규 인바운드", value: newLeadsInbound },
        { label: "신규 아웃바운드", value: newLeadsOutbound },
        { label: "인바운드 리드전환", value: inboundLeadConversion },
        { label: "아웃바운드 리드전환", value: outboundLeadConversion },
      ];
    } else return [];
  }, [groupInfo, groupGoalInfo]);

  return (
    <>
      {adminGroupInfo.map((info) => {
        return (
          <AdminGroupInfoListItem
            info={info}
            key={`adminGroupInfo${info.label}`}
          />
        );
      })}
    </>
  );
};

export default AdminGroupInfoList;
