import { useHistory, useParams } from "react-router-dom";
import { CustomText } from "../../styles/CustomText";
import { useCallback, useEffect, useRef, useState } from "react";
import { ErrorHandler } from "../../system/ApiService";
import { AlertModal } from "../../components/Common/AlertModal";
import { InitQnaInfo } from "../../system/types/initObject";
import { Grid, styled } from "@mui/material";
import { GRAYPALEBORDER } from "../../styles/Color";
import { CustomButtonGreen } from "../../styles/buttonStyle";
import { QnaApi } from "../../system/api/qnaApi";
import { QnaInfo } from "../../system/types/Qna";
import { QNACLS_TYPE } from "../../system/constants";
import {
  CustomFileUpload,
  DisabledImgBox,
  DropDownListComponentBox,
} from "../../styles/theme";
import { useRecoilValue } from "recoil";
import { MyInfoState } from "../../system/atoms";
import { BlobServiceClient } from "@azure/storage-blob";
import { TokenApi } from "../../system/api/tokenApi";
import { AttachmentFilesInfo } from "../../system/types/MeetingNote";
import uuid from "react-uuid";
import { AttachmentFileDefaultBox } from "../../styles/byCaseStyle";
import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import Editor from "../../components/Common/Editor/Editor";

interface Params {
  id: string;
}
function QnaEditPage() {
  const { id } = useParams<Params>();
  const detailId = parseInt(id);
  const [data, setData] = useState<QnaInfo>(InitQnaInfo);
  const myInfoData = useRecoilValue(MyInfoState);
  const history = useHistory();
  const refContent = useRef<Editor>(null);
  const getQna = useCallback(() => {
    if (detailId) {
      QnaApi.getQnaDetail(detailId)
        .then((res) => {
          if (myInfoData.id === res.data?.createBy?.id) {
            setData(res.data);
          } else {
            history.push(`/qna`);
            AlertModal("msg", "해당 질문의 수정 권한이 없습니다.");
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [detailId, history, myInfoData.id]);

  useEffect(() => {
    getQna();
  }, [getQna]);

  var submitcheck = false;
  function onSave() {
    submitcheck = true;

    if (submitcheck === true) {
      UploadFileToBlob(fileList);
      if (data.id) {
        QnaApi.updateQna({
          ...data,
          content: refContent.current?.getContent() || "",
        })
          .then(() => {
            AlertModal("success", "질문이 수정되었습니다.");
            history.push(`/Qna/detail/${data.id}`);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      } else {
        QnaApi.createQna({
          ...data,
          content: refContent.current?.getContent() || "",
        })
          .then(() => {
            AlertModal("success", "질문이 생성되었습니다.");
            history.push("/Qna/");
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  }

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };
  //첨부파일
  const [sasToken, setSasToken] = useState("");

  useEffect(() => {
    TokenApi.getSasToken()
      .then((res) => {
        setSasToken(res.data.sasUrl);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  const [fileList, setFileList] = useState<File[]>([]);

  const deleteFile = (name: string) => {
    setFileList(fileList.filter((dt: File) => dt.name !== name));
    setData({
      ...data,
      attachmentFiles: data.attachmentFiles.filter(
        (dt: AttachmentFilesInfo) => dt.changedName !== name
      ),
    });
  };
  const AttachmentKeyId = uuid();
  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      let preFileList: File[] = [];
      let preDataFileList: AttachmentFilesInfo[] = [];
      let hasDuplicate = false;
      const attachmentId = AttachmentKeyId;
      for (var i = 0; i < event.target.files.length; i++) {
        const oldFile = event.target.files[i];

        let newFile = null;
        let newDataFile = null;

        newFile = new File(
          [oldFile],
          `${attachmentId + "_" + event.target.files[i].name}`,
          {
            type: oldFile.type,
          }
        );

        newDataFile = {
          filename: event.target.files[i].name,
          changedName: `${attachmentId + "_" + event.target.files[i].name}`,
          filePath:
            "https://inbodyinfra.blob.core.windows.net/inbodylead/" +
            process.env.REACT_APP_ACTIVE_MODE +
            "/" +
            attachmentId +
            "_" +
            event.target.files[i].name,
        };

        if (newFile !== null && newDataFile !== null) {
          const fileName = newFile.name;
          if (fileList.some((file) => file.name === fileName)) {
            hasDuplicate = true;
            AlertModal("msg", `동일한 이름의 파일이 존재합니다: ${fileName}`);
            break;
          } else {
            preFileList = preFileList.concat([newFile]);
            preDataFileList = preDataFileList.concat([newDataFile]);
          }
        }
      }
      if (!hasDuplicate) {
        const completedFileList = fileList.concat(preFileList);
        const completedFileDataList =
          data.attachmentFiles.concat(preDataFileList);
        setFileList(completedFileList);
        setData({ ...data, attachmentFiles: completedFileDataList });
      }
    }
  };
  const UploadFileToBlob = async (file: File[]) => {
    // upload file
    const blobService = new BlobServiceClient(sasToken);
    const containerClient = blobService.getContainerClient("");

    const uploadPromises = file.map((document) => {
      if (document.name === null) {
        return Promise.resolve();
      }

      const blockBlobClient = containerClient.getBlockBlobClient(
        process.env.REACT_APP_ACTIVE_MODE + "/" + document.name
      );

      return blockBlobClient.uploadData(document!);
    });
    return Promise.all(uploadPromises);
  };

  return (
    <>
      <CustomText type="title">질문 {data.id ? "수정" : "생성"}</CustomText>

      <TableMainBox container mt="20px" justifyContent="center">
        <Grid container item xs={2} pr="10px">
          <TableContentBox item xs={12}>
            <DropDownListComponentBox
              showClearButton={true}
              dataSource={QNACLS_TYPE}
              name="qnaCls"
              onChange={onChange}
              placeholder="분류를 선택해 주세요."
              autoComplete="new-password"
            />
          </TableContentBox>
        </Grid>

        <TableContentBox item xs={7} container alignItems="center">
          <InputBox
            name="title"
            onChange={onChange}
            value={data.title}
            placeholder="제목을 입력해 주세요."
            autoComplete="new-password"
          />
        </TableContentBox>

        <Grid item xs={9} mt="10px">
          <Editor content={data.content || ""} ref={refContent}></Editor>
        </Grid>
      </TableMainBox>

      <AttachmentTableContentBox container>
        <AttachmentTitleBox
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>첨부파일</Grid>

          <Grid>
            <CustomFileUpload htmlFor={"file"}>
              <Grid container justifyContent="center">
                파일 업로드
              </Grid>
            </CustomFileUpload>
            <DisabledImgBox
              id={"file"}
              type="file"
              onChange={(e) => onSaveFile(e)}
              multiple
            ></DisabledImgBox>
          </Grid>
        </AttachmentTitleBox>
        <Grid container justifyContent="right">
          {data.attachmentFiles.length === 0 ? (
            <Grid container item xs={12} justifyContent="center" mt="10px">
              첨부 파일 없음
            </Grid>
          ) : (
            <>
              {data.attachmentFiles.map(
                (dt: AttachmentFilesInfo, index: number) => (
                  <AttachmentFileDefaultBox
                    key={index}
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid ml="5px"> {dt.filename}</Grid>
                    <CloseIcon
                      onClick={() => deleteFile(dt.changedName)}
                      sx={{ color: red[700], cursor: "pointer" }}
                    />
                  </AttachmentFileDefaultBox>
                )
              )}
            </>
          )}
        </Grid>
      </AttachmentTableContentBox>
      <Grid container justifyContent="right" mt="10px">
        <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
      </Grid>
    </>
  );
}

export default QnaEditPage;

const TableMainBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  padding: "20px 0px 30px 0px",
});

const TableContentBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  margin: "10px 0px",
  overflow: "auto",
  minHeight: "40px",
  padding: "0px 15px",
});

const InputBox = styled("input")({
  width: "100%",
  height: "20px",
  border: "none",
  "&::placeholder": {
    color: "#B2B4B8",
    fontWeight: "300",
    fontSize: "16px",
    paddingLeft: "5px",
  },
  "&:hover": { border: "none" },
  "&:focus": { border: "none", outline: "none" },
  "&:active": { border: "none" },
});

const AttachmentTitleBox = styled(Grid)({
  paddingBottom: "5px",
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
});

const AttachmentTableContentBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  padding: "10px 20px",
  marginTop: "10px",
  textAlign: "center",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
});
