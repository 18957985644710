import { Grid, styled } from "@mui/material";
import { GRAYPALEBACKGROUND2, GRAYPALEBORDER } from "../../../../styles/Color";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  FileValidInfo,
  LeadOrdersInfo,
} from "../../../../system/types/Customer";
import { Dispatch } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { PointerItem } from "../../../../styles/theme";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { red } from "@mui/material/colors";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import uuid from "react-uuid";
import { CustomText } from "../../../../styles/CustomText";
interface ValidListProps {
  id: number;
  data: FileValidInfo;
}

interface ValidationDataTableProps {
  data: FileValidInfo[];
  validList: ValidListProps[];
  setValidList: Dispatch<React.SetStateAction<ValidListProps[]>>;
  orderOpenState: number[];
  setOrderOpenState: Dispatch<React.SetStateAction<number[]>>;
}

function ValidationDataTable({
  data,
  validList,
  setValidList,
  orderOpenState,
  setOrderOpenState,
}: ValidationDataTableProps) {
  const onChangeOpenState = (index: number) => {
    if (orderOpenState.includes(index)) {
      setOrderOpenState((prevList) =>
        prevList.filter((item) => item !== index)
      );
    } else {
      setOrderOpenState((prevList) => [...prevList, index]);
    }
  };

  const onChangeValidList = (idx: number, data: FileValidInfo) => {
    if (validList.some((item) => item.id === idx)) {
      const updatedList = validList.filter((item) => item.id !== idx);
      setValidList(updatedList);
    } else {
      setValidList([...validList, { id: idx, data: data }]);
    }
  };
  function addProductPrice(dt: LeadOrdersInfo) {
    const addResult = dt.quantity * dt.price;
    return addResult;
  }
  function addAllProductPrice(dtList: LeadOrdersInfo[]) {
    return dtList.reduce((total, item) => total + addProductPrice(item), 0);
  }
  const countValidItems = (itemList: FileValidInfo[]) => {
    return itemList.filter((item) => item.allValid).length;
  };

  const extractValidItems = (list: FileValidInfo[]) => {
    let result: ValidListProps[] = [];
    list.forEach((item: FileValidInfo, index: number) => {
      if (item.allValid) {
        result.push({
          id: index,
          data: item,
        });
      }
    });
    return result;
  };

  function onChangeAllCheck() {
    if (validList.length !== countValidItems(data)) {
      setValidList(extractValidItems(data));
    } else {
      setValidList([]);
    }
  }

  return (
    <TableBox container item xs={12}>
      <TableTitleMainBox>
        <ScrollableContainer>
          <TableTitleBoxPosition container>
            <TableCheckgBox>번호</TableCheckgBox>
            <TableCheckgBox onClick={onChangeAllCheck}>
              {validList.length !== countValidItems(data) ? (
                <PointerItem>
                  <CheckBoxOutlineBlankIcon />
                </PointerItem>
              ) : (
                <PointerItem>
                  <CheckBoxIcon sx={{ color: red[900] }} />
                </PointerItem>
              )}
            </TableCheckgBox>
            <TableProductBox>제품 정보</TableProductBox>
            <TableTitleBox>담당지사</TableTitleBox>
            <TableTitleBox>영업 담당자</TableTitleBox>
            <TableTitleLongBox>상호명</TableTitleLongBox>
            <TableTitleBox>고객 담당자명</TableTitleBox>
            <TableTitleBox>고객 연락처(휴대폰)</TableTitleBox>
            <TableTitleBox>고객 연락처 (내선)</TableTitleBox>
            <TableTitleBox>고객 이메일</TableTitleBox>
            <TableTitleLongBox>고객 주소</TableTitleLongBox>
            <TableTitleLongBox>상세 주소</TableTitleLongBox>
            <TableTitleBox>업종</TableTitleBox>
            <TableTitleBox>과/팀/센터</TableTitleBox>
            <TableTitleBox>중개업체명</TableTitleBox>
            <TableTitleBox>판매방식</TableTitleBox>
            <TableTitleLongBox>영업방식</TableTitleLongBox>
            <TableTitleBox>수주유형</TableTitleBox>
            <TableTitleBox>공급유형</TableTitleBox>
            <TableTitleBox>영업단계</TableTitleBox>
            <TableTitleBox>구매 예상 시기</TableTitleBox>
            <TableTitleBox>태그</TableTitleBox>
            <TableTitleLongBox>비고</TableTitleLongBox>
          </TableTitleBoxPosition>

          {data.length > 0 ? (
            <>
              {data.map((dt: FileValidInfo, index: number) => (
                <TableContentox container key={index}>
                  <TableCheckgBox>{index + 1}</TableCheckgBox>
                  <TableCheckgBox>
                    {dt.allValid ? (
                      <PointerItem
                        onClick={() => {
                          onChangeValidList(index, dt);
                        }}
                      >
                        {validList.some((item) => item.id === index) ? (
                          <CheckBoxIcon sx={{ color: red[900] }} />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </PointerItem>
                    ) : (
                      <CheckBoxOutlineBlankIcon color="disabled" />
                    )}
                  </TableCheckgBox>
                  <TableProductBox>
                    {dt.orders.length > 0 && (
                      <PointerItem onClick={() => onChangeOpenState(index)}>
                        {orderOpenState.includes(index) ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </PointerItem>
                    )}
                  </TableProductBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body">{dt?.group?.name}</CustomText>
                    </TableWidthLimitedBox>
                    {!dt.groupValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body"> {dt?.seller?.name}</CustomText>
                    </TableWidthLimitedBox>
                    {!dt.sellerValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleLongBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      {" "}
                      <CustomText type="body">
                        {dt?.address?.placeName}
                      </CustomText>
                    </TableWidthLimitedBox>
                  </TableTitleLongBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body"> {dt?.name}</CustomText>
                    </TableWidthLimitedBox>
                    {!dt.nameValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body"> {dt?.phone}</CustomText>
                    </TableWidthLimitedBox>

                    {!dt.phoneValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body"> {dt?.officeNumber}</CustomText>
                    </TableWidthLimitedBox>
                    {!dt.officeNumberValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body"> {dt?.email}</CustomText>
                    </TableWidthLimitedBox>
                    {!dt.emailValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleLongBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      {" "}
                      <CustomText type="body">
                        {dt?.address?.road
                          ? dt?.address?.road
                          : dt?.address?.jibun}
                      </CustomText>
                    </TableWidthLimitedBox>
                    {!dt.addressValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleLongBox>
                  <TableTitleLongBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body">
                        {" "}
                        {dt?.address?.detail}
                      </CustomText>
                    </TableWidthLimitedBox>
                  </TableTitleLongBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      {" "}
                      <CustomText type="body">
                        {dt?.businessType?.name}
                      </CustomText>
                    </TableWidthLimitedBox>
                    {!dt.businessTypeValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body">
                        {" "}
                        {dt?.department?.name}
                      </CustomText>
                    </TableWidthLimitedBox>
                    {!dt.departmentTypeValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body">
                        {dt?.intermediaryCompany?.address?.placeName}
                      </CustomText>
                    </TableWidthLimitedBox>
                    {!dt.intermediaryCompanyValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body"> {dt?.saleType?.name}</CustomText>
                    </TableWidthLimitedBox>
                    {!dt.saleTypeValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleLongBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body">
                        {dt?.contactType?.major &&
                          dt?.contactType?.sub &&
                          `[${dt?.contactType?.major}] ${dt?.contactType?.sub}`}
                      </CustomText>
                    </TableWidthLimitedBox>
                    {!dt.contactTypeValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleLongBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body">
                        {dt?.contractType?.name}
                      </CustomText>
                    </TableWidthLimitedBox>
                    {!dt.contractTypeValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body">
                        {dt?.supplyType?.name}
                      </CustomText>
                    </TableWidthLimitedBox>
                    {!dt.supplyTypeValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body">{dt?.step?.name}</CustomText>
                    </TableWidthLimitedBox>
                    {!dt.stepValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body"> {dt?.estimatedDate}</CustomText>
                    </TableWidthLimitedBox>
                    {!dt.estimatedDateValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      <CustomText type="body">
                        {dt?.tags && dt?.tags[0]?.name}
                      </CustomText>
                    </TableWidthLimitedBox>

                    {!dt.tagsValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleBox>
                  <TableTitleLongBox container justifyContent="center">
                    <TableWidthLimitedBox item xs={10}>
                      {" "}
                      <CustomText type="body"> {dt?.description}</CustomText>
                    </TableWidthLimitedBox>
                    {!dt.descriptionValid && (
                      <HighlightOffIcon sx={{ color: red[600] }} />
                    )}
                  </TableTitleLongBox>

                  {orderOpenState.includes(index) && (
                    <OrderBigBox container>
                      <OrderTableBox>
                        <OrderTableTitleBox container item xs={12}>
                          <OrderTableTitle>제품군</OrderTableTitle>
                          <OrderTableLongTitle>제품명</OrderTableLongTitle>
                          <OrderTableTitle>수량</OrderTableTitle>
                          <OrderTableTitle>단가 </OrderTableTitle>
                        </OrderTableTitleBox>
                        <TableBorder container>
                          {Object.keys(dt.ordersValid).map(
                            (key: string, index: number) => (
                              <Grid container key={uuid()}>
                                <OrderTableContent
                                  container
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <CustomText type="body">
                                    {dt.orders[index].product.family}
                                  </CustomText>

                                  {!dt?.ordersValid[key]
                                    ?.productFamilyValid && (
                                    <HighlightOffIcon
                                      sx={{ color: red[600] }}
                                    />
                                  )}
                                </OrderTableContent>
                                <OrderTableLongContent
                                  container
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <CustomText type="body">
                                    {" "}
                                    {dt.orders[index].product.name}
                                  </CustomText>

                                  {!dt?.ordersValid?.[key]
                                    ?.productNameValid && (
                                    <HighlightOffIcon
                                      sx={{ color: red[600] }}
                                    />
                                  )}
                                </OrderTableLongContent>
                                <OrderTableContent
                                  container
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <CustomText type="body">
                                    {dt.orders[index].quantity}
                                  </CustomText>

                                  {!dt?.ordersValid?.[key]?.quantityValid && (
                                    <HighlightOffIcon
                                      sx={{ color: red[600] }}
                                    />
                                  )}
                                </OrderTableContent>
                                <OrderTableContentRightAlign
                                  container
                                  alignItems="center"
                                  justifyContent="right"
                                >
                                  <CustomText type="body" textAlign="right">
                                    {dt.orders[index].price.toLocaleString()} 원
                                  </CustomText>
                                  {!dt?.ordersValid?.[key]?.priceValid && (
                                    <HighlightOffIcon
                                      sx={{ color: red[600] }}
                                    />
                                  )}
                                </OrderTableContentRightAlign>
                              </Grid>
                            )
                          )}
                        </TableBorder>
                        <Grid container justifyContent="right">
                          <OrderTableTotalTitle> 총 합계 </OrderTableTotalTitle>
                          <OrderTableTotalContentRightAlign>
                            <CustomText type="body">
                              {addAllProductPrice(dt.orders).toLocaleString()}{" "}
                              원
                            </CustomText>
                          </OrderTableTotalContentRightAlign>
                        </Grid>
                      </OrderTableBox>
                    </OrderBigBox>
                  )}
                </TableContentox>
              ))}
            </>
          ) : (
            <TableContentox container>
              <TableEmptyBox> 등록된 정보가 없습니다.</TableEmptyBox>
            </TableContentox>
          )}
        </ScrollableContainer>
      </TableTitleMainBox>
    </TableBox>
  );
}

export default ValidationDataTable;

const TableBox = styled(Grid)(() => ({
  border: `1px solid ${GRAYPALEBORDER}`,
  marginTop: "5px",
}));

const TableTitleBox = styled(Grid)(() => ({
  margin: "9px 5px 5px 5px",
  width: "150px",
  textAlign: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));
const TableCheckgBox = styled(Grid)(() => ({
  margin: "6px 5px 5px 5px",
  width: "40px",
  textAlign: "center",
}));
const TableProductBox = styled(Grid)(() => ({
  margin: "6px 5px 5px 5px",
  width: "70px",
  textAlign: "center",
}));
const TableTitleLongBox = styled(Grid)(() => ({
  margin: "9px 5px 5px 5px",
  width: "300px",
  textAlign: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const TableTitleMainBox = styled("div")(() => ({
  width: "100%",
  overflowX: "auto",
  height: "500px",
  overflowY: "auto",
}));
const ScrollableContainer = styled("div")(() => ({
  minWidth: "4130px",
}));

const TableContentox = styled(Grid)(() => ({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
}));
const OrderBigBox = styled(Grid)(() => ({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  padding: "",
  marginBottom: "5px",
}));

const OrderTableBox = styled(Grid)(() => ({
  width: "1400px",
}));

const OrderTableTitleBox = styled(Grid)(() => ({
  marginLeft: "100px",
}));

const OrderTableTitle = styled(Grid)(() => ({
  marginTop: "5px",
  padding: "5px 0px",
  width: "250px",
  textAlign: "center",
  backgroundColor: GRAYPALEBACKGROUND2,
}));
const OrderTableTotalTitle = styled(Grid)(() => ({
  padding: "5px 5px",
  width: "250px",
  textAlign: "center",
  backgroundColor: GRAYPALEBACKGROUND2,
}));
const OrderTableLongTitle = styled(Grid)(() => ({
  marginTop: "5px ",
  padding: "5px 0px",
  width: "450px",
  textAlign: "center",
  backgroundColor: GRAYPALEBACKGROUND2,
}));

const OrderTableContent = styled(Grid)(() => ({
  margin: "5px 0px",
  width: "250px",
  textAlign: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));
const OrderTableContentRightAlign = styled(Grid)(() => ({
  margin: "5px 0px",
  width: "246px",
  paddingRight: "9px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));
const OrderTableLongContent = styled(Grid)(() => ({
  margin: "5px 0px",
  width: "450px",
  textAlign: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));
const OrderTableTotalContentRightAlign = styled(Grid)(() => ({
  marginRight: "100px",
  padding: "5px 10px 5px 0px",
  width: "250px",
  textAlign: "right",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  borderLeft: `1px solid ${GRAYPALEBACKGROUND2}`,
  borderRight: `1px solid ${GRAYPALEBACKGROUND2}`,
  borderBottom: `1px solid ${GRAYPALEBACKGROUND2}`,
}));

const TableBorder = styled(Grid)(() => ({
  border: `1px solid ${GRAYPALEBACKGROUND2}`,
  width: "1200px",
  marginLeft: "100px",
}));

const TableEmptyBox = styled(Grid)(() => ({
  width: "1398px",
  textAlign: "center",
  margin: "200px 0px",
}));

const TableWidthLimitedBox = styled(Grid)(() => ({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const TableTitleBoxPosition = styled(Grid)(() => ({
  position: "sticky",
  top: "0",
  backgroundColor: "#ffffff",
}));
