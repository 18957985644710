import { Grid } from "@mui/material";
import { CustomText } from "../../styles/CustomText";
import MyInfoPageBox from "./components/MyInfoPageBox";

function MyInfoPage() {
  return (
    <>
      <CustomText type="title">내 정보</CustomText>
      <Grid item xs={12}></Grid>
      <Grid container item xs={12} justifyContent="center" mt="30px">
        <MyInfoPageBox />
      </Grid>
    </>
  );
}

export default MyInfoPage;
