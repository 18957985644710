import { Box, styled, Typography } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import { DisplayMode } from "../../../types/DashBoard/Portlet";
import { Brand600, Gray600 } from "../../../styles/Color";

export const ReactNavigatorWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "16px",
  gap: 1,
});

export const ReportSelectBoxWrapper = styled(Box)({});
export const ReportGroupSelectBoxWrapper = styled(ReportSelectBoxWrapper)({
  width: "200px",
});
export const ReportDateSelectBoxWrapper = styled(ReportSelectBoxWrapper)({
  width: "100px",
});

export const ReportHeaderText = styled("span")({
  marginRight: "4px",
  cursor: "pointer",
  fontWeight: 600,
});

export const GroupDisplayIcon = styled(PeopleAltIcon)(
  ({ displaymode }: { displaymode: DisplayMode }) => ({
    cursor: "pointer",
    fill: displaymode === DisplayMode.FIRST ? Brand600 : Gray600,
  })
);

export const ReportHeaderGroupText = styled(ReportHeaderText)(
  ({ displaymode }: { displaymode: DisplayMode }) => ({
    cursor: "pointer",
    color: displaymode === DisplayMode.FIRST ? Brand600 : Gray600,
  })
);

export const UserDisplayIcon = styled(PersonIcon)(
  ({ displaymode }: { displaymode: DisplayMode }) => ({
    cursor: "pointer",
    fill: displaymode === DisplayMode.SECOND ? Brand600 : Gray600,
  })
);

export const ReportHeaderUserText = styled(ReportHeaderText)(
  ({ displaymode }: { displaymode: DisplayMode }) => ({
    cursor: "pointer",
    color: displaymode === DisplayMode.SECOND ? Brand600 : Gray600,
  })
);
