import { atom } from "recoil";
import { DisplayMode, PortletStatusList } from "../../types/DashBoard/Portlet";
import { TodoBarChartDataList } from "../../types/DashBoard/Sales";

type initialPortletStattus = {
  [key in DisplayMode]: PortletStatusList;
};

export const initialAdminPortletStatus: initialPortletStattus = {
  [DisplayMode.FIRST]: [
    { id: 1, title: "Goal Achieved", width: "50%", alignItems: "flex-start" },
    { id: 2, title: "Forecast", width: "50%" },
    { id: 3, title: "Business Status", width: "100%" },
    { id: 4, title: "Work Schedule", width: "100%" },
    {
      id: 5,
      title: "Work Status",
      width: "100%",
      justifyContent: "flex-start",
    },
    {
      id: 6,
      title: "Work Status",
      width: "100%",
      justifyContent: "flex-start",
    },
  ],
  [DisplayMode.SECOND]: [],
};

export const initialManagerPortletStatus: initialPortletStattus = {
  [DisplayMode.FIRST]: [
    { id: 1, title: "Goal Achieved", width: "50%" },
    { id: 2, title: "Forecast", width: "50%" },
    { id: 3, title: "Business Status", width: "100%" },
    { id: 4, title: "Work Schedule", width: "100%" },
    { id: 5, title: "Work Status", width: "100%" },
    { id: 6, title: "Work Status", width: "100%" },
  ],
  [DisplayMode.SECOND]: [
    { id: 1, title: "Goal Achieved", width: "50%" },
    { id: 2, title: "Forecast", width: "50%" },
    { id: 3, title: "Business Status", width: "100%" },
    { id: 4, title: "Work Schedule", width: "100%" },
  ],
};

export const initialUserPortletStatus: initialPortletStattus = {
  [DisplayMode.FIRST]: [
    { id: 1, title: "Goal Achieved", width: "50%" },
    { id: 2, title: "Forecast", width: "50%" },
    { id: 3, title: "Business Status", width: "100%" },
    { id: 4, title: "Work Schedule", width: "100%" },
  ],
  [DisplayMode.SECOND]: [],
};

export const portletStatusStore = atom<PortletStatusList>({
  key: "portletStatusStore",
  default: [],
});

export const portletModeStore = atom<DisplayMode>({
  key: "portletModeStore",
  default: DisplayMode.FIRST,
});

export const salesConditionListStore = atom<TodoBarChartDataList>({
  key: "salesConditionListStore",
  default: [],
});
