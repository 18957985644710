import client from "../../system/ApiService";
import { _BasicApi } from "../../system/BasicApi";

class _ListApi extends _BasicApi {
  // 드랍다운 목록

  getDropdownInfo<RQ, RP>(type: string, params?: RQ) {
    return this.client.get<RP>(`/list/${type}`, { params });
  }

  getContactInfo<RQ, RP>(params?: RQ) {
    return this.client.get<RP>(`/list/contact`, { params });
  }
}

export const ListApi = new _ListApi(client);
