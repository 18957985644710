import { ReactNode } from "react";
import {
  ContentWrapper,
  ContentBody,
  ContentBodyMobile,
} from "../../styles/theme";
import { useRecoilValue } from "recoil";
import { leftDrawerOpenState } from "../../system/atoms";
import {
  Mobile,
  Pc,
  Tablet,
} from "../../components/Common/Responseive/ResponsiveScreen";
import Footer from "./Footer";
interface ContentProps {
  children: ReactNode;
}

function Content({ children }: ContentProps) {
  const open = useRecoilValue(leftDrawerOpenState);

  return (
    <>
      <ContentWrapper isOpen={open}>
        <Pc>
          <ContentBody>{children}</ContentBody>
        </Pc>
        <Tablet>
          <ContentBody>{children}</ContentBody>
        </Tablet>
        <Mobile>
          <ContentBodyMobile>{children}</ContentBodyMobile>
        </Mobile>
      </ContentWrapper>
      {/* Footer */}
      <Footer />
    </>
  );
}

export default Content;
