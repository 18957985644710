import { ReactNode } from "react";
import {
  PortletItemContentWrapper,
  PortletItemHeaderWrapper,
  PortletItemTitle,
  PortletItemWrapper,
} from "../../styles/Portlet/portletStyle";
import { PortletStatus } from "../../../../types/DashBoard/Portlet";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { t } from "i18next";

type PortletItemProps = {
  info: PortletStatus;
  children: ReactNode;
};

const PortletItem = ({ info, children }: PortletItemProps) => {
  const deviceType = useDeviceType();

  return (
    <PortletItemWrapper
      key={`portlet${info.id}`}
      item
      deviceType={deviceType}
      sx={{
        flex: `0 1 ${
          info.width === "100%" ? info.width : `calc(${info.width} - 4px)`
        }`,
        maxWidth:
          info.width === "100%" ? info.width : `calc(${info.width} - 4px)`,
      }}
    >
      <PortletItemHeaderWrapper deviceType={deviceType}>
        <PortletItemTitle>{t(info.title)}</PortletItemTitle>
      </PortletItemHeaderWrapper>
      <PortletItemContentWrapper
        sx={{
          justifyContent: info.justifyContent ? info.justifyContent : "center",
          alignItems: info.alignItems ? info.alignItems : "center",
        }}
      >
        {children}
      </PortletItemContentWrapper>
    </PortletItemWrapper>
  );
};

export default PortletItem;
