import { CustomText } from "../../../styles/CustomText";
import MyTableList from "../My/components/MyTableList";

function NewPage() {
  return (
    <>
      <CustomText type="title"> 신규 거래 목록</CustomText>
      <MyTableList type="new" />
    </>
  );
}

export default NewPage;
