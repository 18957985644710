import {
  ColumnDirective,
  ColumnsDirective,
  EditSettingsModel,
  Filter,
  FilterSettingsModel,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { Grid } from "@mui/material";
import { CustomButtonYellow } from "../../../../styles/buttonStyle";
import {
  EmployeeInfo,
  EmployeeTableInfo,
} from "../../../../types/Employee/Employee";
interface AdminUserTablePcProps {
  data: EmployeeTableInfo[];
  moveToUpdateEmployee: (props: EmployeeInfo) => void;
}

function AdminUserTablePc({
  data,
  moveToUpdateEmployee,
}: AdminUserTablePcProps) {
  const pageSettings: PageSettingsModel = { pageSize: 10 };
  let grid: any;
  const editOptions: EditSettingsModel = { allowDeleting: true };
  const FilterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  const activeTemplate = (props: EmployeeInfo) => {
    return (
      <CustomButtonYellow onClick={() => moveToUpdateEmployee(props)}>
        수정
      </CustomButtonYellow>
    );
  };

  const jobTemplate = (props: EmployeeInfo) => {
    return (
      <>
        {props.jobTitle ? (
          <>
            {props.jobPosition ? (
              <>
                {props.jobTitle?.name} / {props.jobPosition?.name}
              </>
            ) : (
              <>{props.jobTitle?.name}</>
            )}
          </>
        ) : (
          <>{props.jobPosition ? <>{props.jobPosition?.name}</> : <>-</>}</>
        )}
      </>
    );
  };
  return (
    <Grid container justifyContent="center">
      <GridComponent
        dataSource={data}
        allowPaging={true}
        allowFiltering={true}
        allowSorting={true}
        editSettings={editOptions}
        pageSettings={pageSettings}
        filterSettings={FilterOptions}
        locale="ko"
        ref={(g) => {
          grid = g;
        }}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="name"
            headerText="이름"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="group"
            headerText="소속 그룹"
            width="150"
            textAlign="Center"
          />
          <ColumnDirective
            field="email"
            headerText="계정"
            width="150"
            textAlign="Center"
          />

          <ColumnDirective
            field="phone"
            headerText="연락처"
            width="150"
            textAlign="Center"
          />

          <ColumnDirective
            field="jobTitle"
            headerText="직책 / 직위"
            width="100"
            template={jobTemplate}
            textAlign="Center"
          />
          <ColumnDirective
            field="id"
            headerText=""
            width="100"
            template={activeTemplate}
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject services={[Page, Filter, Sort]} />
      </GridComponent>
    </Grid>
  );
}

export default AdminUserTablePc;
