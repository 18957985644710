import { Grid } from "@mui/material";
import { HistoryInfo } from "../../../../../system/types/History";
import {
  TableBorderBox,
  TableContentBox,
  TableTitleBox,
} from "../../../../../styles/theme";
import { isoDateFormatter } from "../../../../../components/Common/isoDateFormatter";

interface LeadHistoryProps {
  historyData: HistoryInfo[];
}

function LeadHistory({ historyData }: LeadHistoryProps) {
  return (
    <Grid container item xs={12} mt="10px">
      <TableBorderBox container>
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={8}
        >
          내용
        </TableTitleBox>
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={2}
        >
          담당자
        </TableTitleBox>
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={2}
        >
          날짜
        </TableTitleBox>
        {historyData.map((dt: HistoryInfo, index: number) => (
          <Grid container key={index}>
            <TableContentBox item xs={8} container alignItems="center">
              {dt.message}
            </TableContentBox>
            <TableContentBox
              item
              xs={2}
              container
              justifyContent="center"
              alignItems="center"
            >
              {dt.generator.name}
            </TableContentBox>
            <TableContentBox
              item
              xs={2}
              container
              justifyContent="center"
              alignItems="center"
            >
              {isoDateFormatter(new Date(dt.createdAt))}
            </TableContentBox>
          </Grid>
        ))}
        {historyData.length === 0 && (
          <TableContentBox
            container
            item
            xs={12}
            justifyContent="center"
            padding="100px 0px"
          >
            조회된 데이터가 없습니다.
          </TableContentBox>
        )}
      </TableBorderBox>
    </Grid>
  );
}

export default LeadHistory;
