import { Grid, Paper, TextField } from "@mui/material";

import {
  DropDownListComponent,
  DropDownTreeComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  BLUEBACKGROUND,
  GRAYPALEBACKGROUND2,
  GRAYPALEBORDER,
  GRAYPALECONTENTS,
  GRAYPALESTROKE,
  PRIMARY,
} from "./Color";
import { styled, css } from "@mui/material/styles";
interface PersistentDrawerLeftProps {
  isOpen: boolean;
}

export const ContentWrapper = styled("div")(
  ({ isOpen }: PersistentDrawerLeftProps) => ({
    marginTop: "80px",
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
    flexGrow: 1,
    padding: "15px 0px",
    marginLeft: isOpen ? "240px" : "0px",
    transition: "all 0.5s",
  })
);

export const ContentBody = styled("div")({
  margin: "0px 50px",
  width: "1600px",
  "@media (max-width: 1440px)": {
    width: "1200px",
  },
  "@media (max-width: 1380px)": {
    width: "1200px",
  },
  "@media (max-width: 1279px)": {
    width: "1200px",
  },
});

export const ContentBodyMobile = styled("div")({
  width: "1400px",
  "@media (max-width: 1680px)": {
    width: "1400px",
  },
  "@media (max-width: 1440px)": {
    width: "1200px",
  },
  "@media (max-width: 1380px)": {
    width: "1200px",
  },
  "@media (max-width: 1279px)": {
    width: "1200px",
  },
});

interface PageWidthProps {
  pageWidth: string;
}

export const WidthLayout = styled(Grid)(({ pageWidth }: PageWidthProps) => ({
  width: `${pageWidth}`,
}));

// header
export const HeaderStyle = styled("div")({
  flexGrow: 1,
});
export const MenuButton = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
}));
export const Title = styled("div")({
  flexGrow: 1,
  marginLeft: "10px",
});
export const NavProfile = styled("div")({
  marginRight: "10px",
});

export const FooterStyle = styled("div")(({ theme }) => ({
  height: "60px",
  width: "100%",
  padding: "10px 0px",

  marginBottom: theme.spacing(1),
  textAlign: "center",
  zIndex: -1,
}));

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "1px solid #000000",
    },
  },
});

// Input Box
export const InputBox = styled("input")({
  width: "100%",
  height: "3rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  paddingLeft: "10px",
  margin: "5px 0px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300", fontSize: "16px" },
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
});

export const InputSmallBox = styled("input")({
  width: "94%",
  height: "20px",
  paddingLeft: "7px",
  margin: "0px 3%",
  border: "none",
  "&::placeholder": {
    color: "#B2B4B8",
    fontWeight: "300",
    fontSize: "16px",
    paddingLeft: "5px",
  },
  "&:hover": { border: "none" },
  "&:focus": { border: "none", outline: "none" },
  "&:active": { border: "none" },
});

export const InputLineSmallBox = styled("input")({
  width: "94%",
  height: "20px",
  paddingLeft: "7px",
  margin: "0px 3%",
  border: "none",
  borderBottom: "1px solid #000",
  "&::placeholder": {
    color: "#B2B4B8",
    fontWeight: "300",
    fontSize: "16px",
  },
  "&:hover": { border: "none", borderBottom: "1px solid #000" },
  "&:focus": {
    border: "none",
    outline: "none",
    borderBottom: "1px solid #000",
  },
  "&:active": { border: "none", borderBottom: "1px solid #000" },
});
export const EditInputSmallBox = styled("input")({
  width: "100%",
  height: "20px",
  padding: "0px 5px",
  border: "none",
  "&::placeholder": {
    color: "#B2B4B8",
    fontWeight: "300",
    fontSize: "16px",
  },
  "&:hover": { border: "none" },
  "&:focus": { border: "none", outline: "none" },
  "&:active": { border: "none" },
});

export const TextareaBox = styled("textarea")({
  width: "100%",
  border: "none",
  resize: "none",
  height: "101px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300", fontSize: "16px" },
  "&:hover": { border: "none" },
  "&:focus": { border: "none", outline: "none" },
  "&:active": { border: "none" },
});

export const TextareaLineBox = styled("textarea")({
  width: "100%",
  border: "1px solid #DCDFE3",
  resize: "none",
  height: "100px",
  // borderRadius: "8px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300", fontSize: "16px" },
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
});

export const TextareaPreLine = styled(Grid)({
  whiteSpace: "pre-line",
});

export const InputSmallPercentBox = styled("input")({
  width: "60%",
  height: "20px",
  paddingLeft: "10px",
  marginLeft: "3%",
  border: "none",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300", fontSize: "16px" },
  "&:hover": { border: "none" },
  "&:focus": { border: "none", outline: "none" },
  "&:active": { border: "none" },
});

export const InputGridBox = styled(Grid)({
  width: "100%",
  height: "3rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  margin: "5px 0px",
  padding: "5px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300", fontSize: "16px" },
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
});

export const InputDisabledBox = styled(Grid)({
  width: "100%",
  height: "3rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  paddingLeft: "10px",
  margin: "5px 0px",
  backgroundColor: GRAYPALEBACKGROUND2,
  cursor: "default",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300", fontSize: "16px" },
});

export const DropDownListComponentGridBox = styled(Grid)({
  width: "100%",
  height: "3rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  marginTop: "3px",
  padding: "9px",
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
});

interface DropDownListComponentProps {
  colorvalue?: string;
}

export const DropDownListComponentBox = styled(DropDownListComponent)(
  ({ colorvalue }: DropDownListComponentProps) => ({
    width: "100%",
    marginTop: "5px",
    minHeigth: "2rem",
    border: "0px",
    fontSize: "16px",
    fontFamily: "Noto Sans KR",
    "&::placeholder": {
      color: colorvalue ? `${colorvalue} !important` : "#B2B4B8 !important",
      fontWeight:
        colorvalue === "#000000" ? "400 !important" : "300 !important",
      fontSize: "16px !important",
      fontFamily: "Noto Sans KR",
    },
  })
);

export const EditDropDownListComponentBox = styled(DropDownListComponent)(
  () => ({
    border: "0px",
    fontSize: "16px",
    fontFamily: "Noto Sans KR",
    "&::placeholder": {
      color: "#B2B4B8 !important",
      fontWeight: "300 !important",
      fontSize: "16px !important",
      fontFamily: "Noto Sans KR",
    },
  })
);

export const DropDownListBlackPlacehodlerBox = styled(DropDownListComponent)({
  width: "100%",
  height: "2rem",
  border: "0px",
  fontFamily: "Noto Sans KR",
  "&::placeholder": {
    color: "#000000 !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    fontFamily: "Noto Sans KR !important",
    paddingLeft: "-5px",
  },
});

export const DropDownTreeComponentBox = styled(DropDownTreeComponent)(
  ({ colorvalue }: DropDownListComponentProps) => ({
    width: "100%",
    height: "2rem",
    border: "0px",
    fontSize: "16px",
    fontFamily: "Noto Sans KR",
    "&::placeholder": {
      color: colorvalue ? `${colorvalue} !important` : "#B2B4B8 !important",
      fontWeight:
        colorvalue === "#000000" ? "400 !important" : "300 !important",
      fontSize: "16px !important",
      fontFamily: "Noto Sans KR",
    },
  })
);

export const DropDownTreeComponentPlaceholderBox = styled(
  DropDownTreeComponent
)({
  width: "100%",
  height: "2rem",
  border: "0px",
  "&::placeholder": {
    color: "#000000 !important",
    fontWeight: "400 !important",
    fontSize: "16px",
    fontFamily: "Noto Sans KR",
  },
});

export const SearchBoxComponent = styled(Paper)({
  padding: "0px 4px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
  width: 250,
  height: "40px",
});

export const ButtonSize = styled(Grid)({
  width: "200px",
  margin: "0px 5px",
  border: "1px solid #ffffff",
});

export const ModalStyle = () => {
  return {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    maxWidth: "40rem",
    maxHeight: "780px",
    bgcolor: "background.paper",
    border: "0px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    borderRadius: "5px",
  };
};

export const BigModalStyle = () => {
  return {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "60rem",
    maxHeight: "100vh",
    bgcolor: "background.paper",
    border: "0px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    borderRadius: "5px",
  };
};

export const SmallBoxStyle = styled(Grid)({
  width: "100%",
  maxWidth: "35.5rem",
  padding: "4px",
});

interface MarginNumberProps {
  marginNumber: number;
}

export const MarginLayout = styled("div")(
  ({ marginNumber }: MarginNumberProps) => ({
    margin: `${marginNumber}px 0px`,
  })
);

export const PointerItem = styled(Grid)({ cursor: "pointer" });

// table
export const TableNoticeTitleBox = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: GRAYPALEBACKGROUND2,
  padding: "7px 0px",
  textAlign: "center",
  minHeight: "40px",
  overflow: "auto",
});

export const TableTitleBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: GRAYPALEBACKGROUND2,
  padding: "7px 0px",
  textAlign: "center",
  minHeight: "40px",
  overflow: "auto",
});

const commonStyles = css`
  border-bottom: 1px solid ${GRAYPALEBORDER};
  border-right: 1px solid ${GRAYPALEBORDER};
  text-align: center;
  min-height: 40px;
  overflow: auto;
  overflow-wrap: anywhere;
`;

export const TableContentBox = styled(Grid)`
  ${commonStyles}
`;

export const TableContentBoxPointer = styled(Grid)`
  ${commonStyles}
  cursor: pointer;
`;

export const TableContentTextareaBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  padding: "5px",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
  whiteSpace: "pre-line",
});

export const TableContentMoneyBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  padding: "7px 10px 7px 0px",
  textAlign: "right",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
});

export const TableContentPointBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  padding: "0px 5px",
  textAlign: "center",
  minHeight: "40px",
  cursor: "pointer",
  overflow: "auto",
  overflowWrap: "break-word",
});

export const TableContentRegisterBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  paddingLeft: "2.7%",
  paddingTop: "5px",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "break-word",
});

export const TableContentDetailBox = styled(Grid)({
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  paddingTop: "5px",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "break-word",
});

export const TableContentNoPaddingBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "break-word",
});

export const TableContentNoPaddingBigBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  height: "121px",
  overflow: "auto",
  overflowWrap: "break-word",
});

export const TableContentDropdownBox = styled(Grid)({
  borderRight: `1px solid ${GRAYPALEBORDER}`,
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  minHeight: "40px",
  width: "94%",
  padding: "2px 3% 0px 3%",
  overflow: "auto",
  overflowWrap: "anywhere",
});

// card
export const TableCardBox = styled(Grid)({
  border: `1px solid ${GRAYPALESTROKE}`,
  borderRadius: "8px",
  padding: "15px",
  marginTop: "5px",
});

// mobile

export const MobileTableTitleBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  backgroundColor: GRAYPALEBACKGROUND2,
  overflow: "auto",
  padding: "0px 5px",
  height: "40px",
});

export const EmptyTableBolder = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  padding: "200px 0px",
});

export const MobileTableContentsBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  height: "40px",
});

export const MobileTableContentBox = styled(Grid)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "5px 0px",
  cursor: "pointer",
  textAlign: "center",
});

export const MobileTableContentDetailBox = styled(Grid)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "5px",
  textAlign: "center",
});

export const TableContentsBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  height: "40px",
  cursor: "pointer",
});

export const TextOverflowBox = styled(Grid)({
  width: "100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const CustomFileUpload = styled("label")({
  backgroundColor: `#F0FAF7`,
  color: "#47B998",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  cursor: "pointer",
  fontWeight: 600,
  border: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  paddingTop: "7px",
  "&:hover": {
    backgroundColor: "#E4F7F1",
    boxShadow: "none",
    color: "#47B998",
  },
});

export const DisabledImgBox = styled("input")(() => ({
  width: "0",
  height: "0",
  padding: "0",
  overflow: "hidden",
  border: "0",
}));

export const TableBorderBox = styled(Grid)({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
  borderLeft: `1px solid ${GRAYPALEBORDER}`,
});

export const MainTableChip = styled(Grid)({
  borderRadius: "20px",
  backgroundColor: PRIMARY,
  color: "#ffffff",
  width: "50px",
  textAlign: "center",
  fontSize: "14px",
  height: "22px",
});

export const TableTypeChip = styled(Grid)({
  borderRadius: "20px",
  border: `1px solid ${GRAYPALEBORDER}`,
  width: "50px",
  color: GRAYPALECONTENTS,
  textAlign: "center",
  fontSize: "14px",
  height: "22px",
  backgroundColor: "#ffffff",
});

export const LeadTableTitleHover = styled(Grid)({
  padding: "10px",
  "&:hover": {
    backgroundColor: BLUEBACKGROUND,
    cursor: "pointer",
    borderRadius: "8px",
  },
});

export const LeadTableTitle = styled(Grid)({
  textAlign: "right",
});

export const LeadTableContent = styled(Grid)({
  wordBreak: "break-all",
});

export const LeadTableContentsBox = styled(Grid)({
  borderTop: "1px solid #DCDFE3",
  padding: "10px 0px",
});

export const LeadReplyBox = styled(Grid)({
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  marginTop: "10px",
  paddingLeft: "5px",
  paddingBottom: "10px",
});

export const LeadTableCardBox = styled(Grid)({
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  width: "100%",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
});

export const LeadTableCardBoxScroll = styled(Grid)({
  border: "1px solid #DCDFE3",
  padding: "10px 0px",
  marginTop: "20px",
  borderRadius: "8px",
  width: "100vw",
  maxWidth: "100%",
  overflow: "scroll",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
});

export const TableFixedHeight = styled(Grid)({
  minHeight: "30px",
});

export const LeadTableTitlBox = styled(Grid)({
  paddingBottom: "10px",
  borderBottom: "1px solid #DCDFE3",
});

export const LeadStepperBox = styled(Grid)({
  width: "100vw",
  maxWidth: "100%",
  overflow: "scroll",
});
