import styled from "@emotion/styled";

//MUI
import {
  AppBar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Popover,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

//MUI icon
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";

//user component
import { NavLink, useHistory } from "react-router-dom";
import { MenuButton, TextOverflowBox, Title } from "../../styles/theme";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  BoardPageNumber,
  MyInfoState,
  NotiDataState,
  NotiIdState,
  leftDrawerOpenState,
} from "../../system/atoms";
import { CustomText } from "../../styles/CustomText";
import PersistentDrawerLeft from "./PersistentDrawerLeft";
import { useCallback, useEffect, useState } from "react";
import { Urls } from "../../route/Urls";
import { TabletPc } from "../../components/Common/Responseive/ResponsiveScreen";
import { NotificationInfo } from "../../system/types/Notification";
import { NotificationApi } from "../../system/api/notificationApi";
import { ErrorHandler } from "../../system/ApiService";
import { AlertModal } from "../../components/Common/AlertModal";
import { GRAYPALEDISABLED, PRIMARY } from "../../styles/Color";
import { useTranslation } from "react-i18next";
import { InitMyInfo } from "../../system/types/initObject";
function Header() {
  const history = useHistory();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const setMenuOpen = useSetRecoilState(leftDrawerOpenState);

  const [myInfoState, setMyInfoState] = useRecoilState(MyInfoState);

  const handleDrawerOpen = () => {
    setMenuOpen((pre) => !pre);
  };
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeLogout = () => {
    setAnchorEl(null);
    localStorage.clear();
    setMyInfoState(InitMyInfo);
    history.push({
      pathname: `${Urls.login}`,
    });
  };

  // notification
  const [notificationData, setNotificationData] = useRecoilState(NotiDataState);
  const [notifiIdData, setNotifiIdData] = useRecoilState(NotiIdState);
  const [NotiAnchorEl, setNotiAnchorEl] = useState<null | HTMLElement>(null);
  const [, setPageNumber] = useRecoilState(BoardPageNumber);
  const notiOpen = Boolean(NotiAnchorEl);
  const notiId = notiOpen ? "simple-popover" : undefined;
  const handleNotiClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotiAnchorEl(event.currentTarget);
  };

  const handleNotiClose = () => {
    setNotiAnchorEl(null);
  };

  // token 있으면
  const existedToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (existedToken) {
      NotificationApi.getMyNotification("all", 0, 2000000000)
        .then((res) => {
          setNotificationData(
            res.data.notifications.filter(
              (item: NotificationInfo) => !item.isRead
            )
          );
          setNotifiIdData(
            res.data.notifications.map(
              (item: NotificationInfo) => item.messageId
            )
          );
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [existedToken, setNotificationData, setNotifiIdData]);

  const onChangeNotiRead = () => {
    NotificationApi.updateReadState(notifiIdData)
      .then(() => {
        AlertModal("success", "알림이 모두 읽음 처리되었습니다.");
        setNotiAnchorEl(null);
        setNotificationData([]);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };
  const moveDetailPage = (path: string, messageId: string) => {
    NotificationApi.updateReadState([messageId])
      .then(() => {
        history.push(`/${path}`);
        setNotiAnchorEl(null);
        setNotificationData(
          notificationData.filter(
            (item: NotificationInfo) => item.messageId !== messageId
          )
        );
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  const handleDbClickCopyToken = useCallback(() => {
    if (
      process.env.NODE_ENV === "development" ||
      (process.env.NODE_ENV === "production" &&
        process.env.REACT_APP_APP_ENV === "pipeline_dev")
    ) {
      const token = `${localStorage.getItem("accessToken")}`;
      navigator.clipboard.writeText(token);
    } else return;
  }, []);

  return (
    <Box>
      <AppBar color="inherit" position="fixed">
        <HeaderContentWrapper>
          <SideMenu>
            <MenuButton>
              <IconButton
                edge="start"
                onClick={handleDrawerOpen}
                color="inherit"
                aria-label="menu"
                size="large"
              >
                <MenuIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </MenuButton>
            <NavLink to="/">
              <img
                src="/images/inbody_logo.png"
                width="100px"
                alt="title"
              ></img>
            </NavLink>
            <TabletPc>
              <Title>
                <CustomText
                  type="title"
                  ml={"10px"}
                  bold500
                  onClick={handleDbClickCopyToken}
                >
                  {t("Pipeline")}
                </CustomText>
              </Title>
            </TabletPc>
          </SideMenu>
          <Grid>
            <IconButton color="inherit" onClick={handleNotiClick}>
              <Badge
                overlap="rectangular"
                badgeContent={notificationData.length}
                max={99}
                color="warning"
              >
                <NotificationsIcon sx={{ fontSize: 30 }} />
              </Badge>
            </IconButton>
            <Popover
              id={notiId}
              open={notiOpen}
              anchorEl={NotiAnchorEl}
              onClose={handleNotiClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Grid style={{ height: "350px" }}>
                <Grid
                  style={{
                    width: "300px",
                    height: "300px",
                    margin: "10px",
                    textAlign: "center",
                    overflow: "scroll",
                  }}
                >
                  {notificationData.map((noti: NotificationInfo) => (
                    <Grid
                      style={{ marginTop: "5px", cursor: "pointer" }}
                      key={noti.messageId.toString()}
                      onClick={() => moveDetailPage(noti.path, noti.messageId)}
                    >
                      <Grid> {noti.title}</Grid>
                      <TextOverflowBox>{noti.body}</TextOverflowBox>
                      <Divider style={{ marginTop: "5px" }} />
                    </Grid>
                  ))}
                  {notificationData.length === 0 && (
                    <Grid mt="130px">{t("No Notification History")}</Grid>
                  )}
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  padding="0px 10px"
                >
                  <CustomText
                    color="#000000"
                    cursorPointer
                    onClick={() => {
                      history.push("/list/noti");
                      setNotiAnchorEl(null);
                      setPageNumber(1);
                    }}
                    style={{ textDecoration: "underline" }}
                  >
                    {t("View History")}
                  </CustomText>
                  {notificationData.length === 0 ? (
                    <CustomText color={GRAYPALEDISABLED}>
                      {t("Mark All as Read")}
                    </CustomText>
                  ) : (
                    <CustomText
                      color={PRIMARY}
                      cursorPointer
                      onClick={onChangeNotiRead}
                    >
                      {t("Mark All as Read")}
                    </CustomText>
                  )}
                </Grid>
              </Grid>
            </Popover>
            <IconButton onClick={handleMenu} color="inherit">
              <AccountCircle sx={{ fontSize: 30 }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorEl)}
              onClick={handleClose}
            >
              <MenuItem onClick={onChangeLogout}> {t("Logout")}</MenuItem>
            </Menu>
          </Grid>
        </HeaderContentWrapper>
      </AppBar>
      <PersistentDrawerLeft />
    </Box>
  );
}

export default Header;

/** Desktop */
const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 15px;
  height: 64px;
  width: 100vw;
`;
const SideMenu = styled.div`
  padding-left: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
