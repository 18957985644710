import { Grid } from "@mui/material";
import {
  MeetingNoteReplyBox,
  MeetingNoteTextarea,
} from "../../../../styles/byCaseStyle";
import { CustomButtonGreen } from "../../../../styles/buttonStyle";

interface CommentInputBoxProps {
  onSaveReply: () => void;
  replyContent: string;
  setReplyContent: (replyContent: string) => void;
}

function CommentInputBox({
  replyContent,
  setReplyContent,
  onSaveReply,
}: CommentInputBoxProps) {
  const onChangeReply = (args: any) => {
    setReplyContent(args.target.value);
  };
  return (
    <MeetingNoteReplyBox container>
      <Grid item xs={12}>
        <MeetingNoteTextarea
          onChange={onChangeReply}
          placeholder="답변을 작성해 주세요."
          value={replyContent}
        />
      </Grid>
      <Grid container justifyContent="right" alignItems="center">
        <CustomButtonGreen onClick={onSaveReply}>저장</CustomButtonGreen>
      </Grid>
    </MeetingNoteReplyBox>
  );
}

export default CommentInputBox;
