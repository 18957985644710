import ReportHeader from "./components/ReportHeader";
import SwitchReportContent from "./components/SwitchReportContent";

const ReportPage = () => {
  return (
    <>
      <ReportHeader />
      <SwitchReportContent />
    </>
  );
};
export default ReportPage;
