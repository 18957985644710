import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GRAYPALEACCENT, GRAYPALETITLE, TETIARY } from "./Color";

// LandingMain

export const LoginPageHeight = styled(Grid)({
  height: "100%",
  minHeight: "100vh",
});

export const LoginPageLeftBox = styled(Grid)({
  backgroundColor: GRAYPALEACCENT,
  height: "100%",
  padding: "10px",
});

export const LoginPageRightBox = styled(Grid)({
  backgroundColor: GRAYPALEACCENT,
  height: "100%",
  minHeight: "100vh",
});

export const LoginPageRightModalBox = styled(Grid)({
  backgroundColor: TETIARY,
  width: "100%",
  maxWidth: "650px",
  borderRadius: "8px",
  height: "80%",
  overflow: "scroll",
});

export const LoginPageIconBox = styled(Grid)({
  margin: "10px",
  cursor: "pointer",
});

export const LoginPageIconButton = styled(Grid)({
  backgroundColor: GRAYPALETITLE,
  borderRadius: "50%",
  width: "60px",
  height: "60px",
  marginBottom: "5px",
  color: "#ffffff",
});

export const LoginBoxMainBox = styled(Grid)({
  width: "100%",
  maxWidth: "600px",
  padding: "10px",
});
