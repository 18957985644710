import { Dispatch } from "react";
import { LeadCustomerCreateInfo } from "../../../../../system/types/Customer";

import {
  DropDownTreeComponentBox,
  EditDropDownListComponentBox,
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  TableFixedHeight,
} from "../../../../../styles/theme";
import { Grid } from "@mui/material";
import { CustomText } from "../../../../../styles/CustomText";
import { GRAYPALECONTENTS } from "../../../../../styles/Color";
import { useRecoilValue } from "recoil";
import {
  ManagedGroupDataState,
  MyInfoState,
} from "../../../../../system/atoms";
import { GroupApi } from "../../../../../system/api/groupApi";
import { EmployeeTableInfo } from "../../../../../types/Employee/Employee";
import { GroupInfo } from "../../../../../types/Group/group";

interface EditBranchProps {
  data: LeadCustomerCreateInfo;
  setData: Dispatch<React.SetStateAction<LeadCustomerCreateInfo>>;
  employeeList: EmployeeTableInfo[];
  setEmployeeList: (dt: EmployeeTableInfo[]) => void;
}

function EditLeadBranch({
  data,
  setData,
  employeeList,
  setEmployeeList,
}: EditBranchProps) {
  const groupData = useRecoilValue<GroupInfo[]>(ManagedGroupDataState);
  const myInfoData = useRecoilValue(MyInfoState);
  const onChange = (args: any) => {
    if (args.target.itemData?.id) {
      setData({
        ...data,
        sellerId: args.target.itemData.id,
      });
    } else {
      setData({
        ...data,
        sellerId: null,
      });
    }
  };

  const findObjectByID = (
    id: number,
    tree: GroupInfo[]
  ): GroupInfo | undefined => {
    for (const node of tree) {
      if (node.id === id) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const foundInChildren = findObjectByID(id, node.children);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
  };

  const onChangeTree = (args: any) => {
    if (args.value && args.value.length > 0) {
      const selectedValue = Number(args.value[0]);
      if (selectedValue) {
        GroupApi.findGroupEmployee(selectedValue).then((employeeRes) => {
          setEmployeeList(employeeRes.data);
          if (employeeRes.data.length > 0) {
            setData({
              ...data,
              groupId: selectedValue,
              sellerId: employeeRes.data[0].id,
            });
          } else {
            setData({
              ...data,
              groupId: selectedValue,
              sellerId: null,
            });
          }
        });
      }
    }
  };

  // 영업 담당자

  const employeeFields: Object = {
    value: "id",
    text: "name",
  };

  let groupfields: Object = {
    dataSource: groupData,
    value: "id",
    text: "name",
    child: "children",
  };
  return (
    <LeadTableCardBox style={{ marginTop: "20px" }}>
      <Grid container item xs={12} padding="10px">
        <CustomText type="subtitle">지사 정보</CustomText>
      </Grid>
      <LeadTableContentsBox container item xs={12}>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              지사
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8} padding="0px 10px">
            <DropDownTreeComponentBox
              colorvalue={data.groupId ? "#000000" : "#B2B4B8"}
              fields={groupfields}
              changeOnBlur={false}
              change={onChangeTree}
              placeholder={
                myInfoData?.groups.length > 0
                  ? myInfoData?.groups[0]?.name
                  : "지사를 선택해주세요."
              }
              showClearButton={false}
              autoComplete="new-password"
            />
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle container justifyContent="right" item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              영업 담당자
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent container item xs={8} padding="0px 10px">
            <EditDropDownListComponentBox
              showClearButton={true}
              dataSource={employeeList}
              fields={employeeFields}
              name="seller"
              value={data?.sellerId || null}
              onChange={onChange}
              placeholder={"영업 담당자를 선택해주세요."}
              autoComplete="new-password"
            />
          </LeadTableContent>
        </TableFixedHeight>
      </LeadTableContentsBox>
    </LeadTableCardBox>
  );
}

export default EditLeadBranch;
