import { Grid, IconButton, styled, Tooltip } from "@mui/material";
import {
  DropDownListComponentBox,
  DropDownTreeComponentBox,
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  LeadTableTitleHover,
  TableFixedHeight,
} from "../../../../../styles/theme";
import { CustomText } from "../../../../../styles/CustomText";
import {
  LeadCustomerInfo,
  SectionStatesInfo,
} from "../../../../../system/types/Customer";
import {
  GRAYPALEBORDER,
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  GREENTITLE,
  REDTITLE,
} from "../../../../../styles/Color";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import { GroupApi } from "../../../../../system/api/groupApi";

import { useRecoilValue } from "recoil";
import { ManagedGroupDataState } from "../../../../../system/atoms";
import { CustomerApi } from "../../../../../system/api/customerApi";
import { ErrorHandler } from "../../../../../system/ApiService";
import { AlertModal } from "../../../../../components/Common/AlertModal";
import { EmployeeTableInfo } from "../../../../../types/Employee/Employee";
import { GroupInfo } from "../../../../../types/Group/group";
interface LeadBranchProps {
  data: LeadCustomerInfo | null;
  refreshData: () => void;
  toggleSection: (title: keyof SectionStatesInfo) => void;
  sectionStates: SectionStatesInfo;
}
interface EditDataProps {
  editState: boolean;
  title: string;
  content: number | string | null | undefined;
}
function LeadBranch({
  data,
  refreshData,
  toggleSection,
  sectionStates,
}: LeadBranchProps) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [editData, setEditData] = useState<EditDataProps>({
    editState: false,
    title: "",
    content: null,
  });

  const openEdit = (type: string) => {
    const existingInfo =
      type === "sellerId"
        ? data?.seller?.id
        : type === "groupId"
        ? data?.group?.id
        : "";

    setEditData({ editState: true, title: type, content: existingInfo });
  };

  var submitcheck = false;
  const onSave = () => {
    if (submitcheck === false && data?.id) {
      submitcheck = true;

      CustomerApi.updateCustomerSingleFieldInfo(
        data.id,
        editData.title,
        typeof editData.content === "string"
          ? { value: editData.content }
          : editData.content
      )
        .then(() => {
          refreshData();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        })
        .finally(() => {
          submitcheck = false;
        });
    }
  };

  const onCancel = () => {
    setEditData({ editState: false, title: "", content: null });
  };
  const [employeeList, setEmployeeList] = useState<EmployeeTableInfo[]>([]);
  const onChangeTree = (args: any) => {
    if (args.value && args.value.length > 0) {
      const selectedValue = args.value[0];
      setEditData({ ...editData, content: Number(selectedValue) });
    }
  };

  // 영업 담당자
  const groupData = useRecoilValue<GroupInfo[]>(ManagedGroupDataState);
  const employeeFields: Object = {
    value: "id",
    text: "name",
  };

  let groupfields: Object = {
    dataSource: groupData,
    value: "id",
    text: "name",
    child: "children",
  };

  const onChange = (args: any) => {
    if (args.target.itemData) {
      setEditData({
        ...editData,
        content: args.target.itemData.id,
      });
    } else {
      setEditData({
        ...editData,
        content: null,
      });
    }
  };

  const getEmployee = () => {
    if (data?.group.id) {
      GroupApi.findGroupEmployee(data?.group.id).then((employeeRes) => {
        setEmployeeList(employeeRes.data);
      });
    }
  };

  return (
    <LeadTableCardBox style={{ marginTop: "20px" }}>
      <LeadTableTitleHover
        container
        item
        xs={12}
        onClick={() => toggleSection("LeadBranch")}
        justifyContent="space-between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CustomText type="subtitle">지사 정보</CustomText>
        {isHovered ? (
          <>{sectionStates["LeadBranch"] ? <ExpandLess /> : <ExpandMore />}</>
        ) : (
          ""
        )}
      </LeadTableTitleHover>

      {sectionStates["LeadBranch"] && (
        <LeadTableContentsBox container item xs={12}>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                지사
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "groupId" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownTreeComponentBox
                    fields={groupfields}
                    changeOnBlur={false}
                    change={onChangeTree}
                    placeholder={"지사를 선택해주세요."}
                    showClearButton={false}
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("groupId")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.group?.name ? data?.group?.name : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
          <TableFixedHeight container item xs={12} sm={6} alignItems="center">
            <LeadTableTitle item xs={4} pr="10px">
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALECONTENTS}
                lineSpace
              >
                영업 담당자
              </CustomText>
            </LeadTableTitle>
            {editData.editState && editData.title === "sellerId" ? (
              <Grid
                container
                item
                xs={8}
                pl="10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sx={{ width: "calc( 100% - 60px )" }}>
                  <DropDownListComponentBox
                    showClearButton={true}
                    dataSource={employeeList}
                    fields={employeeFields}
                    name="sellerId"
                    value={data?.seller?.id || null}
                    onChange={onChange}
                    beforeOpen={() => getEmployee()}
                    placeholder={"영업 담당자를 선택해주세요."}
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item sx={{ width: "60px" }}>
                  <Tooltip title="저장">
                    <IconButton onClick={onSave}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 14, color: GREENTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="취소">
                    <IconButton onClick={onCancel}>
                      <HighlightOffIcon
                        sx={{ fontSize: 14, color: REDTITLE }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <LeadTableContentStyled
                item
                xs={8}
                pl="10px"
                onClick={() => openEdit("sellerId")}
              >
                <CustomText
                  type="subtitle"
                  bold400
                  color={GRAYPALETITLE}
                  lineSpace
                  cursorPointer
                >
                  {data?.seller?.name ? data?.seller.name : "-"}
                </CustomText>
                <EditIconStyled className="editIcon" />
              </LeadTableContentStyled>
            )}
          </TableFixedHeight>
        </LeadTableContentsBox>
      )}
    </LeadTableCardBox>
  );
}

export default LeadBranch;

const LeadTableContentStyled = styled(LeadTableContent)`
  position: relative;
  &:hover {
    cursor: pointer;
    border: 1px solid ${GRAYPALEBORDER};
    border-radius: 8px;
    & .editIcon {
      display: block;
    }
  }
`;

const EditIconStyled = styled(EditIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
`;
