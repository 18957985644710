import Swal from "sweetalert2";
import { GREENCONTENTS } from "../../styles/Color";

export const memoModal = (message?: string, content?: string) => {
  return Swal.fire({
    icon: "success",
    title:
      "<div style='font-family:Noto Sans KR;font-size:20px;margin-bottom:10px'>" +
      message +
      "</div>",
    text: content,
    confirmButtonText: "확인",
    confirmButtonColor: GREENCONTENTS,
    timer: 1500,
  });
};
