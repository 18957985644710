import { Grid } from "@mui/material";
import Message from "../../../../../components/Common/Message/message";
import { CustomText } from "../../../../../styles/CustomText";
import AdminGroupInfoList from "./AdminGroupInfoList";
import { GroupInfo } from "../../../../../types/Group/group";
import { GroupGoalInfo } from "../../../../../types/DashBoard/Achievement";

type AdminGroupInfoProps = {
  groupInfo: GroupInfo;
  errorMessage: string;
  groupGoalInfo: GroupGoalInfo;
};
const AdminGroupInfo = ({
  groupInfo,
  errorMessage,
  groupGoalInfo,
}: AdminGroupInfoProps) => {
  return errorMessage.length > 0 ? (
    <Message text={errorMessage} />
  ) : !groupInfo.id ? (
    <Grid container justifyContent="center" margin="10px">
      <CustomText type="subtitle" textAlign="center" color="#3D4861">
        해당 정보가 없습니다.
      </CustomText>
    </Grid>
  ) : (
    <AdminGroupInfoList groupInfo={groupInfo} groupGoalInfo={groupGoalInfo} />
  );
};

export default AdminGroupInfo;
