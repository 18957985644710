import client from "../ApiService";
import { _BasicApi } from "../BasicApi";
import { TagListInfo } from "../types/List";

const listUri: string = "/tag";

class _TagApi extends _BasicApi {
  getTagList(searchText?: string) {
    return this.client.get(`${listUri}`, {
      params: { searchText: searchText },
    });
  }
  updateTagList(data: TagListInfo) {
    return this.client.put(`${listUri}`, data);
  }
  createTagList(data: TagListInfo) {
    return this.client.post(`${listUri}`, data);
  }
  deleteTagList(id: number) {
    return this.client.delete(`${listUri}`, { params: { id: id } });
  }
}

export const TagApi = new _TagApi(client);
