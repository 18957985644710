// Primary
export const PRIMARY = "#971B2F";
export const PRIMARYHOVERED = "#7D1628";
export const PRIMARYPRESSED = "#63121F";
export const PRIMARYDISABLED = "rgba(151, 27, 47,0.3)";

// Tetiary (Border)
export const TETIARY = "#FFFFFF";
export const TETIARYHOVERED = "#F0F2F5";
export const TETIARYPRESSED = "#E9ECF0";
export const TETIARYDISABLED = "#B3BBC5";

// Secondary
export const SECONDARY = "#5B6770";

// Gray Neutral
export const GRAYNeutralACCENT = "#252933";
export const GRAYNeutralTITLE = "#4B4F5A";
export const GRAYNeutralCONTENTS = "#81848B";
export const GRAYNeutralDISABLED = "#B2B4B8";
export const GRAYNeutralSTROKE = "#CFD0D1";
export const GRAYNeutralBORDER = "#E0E0E0";
export const GRAYNeutralBOX = "#EDEDED";
export const GRAYNeutralBACKGROUND2 = "#F4F4F4";
export const GRAYNeutralBACKGROUND1 = "#F8F8F8";

// Gray Pale
export const GRAYPALEACCENT = "#182031";
export const GRAYPALETITLE = "#3D4861";
export const GRAYPALECONTENTS = "#718093";
export const GRAYPALEDISABLED = "#B3BBC5";
export const GRAYPALESTROKE = "#C7CBD1";
export const GRAYPALEBORDER = "#DCDFE3";
export const GRAYPALEBOX = "#E9ECF0";
export const GRAYPALEBACKGROUND2 = "#F0F2F5";
export const GRAYPALEBACKGROUND1 = "#F7F8FA";

// RED
export const REDTITLE = "#CC1F3B";
export const REDCONTENTS = "#F25C75";
export const REDDISABLED = "#F78194";
export const REDSTROKE = "#FCB6C3";
export const REDBORDER = "#FFD4DC";
export const REDBOX = "#FFE6EB";
export const REDBACKGROUND = "#FFF0F3";

// GREEN
export const GREENACCENT = "#0A6649";
export const GREENTITLE = "#0E8A65";
export const GREENCONTENTS = "#47B998";
export const GREENDISABLED = "#70CCAF";
export const GREENSTROKE = "#9FE0CC";
export const GREENBORDER = "#CAEDE2";
export const GREENBOX = "#E4F7F1";
export const GREENBACKGROUND = "#F0FAF7";

// BLUE
export const BLUEACCENT = "#124FB3";
export const BLUETITLE = "#1E66D9";
export const BLUECONTENTS = "#4C8DF5";
export const BLUEDISABLED = "#7CABF7";
export const BLUESTROKE = "#B6D0FC";
export const BLUEBORDER = "#D4E4FF";
export const BLUEBOX = "#E6EFFF";
export const BLUEBACKGROUND = "#F0F6FF";

// ORANGE
export const ORANGEACCENT = "#A65800";
export const ORANGETITLE = "#D47100";
export const ORANGECONTENTS = "#EF8100";
export const ORANGEDISABLED = "#FFA947";
export const ORANGESTROKE = "#FFCD94";
export const ORANGEBORDER = "#FFDCB8";
export const ORANGEBOX = "#FFEAD1";
export const ORANGEBACKGROUND = "#FCF1E3";

// 새로운 색상 추가
// Primary Colors
// Base
export const BaseWhite = "#FFFFFF";
export const BaseBlack = "#000000";

// Gray
export const Gray25 = "#F6F7F9";
export const Gray50 = "#EEEFF3";
export const Gray100 = "#E2E4E9";
export const Gray200 = "#C8CBD3";
export const Gray300 = "#AEB3BE";
export const Gray400 = "#949AA8";
export const Gray500 = "#7B818F";
export const Gray600 = "#636875";
export const Gray700 = "#4B4F5A";
export const Gray800 = "#34363E";
export const Gray900 = "#1C1D21";

// Brand
export const Brand25 = "#FBF3F5";
export const Brand50 = "#F7E3E7";
export const Brand100 = "#EDC0C7";
export const Brand200 = "#E29CA7";
export const Brand300 = "#D77584";
export const Brand400 = "#CB4D61";
export const Brand500 = "#B23448";
export const Brand600 = "#971B2F";
export const Brand700 = "#751524";
export const Brand800 = "#570F1B";
export const Brand900 = "#410C14";

// Secondary Colors
// Orange
export const Orange25 = "#FEF3EC";
export const Orange50 = "#FDEBDD";
export const Orange100 = "#FBD0B1";
export const Orange200 = "#F9B686";
export const Orange300 = "#F69855";
export const Orange400 = "#F47B25";
export const Orange500 = "#DA620B";
export const Orange600 = "#AA4C09";
export const Orange700 = "#833B07";
export const Orange800 = "#572705";
export const Orange900 = "#3A1A03";

// Yellow
export const Yellow25 = "#FEF9EC";
export const Yellow50 = "#FDF5DD";
export const Yellow100 = "#FBE9B1";
export const Yellow200 = "#F9DC86";
export const Yellow300 = "#F6CE55";
export const Yellow400 = "#F4C025";
export const Yellow500 = "#DAA60B";
export const Yellow600 = "#AA8109";
export const Yellow700 = "#836407";
export const Yellow800 = "#574305";
export const Yellow900 = "#3A2C03";

// Green
export const Green25 = "#F0FAF7";
export const Green50 = "#E4F6F2";
export const Green100 = "#C2EBE0";
export const Green200 = "#9FDFCF";
export const Green300 = "#79D2BC";
export const Green400 = "#53C6A9";
export const Green500 = "#39AC8F";
export const Green600 = "#2D8670";
export const Green700 = "#226756";
export const Green800 = "#174539";
export const Green900 = "#0F2E26";

// Blue
export const Blue25 = "#F0F6FA";
export const Blue50 = "#E4EEF6";
export const Blue100 = "#C2DAEB";
export const Blue200 = "#9FC5DF";
export const Blue300 = "#79ADD2";
export const Blue400 = "#5396C6";
export const Blue500 = "#397CAC";
export const Blue600 = "#2D6186";
export const Blue700 = "#224B67";
export const Blue800 = "#173245";
export const Blue900 = "#0F212E";

// Purple
export const Purple25 = "#F3F1F9";
export const Purple50 = "#EAE6F5";
export const Purple100 = "#CEC6E7";
export const Purple200 = "#B2A6D9";
export const Purple300 = "#9482C9";
export const Purple400 = "#755EBA";
export const Purple500 = "#5C45A1";
export const Purple600 = "#47367D";
export const Purple700 = "#372960";
export const Purple800 = "#251C40";
export const Purple900 = "#18122B";

// Semantic Colors
export const ErrorText = "#F04438";
export const ErrorBackground = "#FDE4E3";

export const WarningText = "#F79009";
export const WarningBackground = "#FEF2E1";

export const SuccessText = "#17B26A";
export const SuccessBackground = "#E4FBF1";

export const WaitingText = "#1782CF";
export const WaitingBackground = "#E3F2FC";
