import { Grid } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import {
  CustomButtonBlue,
  CustomButtonGreen,
  CustomButtonYellow,
} from "../../../styles/buttonStyle";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { useState } from "react";
import { CustomerApi } from "../../../system/api/customerApi";
import { ErrorHandler } from "../../../system/ApiService";
import { AlertModal } from "../../../components/Common/AlertModal";
import { CustomFileUpload, DisabledImgBox } from "../../../styles/theme";
import ValidationDataTable from "./components/ValidationDataTable";
import { FileValidInfo } from "../../../system/types/Customer";
import { useRecoilState } from "recoil";
import { LoadingAtom } from "../../../system/atoms";
import { AxiosResponse } from "axios";

interface ValidListProps {
  id: number;
  data: FileValidInfo;
}

function WithExcelPage() {
  const [data, setData] = useState<FileValidInfo[]>([]);
  const [uploadFileData, setUploadFileData] = useState<FormData | null>(null);
  const [pageState, setPageState] = useState(0);
  const [validList, setValidList] = useState<ValidListProps[]>([]);
  const [, setLoadingSpinnerState] = useRecoilState(LoadingAtom);
  const [orderOpenState, setOrderOpenState] = useState<number[]>([]);
  var submitcheck = false;
  const onSave = () => {
    if (submitcheck === false) {
      setLoadingSpinnerState(true);
      const saveData = validList.map((item) => item.data);
      CustomerApi.createMultipleCustomer(saveData)
        .then(() => {
          submitcheck = false;
          setPageState(0);
          AlertModal(
            "success",
            `입력하신 Pipeline ${data.length}건 중 ${validList.length}건이 등록되었습니다.`
          );
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        })
        .finally(() => {
          setLoadingSpinnerState(false);
        });
    }
  };

  function TemplateDownload(type: string) {
    CustomerApi.getFileTemplate(type)
      .then((res) => {
        // 받아온 데이터를 Blob으로 변환
        const blob = new Blob([res.data], { type: "application/octet-stream" });

        // Blob을 파일로 다운로드
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Pipeline 양식.xlsx"; // 다운로드될 파일 이름 설정
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }

  function UploadValidationFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files !== null) {
      setLoadingSpinnerState(true);
      const formData = new FormData();
      formData.append("uploadFile", event.target.files[0]);
      setUploadFileData(formData);
      CustomerApi.getFileValidation(formData, "json")
        .then((res) => {
          setData(res.data);
          const newList = Array.from(
            { length: res.data.length },
            (_, index) => index
          );
          setOrderOpenState(newList);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          setPageState(1);
          setLoadingSpinnerState(false);
        });
    }
  }

  const injectFilename = (res: AxiosResponse<any, any>) => {
    var filename = "";
    var disposition = res.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    return decodeURI(filename);
  };

  function downloadBlobData() {
    if (uploadFileData) {
      setLoadingSpinnerState(true);
      CustomerApi.getFileValidation(uploadFileData, "file")
        .then((res) => {
          const blob = new Blob([res.data]);
          const url = window.URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = injectFilename(res); // 다운로드될 파일 이름
          document.body.appendChild(downloadLink);
          downloadLink.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(downloadLink);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          setLoadingSpinnerState(false);
        });
    }
  }

  function onChangePageState() {
    setPageState(0);
    setData([]);
    setUploadFileData(null);
  }

  return (
    <>
      <CustomText type="title">엑셀 등록</CustomText>
      {pageState === 0 && (
        <>
          <Grid container justifyContent="right" mt="5px">
            <CustomButtonYellow
              style={{ marginRight: "5px" }}
              onClick={() => TemplateDownload("normal")}
            >
              양식 다운로드
            </CustomButtonYellow>
            <Grid>
              <CustomFileUpload htmlFor={"file"}>
                <Grid container justifyContent="center">
                  파일 업로드
                </Grid>
              </CustomFileUpload>
              <DisabledImgBox
                id={"file"}
                type="file"
                onChange={(e) => UploadValidationFile(e)}
              ></DisabledImgBox>
            </Grid>
          </Grid>
          <Grid item xs={12} mt="5px">
            <MessageComponent content="엑셀 등록 시, '양식 다운로드'는 항상 새로 다운받아 사용해 주세요." />
          </Grid>
          <Grid item xs={12} mt="5px">
            <MessageComponent content="양식 다운로드 후 작성을 완료한 뒤, 업로드 버튼을 클릭하여 제출해 주세요." />
          </Grid>
        </>
      )}
      {pageState === 1 && (
        <>
          <Grid container justifyContent="space-between">
            <Grid mt="5px" item xs={12} sm={6}>
              <CustomButtonBlue onClick={onChangePageState}>
                처음으로
              </CustomButtonBlue>
            </Grid>
            <Grid container justifyContent="right" mt="5px" item xs={12} sm={6}>
              <CustomButtonYellow
                style={{ marginRight: "5px" }}
                onClick={downloadBlobData}
              >
                결과 파일 받기
              </CustomButtonYellow>
              <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
            </Grid>
          </Grid>
          <Grid item xs={12} mt="5px">
            <MessageComponent content="유효성 검사를 통과한 Pipeline 데이터만 선택하실 수 있습니다. 저장할 Pipeline을 선택해 저장 버튼을 눌러주세요. " />
          </Grid>
          <ValidationDataTable
            data={data}
            validList={validList}
            setValidList={setValidList}
            orderOpenState={orderOpenState}
            setOrderOpenState={setOrderOpenState}
          />
        </>
      )}
    </>
  );
}

export default WithExcelPage;
