import { Grid } from "@mui/material";
import { IntermediaryCompanyInfo } from "../../../../system/types/Customer";
import {
  EmptyTableBolder,
  TableBorderBox,
  TableContentBox,
  TableTitleBox,
} from "../../../../styles/theme";
import uuid from "react-uuid";
import { useHistory } from "react-router-dom";

interface IntermediaryTableListPcProps {
  data: IntermediaryCompanyInfo[];
}

function IntermediaryTableListPc({ data }: IntermediaryTableListPcProps) {
  const history = useHistory();
  function onMove(id: number | undefined) {
    if (id) {
      history.push(`/list/detail/${id}`);
    }
  }
  return (
    <>
      <TableBorderBox container mt="10px">
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={3}
        >
          상호
        </TableTitleBox>
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={4}
        >
          주소
        </TableTitleBox>
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={3}
        >
          상세주소
        </TableTitleBox>
        <TableTitleBox
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={2}
        >
          영업 담당자
        </TableTitleBox>

        {data.map((dt: IntermediaryCompanyInfo, index: number) => (
          <Grid
            container
            key={uuid()}
            sx={{ cursor: "pointer" }}
            onClick={() => onMove(dt?.id)}
          >
            <TableContentBox
              item
              xs={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              {dt.address?.placeName}
            </TableContentBox>
            <TableContentBox
              item
              xs={4}
              container
              justifyContent="center"
              alignItems="center"
            >
              {dt.address?.road}
            </TableContentBox>
            <TableContentBox
              item
              xs={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              {dt.address?.detail}
            </TableContentBox>
            <TableContentBox
              item
              xs={2}
              container
              justifyContent="center"
              alignItems="center"
            ></TableContentBox>
          </Grid>
        ))}
        {data.length === 0 && (
          <EmptyTableBolder
            container
            justifyContent="center"
            alignItems="center"
          >
            조회된 데이터가 없습니다.
          </EmptyTableBolder>
        )}
      </TableBorderBox>
    </>
  );
}

export default IntermediaryTableListPc;
