import { Grid } from "@mui/material";
import {
  TabletMobile,
  PcLogin992,
  PcLogin1200,
} from "../../components/Common/Responseive/ResponsiveScreen";
import { LoginPageHeight } from "../../styles/mainStyle";
import LeftBox from "../Main/components/LeftBox";
import PasswordBox from "./components/PasswordBox";
import PasswordRightBox from "./components/PasswordRightBox";

function PasswordPage() {
  return (
    <div>
      <TabletMobile>
        <LoginPageHeight container justifyContent="center" alignItems="center">
          <PasswordBox />
        </LoginPageHeight>
      </TabletMobile>
      <PcLogin992>
        <Grid container>
          <Grid item xs={6}>
            <LeftBox />
          </Grid>
          <Grid item xs={6}>
            <LoginPageHeight
              container
              justifyContent="center"
              alignItems="center"
            >
              <PasswordBox />
            </LoginPageHeight>
          </Grid>
        </Grid>
      </PcLogin992>
      <PcLogin1200>
        <Grid container>
          <Grid item xs={6}>
            <LeftBox />
          </Grid>
          <Grid item xs={6}>
            <PasswordRightBox />
          </Grid>
        </Grid>
      </PcLogin1200>
    </div>
  );
}

export default PasswordPage;
