import { Grid } from "@mui/material";
import {
  TabletMobile,
  PcLogin992,
  PcLogin1200,
} from "../../components/Common/Responseive/ResponsiveScreen";
import LoginBox from "./components/LoginBox";
import LeftBox from "./components/LeftBox";
import RightBox from "./components/RigitBox";
import { LoginPageHeight } from "../../styles/mainStyle";

function LandingMain() {
  return (
    <div>
      <TabletMobile>
        <LoginPageHeight container justifyContent="center" alignItems="center">
          <LoginBox />
        </LoginPageHeight>
      </TabletMobile>
      <PcLogin992>
        <Grid container>
          <Grid item xs={6}>
            <LeftBox />
          </Grid>
          <Grid item xs={6}>
            <LoginPageHeight
              container
              justifyContent="center"
              alignItems="center"
            >
              <LoginBox />
            </LoginPageHeight>
          </Grid>
        </Grid>
      </PcLogin992>
      <PcLogin1200>
        <Grid container>
          <Grid item xs={6}>
            <LeftBox />
          </Grid>
          <Grid item xs={6}>
            <RightBox />
          </Grid>
        </Grid>
      </PcLogin1200>
    </div>
  );
}

export default LandingMain;
