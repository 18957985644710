import { useEffect, useState } from "react";
import { CustomText } from "../../styles/CustomText";
import { NoticeApi } from "../../system/api/noticeApi";
import { NoticeInfo } from "../../system/types/Board";
import { useRecoilState, useRecoilValue } from "recoil";
import { BoardPageNumber, MyInfoState } from "../../system/atoms";
import { useHistory } from "react-router-dom";
import { ErrorHandler } from "../../system/ApiService";
import { AlertModal } from "../../components/Common/AlertModal";
import { Grid, IconButton, InputBase, Pagination } from "@mui/material";
import { CustomButtonGreen } from "../../styles/buttonStyle";
import NoticeTable from "./components/NoticeTable";
import { SearchBoxComponent } from "../../styles/theme";
import SearchIcon from "@mui/icons-material/Search";
import { EmployeeRole } from "../../types/Employee/Employee";

function NoticePage() {
  const [data, setData] = useState<NoticeInfo[]>([]);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [pageNumber, setPageNumber] = useRecoilState(BoardPageNumber);
  const { role } = useRecoilValue(MyInfoState);
  const history = useHistory();
  function onMoveEdit() {
    history.push(`/notice/edit/`);
  }

  const [preSearchWord, setPreSearchWord] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const onChangeSearch = (e: any) => {
    setPreSearchWord(e.target.value);
  };
  const ClickSearch = () => {
    setSearchWord(preSearchWord);
  };

  const handleOnKeyDownSearch = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      ClickSearch();
    }
  };
  useEffect(() => {
    NoticeApi.searchNotice(pageNumber - 1, 10, searchWord)
      .then((res) => {
        setTotalPageNumber(res.data.totalPages);
        setData(res.data.notices);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [pageNumber, searchWord]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };
  return (
    <>
      <CustomText type="title">공지사항</CustomText>
      <Grid container justifyContent="right" mt="10px">
        <Grid item>
          <SearchBoxComponent>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="검색어를 입력하세요."
              inputProps={{ "aria-label": "검색어를 입력하세요." }}
              onChange={onChangeSearch}
              onKeyDown={handleOnKeyDownSearch}
              autoComplete="new-password"
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={ClickSearch}
            >
              <SearchIcon />
            </IconButton>
          </SearchBoxComponent>
        </Grid>
        {role === EmployeeRole.ADMIN && (
          <Grid item>
            <CustomButtonGreen onClick={onMoveEdit}>
              공지 등록
            </CustomButtonGreen>
          </Grid>
        )}
      </Grid>
      <NoticeTable data={data} />
      <Grid container justifyContent="center" alignItems="center" mt="20px">
        <Pagination
          count={totalPageNumber}
          showFirstButton
          showLastButton
          page={pageNumber}
          boundaryCount={2}
          onChange={handleChange}
          size="large"
        />
      </Grid>
    </>
  );
}

export default NoticePage;
