import client from "../ApiService";
import { _BasicApi } from "../BasicApi";
import { QnaInfo } from "../types/Qna";

const listUri: string = "/qna";

class _QnaApi extends _BasicApi {
  getQna(page: number, size: number, sort: string, searchText?: string) {
    return this.client.get(`${listUri}`, {
      params: { page: page, size: size, sort: sort, searchText: searchText },
    });
  }
  updateQna(data: QnaInfo) {
    return this.client.put(`${listUri}/question`, data);
  }
  createQna(data: QnaInfo) {
    return this.client.post(`${listUri}/question`, data);
  }
  deleteQna(id: number) {
    return this.client.delete(`${listUri}`, { params: { id: id } });
  }
  getQnaDetail(id: number) {
    return this.client.get(`${listUri}/detail`, {
      params: { id: id },
    });
  }
}

export const QnaApi = new _QnaApi(client);
