import { AdminGroupItemBox } from "../../../../../styles/adminStyle";
import AdminEmployeeTable from "./AdminEmployeeTable";
import AdminEmployeeSectionTitle from "./AdminEmployeeSectionTitle";
import { useCallback, useEffect, useState } from "react";
import { GroupApi } from "../../../../../api/Group/groupApi";
import { AxiosError } from "axios";
import { ErrorHandler } from "../../../../../system/ApiService";
import { useGenerateEditColumnList } from "../../../../../hooks/Table/useGenerateColumnList";
import AdminEmployeeAddModal from "./AdminEmployeeAddModal";
import {
  EmployeeGoalResponse,
  EmployeeGoalsInfoList,
} from "../../../../../types/DashBoard/Achievement";
import { EmployeeInfo } from "../../../../../types/Employee/Employee";

type AdminEmployeeSectionProps = {
  id: number;
  list: EmployeeInfo[];
  allEmployeeGoalList: EmployeeGoalsInfoList;
  getAllGroupAndEmployeeGoalInfo: () => Promise<void>;
};
const AdminEmployeeSection = ({
  id,
  list,
  allEmployeeGoalList,
  getAllGroupAndEmployeeGoalInfo,
}: AdminEmployeeSectionProps) => {
  const [columnList, setColumnList] = useState(
    useGenerateEditColumnList([
      { key: "name", value: "이름", isEdit: false, width: "40px" },
      { key: "salesAmount", value: "매출액", isEdit: true },
      { key: "newLeadsInbound", value: "신규 인바운드", isEdit: true },
      { key: "newLeadsOutbound", value: "신규 아웃바운드", isEdit: true },
      {
        key: "inboundLeadConversion",
        value: "인바운드<br/>리드전환",
        isEdit: true,
      },
      {
        key: "outboundLeadConversion",
        value: "아웃바운드<br/>리드전환",
        isEdit: true,
      },
    ])
  );
  const [groupEmployeeList, setGroupEmployeeList] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // 모달
  const [isOpen, setIsOpen] = useState(false);

  // Convert RowList
  const convertedRowList = useCallback(
    (data) => {
      // TODO: groupEmployeeList와 any 문제 해결하기
      const result = data.map((item: any) => {
        const newItem = { ...item }; // item의 기존 값 유지

        // allEmployeeGoalList에서 id가 같은 항목 찾기
        const goalItem = allEmployeeGoalList.find(
          ({ employee }) => employee.id === item.id
        );

        // goalItem이 없으면, 설정되지 않은 인원.
        newItem.goalIsSet = goalItem ? true : false;

        if (goalItem) newItem["goalId"] = goalItem.id;

        columnList.forEach(({ key }) => {
          // groupEmployeeList에서 해당 항목의 값 가져오기
          const existingValue = newItem[key]; // groupEmployeeList에서 가져오기

          if (existingValue !== undefined) {
            // groupEmployeeList에 값이 있을 경우
            newItem[key] = existingValue;
          } else if (goalItem && key in goalItem) {
            // groupEmployeeList에 없고 allEmployeeGoalList에서 id가 같은 항목이 있는 경우
            newItem[key] = goalItem[key as keyof EmployeeGoalResponse]; // allEmployeeGoalList에서 값 가져오기
          } else {
            // 두 곳 모두에 값이 없을 경우 0으로 초기화
            newItem[key] = 0;
          }
        });
        return newItem;
      });
      return result;
    },
    [columnList, allEmployeeGoalList]
  );

  // 그룹에 속한 팀원 조회
  const getGroupEmployeeList = useCallback(async () => {
    if (id > 0) {
      try {
        const params = { groupId: id };
        const { data } = await GroupApi.findGroupEmployee(params);
        setGroupEmployeeList(convertedRowList(data));
      } catch (err) {
        const message = ErrorHandler(err as AxiosError);
        setErrorMessage(message);
      }
    }
  }, [convertedRowList, id]);

  // AddModal Toggle
  const handleAddModal = useCallback((status: boolean) => {
    setIsOpen(status);
  }, []);

  useEffect(() => {
    getGroupEmployeeList();
  }, [getGroupEmployeeList]);

  return (
    <>
      <AdminGroupItemBox container>
        <AdminEmployeeSectionTitle
          employeeList={groupEmployeeList}
          handleAddModal={handleAddModal}
        />
        <AdminEmployeeTable
          groupId={id}
          columnList={columnList}
          rowList={groupEmployeeList}
          errorMessage={errorMessage}
          fetchEmployeeList={getAllGroupAndEmployeeGoalInfo}
        />
      </AdminGroupItemBox>
      <AdminEmployeeAddModal
        isOpen={isOpen}
        handleAddModal={handleAddModal}
        id={id}
        employeeList={list}
        groupEmployeeList={groupEmployeeList}
        fetchEmployeeList={getGroupEmployeeList}
      />
    </>
  );
};

export default AdminEmployeeSection;
