import { CustomText } from "../../../styles/CustomText";
import AdminUserTable from "./components/AdminUserTable";

function AdminUserPage() {
  return (
    <>
      <CustomText type="title"> 사용자 관리</CustomText>
      <AdminUserTable />
    </>
  );
}

export default AdminUserPage;
