export interface PortletStatus {
  id: number;
  title: string;
  width: string;
  justifyContent?: "center" | "flex-start" | "flex-end";
  alignItems?: "center" | "flex-start" | "flex-end";
}
export type PortletStatusList = PortletStatus[];

export enum DisplayMode {
  FIRST = "firstMode",
  SECOND = "secondMode",
}

export interface SwitchPortletContentMapProps {
  id: number;
  displayMode: DisplayMode;
}
